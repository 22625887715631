import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceEntity = AuditEntity & {
  __typename?: 'APAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApAllocationEntity = AuditEntity & {
  __typename?: 'APAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
};

export type ApCreditNoteEntity = AuditEntity & {
  __typename?: 'APCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApCreditNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APCreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<ApCreditNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APCreditNoteTaxItem?: Maybe<Array<ApCreditNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApCreditNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APCreditNoteItemID: Scalars['String'];
  APCreditNoteItem?: Maybe<ApCreditNoteItemEntity>;
};

export type ApDebitNoteEntity = AuditEntity & {
  __typename?: 'APDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApDebitNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APDebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<ApDebitNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APDebitNoteTaxItem?: Maybe<Array<ApDebitNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApDebitNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APDebitNoteItemID: Scalars['String'];
  APDebitNoteItem?: Maybe<ApDebitNoteItemEntity>;
};

export type ApeInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'APEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type ApInvoiceEntity = AuditEntity & {
  __typename?: 'APInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  discountAmt: Scalars['Float'];
  otherChargeAmt: Scalars['Float'];
  /** CustomFieldResolver */
  invoiceAmt?: Maybe<Scalars['Float']>;
  grnMatch?: Maybe<Array<Scalars['JSON']>>;
  supplierAllocation: Array<ApAllocationEntity>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  pendingPayment?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  invoiceDo?: Maybe<Array<DoEntity>>;
  /** CustomFieldResolver */
  outstanding?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApInvoiceItemEntity = AuditEntity & {
  __typename?: 'APInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APInvoiceID: Scalars['String'];
  APInvoice?: Maybe<ApInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  doItemID?: Maybe<Scalars['String']>;
  doItem?: Maybe<DoItemEntity>;
  withDO: Scalars['Boolean'];
  APInvoiceTaxItem?: Maybe<Array<ApInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'APInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APInvoiceItemID: Scalars['String'];
  APInvoiceItem?: Maybe<ApInvoiceItemEntity>;
};

export type ApPaymentEntity = AuditEntity & {
  __typename?: 'APPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  bankAccount?: Maybe<BankAccountEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  supplierAllocation: Array<ApAllocationEntity>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApRefundEntity = AuditEntity & {
  __typename?: 'APRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  VOSum: Scalars['String'];
  claimSum: Scalars['String'];
  originalClaimSum: Scalars['String'];
  adjustmentSum: Scalars['String'];
  retentionSum: Scalars['String'];
  contractSum: Scalars['String'];
  progressRetention: Scalars['String'];
  maxRetention: Scalars['String'];
  expectedRetentionReleaseDate: Scalars['String'];
  cpcDate: Scalars['String'];
  cmgdDate: Scalars['String'];
  penaltyAmt: Scalars['String'];
  revisedContractSum: Scalars['String'];
  billtoDate: Scalars['String'];
  maxRetentionAmt: Scalars['String'];
  balancetoClaim: Scalars['String'];
  name: Scalars['String'];
  retentionReleaseSum: Scalars['String'];
  taxCode: Scalars['String'];
  customerID: Scalars['String'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ActivityLogInput = {
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum ActivityStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentItem = AuditEntity & {
  __typename?: 'AdjustmentItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type AdjustmentTaxItem = AuditEntity & {
  __typename?: 'AdjustmentTaxItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
};

export type AdjustmentTypeEntity = AuditEntity & {
  __typename?: 'AdjustmentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  commonStatus: CommonStatus;
  glAdjustmentDynamic?: Maybe<Array<GlAdjustmentDynamicEntity>>;
  contractDn?: Maybe<Array<ContractDnEntity>>;
  contractCn?: Maybe<Array<ContractCnEntity>>;
  clientDn?: Maybe<Array<ClientDnEntity>>;
  clientCn?: Maybe<Array<ClientCnEntity>>;
  subcontractDn?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCn?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  apDn?: Maybe<Array<ApDebitNoteEntity>>;
  apCn?: Maybe<Array<ApCreditNoteEntity>>;
  dnToSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
};

export type AdjustmentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type AlertAssignmentEntity = AuditEntity & {
  __typename?: 'AlertAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  alertID: Scalars['String'];
  alert?: Maybe<AlertEntity>;
  userID: Scalars['String'];
  reminder?: Maybe<Scalars['String']>;
  isAdminControl: Scalars['Boolean'];
};

export type AlertAssignmentInput = {
  alertID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
};

export type AlertCountInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sqlCode: Scalars['String'];
};

export type AlertEntity = AuditEntity & {
  __typename?: 'AlertEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlCode: Scalars['String'];
  commonStatus: CommonStatus;
  alertAssignment?: Maybe<Array<AlertAssignmentEntity>>;
  /** CustomFieldResolver */
  defaultFromAdmin?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  alertCount?: Maybe<Scalars['Float']>;
};

export type AlertInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  reminder: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum AllocationStatus {
  Credit = 'CREDIT',
  Retention = 'RETENTION',
  Pending = 'PENDING'
}

export enum ApprovalLevel {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH'
}

export type ApprovalList = {
  __typename?: 'ApprovalList';
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalListInput = {
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalLog = {
  __typename?: 'ApprovalLog';
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalLogInput = {
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  listlength?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyID: Scalars['String'];
  approvalPolicy?: Maybe<ApprovalPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  latestLimitAssignment: Scalars['JSON'];
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  latestLimit: Scalars['JSON'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
};

export type ApprovalPolicyList = {
  stepNo?: Maybe<Scalars['Float']>;
  predicate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED'
}

export type ApprovePaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  batchPaymentDetailInput: Array<BatchPaymentDetailInput>;
};

export type ApprovedVendorsEntity = AuditEntity & {
  __typename?: 'ApprovedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierName: Scalars['String'];
  registrationNo: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['JSON'];
  email: Scalars['String'];
  gstRegNo: Scalars['String'];
  gstExpDate: Scalars['String'];
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
};

export enum AsgMainStatus {
  Active = 'ACTIVE',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type AssignmentEntity = AuditEntity & {
  __typename?: 'AssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  assignmentRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  assignmentStatus: AsgMainStatus;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export enum AssociatedStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BqEntity = AuditEntity & {
  __typename?: 'BQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  description: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  isLastNode: Scalars['Boolean'];
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  childrenBq?: Maybe<Array<BqEntity>>;
  parentBq?: Maybe<BqEntity>;
  /** CustomFieldResolver */
  numOfWbsBudgetDetail?: Maybe<Scalars['Float']>;
};


export type BqEntityChildrenBqArgs = {
  wbsID?: Maybe<Scalars['String']>;
};

export type BqWbsAssignmentEntity = AuditEntity & {
  __typename?: 'BQWbsAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  bqID: Scalars['String'];
  bq?: Maybe<BqEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
};

export type BankAccountCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountDeleteInput = {
  ID: Scalars['String'];
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  status: BankAccountStatus;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  contract?: Maybe<Array<ContractEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  subcontractAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  subcontractPayment?: Maybe<Array<SubcontractPaymentEntity>>;
  subcontractRefund?: Maybe<Array<SubcontractRefundEntity>>;
  APAdvance?: Maybe<Array<ApAdvanceEntity>>;
  APPayment?: Maybe<Array<ApPaymentEntity>>;
  batchPaymentHeader?: Maybe<Array<BatchPaymentHeaderEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  contactNo: Scalars['String'];
  companyID: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  address: AddressInput;
  contactPerson: Array<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BankAccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

/** JsonType */
export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BatchPaymentDetailDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentDetailEntity = AuditEntity & {
  __typename?: 'BatchPaymentDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  batchPaymentHeaderID: Scalars['String'];
  batchPaymentHeader?: Maybe<BatchPaymentHeaderEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  associateRefID: Scalars['String'];
  paymentAmt: Scalars['Float'];
  APPaymentID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ledgerInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  associateRefTable?: Maybe<Scalars['String']>;
  associateRefID?: Maybe<Scalars['String']>;
  paymentAmt?: Maybe<Scalars['Float']>;
  APPaymentID?: Maybe<Scalars['String']>;
};

export type BatchPaymentHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentHeaderEntity = AuditEntity & {
  __typename?: 'BatchPaymentHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
  submittedDate?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['String']>;
  paidBy?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  batchPaymentDetail?: Maybe<Array<BatchPaymentDetailEntity>>;
  /** CustomFieldResolver */
  totalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  batchPaymentAssocDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  batchPaymentDocNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  bankAccountID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BillInterestEntity = AuditEntity & {
  __typename?: 'BillInterestEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerEntity>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docRefTable?: Maybe<Scalars['String']>;
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BudgetChecker {
  Ignore = 'IGNORE',
  Warning = 'WARNING',
  Block = 'BLOCK'
}

export enum BudgetStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export enum BudgetType {
  Cost = 'COST',
  Revenue = 'REVENUE'
}

export type BulkSubcontractorInput = {
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  costCategoryID?: Maybe<Scalars['String']>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type BulkSupplierInput = {
  currencyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  bankDetails?: Maybe<BankDetailsInput>;
  creditorAC?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CbExportDetailEntity = AuditEntity & {
  __typename?: 'CBExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  cbExportID: Scalars['String'];
  bankAccountID: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  clientName: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExport?: Maybe<CbExportEntity>;
};

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExportDetail?: Maybe<Array<CbExportDetailEntity>>;
};

export type CNtoSubconEntity = AuditEntity & {
  __typename?: 'CNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  chargeToRevenue: Scalars['Boolean'];
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSubconItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  CNtoSubconID: Scalars['String'];
  CNtoSubcon?: Maybe<CNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSubconTaxItem?: Maybe<Array<CNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  CNtoSubconItemID: Scalars['String'];
  CNtoSubconItem?: Maybe<CNtoSubconItemEntity>;
};

export type CNtoSupplierEntity = AuditEntity & {
  __typename?: 'CNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  CNtoSupplierID: Scalars['String'];
  CNtoSupplier?: Maybe<CNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSupplierTaxItem?: Maybe<Array<CNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  CNtoSupplierItemID: Scalars['String'];
  CNtoSupplierItem?: Maybe<CNtoSupplierItemEntity>;
};

export type CalendarDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarEntity = AuditEntity & {
  __typename?: 'CalendarEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  stateID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
  isDefault: Scalars['Boolean'];
  weekend: Scalars['JSON'];
  commonStatus: CommonStatus;
  publicHolConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  holidayType?: Maybe<HolidayTypeEntity>;
  publicHoliday: Array<PublicHolidayEntity>;
  costCategory: Array<CalendarEntity>;
};

export type CalendarInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  state?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  weekend?: Maybe<Scalars['JSON']>;
};

export type CalendarPhPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarPhPolicyEntity = AuditEntity & {
  __typename?: 'CalendarPHPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  publicHolidayID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  calendar?: Maybe<CalendarEntity>;
  publicHoliday?: Maybe<PublicHolidayEntity>;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type CalendarPhPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  publicHolidayID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
};

export type CalendarWeekendPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarWeekendPolicyEntity = AuditEntity & {
  __typename?: 'CalendarWeekendPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  daysOfWeek: DaysofWeek;
};

export type CalendarWeekendPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  daysOfWeek: DaysofWeek;
};

export type CancelInput = {
  companyID?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
};

export type CashFlowEntity = AuditEntity & {
  __typename?: 'CashFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CheckingInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChecklistCategoryDeleteInput = {
  ID: Scalars['String'];
};

export type ChecklistCategoryEntity = AuditEntity & {
  __typename?: 'ChecklistCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
};

export type ChecklistCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type CidbDetails = {
  __typename?: 'CidbDetails';
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type CidbDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimDetailsInput = {
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ClaimLog = {
  __typename?: 'ClaimLog';
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimLogInput = {
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  previousFinalClaim?: Maybe<Scalars['Float']>;
  previousClaim?: Maybe<Scalars['Float']>;
  previousMOS?: Maybe<Scalars['Float']>;
  previousVOClaim?: Maybe<Scalars['Float']>;
  previousDeduction?: Maybe<Scalars['Float']>;
  previousDayWork?: Maybe<Scalars['Float']>;
  retentionPerc?: Maybe<Scalars['Float']>;
  previousRetentionAmt?: Maybe<Scalars['Float']>;
  previousRetentionSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
  advanceSum?: Maybe<Scalars['Float']>;
  advanceRecouped?: Maybe<Scalars['Float']>;
  advanceBalance?: Maybe<Scalars['Float']>;
  deductionSum?: Maybe<Scalars['Float']>;
  deductionRecouped?: Maybe<Scalars['Float']>;
  deductionBalance?: Maybe<Scalars['Float']>;
  checkPendingSubmit?: Maybe<Scalars['Boolean']>;
  previousDocDate?: Maybe<Scalars['DateTime']>;
  submittedDocDate?: Maybe<Scalars['DateTime']>;
};

export type ClientCnEntity = AuditEntity & {
  __typename?: 'ClientCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientCnItemEntity = AuditEntity & {
  __typename?: 'ClientCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientCNID: Scalars['String'];
  clientCN?: Maybe<ClientCnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientCNTaxItem?: Maybe<Array<ClientCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientCnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientCNItemID: Scalars['String'];
  clientCNItem?: Maybe<ClientCnItemEntity>;
};

export type ClientConAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientConAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientContractAccReceipt = AuditEntity & {
  __typename?: 'ClientContractAccReceipt';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientDnEntity = AuditEntity & {
  __typename?: 'ClientDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientDnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientDnItemEntity = AuditEntity & {
  __typename?: 'ClientDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientDNID: Scalars['String'];
  clientDN?: Maybe<ClientDnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientDNTaxItem?: Maybe<Array<ClientDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientDnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientDNItemID: Scalars['String'];
  clientDNItem?: Maybe<ClientDnItemEntity>;
};

export type ClientEInvoiceAccTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type ClientSoaInfo = {
  __typename?: 'ClientSOAInfo';
  totalOsIvCount?: Maybe<Scalars['Float']>;
  totalOsIvAmt?: Maybe<Scalars['Float']>;
};

export type CollectionActivityDeleteId = {
  ID?: Maybe<Scalars['String']>;
};

export type CollectionActivityEntity = AuditEntity & {
  __typename?: 'CollectionActivityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contactID?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus: ActivityStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
};

export type CollectionActivityInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus?: Maybe<ActivityStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CollectionActivityStatusInput = {
  ID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CommonBaseInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type Company = {
  __typename?: 'Company';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  OfficeTel?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  TourismTaxNo?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  RecordStatus: Scalars['String'];
  companyMsicAssignment?: Maybe<Array<CompanyMsicAssignmentEntity>>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  FileImage?: Maybe<Scalars['Upload']>;
};

export type CompanyLogoInput = {
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
};

export type CompanyMsicAssignmentEntity = AuditEntity & {
  __typename?: 'CompanyMsicAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  eInvoiceMsicCodeID: Scalars['String'];
  eInvoiceMsicCode?: Maybe<EInvoiceMsicEntity>;
  isDefault: Scalars['Boolean'];
};

export type CompanySignatureEntity = AuditEntity & {
  __typename?: 'CompanySignatureEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  documentType: Scalars['String'];
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ConDocTypeEntity = AuditEntity & {
  __typename?: 'ConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: ContractDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ConNumberFormatEntity>;
  docNumHeader: Array<DocNumHeaderEntity>;
};

export type ConNumberFormatEntity = AuditEntity & {
  __typename?: 'ConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  docType: ConDocTypeEntity;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  prefixExtra?: Maybe<Scalars['String']>;
  runningNum: Array<ConRunningNumberSetupEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type ConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ConNumberFormatEntity;
};

export type ConTaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'ConTaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  date?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Float'];
};

export type ConTaxEffectiveDateInput = {
  taxSchemeID: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
};

export type ConTaxEffectiveDateRateInput = {
  effectiveDate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ConTaxSchemeEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  effectiveDateRate: Array<ConTaxEffectiveDateRateInput>;
};

export type ConTaxSchemeEntity = AuditEntity & {
  __typename?: 'ConTaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxCategory: TaxCategory;
  taxClass: TaxClass;
  isClaimable: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  taxEffective?: Maybe<Array<ConTaxEffectiveDateEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  revenueCategory?: Maybe<Array<RevenueCategoryEntity>>;
  miscExpense?: Maybe<Array<MiscExpenseEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  customer?: Maybe<Array<CustomerEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  approvedVendors?: Maybe<Array<ApprovedVendorsEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
};


export type ConTaxSchemeEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type ConTaxSchemeInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ConTaxTypeEntity = AuditEntity & {
  __typename?: 'ConTaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxScheme?: Maybe<Array<ConTaxSchemeEntity>>;
  glTaxDynamic?: Maybe<Array<GlTaxDynamicEntity>>;
};

export type ConTaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtProjectWbsBudgettingView = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______VIEW',
  ContractMgmtProjectWbsBudgettingCreate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CREATE',
  ContractMgmtProjectWbsBudgettingUpdate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPDATE',
  ContractMgmtProjectWbsBudgettingDelete = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______DELETE',
  ContractMgmtProjectWbsBudgettingCancel = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CANCEL',
  ContractMgmtProjectWbsBudgettingMoveDown = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_DOWN',
  ContractMgmtProjectWbsBudgettingMoveUp = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_UP',
  ContractMgmtProjectWbsBudgettingUpload = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPLOAD',
  ContractMgmtWbsDayworkView = 'CONTRACT_MGMT_WBS_DAYWORK_______VIEW',
  ContractMgmtWbsDayworkUpdate = 'CONTRACT_MGMT_WBS_DAYWORK_______UPDATE',
  ContractMgmtBudgetRevisionCreate = 'CONTRACT_MGMT_BUDGET_REVISION_______CREATE',
  ContractMgmtBudgetRevisionUpdate = 'CONTRACT_MGMT_BUDGET_REVISION_______UPDATE',
  ContractMgmtBudgetRevisionDelete = 'CONTRACT_MGMT_BUDGET_REVISION_______DELETE',
  ContractMgmtBudgetRevisionCancel = 'CONTRACT_MGMT_BUDGET_REVISION_______CANCEL',
  ContractMgmtBudgetRevisionView = 'CONTRACT_MGMT_BUDGET_REVISION_______VIEW',
  ContractMgmtUtilizedBudgetView = 'CONTRACT_MGMT_UTILIZED_BUDGET_______VIEW',
  ContractMgmtUtilizedBudgetCreate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CREATE',
  ContractMgmtUtilizedBudgetUpdate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______UPDATE',
  ContractMgmtUtilizedBudgetDelete = 'CONTRACT_MGMT_UTILIZED_BUDGET_______DELETE',
  ContractMgmtUtilizedBudgetCancel = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CANCEL',
  ContractMgmtBudgetTransferView = 'CONTRACT_MGMT_BUDGET_TRANSFER_______VIEW',
  ContractMgmtBudgetTransferCreate = 'CONTRACT_MGMT_BUDGET_TRANSFER_______CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeDelete = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceDelete = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ContractMgmtContractAprovalPolicyIcon = 'CONTRACT_MGMT_CONTRACT_APROVAL_POLICY_______ICON',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ProfitRecognitionContractApprovalPolicyIcon = 'PROFIT_RECOGNITION_CONTRACT_APPROVAL_POLICY__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctCreditControlOutstandingCollectionView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION____VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_CREATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_UPDATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_RESCHEDULE',
  ClientAcctCreditControlCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____VIEW',
  ClientAcctCreditControlCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____UPDATE',
  ClientAcctCreditControlCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____RESCHEDULE',
  ClientAcctCreditControlCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____CREATE',
  ClientAcctCreditControlPaymentReminderView = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____VIEW',
  ClientAcctCreditControlPaymentReminderDownload = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____DOWNLOAD',
  ClientAcctCreditControlPaymentReminderCreate = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____CREATE',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByContractParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____REPORTING',
  ClientAcctDigitalReportingClientAgingSummaryParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____PARAMETER',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____REPORTING',
  ClientAcctDigitalReportingStatementOfAccountParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____PARAMETER',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctDigitalReportingStatementOfAccountReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____REPORTING',
  ClientAcctDigitalReportingClientTaxLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientTaxLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientTaxLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingRetentionLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_RETENTION_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientRetentionLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_RETENTION_LEDGER_REPORT____VIEW',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  ClientAcctContractApprovalPolicyIcon = 'CLIENT_ACCT_CONTRACT_APPROVAL_POLICY_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeDelete = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceDelete = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubcontractsContractApprovalPolicyIcon = 'SUBCONTRACTS_CONTRACT_APPROVAL_POLICY____ICON',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountDownload = 'SUBCON_ACCOUNT__________DOWNLOAD',
  SubconAccountUpload = 'SUBCON_ACCOUNT__________UPLOAD',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesAllocation = 'SUBCON_ACCOUNT_CREDIT_NOTES_______ALLOCATION',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____PARAMETER',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____REPORTING',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorControlSummaryView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____PARAMETER',
  SubconAccountDigitalReportingSubcontractorControlSummaryReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____REPORTING',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____VIEW',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____PARAMETER',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubconAccountContractApprovalPolicyIcon = 'SUBCON_ACCOUNT_CONTRACT_APPROVAL_POLICY_______ICON',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchasePurchaseRequisitionDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseDeliveryOrderClose = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CLOSE',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteClose = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CLOSE',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______PARAMETER',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______REPORTING',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingLateDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPricingHistoryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______REPORTING',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  ProjectPurchaseContractApprovalPolicyIcon = 'PROJECT_PURCHASE_CONTRACT_APPROVAL_POLICY_______ICON',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfileDownload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DOWNLOAD',
  SupplierAcctSupplierProfileUpload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPLOAD',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsCancel = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CANCEL',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAdvanceDepositsAllocation = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesApproveReject = 'SUPPLIER_ACCT_INVOICES____APPROVE_REJECT',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierControlSummaryReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____REPORTING',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SupplierAcctContractApprovalPolicyIcon = 'SUPPLIER_ACCT_CONTRACT_APPROVAL_POLICY_______ICON',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingMiscellaneousExpenseDelete = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectCostingMiscellaneousExpenseApproveReject = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____APPROVE_REJECT',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______PARAMETER',
  ProjectCostingDigitalReportExecutiveSummaryReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectCostAnalysisReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectLedgerReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______REPORTING',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryFuelConsumptionView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_______VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____CREATE',
  PlantMachineryFuelConsumptionFuelTankSetupUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____UPDATE',
  PlantMachineryFuelConsumptionFuelTankSetupDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____DELETE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____VIEW',
  PlantMachineryFuelConsumptionFuelTankMeterReadingCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____CREATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____UPDATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____VIEW',
  PlantMachineryFuelConsumptionFuelSupplyCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____CREATE',
  PlantMachineryFuelConsumptionFuelSupplyUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____UPDATE',
  PlantMachineryFuelConsumptionFuelSupplyDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____APPROVE',
  PlantMachineryFuelConsumptionFuelSupplyReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____REJECT',
  PlantMachineryFuelConsumptionFuelDischargeView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____VIEW',
  PlantMachineryFuelConsumptionFuelDischargeCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____CREATE',
  PlantMachineryFuelConsumptionFuelDischargeUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____UPDATE',
  PlantMachineryFuelConsumptionFuelDischargeDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____DELETE',
  PlantMachineryFuelConsumptionFuelDischargeApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____APPROVE',
  PlantMachineryFuelConsumptionFuelDischargeReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____REJECT',
  PlantMachineryDigitalReportingView = 'PLANT_MACHINERY_DIGITAL_REPORTING____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionDetailReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_DETAIL_REPORT____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionSummaryReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_SUMMARY_REPORT____VIEW',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceDigitalReportingView = 'GL_INTERFACE_DIGITAL_REPORTING_______VIEW',
  GlInterfaceDigitalReportingTaxListingReportView = 'GL_INTERFACE_DIGITAL_REPORTING_TAX_LISTING_REPORT____VIEW',
  GlInterfaceDigitalReportingGlTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GL_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingFiTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_FI_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingGstSummaryReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GST_SUMMARY_REPORT____VIEW',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsCurrencySetupView = 'COMMON_SETTINGS_CURRENCY_SETUP_______VIEW',
  CommonSettingsCurrencySetupCreate = 'COMMON_SETTINGS_CURRENCY_SETUP_______CREATE',
  CommonSettingsCurrencySetupUpdate = 'COMMON_SETTINGS_CURRENCY_SETUP_______UPDATE',
  CommonSettingsCurrencySetupDelete = 'COMMON_SETTINGS_CURRENCY_SETUP_______DELETE',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE',
  CommonSettingsAlertAssignmentView = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______VIEW',
  CommonSettingsAlertAssignmentUpdate = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______UPDATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupCompanyProfileView = 'COMPANY_SETUP_COMPANY_PROFILE_______VIEW',
  CompanySetupCompanyProfileUpdate = 'COMPANY_SETUP_COMPANY_PROFILE_______UPDATE',
  CompanySetupDocumentNumberingSetupView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_______VIEW',
  CompanySetupDocumentNumberingSetupAccountReceivableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  CompanySetupDocumentNumberingSetupAccountPayableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  CompanySetupDocumentNumberingSetupProjectPurchaseView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  CompanySetupDocumentNumberingSetupSiteManagementView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  CompanySetupBankAccountView = 'COMPANY_SETUP_BANK_ACCOUNT_______VIEW',
  CompanySetupBankAccountCreate = 'COMPANY_SETUP_BANK_ACCOUNT_______CREATE',
  CompanySetupBankAccountUpdate = 'COMPANY_SETUP_BANK_ACCOUNT_______UPDATE',
  CompanySetupBankAccountActive = 'COMPANY_SETUP_BANK_ACCOUNT_______ACTIVE',
  CompanySetupBankAccountInactive = 'COMPANY_SETUP_BANK_ACCOUNT_______INACTIVE',
  CompanySetupBankAccountDelete = 'COMPANY_SETUP_BANK_ACCOUNT_______DELETE',
  CompanySetupWatermarkLogoSetupUpdate = 'COMPANY_SETUP_WATERMARK_LOGO_SETUP_______UPDATE',
  CompanySetupCompanyAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_______VIEW',
  CompanySetupCompanyAssignmentUserAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentUserAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentUserAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSupplierAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentClientAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSubcontractorAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____UNASSIGN',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______ACTIVE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  GeneralSettingsCostCategoryView = 'GENERAL_SETTINGS_COST_CATEGORY_______VIEW',
  GeneralSettingsCostCategoryCreate = 'GENERAL_SETTINGS_COST_CATEGORY_______CREATE',
  GeneralSettingsCostCategoryUpdate = 'GENERAL_SETTINGS_COST_CATEGORY_______UPDATE',
  GeneralSettingsCostCategoryActive = 'GENERAL_SETTINGS_COST_CATEGORY_______ACTIVE',
  GeneralSettingsCostCategoryInactive = 'GENERAL_SETTINGS_COST_CATEGORY_______INACTIVE',
  GeneralSettingsCostCategoryDelete = 'GENERAL_SETTINGS_COST_CATEGORY_______DELETE',
  GeneralSettingsCostCategoryDownload = 'GENERAL_SETTINGS_COST_CATEGORY_______DOWNLOAD',
  GeneralSettingsCostCategoryUpload = 'GENERAL_SETTINGS_COST_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  GeneralSettingsLabourCategoryCreate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  GeneralSettingsLabourCategoryUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  GeneralSettingsLabourCategoryActive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______ACTIVE',
  GeneralSettingsLabourCategoryInactive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  GeneralSettingsLabourCategoryDelete = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  GeneralSettingsLabourCategoryUpload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryDownload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______ACTIVE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  GeneralSettingsMiscellaneousExpenseView = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  GeneralSettingsMiscellaneousExpenseCreate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  GeneralSettingsMiscellaneousExpenseUpdate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  GeneralSettingsMiscellaneousExpenseActive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______ACTIVE',
  GeneralSettingsMiscellaneousExpenseInactive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  GeneralSettingsMiscellaneousExpenseDelete = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  GeneralSettingsMiscellaneousExpenseUpload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  GeneralSettingsMiscellaneousExpenseDownload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  GeneralSettingsGuaranteeTypeView = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  GeneralSettingsGuaranteeTypeCreate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  GeneralSettingsGuaranteeTypeUpdate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  GeneralSettingsGuaranteeTypeInactive = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  GeneralSettingsGuaranteeTypeDelete = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  GeneralSettingsSupplierTypeView = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______VIEW',
  GeneralSettingsSupplierTypeCreate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______CREATE',
  GeneralSettingsSupplierTypeUpdate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______UPDATE',
  GeneralSettingsSupplierTypeDelete = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______DELETE',
  GeneralSettingsSupplierTypeActive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______ACTIVE',
  GeneralSettingsSupplierTypeInactive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______INACTIVE',
  GeneralSettingsSubcontractorTypeView = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______VIEW',
  GeneralSettingsSubcontractorTypeCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______CREATE',
  GeneralSettingsSubcontractorTypeUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______UPDATE',
  GeneralSettingsSubcontractorTypeDelete = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______DELETE',
  GeneralSettingsSubcontractorTypeActive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______ACTIVE',
  GeneralSettingsSubcontractorTypeInactive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeView = 'GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  GeneralSettingsInsuranceTypeCreate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  GeneralSettingsInsuranceTypeUpdate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  GeneralSettingsInsuranceTypeInactive = 'GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeDelete = 'GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyCreate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsPmCategoryView = 'GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  GeneralSettingsPmCategoryCreate = 'GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  GeneralSettingsPmCategoryUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  GeneralSettingsPmCategoryActive = 'GENERAL_SETTINGS_PM_CATEGORY_______ACTIVE',
  GeneralSettingsPmCategoryInactive = 'GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  GeneralSettingsPmCategoryDelete = 'GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  GeneralSettingsPmCategoryUpload = 'GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  GeneralSettingsPmCategoryDownload = 'GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  GeneralSettingsWorkshopMaintenanceView = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  GeneralSettingsWorkshopMaintenanceCreate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  GeneralSettingsWorkshopMaintenanceUpdate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  GeneralSettingsClientAccountSetupView = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsClientAccountSetupCreate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsClientAccountSetupUpdate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsClientAccountSetupAssign = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsClientAccountSetupDownload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsClientAccountSetupUpload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSubcontractorAccountSetupView = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSubcontractorAccountSetupCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSubcontractorAccountSetupUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSubcontractorAccountSetupAssign = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSubcontractorAccountSetupDownload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSubcontractorAccountSetupUpload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSupplierAccountSetupView = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSupplierAccountSetupCreate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSupplierAccountSetupUpdate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSupplierAccountSetupAssign = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSupplierAccountSetupDownload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSupplierAccountSetupUpload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsPurchaseAccountSetupView = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPurchaseAccountSetupUpdate = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsPobAccountSetupView = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPobAccountSetupUpdate = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsBankAccountSetupView = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsBankAccountSetupUpdate = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTaxAccountSetupView = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTaxAccountSetupUpdate = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTradeAccountSetupView = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTradeAccountSetupUpdate = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsMaterialCategorySetupView = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMaterialCategorySetupUpdate = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsPmCategorySetupView = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______VIEW',
  GeneralSettingsPmCategorySetupUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsLabourCategorySetupView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______VIEW',
  GeneralSettingsLabourCategorySetupUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsMiscCategorySetupView = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMiscCategorySetupUpdate = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsJournalTypeSetupView = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______VIEW',
  GeneralSettingsJournalTypeSetupUpdate = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  GeneralSettingsGlAccountCodeSetupView = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______VIEW',
  GeneralSettingsGlAccountCodeSetupCreate = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsRevenueCategoryView = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______VIEW',
  GeneralSettingsRevenueCategoryCreate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______CREATE',
  GeneralSettingsRevenueCategoryUpdate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPDATE',
  GeneralSettingsRevenueCategoryActive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______ACTIVE',
  GeneralSettingsRevenueCategoryInactive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______INACTIVE',
  GeneralSettingsRevenueCategoryDelete = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DELETE',
  GeneralSettingsRevenueCategoryDownload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DOWNLOAD',
  GeneralSettingsRevenueCategoryUpload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPLOAD',
  GeneralSettingsLabourItemsView = 'GENERAL_SETTINGS_LABOUR_ITEMS_______VIEW',
  GeneralSettingsLabourItemsCreate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______CREATE',
  GeneralSettingsLabourItemsUpdate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPDATE',
  GeneralSettingsLabourItemsActive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______ACTIVE',
  GeneralSettingsLabourItemsInactive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______INACTIVE',
  GeneralSettingsLabourItemsDelete = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DELETE',
  GeneralSettingsLabourItemsDownload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DOWNLOAD',
  GeneralSettingsLabourItemsUpload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPLOAD',
  GeneralSettingsMaterialItemsView = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______VIEW',
  GeneralSettingsMaterialItemsCreate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______CREATE',
  GeneralSettingsMaterialItemsUpdate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPDATE',
  GeneralSettingsMaterialItemsActive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______ACTIVE',
  GeneralSettingsMaterialItemsInactive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______INACTIVE',
  GeneralSettingsMaterialItemsDelete = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DELETE',
  GeneralSettingsMaterialItemsDownload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DOWNLOAD',
  GeneralSettingsMaterialItemsUpload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPLOAD',
  GeneralSettingsPmItemsView = 'GENERAL_SETTINGS_PM_ITEMS_______VIEW',
  GeneralSettingsPmItemsCreate = 'GENERAL_SETTINGS_PM_ITEMS_______CREATE',
  GeneralSettingsPmItemsUpdate = 'GENERAL_SETTINGS_PM_ITEMS_______UPDATE',
  GeneralSettingsPmItemsActive = 'GENERAL_SETTINGS_PM_ITEMS_______ACTIVE',
  GeneralSettingsPmItemsInactive = 'GENERAL_SETTINGS_PM_ITEMS_______INACTIVE',
  GeneralSettingsPmItemsDelete = 'GENERAL_SETTINGS_PM_ITEMS_______DELETE',
  GeneralSettingsPmItemsDownload = 'GENERAL_SETTINGS_PM_ITEMS_______DOWNLOAD',
  GeneralSettingsPmItemsUpload = 'GENERAL_SETTINGS_PM_ITEMS_______UPLOAD',
  GeneralSettingsMiscExpenseItemsView = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______VIEW',
  GeneralSettingsMiscExpenseItemsCreate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______CREATE',
  GeneralSettingsMiscExpenseItemsUpdate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPDATE',
  GeneralSettingsMiscExpenseItemsActive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______ACTIVE',
  GeneralSettingsMiscExpenseItemsInactive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______INACTIVE',
  GeneralSettingsMiscExpenseItemsDelete = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DELETE',
  GeneralSettingsMiscExpenseItemsDownload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DOWNLOAD',
  GeneralSettingsMiscExpenseItemsUpload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPLOAD',
  GeneralSettingsPaymentReminderSettingView = 'GENERAL_SETTINGS_PAYMENT_REMINDER_SETTING_______VIEW',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GroupBusinessInsightIcon = 'GROUP_BUSINESS_INSIGHT__________ICON',
  GroupBusinessInsightView = 'GROUP_BUSINESS_INSIGHT__________VIEW',
  GroupBusinessInsightKeyKpiView = 'GROUP_BUSINESS_INSIGHT_KEY_KPI_______VIEW',
  GroupBusinessInsightSiteGalleryView = 'GROUP_BUSINESS_INSIGHT_SITE_GALLERY_______VIEW',
  GroupBusinessInsightProjectCostingView = 'GROUP_BUSINESS_INSIGHT_PROJECT_COSTING_______VIEW',
  GroupBusinessInsightProjectPlView = 'GROUP_BUSINESS_INSIGHT_PROJECT_PL_______VIEW',
  GroupBusinessInsightCashFlowView = 'GROUP_BUSINESS_INSIGHT_CASH_FLOW_______VIEW',
  GroupBusinessInsightArView = 'GROUP_BUSINESS_INSIGHT_AR_______VIEW',
  GroupBusinessInsightVoView = 'GROUP_BUSINESS_INSIGHT_VO_______VIEW',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON'
}

export type ContactEntity = AuditEntity & {
  __typename?: 'ContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  name: Scalars['String'];
  designation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  userDetail?: Maybe<PlatformUserEntity>;
  worker: WorkerEntity;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAccountEntity = AuditEntity & {
  __typename?: 'ContractAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  contractWbsID: Scalars['String'];
  contractWbs?: Maybe<WbsEntity>;
  revenueCategoryID: Scalars['String'];
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  subcontractWbsID: Scalars['String'];
  subcontractWbs?: Maybe<WbsEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
};

export type ContractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractStatus: ContractStatus;
};

export type ContractAdvanceEntity = AuditEntity & {
  __typename?: 'ContractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractAllocationEntity = AuditEntity & {
  __typename?: 'ContractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  contractCN: Array<ContractCnEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type ContractApprovalLogEntity = AuditEntity & {
  __typename?: 'ContractApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
  toDo?: Maybe<ContractToDoEntity>;
};

export type ContractBudget = {
  __typename?: 'ContractBudget';
  originalBudgetSum?: Maybe<Scalars['Float']>;
  revisedBudgetSum?: Maybe<Scalars['Float']>;
};

export type ContractCnEntity = AuditEntity & {
  __typename?: 'ContractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<ContractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractCnItemEntity = AuditEntity & {
  __typename?: 'ContractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractCNID: Scalars['String'];
  contractCN?: Maybe<ContractCnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractCNTaxItem?: Maybe<Array<ContractCnTaxItemEntity>>;
};

export type ContractCnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractCNItemID: Scalars['String'];
  contractCNItem?: Maybe<ContractCnItemEntity>;
};

export type ContractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractClaimAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractClaimAllocationEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ContractClaimEntity = AuditEntity & {
  __typename?: 'ContractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  claimDetailRevenueWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractClaimEntityRevenueAmtArgs = {
  withTax?: Maybe<Scalars['Boolean']>;
};


export type ContractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type ContractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
};

export type ContractClaimVoRevenueWbsAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVORevenueWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVORevenueWbsID: Scalars['String'];
  contractVORevenueWbs?: Maybe<ContractVoRevenueWbsEntity>;
};

export type ContractDnEntity = AuditEntity & {
  __typename?: 'ContractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractDnItemEntity = AuditEntity & {
  __typename?: 'ContractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractDNID: Scalars['String'];
  contractDN?: Maybe<ContractDnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractDNTaxItem?: Maybe<Array<ContractDnTaxItemEntity>>;
};

export type ContractDnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractDNItemID: Scalars['String'];
  contractDNItem?: Maybe<ContractDnItemEntity>;
};

export enum ContractDocNum {
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO',
  Fs = 'FS',
  Fd = 'FD',
  ProjectMiscExpense = 'PROJECT_MISC_EXPENSE',
  BudgetTransfer = 'BUDGET_TRANSFER'
}

export type ContractEntity = AuditEntity & {
  __typename?: 'ContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  companyID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  customerDetail?: Maybe<CustomerEntity>;
  bankAccountID?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  costCenterID?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenterEntity>;
  profitRecogFormulaID?: Maybe<Scalars['String']>;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contractNo: Scalars['String'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  department?: Maybe<Scalars['String']>;
  implementWbs: Scalars['Boolean'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  budgetChecker: BudgetChecker;
  contractStatus: ContractStatus;
  VO: Array<ContractVoEntity>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  negotiatedSupply?: Maybe<Array<NegotiatedSupplyEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  subcontractTenderDetail?: Maybe<Array<SubcontractTenderEntity>>;
  subcontractDetail?: Maybe<Array<SubcontractEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  projectBudget: Array<ProjectBudgetEntity>;
  site?: Maybe<Array<SiteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  defect?: Maybe<Array<DefectManagementEntity>>;
  siteProgress?: Maybe<Array<SiteProgressEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  profitRecogGeneration?: Maybe<Array<ProfitRecogGenerationEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  budget?: Maybe<Array<WbsBudgetEntity>>;
  wbs?: Maybe<Array<WbsEntity>>;
  projectExpense?: Maybe<Array<ProjectExpenseEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  contractMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  contractDN?: Maybe<Array<ContractDnEntity>>;
  contractCN?: Maybe<Array<ContractCnEntity>>;
  contractClientDN?: Maybe<Array<ClientDnEntity>>;
  contractClientCN?: Maybe<Array<ClientCnEntity>>;
  contractInv?: Maybe<Array<ApInvoiceEntity>>;
  contractDebitNote?: Maybe<Array<ApDebitNoteEntity>>;
  contractCreditNote?: Maybe<Array<ApCreditNoteEntity>>;
  contractDNSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  contractCNSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  apAdvance?: Maybe<Array<ApAdvanceEntity>>;
  apRefund?: Maybe<Array<ApRefundEntity>>;
  contractUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  wbsBudgetTransfer?: Maybe<Array<WbsBudgetTransferEntity>>;
  wbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  bq?: Maybe<Array<BqEntity>>;
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  docNumHeader?: Maybe<Array<DocNumHeaderEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  companyLoader?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  latestBudgetID?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  financialClientReceipt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  latestColAct?: Maybe<Scalars['JSON']>;
  latestCollectionActivity?: Maybe<CollectionActivityEntity>;
  /** CustomFieldResolver */
  contractAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractTenderSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  purchasingSummary?: Maybe<Scalars['JSON']>;
  costCateg: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  contractRolesUsersAssigned?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  defectStatusByContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  voSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedContractSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  contractPOCounts?: Maybe<Scalars['JSON']>;
  maxRetentionSum: Scalars['Float'];
  /** CustomFieldResolver */
  contractDefectCounts?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  osContractAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalBudget?: Maybe<WbsBudgetEntity>;
  /** CustomFieldResolver */
  revisedBudget?: Maybe<WbsBudgetEntity>;
};


export type ContractEntityLatestCollectionActivityArgs = {
  selectedDate?: Maybe<Scalars['String']>;
};


export type ContractEntityCostCategArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
};


export type ContractEntityContractRolesUsersAssignedArgs = {
  softwareCode?: Maybe<Scalars['String']>;
};

export type ContractExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractGuaranteeEntity = AuditEntity & {
  __typename?: 'ContractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  contractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  contractSum?: Maybe<Scalars['Float']>;
  contractDate?: Maybe<Scalars['DateTime']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
  contractNo: Scalars['String'];
  implementWbs: Scalars['Boolean'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  bankAccountID?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  contractStatus?: Maybe<ContractStatus>;
  budgetChecker?: Maybe<BudgetChecker>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractInsuranceEntity = AuditEntity & {
  __typename?: 'ContractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  contractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type ContractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
};


export type ContractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractMiscInvoiceID: Scalars['String'];
  contractMiscInvoice?: Maybe<ContractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractMiscInvoiceTaxItem?: Maybe<Array<ContractMiscInvoiceTaxItemEntity>>;
};

export type ContractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractMiscInvoiceItemID: Scalars['String'];
  contractMiscInvoiceItem?: Maybe<ContractMiscInvoiceItemEntity>;
};

export type ContractNewsEntity = AuditEntity & {
  __typename?: 'ContractNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
  newsRead?: Maybe<Array<ContractNewsReadEntity>>;
};

export type ContractNewsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  content: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  postStatus: PostStatus;
};

export type ContractNewsReadEntity = AuditEntity & {
  __typename?: 'ContractNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
  newsID: Scalars['String'];
  news?: Maybe<ContractNewsEntity>;
};

export type ContractNewsReadInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newsID: Scalars['DateTime'];
  userID: Scalars['String'];
};

export type ContractNotificationEntity = AuditEntity & {
  __typename?: 'ContractNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
  workflowID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  notificationRecipient?: Maybe<Array<ContractNotificationRecipientEntity>>;
};

export type ContractNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type ContractNotificationRecipientEntity = AuditEntity & {
  __typename?: 'ContractNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notificationID?: Maybe<Scalars['String']>;
  recipientID: Scalars['String'];
  isRead: Scalars['Boolean'];
  notification?: Maybe<ContractNotificationEntity>;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type ContractPermissionEntity = {
  __typename?: 'ContractPermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type ContractReceiptEntity = AuditEntity & {
  __typename?: 'ContractReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractReceiptEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractRefundEntity = AuditEntity & {
  __typename?: 'ContractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractRefundEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'ContractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ContractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Terminated = 'TERMINATED'
}

export type ContractToDoEntity = AuditEntity & {
  __typename?: 'ContractToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
  docRefAmt?: Maybe<Scalars['Float']>;
  approvalLog?: Maybe<Array<ContractApprovalLogEntity>>;
};

export type ContractToDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  toDoType: ToDoType;
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
};

export type ContractVo = {
  __typename?: 'ContractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voClientCount?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type ContractVoEntity = AuditEntity & {
  __typename?: 'ContractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  contractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type ContractVoRevenueWbsEntity = AuditEntity & {
  __typename?: 'ContractVORevenueWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousRevenueVOAmt?: Maybe<Scalars['JSON']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractVoRevenueWbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CoreAllocationEntity = AuditEntity & {
  __typename?: 'CoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreApprovalLogEntity = AuditEntity & {
  __typename?: 'CoreApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreAssociateEntity = AuditEntity & {
  __typename?: 'CoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
};

export type CoreAssociateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreClaimAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimEntity = AuditEntity & {
  __typename?: 'CoreClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
};

export type CoreClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimVoAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
};

export type CoreConsubconActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CoreConsubconVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreContractEntity = AuditEntity & {
  __typename?: 'CoreContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'CoreEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreGuarInsEntity = AuditEntity & {
  __typename?: 'CoreGuarInsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type CoreGuaranteeEntity = AuditEntity & {
  __typename?: 'CoreGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
};

export type CoreInsuranceEntity = AuditEntity & {
  __typename?: 'CoreInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
};

export type CoreNewsEntity = AuditEntity & {
  __typename?: 'CoreNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
};

export type CoreNewsReadEntity = AuditEntity & {
  __typename?: 'CoreNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreNotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreNotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type CoreNotificationEntity = AuditEntity & {
  __typename?: 'CoreNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  recipientID?: Maybe<Scalars['String']>;
  senderID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type CoreNotificationGuidelineEntity = AuditEntity & {
  __typename?: 'CoreNotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CorePolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CorePolicyGuidelineEntity = AuditEntity & {
  __typename?: 'CorePolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type CorePolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'CorePolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'CoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CoreToDoEntity = AuditEntity & {
  __typename?: 'CoreToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
};

export type CoreTransactionEntity = AuditEntity & {
  __typename?: 'CoreTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
};

export type CoreTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
};

export type CoreVoEntity = AuditEntity & {
  __typename?: 'CoreVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type CostAdjItemEntity = AuditEntity & {
  __typename?: 'CostAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
};

export type CostCategoryEntity = AuditEntity & {
  __typename?: 'CostCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCategoryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  pnmCostCat?: Maybe<Scalars['JSON']>;
  isLastNode: Scalars['Boolean'];
  tankType: TankType;
  fuelConsumption?: Maybe<Scalars['JSON']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costClass: CostClass;
  commonStatus: CommonStatus;
  costToDate?: Maybe<Scalars['Float']>;
  committedCost?: Maybe<Scalars['Float']>;
  supplierConnection: Array<SupplierCCategoryEntity>;
  contractConnection: Array<ProjectBudgetEntity>;
  workshopConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  subcontractTrade: Array<SubcontractEntity>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  costSubcontractWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  budget: Array<ProjectBudgetEntity>;
  /** CustomFieldResolver */
  plantMachineryStatus?: Maybe<PmStatus>;
  subconTender: Array<SubcontractTenderEntity>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  parentCostCategory?: Maybe<CostCategoryEntity>;
  childrenCostCategory?: Maybe<Array<CostCategoryEntity>>;
  subcontract: Array<SubcontractEntity>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type CostCategoryEntityPlantMachineryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityBudgetArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityPlantMachineryStatusArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CostCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  costName?: Maybe<Scalars['String']>;
  parentCostName?: Maybe<Scalars['String']>;
  parentCostCategoryId?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  originalCostClass?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  tankType?: Maybe<Scalars['String']>;
  consumptionMethod?: Maybe<Scalars['String']>;
  newCostCategory?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  trackQty?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
  isTank?: Maybe<Scalars['Boolean']>;
  lowerRange?: Maybe<Scalars['Float']>;
  upperRange?: Maybe<Scalars['Float']>;
};

export type CostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
};

export type CostCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type CostCenterEntity = AuditEntity & {
  __typename?: 'CostCenterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType: CostCenterType;
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractEntity>>;
};

export enum CostCenterType {
  Company = 'COMPANY',
  Contract = 'CONTRACT',
  Bank = 'BANK'
}

export enum CostClass {
  Main = 'MAIN',
  Subcontract = 'SUBCONTRACT',
  Labour = 'LABOUR',
  Misc = 'MISC',
  Material = 'MATERIAL',
  Plant = 'PLANT'
}

export type CostCodeEntity = AuditEntity & {
  __typename?: 'CostCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCodeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  level: Scalars['Float'];
  isLastNode: Scalars['Boolean'];
};

export type CostEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
};

export type CostItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CostItemEntity = AuditEntity & {
  __typename?: 'CostItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  uomID?: Maybe<Scalars['String']>;
  UOM?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  lastPurchasePrice?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  poItem?: Maybe<Array<PoItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  supplierConnection: Array<PricingSupplierEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  latestPurchase?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  isInventoryChecking?: Maybe<Scalars['Boolean']>;
};


export type CostItemEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type CostItemImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['String']>;
  costName?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  newCostItem?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  variancePercentage?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPercentage?: Maybe<Scalars['Float']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};

export type CostItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  uomID: Scalars['String'];
  name: Scalars['String'];
  unitPrice: Scalars['Float'];
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type CostItems = {
  costCategoryID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPerc?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  variancePerc?: Maybe<Scalars['Float']>;
};

export type CostRevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostRevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<Scalars['JSON']>>;
  /** CustomFieldResolver */
  fromCurrency: Scalars['JSON'];
  /** CustomFieldResolver */
  toCurrency: Scalars['JSON'];
};

export type CurrencyNoteEntity = AuditEntity & {
  __typename?: 'CurrencyNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  format: NumericFormatter;
};

export type CurrencySetupDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencySetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CustomerCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  genPaymentReminder: Scalars['Boolean'];
  contactPerson: Array<ContactEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  followUp?: Maybe<Array<FollowUpEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  customerMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  customerDN?: Maybe<Array<ContractDnEntity>>;
  customerCN?: Maybe<Array<ContractCnEntity>>;
  customerClientDN?: Maybe<Array<ClientDnEntity>>;
  customerClientCN?: Maybe<Array<ClientCnEntity>>;
  customerReceipt?: Maybe<Array<ContractReceiptEntity>>;
  customerAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  customerRefund?: Maybe<Array<ContractRefundEntity>>;
  paymentReminder?: Maybe<Array<DocReminderEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  customerCompanyAssignment?: Maybe<Array<CustomerCompanyAssignmentEntity>>;
  projectOpportunity?: Maybe<Array<ProjectOpportunityEntity>>;
  /** CustomFieldResolver */
  activeContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientReceiptInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  OSClientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestCollectionActivity?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
};


export type CustomerEntityContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityActiveContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOsClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityConsubconDropdownArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerReminderEntity = AuditEntity & {
  __typename?: 'CustomerReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
};

export type DNtoSubconEntity = AuditEntity & {
  __typename?: 'DNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupTaxAmt?: Maybe<Scalars['Float']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  chargeToRevenue: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<SubcontractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSubconItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  DNtoSubconID: Scalars['String'];
  DNtoSubcon?: Maybe<DNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  markupAmt?: Maybe<Scalars['Float']>;
  DNtoSubconTaxItem?: Maybe<Array<DNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  DNtoSubconItemID: Scalars['String'];
  DNtoSubconItem?: Maybe<DNtoSubconItemEntity>;
};

export type DNtoSupplierEntity = AuditEntity & {
  __typename?: 'DNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  DNtoSupplierID: Scalars['String'];
  DNtoSupplier?: Maybe<DNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  DNtoSupplierTaxItem?: Maybe<Array<DNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  DNtoSupplierItemID: Scalars['String'];
  DNtoSupplierItem?: Maybe<DNtoSupplierItemEntity>;
};

export type DoEntity = AuditEntity & {
  __typename?: 'DOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  deliveryAddress?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contactPerson: Scalars['JSON'];
  glExportID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  /** CustomFieldResolver */
  DODetails?: Maybe<Scalars['JSON']>;
  packedDetail?: Maybe<Array<Scalars['JSON']>>;
  shippedDetail?: Maybe<Array<Scalars['JSON']>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  poItemConnection: Array<DoItemEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  POs: Array<PurchaseOrderEntity>;
  GRTNs: Array<GoodReturnNoteEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DOdocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type DoEntityUpToDateAmtArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type DoEntityDOdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoItem = {
  __typename?: 'DOItem';
  costItemID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  deliveryOrderID: Scalars['String'];
  DO?: Maybe<DoEntity>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POs?: Maybe<PurchaseOrderEntity>;
  grtnID?: Maybe<Scalars['String']>;
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  poItemID?: Maybe<Scalars['String']>;
  poItem?: Maybe<PoItemEntity>;
  grtnItemID?: Maybe<Scalars['String']>;
  grtnItem?: Maybe<GoodReturnNoteItemEntity>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  acceptedAmt?: Maybe<Scalars['Float']>;
  deliveredAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  doItemAmt?: Maybe<Scalars['Float']>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  /** CustomFieldResolver */
  doItemSubmitted?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  upToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  returnedQty?: Maybe<Scalars['Float']>;
};

export type DorpaResult = {
  __typename?: 'DORPAResult';
  supplierID?: Maybe<Scalars['String']>;
  doNo?: Maybe<Scalars['String']>;
  poNo?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  doItems?: Maybe<Array<DoItem>>;
};


export enum DaysofWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DefectAssignmentEntity = AuditEntity & {
  __typename?: 'DefectAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  defectID: Scalars['String'];
  defect?: Maybe<DefectEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  assignmentDate: Scalars['String'];
  expectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type DefectAssignmentInput = {
  defectID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  assignmentDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectEntity = AuditEntity & {
  __typename?: 'DefectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  costCategoryID: Scalars['String'];
  trade?: Maybe<CostCategoryEntity>;
  reportedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type DefectInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  defectID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  reportedDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectLog = {
  __typename?: 'DefectLog';
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectManagementEntity = AuditEntity & {
  __typename?: 'DefectManagementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  defectLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  contract?: Maybe<ContractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type DefectManagementInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  title: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum DefectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Assigned = 'ASSIGNED',
  Reassigned = 'REASSIGNED',
  SelfAssigned = 'SELF_ASSIGNED'
}

export type DeleteTaxInput = {
  id: Scalars['String'];
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  doNo?: Maybe<Scalars['String']>;
};

export type DiaryTypeEntity = AuditEntity & {
  __typename?: 'DiaryTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  followUp: Scalars['Boolean'];
  siteDiary?: Maybe<Array<SiteDiaryEntity>>;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type DiaryTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  followup: Scalars['Boolean'];
};

export type DigitalDocumentInput = {
  refTable: Scalars['String'];
  refID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type DigitalDocuments = AuditEntity & {
  __typename?: 'DigitalDocuments';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
  size: Scalars['String'];
};

export type DoGrnMatch = {
  __typename?: 'DoGrnMatch';
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoGrnMatchInput = {
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export enum DoStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Pendingclose = 'PENDINGCLOSE',
  New = 'NEW',
  Packed = 'PACKED'
}

export type DocNumDetailDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumDetailEntity = AuditEntity & {
  __typename?: 'DocNumDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNumHeaderID: Scalars['String'];
  docNumHeader: DocNumHeaderEntity;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  startFrom?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type DocNumDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docNumHeaderID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
};

export type DocNumHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumHeaderEntity = AuditEntity & {
  __typename?: 'DocNumHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docTypeID?: Maybe<Scalars['String']>;
  docType?: Maybe<ConDocTypeEntity>;
  sampleOutput: Scalars['String'];
  commonStatus: CommonStatus;
  docNumDetail: Array<DocNumDetailEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type DocNumHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
  sampleOutput: Scalars['String'];
};

export type DocNumInput = {
  contractID?: Maybe<Scalars['String']>;
  nextNumber?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  numberLength?: Maybe<Scalars['Float']>;
  runNumID?: Maybe<Scalars['String']>;
  numFormatID?: Maybe<Scalars['String']>;
};

export type DocRef = AuditEntity & {
  __typename?: 'DocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DocReminderEntity = AuditEntity & {
  __typename?: 'DocReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminderID: Scalars['String'];
  paymentReminder?: Maybe<PaymentReminderEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq?: Maybe<Scalars['Float']>;
  outstandingAmt: Scalars['Float'];
};

export type DocTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docName: Scalars['String'];
  code: Scalars['String'];
  docCode: ContractDocNum;
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type DocumentFolderInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};

export type DocumentsEntity = AuditEntity & {
  __typename?: 'DocumentsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
};

export type DocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  status?: Maybe<CommonStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type EInvoiceItem = AuditEntity & {
  __typename?: 'EInvoiceItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum EInvoiceType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Foreigner = 'FOREIGNER',
  Government = 'GOVERNMENT'
}

export type EditTaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtensionDate = {
  __typename?: 'ExtensionDate';
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type ExtensionDateInput = {
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FieldTypeEntity = AuditEntity & {
  __typename?: 'FieldTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type FileObjectType = {
  __typename?: 'FileObjectType';
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type FollowUpEntity = AuditEntity & {
  __typename?: 'FollowUpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: Scalars['JSON'];
  followUpStatus: FollowUpStatus;
  customer?: Maybe<CustomerEntity>;
};

export enum FollowUpStatus {
  Call = 'CALL',
  Whatsapp = 'WHATSAPP',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Message = 'MESSAGE'
}

export type Forex = AuditEntity & {
  __typename?: 'Forex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type FuelConsumption = {
  __typename?: 'FuelConsumption';
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export type FuelConsumptionInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export enum FuelConsumptionMethod {
  Km = 'KM',
  Hour = 'HOUR'
}

export type FuelDischargeEntity = AuditEntity & {
  __typename?: 'FuelDischargeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  dischargeDate: Scalars['DateTime'];
  docNo?: Maybe<Scalars['String']>;
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  isMalfunction?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelMeterReadingEntity = AuditEntity & {
  __typename?: 'FuelMeterReadingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
  /** CustomFieldResolver */
  unionTank?: Maybe<TankMachinery>;
};

export type FuelSupplyEntity = AuditEntity & {
  __typename?: 'FuelSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type GlAdjustmentDynamicEntity = AuditEntity & {
  __typename?: 'GLAdjustmentDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  adjustmentTypeID: Scalars['String'];
  adjustmentType?: Maybe<AdjustmentTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlAssociationEntity = AuditEntity & {
  __typename?: 'GLAssociationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlExportDetailEntity = AuditEntity & {
  __typename?: 'GLExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  accountCode: Scalars['String'];
  type: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefTable: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
};

export type GlExportDetailSourceEntity = AuditEntity & {
  __typename?: 'GLExportDetailSourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  claimDocAmt?: Maybe<Scalars['Float']>;
  associateRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  doNo?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
  contract?: Maybe<ContractEntity>;
};

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
  commonStatus: CommonStatus;
  glExportDetail?: Maybe<Array<GlExportDetailEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type GlExportPostResponse = {
  __typename?: 'GLExportPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GlInterfaceDetailEntity = AuditEntity & {
  __typename?: 'GLInterfaceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glInterfaceMasterID: Scalars['String'];
  glInterfaceMaster?: Maybe<GlInterfaceMasterEntity>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceMasterEntity = AuditEntity & {
  __typename?: 'GLInterfaceMasterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  glAssociationID: Scalars['String'];
  glTransactionID: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
  glInterfaceDetail?: Maybe<GlInterfaceDetailEntity>;
  glAssociation?: Maybe<GlAssociationEntity>;
  glTransaction?: Maybe<GlTransactionEntity>;
};

export type GlInterfacePolicyEntity = AuditEntity & {
  __typename?: 'GLInterfacePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glIntegration: Scalars['String'];
  glTransactionGeneration: Scalars['String'];
  autoGlExport: Scalars['Boolean'];
};

export type GlTaxDynamicEntity = AuditEntity & {
  __typename?: 'GLTaxDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlTransactionEntity = AuditEntity & {
  __typename?: 'GLTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GrnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrnEntity = AuditEntity & {
  __typename?: 'GRNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplier: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  docRef: Scalars['String'];
  cancelRejectDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  grnStatus: DoStatus;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
};

export type GrnInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  cancelRejectDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  grnStatus?: Maybe<DoStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrnItem = {
  __typename?: 'GRNItem';
  costItemID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Uom>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type GrnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GrnItemEntity = AuditEntity & {
  __typename?: 'GRNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grnID: Scalars['String'];
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  grn?: Maybe<GrnEntity>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  uom?: Maybe<UomEntity>;
  costItem?: Maybe<CostItemEntity>;
};

export type GrnItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  grnID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export type GoodReceiveNoteRpaResult = {
  __typename?: 'GoodReceiveNoteRPAResult';
  customerAddress?: Maybe<Scalars['String']>;
  customerAddressRecipient?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  grnDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  remittanceAddressRecipient?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  vendorAddress?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  invoiceItems?: Maybe<Array<GrnItem>>;
};

export type GoodReturnNoteEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  GRTNdocAmt?: Maybe<Scalars['Float']>;
};


export type GoodReturnNoteEntityGrtNdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type GoodReturnNoteItemEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grtnID: Scalars['String'];
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  doItemID: Scalars['String'];
  doItem?: Maybe<DoItemEntity>;
  returnedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  replacement: Scalars['Boolean'];
  returnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export enum GrntInsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type GuaranteeTypeEntity = AuditEntity & {
  __typename?: 'GuaranteeTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractGuaranteeEntity>>;
  subcontract?: Maybe<Array<SubcontractGuaranteeEntity>>;
};

export type HolidayTypeDeleteInput = {
  ID: Scalars['String'];
};

export type HolidayTypeEntity = AuditEntity & {
  __typename?: 'HolidayTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  wagesRatePolicy?: Maybe<Array<WagesRatePolicyEntity>>;
  commonStatus: CommonStatus;
  calendar?: Maybe<Array<CalendarEntity>>;
  calendarPHP?: Maybe<Array<CalendarPhPolicyEntity>>;
  workingHourPolicy?: Maybe<Array<WorkingHourPolicyEntity>>;
};

export type HolidayTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type HolidayTypeRate = {
  __typename?: 'HolidayTypeRate';
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HolidayTypeRateInput = {
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  voStartDate?: Maybe<Scalars['DateTime']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncomingEInvoiceEntity = AuditEntity & {
  __typename?: 'IncomingEInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type InsuranceTypeEntity = AuditEntity & {
  __typename?: 'InsuranceTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract: Array<ContractInsuranceEntity>;
  subcontract?: Maybe<Array<SubcontractInsuranceEntity>>;
};

export type InvitedVendorsEntity = AuditEntity & {
  __typename?: 'InvitedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  officeNo: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  contractID?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  deliveryOrderNo?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNo?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Items>>;
};


export type JobTypeDeleteInput = {
  ID: Scalars['String'];
};

export type JobTypeEntity = AuditEntity & {
  __typename?: 'JobTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  marketRate: Scalars['String'];
  worker?: Maybe<Array<WorkerEntity>>;
  commonStatus: CommonStatus;
};

export type JobTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  marketRate?: Maybe<Scalars['String']>;
};

export type LedgerCheckingSummary = {
  __typename?: 'LedgerCheckingSummary';
  isApprovable?: Maybe<Scalars['Boolean']>;
  hasNextLevel?: Maybe<Scalars['Boolean']>;
  hasGuideline?: Maybe<Scalars['Boolean']>;
  validFlow?: Maybe<Scalars['Boolean']>;
};

export type LedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  allocationStatus?: Maybe<AllocationStatus>;
};

export type LedgerPdfInput = {
  refID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: UserResponse;
};

export enum LogoPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type MaintenanceEntity = AuditEntity & {
  __typename?: 'MaintenanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  workshopID: Scalars['String'];
  workshop?: Maybe<WorkshopEntity>;
  assignmentID?: Maybe<Scalars['String']>;
  assignment?: Maybe<AssignmentEntity>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  returnDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  usageReading?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  maintenanceStatus: AsgMainStatus;
};

export type MiscExpenseEntity = AuditEntity & {
  __typename?: 'MiscExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  docRef: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  docDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export enum MiscExpenseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
  dueAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateDefect: DefectEntity;
  createDefect: DefectEntity;
  createDefectAssignment: Scalars['Boolean'];
  createActivityLogs: Scalars['Boolean'];
  createDefectMgmt: DefectManagementEntity;
  updateDefectMgmt: DefectManagementEntity;
  assignDefect: Scalars['Boolean'];
  reOpenDefect: Scalars['Boolean'];
  createActivityLog: Scalars['Boolean'];
  createDiaryType: DiaryTypeEntity;
  createSite: Scalars['Boolean'];
  updateSite: Scalars['Boolean'];
  deleteSite: Scalars['Boolean'];
  activateSite: Scalars['Boolean'];
  cancelSite: Scalars['Boolean'];
  insertUpdateSite: Scalars['Boolean'];
  deleteProjectSite: Scalars['Boolean'];
  cancelGRN: Scalars['Boolean'];
  createGRNItem: GrnItemEntity;
  updateGRNItem: GrnItemEntity;
  deleteGRNItem: Scalars['Boolean'];
  cancelGRNItem: Scalars['Boolean'];
  createGRN: Scalars['Boolean'];
  updateGRN: Scalars['Boolean'];
  deleteGRN: Scalars['Boolean'];
  createStockMovement: StockMovementEntity;
  updateStockMovementHOR: StockMovementEntity;
  deleteStockMovement: Scalars['Boolean'];
  cancelStockMovement: Scalars['Boolean'];
  updateStockMovement: StockMovementEntity;
  createSiteProgress: SiteProgressEntity;
  updateSiteProgress: SiteProgressEntity;
  deleteSiteProgress: Scalars['Boolean'];
  createSiteStockLedger: SiteStockLedgerEntity;
  updateSiteStockLedger: SiteStockLedgerEntity;
  deleteSiteStockLedger: Scalars['Boolean'];
  createSiteWorkerAttendance: WorkerAttendanceEntity;
  updateSiteWorkerAttendance: WorkerAttendanceEntity;
  deleteSiteWorkerAttendance: Scalars['Boolean'];
  workerAttendanceValidation: Scalars['JSON'];
  workerAttendanceImport: Scalars['Boolean'];
  createChecklistCategory: ChecklistCategoryEntity;
  updateChecklistCategory: ChecklistCategoryEntity;
  deleteChecklistCategory: Scalars['Boolean'];
  createSafetyChecklist: SafetyChecklistEntity;
  createSafetyChecklistItem: SafetyChecklistItemEntity;
  updateSafetyChecklistItem: SafetyChecklistItemEntity;
  deleteSafetyChecklistItem: Scalars['Boolean'];
  createSiteSafety: SiteSafetyEntity;
  updateSiteSafety: SiteSafetyEntity;
  createSiteSafetyItem: SiteSafetyItemEntity;
  updateSiteSafetyItem: SiteSafetyItemEntity;
  createSiteImage: SiteImageEntity;
  updateSiteImage: SiteImageEntity;
  deleteSiteImage: Scalars['Boolean'];
  cancelSiteImage: Scalars['Boolean'];
  updateWageCycle: WageCycleEntity;
  cancelWageCycle: Scalars['Boolean'];
  deleteWageCycle: Scalars['Boolean'];
  createWageCycle: Scalars['Boolean'];
  updateWorkerVerifiedHour: Scalars['Boolean'];
  createWageCycleDetail: WageCycleDetailEntity;
  updateWageCycleDetail: WageCycleDetailEntity;
  deleteWageCycleDetail: Scalars['Boolean'];
  cancelWageCycleDetail: Scalars['Boolean'];
  createWorkerAdvance: WorkerAdvanceEntity;
  updateWorkerAdvance: WorkerAdvanceEntity;
  deleteWorkerAdvance: Scalars['Boolean'];
  cancelWorkerAdvance: Scalars['Boolean'];
  createWorkerWage: WorkerWageEntity;
  updateWorkerWage: WorkerWageEntity;
  deleteWorkerWage: Scalars['Boolean'];
  cancelWorkerWage: Scalars['Boolean'];
  insertPermissionTable: Scalars['Boolean'];
  insertSitePermissionTable: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  alertPolicySetup: Scalars['Boolean'];
  updateAlertSqlCode: Scalars['JSON'];
  deleteExistPDF: Scalars['Boolean'];
  deleteDocuments: Scalars['Boolean'];
  uploadPDFPO: Scalars['Boolean'];
  uploadPDFDocument: Array<DocumentsEntity>;
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  createContractNews: ContractNewsEntity;
  updateContractNews: ContractNewsEntity;
  deleteContractNews: Scalars['Boolean'];
  createContractNewsRead: ContractNewsReadEntity;
  updateContractNewsRead: ContractNewsReadEntity;
  deleteContractNewsRead: Scalars['Boolean'];
  deleteCollectionActivity: Scalars['Boolean'];
  createCollectionActivity: Scalars['Boolean'];
  deleteColActivity: Scalars['Boolean'];
  updateCollectionActivity: Scalars['Boolean'];
  createMultipleCollectionActivity: Scalars['Boolean'];
  updateCollectionStatus: Scalars['Boolean'];
  patchNewDocNum: Scalars['Boolean'];
  createDocNumRunningNumber: Scalars['Boolean'];
  generateDocumentNumberSetup: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  resetContractRunningNumber: Scalars['Boolean'];
  contractRunNumChecker: Scalars['Boolean'];
  createContractRunNum: Scalars['Boolean'];
  deleteDocRunningNumber: Scalars['Boolean'];
  createCompany: Scalars['Boolean'];
  createRawCompany: Scalars['Boolean'];
  updateCompany: Scalars['Boolean'];
  updateRawCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  deleteRawCompany: Scalars['Boolean'];
  updateContact: ContactEntity;
  addContractContact: Scalars['Boolean'];
  addSubcontractContact: Scalars['Boolean'];
  deleteSubcontractData: Scalars['Boolean'];
  deleteSiteData: Scalars['Boolean'];
  deleteContractData: Scalars['Boolean'];
  deleteAssociateData: Scalars['Boolean'];
  deleteLedgersWorkFlow: Scalars['Boolean'];
  deleteSpecificLedgers: Scalars['Boolean'];
  deletePOData: Scalars['Boolean'];
  deleteClientLedgersData: Scalars['Boolean'];
  deleteSubconLedgersData: Scalars['Boolean'];
  deleteSupplierLedgersData: Scalars['Boolean'];
  deleteCostData: Scalars['Boolean'];
  deletePolicyData: Scalars['Boolean'];
  deleteNotificationData: Scalars['Boolean'];
  deleteWorkFlowData: Scalars['Boolean'];
  updateContractData: Scalars['Boolean'];
  updateSubcontractData: Scalars['Boolean'];
  updateClientData: Scalars['Boolean'];
  updateSubconData: Scalars['Boolean'];
  updateSupplierData: Scalars['Boolean'];
  updatePOData: Scalars['Boolean'];
  updateLedgersData: Scalars['Boolean'];
  rejectLedgerTable: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  removeSuperUserACL: Scalars['Boolean'];
  patchEditableAllocation: Scalars['Boolean'];
  patchContractAllocation: Scalars['Boolean'];
  patchSupplierAllocation: Scalars['Boolean'];
  patchContractAllocationIDs: Scalars['Boolean'];
  patchRejClaimData: Scalars['Boolean'];
  patchAdjustmentDocRefData: Scalars['Boolean'];
  patchClaimBaseDocAmtData: Scalars['Boolean'];
  patchCancelledAllocClaimData: Scalars['Boolean'];
  patchCNDNClaimData: Scalars['Boolean'];
  patchRetentionClaimReject: Scalars['Boolean'];
  patchRetentionClaimData: Scalars['Boolean'];
  patchSAccountData: Scalars['JSON'];
  patchDocumentsData: Scalars['Boolean'];
  patchCompanyImage: Scalars['Boolean'];
  patchContractConSubconData: Scalars['Boolean'];
  patchRealConDateData: Scalars['Boolean'];
  patchConDateData: Scalars['Boolean'];
  patchVODateData: Scalars['Boolean'];
  patchGuarInsDateData: Scalars['Boolean'];
  insertNewContractBudget: Scalars['Boolean'];
  patchContractClaimAllocation: Scalars['Boolean'];
  patchContractCompany: Scalars['Boolean'];
  patchCompanyInAccount: Scalars['JSON'];
  patchNullDocNoExpenses: Scalars['JSON'];
  patchSiteDocumentData: Scalars['Boolean'];
  patchCostCategoryData: Scalars['Boolean'];
  patchDueDateNullData: Scalars['JSON'];
  patchInvoiceData: Scalars['Boolean'];
  patchNullTrxDateData: Scalars['Boolean'];
  removeAllocData: Scalars['Boolean'];
  patchPlantStatusData: Scalars['Boolean'];
  patchProjectExpense: Scalars['Boolean'];
  patchNullGRTNItemData: Scalars['Boolean'];
  patchNullPOItemData: Scalars['Boolean'];
  patchNullPOSiteData: Scalars['Boolean'];
  patchRfqItemSubmissionIsSelected: Scalars['Boolean'];
  patchDOItemDeliveredQty: Scalars['Boolean'];
  patchDocDueDateNullData: Scalars['Boolean'];
  patchExpectedDateNullData: Scalars['Boolean'];
  patchGRTNTrxDateNullData: Scalars['Boolean'];
  patchMainSiteData: Scalars['Boolean'];
  insertLongLatData: Scalars['Boolean'];
  insertSiteInventoryData: Scalars['Boolean'];
  patchNullDocFolderData: Scalars['Boolean'];
  patchEmptyWorkflow: Scalars['Boolean'];
  patchNullSubconTenderData: Scalars['Boolean'];
  patchSubconCostCategory: Scalars['Boolean'];
  patchWorkerSiteAssignment: Scalars['Boolean'];
  patchSupplierTypeData: Scalars['Boolean'];
  patchSupplierWithSupplierType: Scalars['Boolean'];
  patchWbsCostItemDetail: Scalars['Boolean'];
  patchWbsCostDetail: Scalars['Boolean'];
  patchWbsSchedule: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  implementBaseUOM: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  extraGeneralSettingSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraGLMasterSetup: Scalars['Boolean'];
  extraGLTransactionSetup: Scalars['Boolean'];
  extraGLGenSettingSetup: Scalars['Boolean'];
  platformContactSetup: Scalars['Boolean'];
  initialCurrencySetup: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  deleteMasterFile: Scalars['Boolean'];
  initialSiteMasterFileSetup: Scalars['Boolean'];
  deleteSiteMasterFile: Scalars['Boolean'];
  initialGeneralSettingSetup: Scalars['Boolean'];
  deleteGenSetting: Scalars['Boolean'];
  initialGLInterfaceSetup: Scalars['Boolean'];
  initialDocNumSetup: Scalars['Boolean'];
  generateGLMasterFileSetup: Scalars['Boolean'];
  deleteGLMasterFileSetup: Scalars['Boolean'];
  generateGLInitMasterSetup: Scalars['Boolean'];
  deleteGLInitMasterSetup: Scalars['Boolean'];
  initialProfitRecogParameter: Scalars['Boolean'];
  updateProfitRecogParameter: Scalars['Boolean'];
  createBatchPaymentDetail: BatchPaymentDetailEntity;
  updateBatchPaymentDetail: BatchPaymentDetailEntity;
  deleteBatchPaymentDetail: Scalars['Boolean'];
  cancelBatchPaymentDetail: Scalars['Boolean'];
  createBatchPaymentHeader: BatchPaymentHeaderEntity;
  updateBatchPaymentHeader: BatchPaymentHeaderEntity;
  deleteBatchPaymentHeader: Scalars['Boolean'];
  cancelBatchPaymentHeader: Scalars['Boolean'];
  submitBatchPayment: Scalars['Boolean'];
  confirmBatchPayment: Scalars['JSON'];
  payBatchPayment: Scalars['JSON'];
  createSiteMaterialPolicy: SiteMaterialPolicyEntity;
  updateSiteMaterialPolicy: SiteMaterialPolicyEntity;
  createSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  updateSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  createMaterialPolicy: Scalars['Boolean'];
  createMaterialContractPolicy: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  createTaxMasterFile: Scalars['Boolean'];
  editTaxMasterFile: Scalars['Boolean'];
  deleteTaxMasterFile: Scalars['Boolean'];
  deactivateTaxMasterFile: Scalars['Boolean'];
  activateTaxMasterFile: Scalars['Boolean'];
  createSuperUser: UserEntity;
  createContractNotification: ContractNotificationEntity;
  updateContractNotification: ContractNotificationEntity;
  deleteContractNotification: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  markAsReadNoti: Scalars['Boolean'];
  deleteNoti: Scalars['Boolean'];
  patchWorkDeskData: Scalars['Boolean'];
  CreateContractRole: Scalars['Boolean'];
  UpdateContractRole: Scalars['Boolean'];
  CreateSiteRole: Scalars['Boolean'];
  UpdateSiteRole: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  createAdjustmentType: AdjustmentTypeEntity;
  updateAdjustmentType: AdjustmentTypeEntity;
  deleteAdjustmentType: Scalars['Boolean'];
  createAlert: AlertEntity;
  updateAlert: AlertEntity;
  deleteAlert: Scalars['Boolean'];
  updateAlertAssignment: Scalars['JSON'];
  cancelApprovalPolicy: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: ApprovalPolicyEntity;
  updateApprovalPolicy: ApprovalPolicyEntity;
  createApprovalPolicyAssignment: ApprovalPolicyEntity;
  updateApprovalPolicyAssignment: ApprovalPolicyAssignmentEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  deleteMultipleApprovalPolicy: Scalars['Boolean'];
  deleteMultipleApprovalPolicyAssignment: Scalars['Boolean'];
  createBankAccount: BankAccountEntity;
  updateBankAccount: BankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  deleteCalendar: Scalars['Boolean'];
  cancelCalendar: Scalars['Boolean'];
  activateCalendar: Scalars['Boolean'];
  createCalendar: Scalars['Boolean'];
  updateCalendar: Scalars['Boolean'];
  createCalendarPHPolicy: CalendarPhPolicyEntity;
  updateCalendarPHPolicy: CalendarPhPolicyEntity;
  deleteCalendarPHPolicy: Scalars['Boolean'];
  cancelCalendarPHPolicy: Scalars['Boolean'];
  activateCalendarPHPolicy: Scalars['Boolean'];
  createCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  updateCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  deleteCalendarWeekendPolicy: Scalars['Boolean'];
  cancelCalendarWeekendPolicy: Scalars['Boolean'];
  activateCalendarWeekendPolicy: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['Boolean'];
  uploadISOLogo: Scalars['Boolean'];
  uploadCompanyISOLogo: Scalars['Boolean'];
  updateWatermarkCompany: Scalars['Boolean'];
  updateCompanySignature: Scalars['Boolean'];
  deleteCostCategory: Scalars['Boolean'];
  cancelCostCategory: Scalars['Boolean'];
  activateCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategoryWithoutItem: Scalars['Boolean'];
  createCostCategory: CostCategoryEntity;
  updateCostCategory: Scalars['Boolean'];
  deleteCostCategoryCostItem: Scalars['Boolean'];
  addCostCategory: CostCategoryEntity;
  editCostCategory: Scalars['Boolean'];
  deleteTreeCostCategory: Scalars['Boolean'];
  costCategoryImportValidation: Scalars['JSON'];
  costCategoryImport: Scalars['Boolean'];
  deleteCostItem: Scalars['Boolean'];
  activateCostItem: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createCostItem: CostItemEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateCostItem: CostItemEntity;
  /** Create batch */
  createBatchCostItem: Scalars['Boolean'];
  cancelCostItem: Scalars['Boolean'];
  costItemImport: Scalars['Boolean'];
  costItemImportValidation: Scalars['JSON'];
  createCurrencySetup: CurrencyNoteEntity;
  updateCurrencySetup: CurrencyNoteEntity;
  deleteCurrencySetup: Scalars['Boolean'];
  addDigitalDocuments: Scalars['Boolean'];
  deleteDigitalDocuments: Scalars['Boolean'];
  updateNumberFormat: ConNumberFormatEntity;
  createDocType: ConDocTypeEntity;
  updateDocType: ConDocTypeEntity;
  createRunningNumberSetup: ConRunningNumberSetupEntity;
  updateRunningNumberSetup: ConRunningNumberSetupEntity;
  inactiveDocType: Scalars['Boolean'];
  editNumberFormat: Scalars['Boolean'];
  updateDocNumSetup: Scalars['Boolean'];
  createDocNumHeader: DocNumHeaderEntity;
  updateDocNumHeader: DocNumHeaderEntity;
  createDocNumDetail: DocNumDetailEntity;
  updateDocNumDetail: DocNumDetailEntity;
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateSystemAdminDocNum: Scalars['Boolean'];
  createHolidayType: HolidayTypeEntity;
  updateHolidayType: HolidayTypeEntity;
  deleteHolidayType: Scalars['Boolean'];
  cancelHolidayType: Scalars['Boolean'];
  activateHolidayType: Scalars['Boolean'];
  createJobType: JobTypeEntity;
  updateJobType: JobTypeEntity;
  deleteJobType: Scalars['Boolean'];
  cancelJobType: Scalars['Boolean'];
  activateJobType: Scalars['Boolean'];
  createPlatformSuperUser: PlatformUserEntity;
  createPlatformUser: PlatformUserEntity;
  updatePlatformUser: Scalars['Boolean'];
  createPlatformPassword: Scalars['Boolean'];
  createPlatformPasswordTest: Scalars['Boolean'];
  activatePlatformUser: Scalars['Boolean'];
  platformLogin: PlatformLoginResponse;
  changePlatformPassword: Scalars['Boolean'];
  platformLogout: Scalars['Boolean'];
  resetPlatformPassword: Scalars['Boolean'];
  uploadProfilePicPlatform: DocumentsEntity;
  forgotPlatformPassword: Scalars['Boolean'];
  createPolicyGuideline: Scalars['Boolean'];
  updatePolicyGuideline: Scalars['Boolean'];
  createPolicyAmountGuideline: Scalars['Boolean'];
  updatePolicyAmountGuideline: Scalars['Boolean'];
  deletePolicyAmountGuideline: Scalars['Boolean'];
  createNotificationGuideline: Scalars['Boolean'];
  updateNotificationGuideline: Scalars['Boolean'];
  createPublicHoliday: PublicHolidayEntity;
  updatePublicHoliday: PublicHolidayEntity;
  deletePublicHoliday: Scalars['Boolean'];
  cancelPublicHoliday: Scalars['Boolean'];
  activatePublicHoliday: Scalars['Boolean'];
  deleteRevenueCategory: Scalars['Boolean'];
  cancelRevenueCategory: Scalars['Boolean'];
  activateRevenueCategory: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createRevenueCategory: RevenueCategoryEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateRevenueCategory: RevenueCategoryEntity;
  /** Create batch */
  createBatchRevenueCategory: Scalars['Boolean'];
  revenueCategoryImportValidation: Scalars['JSON'];
  revenueCategoryImport: Scalars['Boolean'];
  goodReceiveNoteRPA: Array<GoodReceiveNoteRpaResult>;
  invoiceRPA: Array<InvoiceRpaResult>;
  deliveryOrderRPA: DorpaResult;
  updateSAccount: SAccountEntity;
  createSiteMaterial: SiteMaterialEntity;
  updateSiteMaterial: SiteMaterialEntity;
  deleteSiteMaterial: Scalars['Boolean'];
  cancelSiteMaterial: Scalars['Boolean'];
  activateSiteMaterial: Scalars['Boolean'];
  createBulkSiteMaterial: Scalars['Boolean'];
  createSiteWorkers: WorkerEntity;
  updateSiteWorkers: WorkerEntity;
  deleteSiteWorkers: Scalars['Boolean'];
  createSubcontractorType: SubcontractorTypeEntity;
  updateSubcontractorType: SubcontractorTypeEntity;
  deleteSubcontractorType: Scalars['Boolean'];
  cancelSubcontractorType: Scalars['Boolean'];
  activateSubcontractorType: Scalars['Boolean'];
  createSubcontractorTypeFdw: Scalars['Boolean'];
  createSupplierType: SupplierTypeEntity;
  updateSupplierType: SupplierTypeEntity;
  deleteSupplierType: Scalars['Boolean'];
  cancelSupplierType: Scalars['Boolean'];
  activateSupplierType: Scalars['Boolean'];
  createSupplierTypeFdw: Scalars['Boolean'];
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  cancelUOM: Scalars['Boolean'];
  activateUOM: Scalars['Boolean'];
  createUOM: UomEntity;
  createBatchUOM: Scalars['Boolean'];
  uomImport: Scalars['Boolean'];
  uomImportValidation: Scalars['JSON'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createUserCompanyAssignment: UserCompanyAssignmentEntity;
  updateUserCompanyAssignment: UserCompanyAssignmentEntity;
  assignUserCompany: Scalars['Boolean'];
  unassignUserCompany: Scalars['Boolean'];
  assignUserWithCompany: Scalars['Boolean'];
  unassignUserWithCompany: Scalars['Boolean'];
  createUser: UserEntity;
  createUserEmail: UserEntity;
  sendInvitationEmail: Scalars['Boolean'];
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  updateUserWithPassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  updateLastEntity: Scalars['Boolean'];
  createVideoCam: VideoCamEntity;
  updateVideoCam: VideoCamEntity;
  deleteVideoCam: Scalars['Boolean'];
  cancelVideoCam: Scalars['Boolean'];
  activateVideoCam: Scalars['Boolean'];
  createWagesRatePolicy: WagesRatePolicyEntity;
  deleteWagesRatePolicy: Scalars['Boolean'];
  cancelWagesRatePolicy: Scalars['Boolean'];
  activateWagesRatePolicy: Scalars['Boolean'];
  updateWagesRatePolicy: Scalars['Boolean'];
  createContractToDo: ContractToDoEntity;
  updateContractToDo: ContractToDoEntity;
  deleteContractToDo: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createToDo: Scalars['Boolean'];
  approveToDo: Scalars['Boolean'];
  rejectToDo: Scalars['Boolean'];
  deleteWorker: Scalars['Boolean'];
  cancelWorker: Scalars['Boolean'];
  createWorker: WorkerEntity;
  updateWorker: WorkerEntity;
  createBulkWorker: Scalars['Boolean'];
  updateWorkerAttendance: WorkerAttendanceEntity;
  deleteWorkerAttendance: Scalars['Boolean'];
  cancelWorkerAttendance: Scalars['Boolean'];
  createWorkerAttendance: WorkerAttendanceEntity;
  calculateWorkerWage: Scalars['Boolean'];
  createBatchWorkerAttendance: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createNotificationWorkflow: Scalars['JSON'];
  testEmail: Scalars['Boolean'];
  updateWorkingHourPolicy: WorkingHourPolicyEntity;
  deleteWorkingHourPolicy: Scalars['Boolean'];
  cancelWorkingHourPolicy: Scalars['Boolean'];
  activateWorkingHourPolicy: Scalars['Boolean'];
  createWorkingHourPolicy: WorkingHourPolicyEntity;
  createContract: ContractEntity;
  deleteContractAllAccess: Scalars['Boolean'];
  updateContract: Scalars['Boolean'];
  insertContacts: Scalars['Boolean'];
  actionOnContractStatus: Scalars['Boolean'];
  createContractExtensionDate: Scalars['Boolean'];
  createContractCertificate: Scalars['Boolean'];
  createConTaxType: ConTaxTypeEntity;
  updateConTaxType: ConTaxTypeEntity;
  deleteConTaxType: Scalars['Boolean'];
  activateConTaxType: Scalars['Boolean'];
  createConTaxScheme: ConTaxSchemeEntity;
  updateConTaxScheme: ConTaxSchemeEntity;
  deleteConTaxScheme: Scalars['Boolean'];
  activateConTaxScheme: Scalars['Boolean'];
  createConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  updateConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  deleteConTaxEffectiveDate: Scalars['Boolean'];
  activateConTaxEffectiveDate: Scalars['Boolean'];
  insertConTax: ConTaxSchemeEntity;
  updateConTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createPlatformSubcontractor: SubcontractorEntity;
  updatePlatformSubcontractor: SubcontractorEntity;
  deleteSubcontractor: Scalars['Boolean'];
  createSubcontractorCCategory: SubcontractorCCategoryEntity;
  updateSubcontractorCCategory: SubcontractorCCategoryEntity;
  addSubcontractorContact: Scalars['Boolean'];
  createSubcontractorAndPackage: SubcontractorEntity;
  createBulkSubcon: Scalars['Boolean'];
  addSubcontractorCCategory: Scalars['Boolean'];
  createSubcontractorSetup: Scalars['Boolean'];
  updateSubcontractorSetup: Scalars['Boolean'];
  deleteSubcontractorSetup: Scalars['Boolean'];
  createPlatformSupplier: SupplierEntity;
  updatePlatformSupplier: SupplierEntity;
  deleteSupplier: Scalars['Boolean'];
  createSupplierCCategory: SupplierCCategoryEntity;
  updateSupplierCCategory: SupplierCCategoryEntity;
  deleteSupplierCCategory: Scalars['Boolean'];
  addSupplierContact: Scalars['Boolean'];
  createBulkSupplier: Scalars['Boolean'];
  addCCategoryInSupplier: Scalars['Boolean'];
  addSupplierInCCategory: Scalars['Boolean'];
  deleteSupplierInCCategory: Scalars['Boolean'];
};


export type MutationUpdateDefectArgs = {
  input: DefectInput;
};


export type MutationCreateDefectArgs = {
  input: DefectInput;
};


export type MutationCreateDefectAssignmentArgs = {
  input: DefectAssignmentInput;
  ID: Scalars['String'];
};


export type MutationCreateActivityLogsArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  input: ActivityLogInput;
  defectID: Scalars['String'];
};


export type MutationCreateDefectMgmtArgs = {
  input: DefectManagementInput;
};


export type MutationUpdateDefectMgmtArgs = {
  input: DefectManagementInput;
};


export type MutationAssignDefectArgs = {
  input: DefectAssignmentInput;
  ID: Scalars['String'];
};


export type MutationReOpenDefectArgs = {
  input: ReOpenDefectInput;
  ID: Scalars['String'];
};


export type MutationCreateActivityLogArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  input: DefectLogInput;
  defectID: Scalars['String'];
};


export type MutationCreateDiaryTypeArgs = {
  input: DiaryTypeInput;
};


export type MutationCreateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationDeleteSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationActivateSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationCancelSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationInsertUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  contractID: Scalars['String'];
};


export type MutationDeleteProjectSiteArgs = {
  ID: Scalars['String'];
};


export type MutationCancelGrnArgs = {
  input: GrnDeleteInput;
};


export type MutationCreateGrnItemArgs = {
  input: GrnItemInput;
};


export type MutationUpdateGrnItemArgs = {
  input: GrnItemInput;
};


export type MutationDeleteGrnItemArgs = {
  input: GrnItemDeleteInput;
};


export type MutationCancelGrnItemArgs = {
  input: GrnItemDeleteInput;
};


export type MutationCreateGrnArgs = {
  grnItemInput: Array<GrnItemInput>;
  grnInput: GrnInput;
};


export type MutationUpdateGrnArgs = {
  grnItemInput: Array<GrnItemInput>;
  grnInput: GrnInput;
};


export type MutationDeleteGrnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateStockMovementArgs = {
  input: StockMovementInput;
};


export type MutationUpdateStockMovementHorArgs = {
  input: StockMovementInput;
};


export type MutationDeleteStockMovementArgs = {
  input: StockMovementDeleteInput;
};


export type MutationCancelStockMovementArgs = {
  input: StockMovementDeleteInput;
};


export type MutationUpdateStockMovementArgs = {
  input: StockMovementInput;
};


export type MutationCreateSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationUpdateSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationDeleteSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationCreateSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationUpdateSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationDeleteSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationCreateSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationUpdateSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationDeleteSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationWorkerAttendanceValidationArgs = {
  siteWorkerAttendanceInput: Array<SiteWorkerAttendanceImportInput>;
  siteID: Scalars['String'];
};


export type MutationWorkerAttendanceImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateChecklistCategoryArgs = {
  input: ChecklistCategoryInput;
};


export type MutationUpdateChecklistCategoryArgs = {
  input: ChecklistCategoryInput;
};


export type MutationDeleteChecklistCategoryArgs = {
  input: ChecklistCategoryDeleteInput;
};


export type MutationCreateSafetyChecklistArgs = {
  itemInput?: Maybe<Array<SafetyChecklistItemInput>>;
  input: SafetyChecklistInput;
};


export type MutationCreateSafetyChecklistItemArgs = {
  input: SafetyChecklistItemInput;
};


export type MutationUpdateSafetyChecklistItemArgs = {
  input: SafetyChecklistItemInput;
};


export type MutationDeleteSafetyChecklistItemArgs = {
  input: SafetyChecklistItemDeleteInput;
};


export type MutationCreateSiteSafetyArgs = {
  input: SiteSafetyInput;
};


export type MutationUpdateSiteSafetyArgs = {
  input: SiteSafetyInput;
};


export type MutationCreateSiteSafetyItemArgs = {
  input: SiteSafetyItemInput;
};


export type MutationUpdateSiteSafetyItemArgs = {
  input: SiteSafetyItemInput;
};


export type MutationCreateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationUpdateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationDeleteSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationCancelSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationUpdateWageCycleArgs = {
  input: WageCycleInput;
};


export type MutationCancelWageCycleArgs = {
  input: WageCycleDeleteInput;
};


export type MutationDeleteWageCycleArgs = {
  wageCycleID: Scalars['String'];
};


export type MutationCreateWageCycleArgs = {
  workerWageCycleIDs: Array<WorkerWageCycleInput>;
  input: WageCycleInput;
};


export type MutationUpdateWorkerVerifiedHourArgs = {
  workerRateIDs: Array<WorkerHourInput>;
};


export type MutationCreateWageCycleDetailArgs = {
  input: WageCycleDetailInput;
};


export type MutationUpdateWageCycleDetailArgs = {
  input: WageCycleDetailInput;
};


export type MutationDeleteWageCycleDetailArgs = {
  input: WageCycleDetailDeleteInput;
};


export type MutationCancelWageCycleDetailArgs = {
  input: WageCycleDetailDeleteInput;
};


export type MutationCreateWorkerAdvanceArgs = {
  input: WorkerAdvanceInput;
};


export type MutationUpdateWorkerAdvanceArgs = {
  input: WorkerAdvanceInput;
};


export type MutationDeleteWorkerAdvanceArgs = {
  input: WorkerAdvanceDeleteInput;
};


export type MutationCancelWorkerAdvanceArgs = {
  input: WorkerAdvanceDeleteInput;
};


export type MutationCreateWorkerWageArgs = {
  input: WorkerWageInput;
};


export type MutationUpdateWorkerWageArgs = {
  input: WorkerWageInput;
};


export type MutationDeleteWorkerWageArgs = {
  input: WorkerWageDeleteInput;
};


export type MutationCancelWorkerWageArgs = {
  input: WorkerWageDeleteInput;
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateAlertSqlCodeArgs = {
  allCode?: Maybe<Scalars['Boolean']>;
  startCode?: Maybe<Scalars['String']>;
  arrCode?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteExistPdfArgs = {
  description?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type MutationDeleteDocumentsArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationUploadPdfpoArgs = {
  input: LedgerPdfInput;
};


export type MutationUploadPdfDocumentArgs = {
  input: LedgerPdfInput;
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  contractID?: Maybe<Scalars['String']>;
};


export type MutationCreateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationUpdateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationDeleteContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationCreateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationUpdateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteCollectionActivityArgs = {
  input: CollectionActivityDeleteId;
};


export type MutationCreateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationDeleteColActivityArgs = {
  collectionActivityID: Scalars['String'];
};


export type MutationUpdateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationCreateMultipleCollectionActivityArgs = {
  input: Array<CollectionActivityInput>;
};


export type MutationUpdateCollectionStatusArgs = {
  input: CollectionActivityStatusInput;
};


export type MutationPatchNewDocNumArgs = {
  docCode: ContractDocNum;
};


export type MutationCreateDocNumRunningNumberArgs = {
  docCode: ContractDocNum;
  accountID: Scalars['String'];
};


export type MutationGenerateDocumentNumberSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationResetContractRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationContractRunNumCheckerArgs = {
  contractID: Scalars['String'];
};


export type MutationCreateContractRunNumArgs = {
  maincon: Scalars['Boolean'];
  contractID: Scalars['String'];
};


export type MutationDeleteDocRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationDeleteRawCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationAddContractContactArgs = {
  input: ContactPersonInput;
  contractID: Scalars['String'];
};


export type MutationAddSubcontractContactArgs = {
  input: ContactPersonInput;
  subcontractID: Scalars['String'];
};


export type MutationDeleteSubcontractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteContractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAssociateDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteLedgersWorkFlowArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationDeleteSpecificLedgersArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationDeletePoDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteClientLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSubconLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSupplierLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteCostDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeletePolicyDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteNotificationDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteWorkFlowDataArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateContractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubcontractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateClientDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubconDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSupplierDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdatePoDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateLedgersDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationRejectLedgerTableArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  columnStatus: Scalars['String'];
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationRemoveSuperUserAclArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchEditableAllocationArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchContractAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchSupplierAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchContractAllocationIDsArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchRejClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchAdjustmentDocRefDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchClaimBaseDocAmtDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCancelledAllocClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCndnClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRetentionClaimRejectArgs = {
  accountID?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
};


export type MutationPatchRetentionClaimDataArgs = {
  tableName: Scalars['String'];
};


export type MutationPatchDocumentsDataArgs = {
  insertData: Scalars['Boolean'];
  includeLogo: Scalars['Boolean'];
  accountIDs: Array<Scalars['String']>;
};


export type MutationPatchCompanyImageArgs = {
  accountIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchContractConSubconDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRealConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchVoDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchGuarInsDateDataArgs = {
  guarantee: Scalars['Boolean'];
  maincon: Scalars['Boolean'];
};


export type MutationPatchCompanyInAccountArgs = {
  deleteDoc: Scalars['Boolean'];
  type: Scalars['String'];
};


export type MutationPatchNullDocNoExpensesArgs = {
  accountID?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
};


export type MutationPatchCostCategoryDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchDueDateNullDataArgs = {
  accountID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type MutationPatchNullTrxDateDataArgs = {
  tableName: Scalars['String'];
};


export type MutationRemoveAllocDataArgs = {
  confirmDelete: Scalars['Boolean'];
  tableName: Scalars['String'];
};


export type MutationPatchPlantStatusDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchProjectExpenseArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchMainSiteDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertLongLatDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertSiteInventoryDataArgs = {
  siteID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPatchEmptyWorkflowArgs = {
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  IDs: Array<Scalars['String']>;
  refTable: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGeneralSettingSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGlGenSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationPlatformContactSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialCurrencySetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialSiteMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGeneralSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteGenSettingArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGlInterfaceSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialDocNumSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationUpdateProfitRecogParameterArgs = {
  name: Scalars['String'];
};


export type MutationCreateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationUpdateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationDeleteBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCancelBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCreateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationUpdateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationDeleteBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationCancelBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationSubmitBatchPaymentArgs = {
  detailInput: Array<BatchPaymentDetailInput>;
  headerInput: BatchPaymentHeaderInput;
};


export type MutationConfirmBatchPaymentArgs = {
  detailInput: Array<ApprovePaymentDetailInput>;
  companyID?: Maybe<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationPayBatchPaymentArgs = {
  detailIDs: Array<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationCreateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationUpdateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationCreateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationUpdateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationCreateMaterialPolicyArgs = {
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateMaterialContractPolicyArgs = {
  contractID: Scalars['String'];
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateTaxMasterFileArgs = {
  input: TaxInput;
};


export type MutationEditTaxMasterFileArgs = {
  input: EditTaxInput;
};


export type MutationDeleteTaxMasterFileArgs = {
  deleteInput: Array<DeleteTaxInput>;
  taxId: Scalars['String'];
};


export type MutationDeactivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationActivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationUpdateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationDeleteContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationReadNotificationArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type MutationMarkAsReadNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchWorkDeskDataArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationUpdateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationCreateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationUpdateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  forceDelete?: Maybe<Scalars['Boolean']>;
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationCreateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationUpdateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationDeleteAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertArgs = {
  input: AlertInput;
};


export type MutationDeleteAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertAssignmentArgs = {
  input: Array<AlertAssignmentInput>;
  isFromAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationActivateBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  ID: Scalars['String'];
};


export type MutationDeleteCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCancelCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationActivateCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCreateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationUpdateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationCreateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationUpdateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationDeleteCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCancelCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationActivateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCreateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationUpdateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationDeleteCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCancelCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationActivateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationUploadCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadCompanyIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
  companyID: Scalars['String'];
};


export type MutationUpdateWatermarkCompanyArgs = {
  companyID: Scalars['String'];
  input: CompanyLogoInput;
};


export type MutationUpdateCompanySignatureArgs = {
  companyID: Scalars['String'];
};


export type MutationDeleteCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCancelCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationActivateCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCreateBatchCostCategoryArgs = {
  input: Array<CostCategoryInput>;
};


export type MutationCreateBatchCostCategoryWithoutItemArgs = {
  input: Array<TreeCostCategoryInput>;
};


export type MutationCreateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationUpdateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationDeleteCostCategoryCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationAddCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationEditCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationDeleteTreeCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCostCategoryImportValidationArgs = {
  CostCategoryImportInput: Array<CostCategoryImportInput>;
};


export type MutationCostCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationDeleteCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationActivateCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationCreateCostItemArgs = {
  input: CostItemInput;
};


export type MutationUpdateCostItemArgs = {
  input: CostItemInput;
};


export type MutationCreateBatchCostItemArgs = {
  input: Array<CostItemInput>;
  costCategoryID: Scalars['String'];
};


export type MutationCancelCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCostItemImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCostItemImportValidationArgs = {
  CostItemImportInput: Array<CostItemImportInput>;
};


export type MutationCreateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationUpdateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationDeleteCurrencySetupArgs = {
  input: CurrencySetupDeleteInput;
};


export type MutationAddDigitalDocumentsArgs = {
  input: DigitalDocumentInput;
};


export type MutationDeleteDigitalDocumentsArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationCreateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationUpdateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationCreateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationUpdateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationInactiveDocTypeArgs = {
  ID: Scalars['String'];
};


export type MutationEditNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateDocNumSetupArgs = {
  input: DocNumInput;
  ID: Scalars['String'];
};


export type MutationCreateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationUpdateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationCreateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationUpdateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateSystemAdminDocNumArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationCreateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationUpdateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationDeleteHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCancelHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationActivateHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCreateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationUpdateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationDeleteJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCancelJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationActivateJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCreatePlatformSuperUserArgs = {
  softwareName: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePlatformUserArgs = {
  softwareCode: SoftwareCode;
  input: UserInput;
};


export type MutationUpdatePlatformUserArgs = {
  input: UserInput;
};


export type MutationCreatePlatformPasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePlatformPasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivatePlatformUserArgs = {
  token: Scalars['String'];
};


export type MutationPlatformLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePlatformPasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResetPlatformPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadProfilePicPlatformArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPlatformPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePolicyGuidelineArgs = {
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyGuidelineArgs = {
  input: Array<PolicyAssignmentInput>;
  docRefTable: Scalars['String'];
};


export type MutationCreatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
  ID: Scalars['String'];
};


export type MutationDeletePolicyAmountGuidelineArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  docRefTable: Scalars['String'];
};


export type MutationUpdateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  ID: Scalars['String'];
};


export type MutationCreatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationUpdatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationDeletePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCancelPublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationActivatePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationDeleteRevenueCategoryArgs = {
  input: RevenueCategoryDeleteInput;
};


export type MutationCancelRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationActivateRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationCreateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationUpdateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationCreateBatchRevenueCategoryArgs = {
  input: Array<RevenueCategoryInput>;
};


export type MutationRevenueCategoryImportValidationArgs = {
  RevenueCategoryImportInput: Array<RevenueCategoryImportInput>;
};


export type MutationRevenueCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationGoodReceiveNoteRpaArgs = {
  files: Array<Scalars['Upload']>;
  siteID?: Maybe<Scalars['String']>;
};


export type MutationInvoiceRpaArgs = {
  contractID: Scalars['String'];
  files: Array<Scalars['Upload']>;
};


export type MutationDeliveryOrderRpaArgs = {
  contractID: Scalars['String'];
  files: Scalars['Upload'];
};


export type MutationUpdateSAccountArgs = {
  input: SAccountInput;
};


export type MutationCreateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationUpdateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationDeleteSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCancelSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationActivateSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCreateBulkSiteMaterialArgs = {
  input: Array<SiteMaterialInput>;
};


export type MutationCreateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationUpdateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationCreateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationUpdateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationDeleteSubcontractorTypeArgs = {
  input: SubcontractorTypeDeleteInput;
};


export type MutationCancelSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationActivateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationCreateSubcontractorTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationUpdateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationDeleteSupplierTypeArgs = {
  input: SupplierTypeDeleteInput;
};


export type MutationCancelSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationActivateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationCreateSupplierTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCancelUomArgs = {
  input: UomDeleteInput;
};


export type MutationActivateUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationCreateBatchUomArgs = {
  input: Array<UomInput>;
};


export type MutationUomImportArgs = {
  batchID: Scalars['String'];
};


export type MutationUomImportValidationArgs = {
  input: Array<UomImportInput>;
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationUpdateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationAssignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationUnassignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationAssignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserEmailArgs = {
  input: UserInput;
};


export type MutationSendInvitationEmailArgs = {
  accountID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserWithPasswordArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationLoginArgs = {
  siteAccess?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUpdateLastEntityArgs = {
  companyID: Scalars['String'];
};


export type MutationCreateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationUpdateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationDeleteVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCancelVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationActivateVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCreateWagesRatePolicyArgs = {
  input: WagesRatePolicyInput;
};


export type MutationDeleteWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationCancelWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationActivateWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationUpdateWagesRatePolicyArgs = {
  holTypeRateIDs: Array<HolidayTypeRateInput>;
};


export type MutationCreateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationUpdateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationDeleteContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationCreateNotificationArgs = {
  body?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationCreateToDoArgs = {
  docNo: Scalars['String'];
  input: LedgerInput;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationApproveToDoArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationRejectToDoArgs = {
  remark: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCancelWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCreateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationUpdateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationCreateBulkWorkerArgs = {
  input: Array<WorkerInput>;
};


export type MutationUpdateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationDeleteWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCancelWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCreateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationCreateBatchWorkerAttendanceArgs = {
  input: Array<BatchWorkAttendanceInput>;
};


export type MutationApproveRejectWorkDeskArgs = {
  workDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateNotificationWorkflowArgs = {
  body?: Maybe<Scalars['String']>;
  workflowID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
};


export type MutationTestEmailArgs = {
  includeImage: Scalars['Boolean'];
};


export type MutationUpdateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationDeleteWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCancelWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationActivateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCreateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationCreateContractArgs = {
  siteInput: Array<SiteInput>;
  input: ContractInput;
};


export type MutationDeleteContractAllAccessArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  input: ContractInput;
};


export type MutationInsertContactsArgs = {
  contactInput: Array<ContactPersonInput>;
  maincon: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationActionOnContractStatusArgs = {
  input: ContractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractExtensionDateArgs = {
  input: ContractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCertificateArgs = {
  input: ContractCertificateInput;
  ID: Scalars['String'];
};


export type MutationCreateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationUpdateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationDeleteConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationActivateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationCreateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationUpdateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationDeleteConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationActivateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationCreateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationUpdateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationDeleteConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationActivateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationInsertConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationUpdateConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationUpdatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationDeleteSubcontractorArgs = {
  input: SubcontractorDeleteId;
};


export type MutationCreateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationUpdateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationAddSubcontractorContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorAndPackageArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorInput: SubcontractorInput;
};


export type MutationCreateBulkSubconArgs = {
  subcontractorInput: Array<BulkSubcontractorInput>;
};


export type MutationAddSubcontractorCCategoryArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationCreateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationUpdateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationDeleteSubcontractorSetupArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationDeleteSupplierArgs = {
  input: SupplierDeleteInput;
};


export type MutationCreateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationUpdateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationAddSupplierContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateBulkSupplierArgs = {
  supplierInput: Array<BulkSupplierInput>;
};


export type MutationAddCCategoryInSupplierArgs = {
  costCategoryID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAddSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationDeleteSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type NationalityTypes = {
  __typename?: 'NationalityTypes';
  nationality: Nationality;
};

export type NegotiatedSupplyEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  status: PurchaseStatus;
  negoSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
};

export type NegotiatedSupplyItemEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  negotiatedSupplyID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  balanceQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  costItem?: Maybe<CostItemEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  negoSupply?: Maybe<NegotiatedSupplyEntity>;
  poItemApproved: Array<PoItemEntity>;
  uomExchange?: Maybe<Array<UomExchange>>;
};

export type NotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'NotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationGuidelineEntity>;
};

export type NotificationGuidelineEntity = AuditEntity & {
  __typename?: 'NotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyID?: Maybe<Scalars['String']>;
  notiRoleUsers?: Maybe<Array<NotiRoleUserAssignmentEntity>>;
  /** CustomFieldResolver */
  roles: Scalars['JSON'];
};

export type NotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type NumberFormatInput = {
  softwareID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  documentDivider?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

export type Os = {
  __typename?: 'OS';
  amt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
};

export type PnmCategoryWorkshopEntity = AuditEntity & {
  __typename?: 'PNMCategoryWorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory: CostCategoryEntity;
  workshopID?: Maybe<Scalars['String']>;
  workshop: WorkshopEntity;
};

export type PnmCostCat = {
  __typename?: 'PNMCostCat';
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmCostCatInput = {
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmRentalEntity = AuditEntity & {
  __typename?: 'PNMRentalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  rentalRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  requireFuelSupply: Scalars['Boolean'];
  plantMachineryStatus: PlantMachineryStatus;
  company?: Maybe<SubscriptionCompanyType>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  rentalTankSummary?: Maybe<Scalars['JSON']>;
};


export type PnmRentalEntityRentalTankSummaryArgs = {
  companyID: Scalars['String'];
};

export type PoBudgetExceptionEntity = AuditEntity & {
  __typename?: 'POBudgetExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  remarks: Scalars['String'];
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  prItemID?: Maybe<Scalars['String']>;
  prItem?: Maybe<PrItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  nego?: Maybe<NegotiatedSupplyItemEntity>;
  rfqItemID?: Maybe<Scalars['String']>;
  rfqItem?: Maybe<RfqItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  currencyID?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  expectedDate?: Maybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
  taxAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  poItemVariance?: Maybe<Scalars['JSON']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<PoUomExchange>>;
};


export type PoItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  requestedQty: Scalars['Float'];
  outstandingQty: Scalars['Float'];
  expectedDate?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  negotiatedItemStatus: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<UomExchanges>>;
  negoSupplySelected?: Maybe<Scalars['JSON']>;
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
};


export type PrItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityNegoSupplySelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityRfqSubmissionSelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PackedDetail = {
  __typename?: 'PackedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PackedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PaymentReminderEntity = AuditEntity & {
  __typename?: 'PaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  reminderDate: Scalars['String'];
  commonStatus: CommonStatus;
  docReminder?: Maybe<Array<DocReminderEntity>>;
  /** CustomFieldResolver */
  payReminderTotal?: Maybe<Scalars['JSON']>;
};

export type PkkDetails = {
  __typename?: 'PkkDetails';
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PlantMachineryEntity = AuditEntity & {
  __typename?: 'PlantMachineryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  assignment?: Maybe<Array<AssignmentEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  tank?: Maybe<Array<TankEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
  /** CustomFieldResolver */
  latestActiveAssignment?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestActiveMaintenance?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  currentPnmStatus?: Maybe<Scalars['String']>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  tankSummary?: Maybe<Scalars['JSON']>;
};


export type PlantMachineryEntityTankSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export enum PlantMachineryStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  PartiallyAssigned = 'PARTIALLY_ASSIGNED',
  Maintenance = 'MAINTENANCE',
  OutOfOrder = 'OUT_OF_ORDER',
  Returned = 'RETURNED',
  InTransaction = 'IN_TRANSACTION'
}

export type PlatformLoginResponse = {
  __typename?: 'PlatformLoginResponse';
  accessToken: Scalars['String'];
  user: PlatformUserResponse;
};

export type PlatformUserEntity = AuditEntity & {
  __typename?: 'PlatformUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  contactID: Scalars['String'];
  contactDetail: ContactEntity;
  userName: Scalars['String'];
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type PlatformUserResponse = {
  __typename?: 'PlatformUserResponse';
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type PolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type PolicyGuidelineEntity = AuditEntity & {
  __typename?: 'PolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyRoles: Array<PolicyRoleAssignmentEntity>;
};

export type PolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type PolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'PolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
  policy?: Maybe<PolicyGuidelineEntity>;
};

export enum PostStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED'
}

export type PricingSupplierEntity = AuditEntity & {
  __typename?: 'PricingSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  price: Scalars['Float'];
  supplier?: Maybe<SupplierEntity>;
  costItem: CostItemEntity;
};

export type Product = {
  __typename?: 'Product';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogAssignmentEntity = AuditEntity & {
  __typename?: 'ProfitRecogAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogFormulaEntity = AuditEntity & {
  __typename?: 'ProfitRecogFormulaEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
};

export type ProfitRecogGlAccountCodeEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLAccountCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wipCostRecognized: Scalars['String'];
  wipBillingRecognized: Scalars['String'];
  revenuePL: Scalars['String'];
  costPL: Scalars['String'];
};

export type ProfitRecogGlJournalEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  wipCostRecAccCode: Scalars['String'];
  wipBillingRecAccCode: Scalars['String'];
  revenuePLAccCode: Scalars['String'];
  costPLAccCode: Scalars['String'];
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGenParamAssgmtEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenParamAssgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  profitRecogParameterID: Scalars['String'];
  amount: Scalars['Float'];
  profitRecogParameter: ProfitRecogParameterEntity;
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGenerationEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogParameterEntity = AuditEntity & {
  __typename?: 'ProfitRecogParameterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  sqlScript: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
};

export enum ProfitRecogStatus {
  Active = 'ACTIVE',
  Recognized = 'RECOGNIZED',
  Cancelled = 'CANCELLED'
}

export type ProjectBudgetEntity = AuditEntity & {
  __typename?: 'ProjectBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  costCategoryID: Scalars['String'];
  costCateg: CostCategoryEntity;
  budgetAmt?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectCostingEntity = AuditEntity & {
  __typename?: 'ProjectCostingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  balanceToSpend: Scalars['Float'];
  projectCostAmt: Scalars['Float'];
};

export type ProjectExpenseEntity = AuditEntity & {
  __typename?: 'ProjectExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  cancellationDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
};

export type ProjectExpenseItemEntity = AuditEntity & {
  __typename?: 'ProjectExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  projectExpenseID: Scalars['String'];
  projectExpense?: Maybe<ProjectExpenseEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
};

export type ProjectHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLog = {
  __typename?: 'ProjectOpportunityActivityLog';
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLogInput = {
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityAttachmentEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  title: Scalars['String'];
  date: Scalars['String'];
};

export type ProjectOpportunityEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  title: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  amount: Scalars['Float'];
  personInChargeID: Scalars['String'];
  closingDateTime: Scalars['DateTime'];
  remarks: Scalars['String'];
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  status: ProjectOpportunityStatus;
  projectOpportunitySubmission?: Maybe<Array<ProjectOpportunitySubmissionEntity>>;
  projectOpportunityAttachment?: Maybe<Array<ProjectOpportunityAttachmentEntity>>;
  personInCharge?: Maybe<UserEntity>;
};

export enum ProjectOpportunityStatus {
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  Signed = 'SIGNED',
  Lost = 'LOST'
}

export type ProjectOpportunitySubmissionEntity = AuditEntity & {
  __typename?: 'ProjectOpportunitySubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  submissionDate: Scalars['String'];
  tenderAmt?: Maybe<Scalars['Float']>;
  remarks: Scalars['String'];
};

export type ProjectPlanningEntity = AuditEntity & {
  __typename?: 'ProjectPlanningEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  totalIncome: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type PublicHolidayDeleteInput = {
  ID: Scalars['String'];
};

export type PublicHolidayEntity = AuditEntity & {
  __typename?: 'PublicHolidayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  calendarConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  commonStatus: CommonStatus;
};

export type PublicHolidayInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};

export type PurchaseOrderEntity = AuditEntity & {
  __typename?: 'PurchaseOrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPerson>;
  exchangeRate: Scalars['Float'];
  totalAmt: Scalars['Float'];
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  creditTerm: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  prGenerated: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  purchaseStatus: PurchaseStatus;
  posConnection?: Maybe<Array<DoItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  estimatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type PurchaseOrderEntityUpToDateAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type PurchaseOrderEntityEstimatedAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type PurchaseReqEntity = AuditEntity & {
  __typename?: 'PurchaseReqEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  remarks?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  submittedDate?: Maybe<Scalars['String']>;
  prDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  contactPerson: Scalars['JSON'];
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  toDo: Array<ContractToDoEntity>;
  purchaseRFQItem: Array<PrItemEntity>;
  /** CustomFieldResolver */
  isOutstanding?: Maybe<Scalars['Boolean']>;
};

export enum PurchaseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum PurchaseType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export type QualificationChecklistEntity = AuditEntity & {
  __typename?: 'QualificationChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  includeAttachment: Scalars['Boolean'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type QualificationChecklistItemEntity = AuditEntity & {
  __typename?: 'QualificationChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  qualificationChecklistID: Scalars['String'];
  qualificationChecklist?: Maybe<QualificationChecklistEntity>;
  fieldTypeID: Scalars['String'];
  fieldType?: Maybe<FieldTypeEntity>;
  title: Scalars['String'];
  isCompulsory: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  negoItemInfo: Scalars['JSON'];
  negoItemCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  lastPRDate?: Maybe<Scalars['String']>;
  lastPRDateOB?: Maybe<Scalars['String']>;
  rfqInfo: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  lastRFQDate?: Maybe<Scalars['String']>;
  poInfoCount: Scalars['JSON'];
  lastPODate?: Maybe<Scalars['String']>;
  lastPODateOB?: Maybe<Scalars['String']>;
  doCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  defectLodgeOverTimeSummary: Scalars['JSON'];
  defectStatusAnalysisSummary: Scalars['JSON'];
  defectSubconAnalysisSummary: Scalars['JSON'];
  defectTradeAnalysisSummary: Scalars['JSON'];
  getSiteWorkerAttendanceTracking: Scalars['JSON'];
  getWorkerAnalysis: Scalars['JSON'];
  getWageLabourAnalysis: Scalars['JSON'];
  getManLabourAnalysis: Scalars['JSON'];
  getDefect: Array<DefectEntity>;
  getDefectAssignment: Array<DefectAssignmentEntity>;
  getDefectMgmt: Array<DefectManagementEntity>;
  getSubconDefect: Array<DefectManagementEntity>;
  getDiaryType: Array<DiaryTypeEntity>;
  getProjectSite: Array<ContractEntity>;
  getSite: Array<SiteEntity>;
  verifySite: Scalars['String'];
  getSiteWithAccount: Scalars['JSON'];
  getSiteNameWithAccount: Scalars['JSON'];
  getGRN: Array<GrnEntity>;
  getGRNCount: Scalars['Float'];
  getGRNItem: Array<GrnItemEntity>;
  getCostItemMovementCategories: Scalars['JSON'];
  getAllCostItem: Scalars['JSON'];
  getAllCostStockCode: Scalars['JSON'];
  getCostItemMovementAnalysis: Scalars['JSON'];
  getCostItemMoveInOutAnalysis: Scalars['JSON'];
  getCostItemMovementByDayAnalysis: Scalars['JSON'];
  getCostItemMonthYear: Scalars['JSON'];
  costItemSiteUsageSummary: Scalars['JSON'];
  costItemSiteUsageComparison: Scalars['JSON'];
  costItemMovementChecking: Scalars['Float'];
  getCostItemMovement: Scalars['JSON'];
  getCostItemInStock: Scalars['JSON'];
  getCostItemCategory: Scalars['JSON'];
  getStockMovement: Array<StockMovementEntity>;
  getStockMovementCount: Scalars['Float'];
  getStockMovementCategories: Scalars['JSON'];
  getAllSiteItem: Scalars['JSON'];
  getAllSiteStockCode: Scalars['JSON'];
  getStockMovementAnalysis: Scalars['JSON'];
  getStockMoveInOutAnalysis: Scalars['JSON'];
  getStockMonthYear: Scalars['JSON'];
  getStockMovementByDayAnalysis: Scalars['JSON'];
  stockSiteUsageSummary: Scalars['JSON'];
  stockSiteUsageComparison: Scalars['JSON'];
  stockMovementChecking: Scalars['Float'];
  getSiteMaterialInStock: Scalars['JSON'];
  getCheckInStock: Scalars['Boolean'];
  getStockCategory: Scalars['JSON'];
  getStockMovementOffset: StockMovementData;
  getStockMovementItemDetail: Scalars['JSON'];
  getStockCostItemSummary: Scalars['JSON'];
  getSiteProgress: Array<SiteProgressEntity>;
  getSiteStockLedger: Array<SiteStockLedgerEntity>;
  getSiteStockItems: Scalars['JSON'];
  getStockItemDetail: Scalars['JSON'];
  getDOBySiteID: Scalars['JSON'];
  getSiteWorkerAttendance: Array<WorkerAttendanceEntity>;
  getChecklistCategory: Array<ChecklistCategoryEntity>;
  getSafetyChecklist: Array<SafetyChecklistEntity>;
  getSafetyChecklistItem: Array<SafetyChecklistItemEntity>;
  getSiteSafety: Array<SiteSafetyEntity>;
  getSiteSafetyItem: Array<SiteSafetyItemEntity>;
  getSiteImage: Array<SiteImageEntity>;
  getWageCycle: Array<WageCycleEntity>;
  getWorkerWageAdvance: Scalars['JSON'];
  getUnpaidWageAdvance: Scalars['JSON'];
  getUnpaidWorkerJson: Scalars['JSON'];
  getUnpaidWorker: Array<WorkerEntity>;
  getPaidWorker: Array<WorkerEntity>;
  getWorkingSchedule: Scalars['Boolean'];
  getWageCycleDetail: Array<WageCycleDetailEntity>;
  getWorkerCycleDetail: Scalars['JSON'];
  getWorkerAdvance: Array<WorkerAdvanceEntity>;
  getWorkerWage: Array<WorkerWageEntity>;
  getWorkerWageVerification: Array<WorkerWageEntity>;
  getWorkerWageBook: Array<WorkerEntity>;
  getWorkerSalary: Scalars['JSON'];
  getDrawerPermission: Scalars['JSON'];
  getSiteDrawerPermission: Scalars['JSON'];
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  siteTestPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  testSampleEnum: Scalars['String'];
  checkDocExist?: Maybe<Array<DocumentsEntity>>;
  DocumentListing?: Maybe<Array<DocumentsEntity>>;
  cmgdAttachment?: Maybe<Array<DocumentsEntity>>;
  totalDigitalDoc: Scalars['Float'];
  auditLogDetails: Scalars['JSON'];
  getCashFlowLineChartData: Scalars['JSON'];
  getCashFlowGraphData: Scalars['JSON'];
  getContractNews: Array<ContractNewsEntity>;
  getContractNewsRead: Array<ContractNewsReadEntity>;
  getCollectionActivity: Array<CollectionActivityEntity>;
  getCompanyHOR: Array<CompanyEntity>;
  getCompany: Array<CompanyEntity>;
  getRawCompany: Array<SubscriptionCompanyType>;
  getSubscriptionCurrency: Array<SubscriptionCurrencyType>;
  getContact: Array<ContactEntity>;
  getAdjustmentType: Array<AdjustmentTypeEntity>;
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  totalDocFolder: Scalars['Float'];
  getLocalBank: Array<LocalBankEntity>;
  getProfitRecogFormulaScript: Scalars['String'];
  getDate: Scalars['JSON'];
  submittedRetClaimChecking: Scalars['JSON'];
  submittedClaimChecking?: Maybe<Scalars['JSON']>;
  approvedClaimChecking: ClaimSummary;
  cancellableClaims: Array<Scalars['String']>;
  approvableLedgerChecking: LedgerCheckingSummary;
  getBatchPaymentDetail: Array<BatchPaymentDetailEntity>;
  getBatchPaymentDetailbySupplier: Scalars['JSON'];
  getBatchPaymentHeader: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatus: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatusCount: Scalars['Float'];
  getContractLedgers: Scalars['JSON'];
  getSubcontractorLedgers: Scalars['JSON'];
  getSupplierLedgers: Scalars['JSON'];
  getSiteMaterialPolicy: Array<SiteMaterialPolicyEntity>;
  getSiteMaterialContractPolicy: Array<SiteMaterialContractPolicyEntity>;
  getDefaultMaterialPolicyIDs: Array<Scalars['String']>;
  getSiteMaterialPolicyChecker: Scalars['JSON'];
  calendarTypeDate?: Maybe<Scalars['DateTime']>;
  holidayTypeDate?: Maybe<Scalars['DateTime']>;
  jobTypeDate?: Maybe<Scalars['DateTime']>;
  projectSiteDate?: Maybe<Scalars['DateTime']>;
  publicHolidayDate?: Maybe<Scalars['DateTime']>;
  siteMaterialDate?: Maybe<Scalars['DateTime']>;
  wagesRatePolicyDate?: Maybe<Scalars['DateTime']>;
  workingHourPolicyDate?: Maybe<Scalars['DateTime']>;
  videoCamDate?: Maybe<Scalars['DateTime']>;
  subconSetupDate?: Maybe<Scalars['DateTime']>;
  workerProfileDate?: Maybe<Scalars['DateTime']>;
  siteMaterialPolicyDate?: Maybe<Scalars['DateTime']>;
  associateMenu: Scalars['JSON'];
  totalClients: Scalars['Float'];
  pendingCollectionActivity?: Maybe<Scalars['JSON']>;
  paymentReminderSummary?: Maybe<Scalars['JSON']>;
  clientNotifications: Scalars['JSON'];
  totalDebitClient: Scalars['Float'];
  totalCreditClient: Scalars['Float'];
  totalOutstandingClients: Scalars['Float'];
  totalOsClientInvoices: ClientSoaInfo;
  totalOutstandingAmtClients?: Maybe<Scalars['Float']>;
  supplierProfileDate?: Maybe<Scalars['DateTime']>;
  PrefSupplierProductDate?: Maybe<Scalars['DateTime']>;
  SupplierAccDate?: Maybe<Scalars['DateTime']>;
  contractInfo: ContractInfo;
  contractGuaranteeInfo: Scalars['JSON'];
  contractInsuranceInfo: Scalars['JSON'];
  submittedVONotification: Scalars['Float'];
  contractVO: ContractVo;
  outstandingAdvances: Array<ContractAdvanceEntity>;
  outstandingClaims: Array<ContractClaimEntity>;
  outstandingMiscInvs: Array<ContractMiscInvoiceEntity>;
  retention: RetentionInfo;
  lastSiteProgressDate?: Maybe<Scalars['DateTime']>;
  wbsBudgetInfo?: Maybe<Scalars['JSON']>;
  wbsBudgetRevisionInfo?: Maybe<Scalars['JSON']>;
  utilizedBudgetInfo?: Maybe<Scalars['JSON']>;
  budgetTransferInfo?: Maybe<Scalars['JSON']>;
  lastBqImportDate?: Maybe<Scalars['DateTime']>;
  bqAssignmentInfo?: Maybe<Scalars['JSON']>;
  miscExpInfo?: Maybe<Scalars['JSON']>;
  lastMiscExpDate?: Maybe<Scalars['DateTime']>;
  lastExtensionDate?: Maybe<Scalars['String']>;
  contractClosureDate?: Maybe<Scalars['DateTime']>;
  subcontractInfo: SubontractInfo;
  subconGuaranteeInfo: Scalars['JSON'];
  subconInsuranceInfo: Scalars['JSON'];
  submittedVOSubconNotification: Scalars['Float'];
  subcontractVO: SubcontractVo;
  subconOutstandingAdvances: Array<SubcontractAdvanceEntity>;
  subconOutstandingClaims: Array<SubcontractClaimEntity>;
  subconLastExtensionDate?: Maybe<Scalars['String']>;
  subconClosureDate?: Maybe<Scalars['DateTime']>;
  latestDefectDate?: Maybe<Scalars['DateTime']>;
  latestAssignmentDate?: Maybe<Scalars['DateTime']>;
  siteWorkerAttendanceInfo: Scalars['JSON'];
  MOSCount: Scalars['Float'];
  glExportDate?: Maybe<Scalars['DateTime']>;
  cbExportDate?: Maybe<Scalars['DateTime']>;
  costCategoryDates?: Maybe<Scalars['JSON']>;
  costItemDates?: Maybe<Scalars['JSON']>;
  costCategoryDate?: Maybe<Scalars['DateTime']>;
  costItemDate?: Maybe<Scalars['DateTime']>;
  revenueCategoryDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeDate?: Maybe<Scalars['DateTime']>;
  workshopDate?: Maybe<Scalars['DateTime']>;
  paymentReminderDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyWithContractDate?: Maybe<Scalars['DateTime']>;
  supplierTypeDate?: Maybe<Scalars['DateTime']>;
  subcontractorTypeDate?: Maybe<Scalars['DateTime']>;
  glAccountCodeDate?: Maybe<Scalars['DateTime']>;
  clientSetupDate?: Maybe<Scalars['DateTime']>;
  supplierSetupDate?: Maybe<Scalars['DateTime']>;
  clientAccDate?: Maybe<Scalars['DateTime']>;
  subconAccDate?: Maybe<Scalars['DateTime']>;
  supplierAccDate?: Maybe<Scalars['DateTime']>;
  purchaseAccDate?: Maybe<Scalars['DateTime']>;
  pobAccDate?: Maybe<Scalars['DateTime']>;
  bankAccDate?: Maybe<Scalars['DateTime']>;
  taxAccDate?: Maybe<Scalars['DateTime']>;
  tradeAccDate?: Maybe<Scalars['DateTime']>;
  materialAccDate?: Maybe<Scalars['DateTime']>;
  plantAccDate?: Maybe<Scalars['DateTime']>;
  labourAccDate?: Maybe<Scalars['DateTime']>;
  miscAccDate?: Maybe<Scalars['DateTime']>;
  departmentDate?: Maybe<Scalars['DateTime']>;
  journalAccDate?: Maybe<Scalars['DateTime']>;
  userDate: Scalars['DateTime'];
  roleDate?: Maybe<Scalars['DateTime']>;
  conAuthDate?: Maybe<Scalars['DateTime']>;
  conAuthCount: Scalars['Float'];
  docNumDate?: Maybe<Scalars['DateTime']>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  companyDate?: Maybe<Scalars['DateTime']>;
  policyDate?: Maybe<Scalars['DateTime']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  costCenterDate?: Maybe<Scalars['DateTime']>;
  currencyExchangeDate?: Maybe<Scalars['DateTime']>;
  currencySetupDate?: Maybe<Scalars['DateTime']>;
  activeClientCount: Scalars['Float'];
  clientCount: Scalars['JSON'];
  contractCount: Scalars['JSON'];
  activeContractCount: Scalars['Float'];
  activeSiteCount: Scalars['Float'];
  subcontractCount: Scalars['JSON'];
  activeSubcontractCount: Scalars['Float'];
  activeSubcontractTenderCount: Scalars['Float'];
  subcontractorCount: Scalars['JSON'];
  activeSubcontractorCount: Scalars['Float'];
  supplierCount: Scalars['JSON'];
  activeSupplierCount: Scalars['Float'];
  activePlantMachineryCount: Scalars['Float'];
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  projectPurchaseCount?: Maybe<Scalars['JSON']>;
  fuelInfo: Scalars['JSON'];
  ownedPNMInfo: Scalars['JSON'];
  rentedPNMInfo: Scalars['JSON'];
  assignmentRecord: Scalars['Float'];
  maintenanceRecord: Scalars['Float'];
  fuelTankCount: Scalars['JSON'];
  clientProfileDate: Scalars['String'];
  submittedCusAVNotification: Scalars['Float'];
  advancesInfo?: Maybe<Scalars['JSON']>;
  claimsInfo?: Maybe<Scalars['JSON']>;
  submittedClaimNotification: Scalars['Float'];
  progressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedRetClaimNotification: Scalars['Float'];
  retentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedCNNotification: Scalars['Float'];
  CNInfo?: Maybe<Scalars['JSON']>;
  submittedDNNotification: Scalars['Float'];
  DNInfo?: Maybe<Scalars['JSON']>;
  submittedMiscInvNotification: Scalars['Float'];
  miscInvInfo?: Maybe<Scalars['JSON']>;
  submittedClientDNNotification: Scalars['Float'];
  clientDNInfo?: Maybe<Scalars['JSON']>;
  submittedClientCNNotification: Scalars['Float'];
  clientCNInfo?: Maybe<Scalars['JSON']>;
  submittedReceiptNotification: Scalars['Float'];
  receiptsInfo?: Maybe<Scalars['JSON']>;
  submittedRefundNotification: Scalars['Float'];
  refundsInfo?: Maybe<Scalars['JSON']>;
  outstandingClient?: Maybe<Scalars['JSON']>;
  unallocatedClient?: Maybe<Scalars['JSON']>;
  conClientProfileDate: Scalars['DateTime'];
  conAdvancesInfo?: Maybe<Scalars['JSON']>;
  conClaimsInfo?: Maybe<Scalars['JSON']>;
  conDNInfo?: Maybe<Scalars['JSON']>;
  conCNInfo?: Maybe<Scalars['JSON']>;
  conReceiptsInfo?: Maybe<Scalars['JSON']>;
  conRefundsInfo?: Maybe<Scalars['JSON']>;
  conClientDNInfo?: Maybe<Scalars['JSON']>;
  conOutstandingClient?: Maybe<Scalars['JSON']>;
  subconProfileDate?: Maybe<Scalars['String']>;
  subconLedgersInfo?: Maybe<Scalars['JSON']>;
  submittedSubconAVNotification: Scalars['Float'];
  subconAdvancesInfo?: Maybe<Scalars['JSON']>;
  subconClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconClaimNotification: Scalars['Float'];
  subconProgressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRetClaimNotification: Scalars['Float'];
  subconRetentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconMiscInvNotification: Scalars['Float'];
  subconMiscInvInfo?: Maybe<Scalars['JSON']>;
  submittedSubconDNNotification: Scalars['Float'];
  subconDNInfo?: Maybe<Scalars['JSON']>;
  submittedSubconCNNotification: Scalars['Float'];
  subconCNInfo?: Maybe<Scalars['JSON']>;
  submittedPaymentNotification: Scalars['Float'];
  subconPaymentInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRefundNotification: Scalars['Float'];
  subconRefundsInfo?: Maybe<Scalars['JSON']>;
  submittedDNtoSubconNotification: Scalars['Float'];
  DNtoSubconInfo?: Maybe<Scalars['JSON']>;
  submittedCNtoSubconNotification: Scalars['Float'];
  CNtoSubconInfo?: Maybe<Scalars['JSON']>;
  outstandingSubcon?: Maybe<Scalars['JSON']>;
  unallocatedSubcon?: Maybe<Scalars['JSON']>;
  suppProfileDate: Scalars['DateTime'];
  submittedDepositNotification: Scalars['Float'];
  supplierDepositsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierInvNotification: Scalars['Float'];
  submittedSupplierInvWoDoNotification: Scalars['Float'];
  supplierInvInfo?: Maybe<Scalars['JSON']>;
  supplierInvWoDoInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierDNNotification: Scalars['Float'];
  supplierDNInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierCNNotification: Scalars['Float'];
  supplierCNInfo?: Maybe<Scalars['JSON']>;
  submittedDNToSupplierNotification: Scalars['Float'];
  DNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedCNToSupplierNotification: Scalars['Float'];
  CNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierPaymentNotification: Scalars['Float'];
  supplierPaymentsInfo?: Maybe<Scalars['JSON']>;
  supplierBatchPaymentsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierRefundNotification: Scalars['Float'];
  supplierRefundsInfo?: Maybe<Scalars['JSON']>;
  outstandingSupplier?: Maybe<Scalars['JSON']>;
  unallocatedSupplier?: Maybe<Scalars['JSON']>;
  lastStockMovementDate?: Maybe<Scalars['DateTime']>;
  totalSiteCount: Scalars['Float'];
  totalStockIn: Scalars['Float'];
  totalStockOut: Scalars['Float'];
  totalDefect: Scalars['Float'];
  totalSiteSurveillance: Scalars['Float'];
  todayTotalAttendance: Scalars['JSON'];
  workerCount?: Maybe<Scalars['Float']>;
  lastGRNDate?: Maybe<Scalars['DateTime']>;
  stockMovementCount: Scalars['JSON'];
  lastStockMovementDates?: Maybe<Scalars['JSON']>;
  siteImagesCount: Scalars['JSON'];
  videoCamCount: Scalars['Float'];
  workerAttendanceCount?: Maybe<Scalars['JSON']>;
  getDailyRegisteredWorkHours?: Maybe<Scalars['JSON']>;
  getDailyWagesVerification?: Maybe<Scalars['JSON']>;
  getWagesBook?: Maybe<Scalars['JSON']>;
  wageCycleClosureInfo?: Maybe<Scalars['JSON']>;
  getTax: TaxEntity;
  getTaxListing: Array<TaxListingOutput>;
  getTaxSchemeTypeListing: Array<TaxSchemeTypeListingOutput>;
  getTaxSchemeTypeSortBy: Array<TaxSchemeTypeSortByOutput>;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxSchemeWithPerm: Array<TaxSchemeEntity>;
  getTaxSchemeDetail: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailWithPerm: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
  getTaxSchemeDetailItemWithPerm: Array<TaxSchemeDetailEntity>;
  getContractNotification: Array<ContractNotificationEntity>;
  getNotificationListing: Array<Scalars['JSON']>;
  getStandardParameter: Array<Scalars['JSON']>;
  testImagePath: Scalars['JSON'];
  getRole: Array<RoleEntity>;
  getRoleSetup: Array<RoleEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getPolicyRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getSitePermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Scalars['JSON'];
  getRoleMatrix: Scalars['JSON'];
  getRoleMatrixByIds: Scalars['JSON'];
  getRoleByIds: Array<RoleEntity>;
  getAlert: Array<AlertEntity>;
  getAlertList: Array<AlertEntity>;
  getShowAlertSQL: Scalars['JSON'];
  getAlertAssignment: Array<AlertAssignmentEntity>;
  getApprovalPolicyGuideline: Array<ApprovalPolicyEntity>;
  getApprovalPolicyAssignmentGuideline: Array<ApprovalPolicyAssignmentEntity>;
  getApprovalPolicy?: Maybe<Array<ApprovalPolicyEntity>>;
  getApprovalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountWithPerm: Array<BankAccountEntity>;
  getCalendar: Array<CalendarEntity>;
  getCalendarPHPolicy: Array<CalendarPhPolicyEntity>;
  getCalendarWeekendPolicy: Array<CalendarWeekendPolicyEntity>;
  logoView?: Maybe<DocumentsEntity>;
  WatermarkView: DocumentsEntity;
  getCostCategory: Array<CostCategoryEntity>;
  getActivePNMCostCategory: Array<CostCategoryEntity>;
  getCostCategoryByClass: Scalars['JSON'];
  getMapCostCategory: Scalars['JSON'];
  getParentChildrenCostCategory: Scalars['JSON'];
  getCostCategoryTree: Scalars['JSON'];
  getSubcontractTradeCostCategory: Scalars['JSON'];
  getNonSelectedCostCategory: Scalars['JSON'];
  getCostItem: Array<CostItemEntity>;
  getCostItemCount: Scalars['Float'];
  getCostItemBySupplier: Array<CostItemEntity>;
  getItemByClass: Array<CostItemEntity>;
  getCostItemByClasses: Array<CostItemEntity>;
  getCostItemPagination: Array<CostItemEntity>;
  getCostCategoryItem: Scalars['JSON'];
  getCostItemByWbs: Scalars['JSON'];
  getMapCostItem: Scalars['JSON'];
  getCostItemByWbsIDs: Scalars['JSON'];
  checkingBudgetQty: Scalars['JSON'];
  getCurrency: Array<CurrencyEntity>;
  getCurrencySetup: Array<CurrencyNoteEntity>;
  getDigitalDocument: Scalars['JSON'];
  getTableDocuments: Array<DigitalDocuments>;
  totalDigitalDocuments: Scalars['JSON'];
  getNumberFormat: Array<ConNumberFormatEntity>;
  getConDocType: Array<ConDocTypeEntity>;
  getDocumentType: Array<ConDocTypeEntity>;
  getDocType: Array<Scalars['JSON']>;
  fetchNextDocNum: Scalars['String'];
  fetchMultipleDocNum: Scalars['JSON'];
  getRunningNumberSetupWithLock: Scalars['Float'];
  /** if true, runningNum is editable */
  runningNumChecking: Scalars['Boolean'];
  projMiscExpRunNumChecking: Scalars['Boolean'];
  getDocNumHeader: Array<DocNumHeaderEntity>;
  getDocNumDetail: Array<DocNumDetailEntity>;
  getSysAdminDocType: Array<Scalars['JSON']>;
  getDocumentNumberHeaderAndDetail: Array<DocNumDetailEntity>;
  getClassificationCode: Array<EInvoiceClassificationEntity>;
  getClassificationCodeCount: Scalars['Float'];
  getGeneralTin: Array<EInvoiceGeneralTinEntity>;
  getGeneralTinCount: Scalars['Float'];
  getMsicCode: Array<EInvoiceMsicEntity>;
  getMsicCodeCount: Scalars['Float'];
  getHolidayType: Array<HolidayTypeEntity>;
  getJobType: Array<JobTypeEntity>;
  loggedInPlatformUserProfile: PlatformUserEntity;
  activePlatformUser: Scalars['Float'];
  isPlatformUserValid: Scalars['Boolean'];
  getPolicyGuideLine: Array<PolicyGuidelineEntity>;
  getPolicyRoleAssignment: Array<PolicyRoleAssignmentEntity>;
  getNotificationGuideLine: Array<NotificationGuidelineEntity>;
  getNotiRoleUserAssignment: Array<NotiRoleUserAssignmentEntity>;
  getPublicHoliday: Array<PublicHolidayEntity>;
  getPublicHolidayByStateYear: Scalars['JSON'];
  getRevenueCategory: Array<RevenueCategoryEntity>;
  getRevenueCategoryCount: Scalars['Float'];
  getSAccount: Array<SAccountEntity>;
  getSiteMaterial: Array<SiteMaterialEntity>;
  getSiteWorkers: Array<WorkerEntity>;
  getSubcontractorType: Array<SubcontractorTypeEntity>;
  getSupplierType: Array<SupplierTypeEntity>;
  getUOM: Array<UomEntity>;
  getUOMWithPerm: Array<UomEntity>;
  getUOMCount: Scalars['Float'];
  getUOMWithPermCount: Scalars['Float'];
  getUOMExchange: Array<UomExchangeEntity>;
  getUOMExchangeWithPerm: Array<UomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getUOMExchangeListByWbs: Scalars['JSON'];
  getUserCompanyAssignment: Array<UserCompanyAssignmentEntity>;
  submittedByLedger: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getVideoCam: Array<VideoCamEntity>;
  getWagesRatePolicy: Array<WagesRatePolicyEntity>;
  getContractToDo: Array<ContractToDoEntity>;
  getToDoListing?: Maybe<Array<Scalars['JSON']>>;
  workDeskCount: Scalars['Float'];
  toDoCount: Scalars['Float'];
  notificationCount: Scalars['Float'];
  getWorker: Array<WorkerEntity>;
  getWorkerSiteAssignment: Array<WorkerSiteAssignmentEntity>;
  getWorkerFromSite: Array<WorkerEntity>;
  checkingWageType: Scalars['Boolean'];
  getWorkerAttendance: Array<WorkerAttendanceEntity>;
  checkWorkFlow: Scalars['JSON'];
  getToDoWorkflowSummary: Scalars['JSON'];
  getToDoAllPendingDocs: Scalars['JSON'];
  policyChecking?: Maybe<Scalars['JSON']>;
  policyCheckerAPPayment?: Maybe<Scalars['JSON']>;
  getNotificationWorkflowListing: Scalars['JSON'];
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getAllApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  approvableLedgerWFChecking: Scalars['JSON'];
  getWorkingHourPolicy: Array<WorkingHourPolicyEntity>;
  getContract: Array<ContractEntity>;
  getCustomContract: Array<ContractEntity>;
  contractVoCalculation: Scalars['JSON'];
  maxRetention: Scalars['Float'];
  claimChecking: Scalars['Boolean'];
  voChecking: Scalars['Boolean'];
  retentionChecking: Scalars['Boolean'];
  validateContractStatus?: Maybe<Scalars['Boolean']>;
  getContractWithoutPerm: Array<ContractEntity>;
  getContractEntityWithAccount: Array<ContractEntity>;
  getContractWithAccount: Scalars['JSON'];
  getContractTitleWithAccount: Scalars['JSON'];
  getContractWithActiveDO: Array<ContractEntity>;
  checkWbsExist: Scalars['Boolean'];
  getUserRoleAssigned: Array<RoleEntity>;
  getConTaxScheme: Array<ConTaxSchemeEntity>;
  getConTaxSchemeWithPerm: Array<ConTaxSchemeEntity>;
  getConTaxEffectiveDate: Array<ConTaxEffectiveDateEntity>;
  getConTaxType: Array<ConTaxTypeEntity>;
  verifyTaxType: Scalars['String'];
  getTaxCodeGLExport: Scalars['JSON'];
  getFilterTaxCode: Scalars['JSON'];
  getSubcontractor: Array<SubcontractorEntity>;
  getSubcontractorCCategory: Array<SubcontractorCCategoryEntity>;
  getSubcontractorCostCategory: Scalars['JSON'];
  getCostCategoryBySubcon: Scalars['JSON'];
  getSubcontractorAccessibleContract: Scalars['JSON'];
  getSupplier: Array<SupplierEntity>;
  getSupplierCCategory: Array<SupplierCCategoryEntity>;
  getMultiSupplier: Array<SupplierEntity>;
  getActiveSupplier: Scalars['JSON'];
  getSupplierCostCategory: Scalars['JSON'];
};


export type QueryNegoItemInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryNegoItemCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateObArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastRfqDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateObArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryDoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGrtnCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryDefectLodgeOverTimeSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryDefectStatusAnalysisSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryDefectSubconAnalysisSummaryArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryDefectTradeAnalysisSummaryArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWorkerAttendanceTrackingArgs = {
  workDate: Scalars['String'];
  workerID: Scalars['String'];
};


export type QueryGetWorkerAnalysisArgs = {
  startDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWageLabourAnalysisArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetManLabourAnalysisArgs = {
  siteID?: Maybe<Scalars['String']>;
  selectedYear: Scalars['Float'];
};


export type QueryGetDefectArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
};


export type QueryGetDefectAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  defectID?: Maybe<Scalars['String']>;
};


export type QueryGetDefectMgmtArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
};


export type QueryGetSubconDefectArgs = {
  subcontractID: Scalars['String'];
};


export type QueryGetDiaryTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectSiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
};


export type QueryVerifySiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetGrnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  grnStatus?: Maybe<Scalars['String']>;
};


export type QueryGetGrnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  grnStatus?: Maybe<Scalars['String']>;
};


export type QueryGetGrnItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemMovementCategoriesArgs = {
  siteID: Scalars['String'];
};


export type QueryGetAllCostItemArgs = {
  siteIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAllCostStockCodeArgs = {
  siteIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostItemMovementAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemMoveInOutAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemMovementByDayAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemMonthYearArgs = {
  isBothStatus?: Maybe<Scalars['Boolean']>;
  siteIDs?: Maybe<Array<Scalars['String']>>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryCostItemSiteUsageSummaryArgs = {
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryCostItemSiteUsageComparisonArgs = {
  year?: Maybe<Scalars['Float']>;
  siteIDs?: Maybe<Array<Scalars['String']>>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryCostItemMovementCheckingArgs = {
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetCostItemMovementArgs = {
  stockMovementType: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetCostItemInStockArgs = {
  siteID: Scalars['String'];
};


export type QueryGetCostItemCategoryArgs = {
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetStockMovementArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  stockMovementStatus?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
};


export type QueryGetStockMovementCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  stockMovementStatus?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
};


export type QueryGetStockMovementCategoriesArgs = {
  siteID: Scalars['String'];
};


export type QueryGetAllSiteItemArgs = {
  siteIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAllSiteStockCodeArgs = {
  siteIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetStockMovementAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetStockMoveInOutAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetStockMonthYearArgs = {
  isBothStatus?: Maybe<Scalars['Boolean']>;
  siteIDs?: Maybe<Array<Scalars['String']>>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetStockMovementByDayAnalysisArgs = {
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryStockSiteUsageSummaryArgs = {
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryStockSiteUsageComparisonArgs = {
  year?: Maybe<Scalars['Float']>;
  siteIDs?: Maybe<Array<Scalars['String']>>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCheckingArgs = {
  uomID: Scalars['String'];
  siteMaterialID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetSiteMaterialInStockArgs = {
  siteID: Scalars['String'];
};


export type QueryGetCheckInStockArgs = {
  siteID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCategoryArgs = {
  siteMaterialID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetStockMovementOffsetArgs = {
  stockMovementStatus?: Maybe<StockMovementStatus>;
  stockMovementType?: Maybe<StockMovementType>;
  siteMaterialID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  offSet: Scalars['Float'];
  limit: Scalars['Float'];
};


export type QueryGetStockMovementItemDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  stockMovementType?: Maybe<Scalars['String']>;
  proSubscriber?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCostItemSummaryArgs = {
  proSubscriber?: Maybe<Scalars['Boolean']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteProgressArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteStockLedgerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteStockItemsArgs = {
  siteID: Scalars['String'];
};


export type QueryGetStockItemDetailArgs = {
  uomID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryGetDoBySiteIdArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSiteWorkerAttendanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetChecklistCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSafetyChecklistArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  checklistCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSafetyChecklistItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  safetyChecklistID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteSafetyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  SiteSafetyStatus?: Maybe<SiteSafetyStatus>;
};


export type QueryGetSiteSafetyItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteSafetyID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteImageArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWageCycleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageAdvanceArgs = {
  workerID: Scalars['String'];
};


export type QueryGetUnpaidWageAdvanceArgs = {
  workerID: Scalars['String'];
};


export type QueryGetUnpaidWorkerJsonArgs = {
  siteID: Scalars['String'];
};


export type QueryGetUnpaidWorkerArgs = {
  siteID: Scalars['String'];
};


export type QueryGetPaidWorkerArgs = {
  wageCycleID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetWageCycleDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerCycleDetailArgs = {
  workerID: Scalars['String'];
  wageCycleID: Scalars['String'];
};


export type QueryGetWorkerAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageArgs = {
  workDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageVerificationArgs = {
  workDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerSalaryArgs = {
  workerWageType?: Maybe<Scalars['String']>;
  workerWageRate?: Maybe<Scalars['Float']>;
  workDate?: Maybe<Scalars['DateTime']>;
  workHour?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryGetSiteDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QuerySiteTestPermissionArgs = {
  sitePerm: SitePermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryTestSampleEnumArgs = {
  SampleEnum: SampleEnum;
};


export type QueryCheckDocExistArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryCmgdAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryTotalDigitalDocArgs = {
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryGetCashFlowLineChartDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCashFlowGraphDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractNewsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  postStatus?: Maybe<PostStatus>;
};


export type QueryGetContractNewsReadArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  newsID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetCollectionActivityArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyHorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetRawCompanyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionCurrencyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryTotalDocFolderArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetLocalBankArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaScriptArgs = {
  name: Scalars['String'];
};


export type QuerySubmittedRetClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryApprovedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryCancellableClaimsArgs = {
  identifier: Scalars['String'];
};


export type QueryApprovableLedgerCheckingArgs = {
  docAmt?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailbySupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  batchPaymentID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialContractPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetDefaultMaterialPolicyIDsArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyCheckerArgs = {
  category: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};


export type QueryAssociateMenuArgs = {
  companyID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type QueryPendingCollectionActivityArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentReminderSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalDebitClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalCreditClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOsClientInvoicesArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingAmtClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryPrefSupplierProductDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySupplierAccDateArgs = {
  supplierID: Scalars['String'];
};


export type QueryContractInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractGuaranteeInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractInsuranceInfoArgs = {
  contractID: Scalars['String'];
};


export type QuerySubmittedVoNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryContractVoArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAdvancesArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingClaimsArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingMiscInvsArgs = {
  contractID: Scalars['String'];
};


export type QueryRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastSiteProgressDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetRevisionInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryUtilizedBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBudgetTransferInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastBqImportDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBqAssignmentInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMiscExpInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastMiscExpDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastExtensionDateArgs = {
  contractID: Scalars['String'];
};


export type QueryContractClosureDateArgs = {
  contractID: Scalars['String'];
};


export type QuerySubcontractInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconGuaranteeInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconInsuranceInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubmittedVoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingAdvancesArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingClaimsArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconLastExtensionDateArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconClosureDateArgs = {
  subcontractID: Scalars['String'];
};


export type QueryLatestDefectDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLatestAssignmentDateArgs = {
  defectID?: Maybe<Scalars['String']>;
};


export type QuerySiteWorkerAttendanceInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryMosCountArgs = {
  siteID: Scalars['String'];
};


export type QueryApprovalPolicyDateArgs = {
  parameter: Scalars['String'];
};


export type QueryApprovalPolicyWithContractDateArgs = {
  contractID: Scalars['String'];
  parameter: Scalars['String'];
};


export type QueryConAuthCountArgs = {
  contractID: Scalars['String'];
};


export type QueryDocNumDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSiteCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActivePlantMachineryCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryProjectPurchaseCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryOwnedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryRentedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryAssignmentRecordArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryMaintenanceRecordArgs = {
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelTankCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientProfileDateArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCusAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedReceiptNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryReceiptsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingClientArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryConClientProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConReceiptsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClientDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConOutstandingClientArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubconProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconLedgersInfoArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconPaymentInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySuppProfileDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySubmittedDepositNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDepositsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvWoDoNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvWoDoInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierBatchPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockInArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockOutArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalDefectArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalSiteSurveillanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTodayTotalAttendanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastGrnDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastStockMovementDatesArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QuerySiteImagesCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryVideoCamCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerAttendanceCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyRegisteredWorkHoursArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyWagesVerificationArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWageCycleClosureInfoArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxArgs = {
  taxID: Scalars['String'];
};


export type QueryGetTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetTaxSchemeDetailItemWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetContractNotificationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  senderID?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  readStatus?: Maybe<ReadStatus>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetNotificationListingArgs = {
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetRoleSetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetRolePermissionArgs = {
  siteManagement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetRoleMatrixArgs = {
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleMatrixByIdsArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  softwareCode?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleByIdsArgs = {
  softwareCode?: Maybe<Scalars['String']>;
  roleIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAlertArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetAlertListArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetShowAlertSqlArgs = {
  ID: Scalars['String'];
};


export type QueryGetAlertAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
  alertID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetBankAccountWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetCalendarArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCalendarPhPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  publicHolidayID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCalendarWeekendPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryWatermarkViewArgs = {
  opacity?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetActivePnmCostCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetMapCostCategoryArgs = {
  costClass?: Maybe<CostClass>;
  materialOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetParentChildrenCostCategoryArgs = {
  cost?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostCategoryTreeArgs = {
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractTradeCostCategoryArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetNonSelectedCostCategoryArgs = {
  CommonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemBySupplierArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetItemByClassArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: CostClass;
};


export type QueryGetCostItemByClassesArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: Array<CostClass>;
};


export type QueryGetCostItemPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<CostClass>;
  isInventory?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryItemArgs = {
  costClass?: Maybe<CostClass>;
  itemOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostItemByWbsArgs = {
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetCostItemByWbsIDsArgs = {
  wbsIDs: Array<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckingBudgetQtyArgs = {
  wbsID: Scalars['String'];
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCurrencyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetCurrencySetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetDigitalDocumentArgs = {
  type: Scalars['String'];
  associateID: Scalars['String'];
};


export type QueryGetTableDocumentsArgs = {
  description?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  associateID: Scalars['String'];
  refID: Array<Scalars['String']>;
};


export type QueryTotalDigitalDocumentsArgs = {
  type?: Maybe<Scalars['String']>;
  associateID: Scalars['String'];
};


export type QueryGetNumberFormatArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetConDocTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchNextDocNumArgs = {
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryFetchMultipleDocNumArgs = {
  supplierIDs?: Maybe<Array<Scalars['String']>>;
  numOfDocs?: Maybe<Scalars['Float']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryGetRunningNumberSetupWithLockArgs = {
  contractID?: Maybe<Scalars['String']>;
  numberFormatID: Scalars['String'];
};


export type QueryRunningNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryProjMiscExpRunNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  contractID: Scalars['String'];
};


export type QueryGetClassificationCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetClassificationCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetHolidayTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetJobTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryIsPlatformUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetPolicyGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyRoleAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  policyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetNotiRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  notiPolicyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayByStateYearArgs = {
  year: Scalars['String'];
};


export type QueryGetRevenueCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetRevenueCategoryCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSiteWorkersArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractorTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSupplierTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeListArgs = {
  uomID: Scalars['String'];
};


export type QueryGetUomExchangeListByWbsArgs = {
  uomIDs: Array<Scalars['String']>;
};


export type QueryGetUserCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedByLedgerArgs = {
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetVideoCamArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesRatePolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractToDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetToDoListingArgs = {
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryGetWorkerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetWorkerSiteAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  workerID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerFromSiteArgs = {
  siteID: Scalars['String'];
};


export type QueryCheckingWageTypeArgs = {
  workerID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerAttendanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['String']>;
};


export type QueryCheckWorkFlowArgs = {
  stockMovement?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type QueryGetToDoWorkflowSummaryArgs = {
  length?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoAllPendingDocsArgs = {
  category: Scalars['String'];
};


export type QueryPolicyCheckingArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
};


export type QueryPolicyCheckerApPaymentArgs = {
  category: Scalars['String'];
};


export type QueryGetNotificationWorkflowListingArgs = {
  length?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApprovalLogArgs = {
  refID: Scalars['String'];
};


export type QueryGetAllApprovalLogArgs = {
  refIDs: Array<Scalars['String']>;
};


export type QueryApprovableLedgerWfCheckingArgs = {
  input: CheckingInput;
};


export type QueryGetWorkingHourPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetCustomContractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryContractVoCalculationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMaxRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryVoCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRetentionCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryValidateContractStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractWithoutPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetContractEntityWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractTitleWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithActiveDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsExistArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetConTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxEffectiveDateArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConTaxTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryVerifyTaxTypeArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxCodeGlExportArgs = {
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetFilterTaxCodeArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSubcontractorCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCategoryBySubconArgs = {
  subcontractorID: Scalars['String'];
};


export type QueryGetSubcontractorAccessibleContractArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  creditorAC?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSupplierCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetMultiSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
};


export type QueryGetSupplierCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};

export enum QuotationStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Inactive = 'INACTIVE'
}

export type RfqEntity = AuditEntity & {
  __typename?: 'RFQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  description: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docDate: Scalars['String'];
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  rfqStatus: RfqStatus;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  invitedSupplier?: Maybe<Array<RfqSupplierInvitationEntity>>;
  rfqSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  supplierRFQInvite: Scalars['JSON'];
  supplier: Array<SupplierEntity>;
  /** CustomFieldResolver */
  totalRfqSubmission?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  submittedSupplierQuotes?: Maybe<Scalars['Float']>;
};


export type RfqEntitySupplierRfqInviteArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type RfqEntitySubmittedSupplierQuotesArgs = {
  supplierID: Scalars['String'];
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  prItemID: Scalars['String'];
  prItem?: Maybe<PrItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  quantity: Scalars['Float'];
  rfqSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  supplierSelected?: Maybe<Scalars['Boolean']>;
};


export type RfqItemEntitySupplierSelectedArgs = {
  supplierID?: Maybe<Scalars['String']>;
};

export type RfqItemSubmissionSelectedEntity = AuditEntity & {
  __typename?: 'RFQItemSubmissionSelectedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  rfqSubmissionItemID: Scalars['String'];
  rfqSubmissionItem?: Maybe<RfqSubmissionItemEntity>;
};

export enum RfqStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqSupplierSubmissionID: Scalars['String'];
  rfqSubmission: RfqSupplierSubmissionEntity;
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  unitPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  selected?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  rfqSubmissionItem: Array<RfqSubmissionItemEntity>;
};

export enum RateUomType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Lumpsum = 'LUMPSUM'
}

export enum RatingType {
  Gold = 'GOLD',
  Diamond = 'DIAMOND',
  Platinum = 'PLATINUM'
}

export type ReAssignDefectInput = {
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ReOpenDefectInput = {
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum ReadStatus {
  Seen = 'SEEN',
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum ReceiptStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum RefTable {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontract = 'SUBCONTRACT',
  Supplier = 'SUPPLIER',
  NegotiatedSupply = 'NEGOTIATED_SUPPLY',
  Miscexpense = 'MISCEXPENSE'
}

export type RejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export enum Relationship {
  Supervisor = 'Supervisor',
  Spouse = 'Spouse',
  Family = 'Family',
  Friends = 'Friends'
}

export type RelationshipTypes = {
  __typename?: 'RelationshipTypes';
  relationship: Relationship;
};

export type ReminderDetail = {
  __typename?: 'ReminderDetail';
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ReminderDetailInput = {
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export enum ReminderFor {
  AllBill = 'ALL_BILL',
  ProgressClaim = 'PROGRESS_CLAIM'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RetentionInfo = {
  __typename?: 'RetentionInfo';
  retentionSum?: Maybe<Scalars['Float']>;
  releasedSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
};

export type RevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'RevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
};

export type RevenueCategoryDeleteInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RevenueCategoryEntity = AuditEntity & {
  __typename?: 'RevenueCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  isDaywork: Scalars['Boolean'];
  glAccountCode?: Maybe<Scalars['String']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  eInvoiceIntegrated: Scalars['Boolean'];
  commonStatus: CommonStatus;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  DNToSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNToSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};

export type RevenueCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  revenueName?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type RevenueCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  name: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  isDaywork?: Maybe<Scalars['Boolean']>;
  eInvoiceIntegrated?: Maybe<Scalars['Boolean']>;
};

export type RevenueCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<RolePermissionAssignmentEntity>;
  conRolePerm: Array<RolePermissionAssignmentEntity>;
  siteRolePerm: Array<RolePermissionAssignmentEntity>;
  /** CustomFieldResolver */
  assignedUserEntity?: Maybe<Scalars['JSON']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type SAccountEntity = AuditEntity & {
  __typename?: 'SAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  watermarkImplementation: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  watermarkWording?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition: LogoPosition;
  isoLogoPosition: LogoPosition;
};

export type SAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition?: Maybe<LogoPosition>;
  isoLogoPosition?: Maybe<LogoPosition>;
};

export type SafetyChecklistEntity = AuditEntity & {
  __typename?: 'SafetyChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  title: Scalars['String'];
  nextScheduleDate: Scalars['String'];
  endDate: Scalars['String'];
  isActive: Scalars['Boolean'];
  frequency: Frequency;
  checklistCategoryID: Scalars['String'];
  checklistCategory?: Maybe<ChecklistCategoryEntity>;
  commonStatus: CommonStatus;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklistItem?: Maybe<Array<SafetyChecklistItemEntity>>;
};

export type SafetyChecklistInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  nextScheduleDate: Scalars['String'];
  endDate: Scalars['String'];
  isActive: Scalars['Boolean'];
  frequency: Scalars['String'];
  checklistCategoryID: Scalars['String'];
};

export type SafetyChecklistItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type SafetyChecklistItemEntity = AuditEntity & {
  __typename?: 'SafetyChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  title: Scalars['String'];
  notes: Scalars['String'];
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type SafetyChecklistItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  safetyChecklistID: Scalars['String'];
  title: Scalars['String'];
  notes: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SampleEnum {
  Demo = 'DEMO',
  Testing = 'TESTING'
}

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ShippedDetail = {
  __typename?: 'ShippedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ShippedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type SiteDeleteInput = {
  ID: Scalars['String'];
};

export type SiteDiaryEntity = AuditEntity & {
  __typename?: 'SiteDiaryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  diaryTypeID: Scalars['String'];
  diaryType?: Maybe<DiaryTypeEntity>;
  diaryDate: Scalars['String'];
  subject: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  approvalStatus: ApprovalStatus;
  assignedToID?: Maybe<Scalars['String']>;
  instructionComment?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  closingDate?: Maybe<Scalars['String']>;
  closingRemark?: Maybe<Scalars['String']>;
};

export type SiteEntity = AuditEntity & {
  __typename?: 'SiteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  stateID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: Scalars['JSON'];
  contactNo?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mainSite: Scalars['Boolean'];
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  siteImage?: Maybe<Array<SiteImageEntity>>;
  videoCam?: Maybe<Array<VideoCamEntity>>;
  wageCycle?: Maybe<Array<WageCycleEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  grn?: Maybe<Array<GrnEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  documentFolder?: Maybe<Array<DocumentFolderEntity>>;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
  /** CustomFieldResolver */
  picture?: Maybe<Scalars['Float']>;
  worker: Array<WorkerEntity>;
};

export type SiteImageDeleteInput = {
  ID: Scalars['String'];
};

export type SiteImageEntity = AuditEntity & {
  __typename?: 'SiteImageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
};

export type SiteImageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  balanceQty?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  stateID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type SiteMaterialContractPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialContractPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteMaterialPolicyID: Scalars['String'];
  siteMaterialPolicy?: Maybe<SiteMaterialPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
};

export type SiteMaterialContractPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export type SiteMaterialDeleteInput = {
  ID: Scalars['String'];
};

export type SiteMaterialEntity = AuditEntity & {
  __typename?: 'SiteMaterialEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stockCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  uom?: Maybe<UomEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SiteMaterialPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export enum SitePermission {
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  ChecklistCategoryIcon = 'CHECKLIST_CATEGORY__________ICON',
  ChecklistCategoryView = 'CHECKLIST_CATEGORY__________VIEW',
  ChecklistCategoryCreate = 'CHECKLIST_CATEGORY__________CREATE',
  ChecklistCategoryDelete = 'CHECKLIST_CATEGORY__________DELETE',
  SafetyChecklistIcon = 'SAFETY_CHECKLIST__________ICON',
  SafetyChecklistView = 'SAFETY_CHECKLIST__________VIEW',
  SafetyChecklistCreate = 'SAFETY_CHECKLIST__________CREATE',
  SafetyChecklistDelete = 'SAFETY_CHECKLIST__________DELETE',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE'
}

export type SitePermissionEntity = {
  __typename?: 'SitePermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type SiteProgressEntity = AuditEntity & {
  __typename?: 'SiteProgressEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  imagesInfo?: Maybe<Scalars['JSON']>;
};

export type SiteProgressInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteSafetyEntity = AuditEntity & {
  __typename?: 'SiteSafetyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  docDate: Scalars['String'];
  assignedToID?: Maybe<Scalars['String']>;
  status: SiteSafetyStatus;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
  assignedTo?: Maybe<UserEntity>;
};

export type SiteSafetyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  safetyChecklistID: Scalars['String'];
  docDate: Scalars['String'];
  assignedToID?: Maybe<Scalars['String']>;
  status?: Maybe<SiteSafetyStatus>;
};

export type SiteSafetyItemEntity = AuditEntity & {
  __typename?: 'SiteSafetyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteSafetyID: Scalars['String'];
  siteSafety?: Maybe<SiteSafetyEntity>;
  safetyChecklistItemID: Scalars['String'];
  safetyChecklistItem?: Maybe<SafetyChecklistItemEntity>;
  isChecked: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['Boolean']>;
  diaryTypeID?: Maybe<Scalars['String']>;
  diaryType?: Maybe<DiaryTypeEntity>;
  location?: Maybe<Scalars['String']>;
};

export type SiteSafetyItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteSafetyID: Scalars['String'];
  safetyChecklistItemID: Scalars['String'];
  isChecked: Scalars['Boolean'];
  remarks: Scalars['String'];
  followUp: Scalars['Boolean'];
  diaryTypeID: Scalars['String'];
  location: Scalars['String'];
};

export enum SiteSafetyStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type SiteStockLedgerEntity = AuditEntity & {
  __typename?: 'SiteStockLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  trackingDate: Scalars['String'];
  trxType: TrxType;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  site?: Maybe<SiteEntity>;
};

export type SiteStockLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  trackingDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  trxType?: Maybe<TrxType>;
};

export type SiteSubordinateAssignmentEntity = AuditEntity & {
  __typename?: 'SiteSubordinateAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  assignedTo: Scalars['String'];
  dateAssigned: Scalars['DateTime'];
  status: Scalars['String'];
  targetDate: Scalars['DateTime'];
  location: Scalars['String'];
};

export type SiteToDoEntity = AuditEntity & {
  __typename?: 'SiteToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  notes: Scalars['String'];
  status: Scalars['String'];
  closingDate: Scalars['DateTime'];
  closingRemark: Scalars['String'];
  followUp: Scalars['Boolean'];
  diaryType?: Maybe<Scalars['String']>;
  location: Scalars['String'];
};

export type SiteVideoEntity = AuditEntity & {
  __typename?: 'SiteVideoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  videoCamID: Scalars['String'];
};

export type SiteWorkerAttendanceImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  temperature?: Maybe<Scalars['Float']>;
  identityNo: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut: Scalars['String'];
  workDate: Scalars['DateTime'];
  totalHours?: Maybe<Scalars['Float']>;
  overTime?: Maybe<Scalars['Float']>;
  publicHoliday?: Maybe<Scalars['Float']>;
  weekend?: Maybe<Scalars['Float']>;
};

export type SiteWorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['String']>;
  timeOut?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteWorkersInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  siteID: Scalars['String'];
  contactNo: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['DateTime']>;
  timeOut?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Nationality>;
  emergencyContact?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StockMovementData = {
  __typename?: 'StockMovementData';
  count: Scalars['Float'];
  data: Array<StockMovementEntity>;
};

export type StockMovementDeleteInput = {
  ID: Scalars['String'];
};

export type StockMovementEntity = AuditEntity & {
  __typename?: 'StockMovementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  do?: Maybe<DoEntity>;
  grnID?: Maybe<Scalars['String']>;
  grn?: Maybe<GrnEntity>;
  grtnID?: Maybe<Scalars['String']>;
  grtn?: Maybe<GoodReturnNoteEntity>;
  docDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  baseUomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  cancellationRemark?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  stockMovementStatus: StockMovementStatus;
  stockMovementType: StockMovementType;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  workFlow?: Maybe<Array<WorkFlowEntity>>;
};

export type StockMovementInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  baseUomID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemark?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
  stockMovementStatus?: Maybe<StockMovementStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum StockMovementStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED'
}

export enum StockMovementType {
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  Issue = 'ISSUE',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  Adjustment = 'ADJUSTMENT'
}

export type SubconAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconCoreTransaction = AuditEntity & {
  __typename?: 'SubconCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
};

export type SubconEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type SubconItemTaxCoreTransaction = AuditEntity & {
  __typename?: 'SubconItemTaxCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
};

export type SubconRfqSubmissionEntity = AuditEntity & {
  __typename?: 'SubconRFQSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  submissionDate: Scalars['String'];
  quotationNo: Scalars['String'];
  quotationAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export enum SubconTenderStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export type SubcontractAdvanceEntity = AuditEntity & {
  __typename?: 'SubcontractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type SubcontractCnEntity = AuditEntity & {
  __typename?: 'SubcontractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractCnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractCnItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractCNID: Scalars['String'];
  subcontractCN?: Maybe<SubcontractCnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractCNTaxItem?: Maybe<Array<SubcontractCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractCNItemID: Scalars['String'];
  subcontractCNItem?: Maybe<SubcontractCnItemEntity>;
};

export type SubcontractClaimAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimEntity = AuditEntity & {
  __typename?: 'SubcontractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  contract?: Maybe<ContractEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  claimDetailCostWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type SubcontractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
};

export type SubcontractClaimVoCostWbsAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOCostWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOCostWbsID: Scalars['String'];
  subcontractVOCostWbs?: Maybe<SubcontractVoCostWbsEntity>;
};

export type SubcontractCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractID: Scalars['String'];
  /** CustomFieldResolver */
  claimToDateWithTrade?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  tradeAmt: Scalars['Float'];
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  revisedTradeAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  tradeVoAmt?: Maybe<Scalars['Float']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCostWbsEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractDnEntity = AuditEntity & {
  __typename?: 'SubcontractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractDnItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractDNID: Scalars['String'];
  subcontractDN?: Maybe<SubcontractDnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractDNTaxItem?: Maybe<Array<SubcontractDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractDnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractDNItemID: Scalars['String'];
  subcontractDNItem?: Maybe<SubcontractDnItemEntity>;
};

export type SubcontractEntity = AuditEntity & {
  __typename?: 'SubcontractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  contractID: Scalars['String'];
  mainContractDetail?: Maybe<ContractEntity>;
  subcontractorID: Scalars['String'];
  subcontractorDetail?: Maybe<SubcontractorEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  tradeDetail?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  subcontractSum: Scalars['Float'];
  nominatedSC: Scalars['Boolean'];
  multipleTrade: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  maxRetentionSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimToDate?: Maybe<Scalars['Float']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  subcontractStatus: ContractStatus;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  VO?: Maybe<Array<SubcontractVoEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  advance?: Maybe<Array<SubcontractAdvanceEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  subcontractVOSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  trade: Array<CostCategoryEntity>;
};

export type SubcontractGuaranteeEntity = AuditEntity & {
  __typename?: 'SubcontractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type SubcontractInsuranceEntity = AuditEntity & {
  __typename?: 'SubcontractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type SubcontractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractMiscInvoiceID: Scalars['String'];
  subcontractMiscInvoice?: Maybe<SubcontractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractMiscInvoiceTaxItem?: Maybe<Array<SubcontractMiscInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractMiscInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractMiscInvoiceItemID: Scalars['String'];
  subcontractMiscInvoiceItem?: Maybe<SubcontractMiscInvoiceItemEntity>;
};

export type SubcontractPaymentEntity = AuditEntity & {
  __typename?: 'SubcontractPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  paymentTo: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractRefundEntity = AuditEntity & {
  __typename?: 'SubcontractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractRefundEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type SubcontractTenderEntity = AuditEntity & {
  __typename?: 'SubcontractTenderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID: Scalars['String'];
  costCategoryID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  refNo: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  budgetAmt: Scalars['Float'];
  tenderDate: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  closeDate: Scalars['String'];
  endTime: Scalars['String'];
  subconTenderStatus: SubconTenderStatus;
  costCategory: CostCategoryEntity;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  mainContractDetail?: Maybe<ContractEntity>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
};

export type SubcontractVo = {
  __typename?: 'SubcontractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type SubcontractVoCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractVOCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousTradeVoAmt?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoEntity = AuditEntity & {
  __typename?: 'SubcontractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  subcontractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type SubcontractorCCategoryEntity = AuditEntity & {
  __typename?: 'SubcontractorCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  costCategoryID: Scalars['String'];
  costCategory: CostCategoryEntity;
  subconPackagesData: Array<Scalars['JSON']>;
};

export type SubcontractorCCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export type SubcontractorCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SubcontractorCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type SubcontractorDeleteId = {
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorEntity = AuditEntity & {
  __typename?: 'SubcontractorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  subcontractorType?: Maybe<SubcontractorTypeEntity>;
  nominatedSC: Scalars['Boolean'];
  paymentTo: Scalars['String'];
  creditorAC?: Maybe<Scalars['String']>;
  statusRemarks?: Maybe<Scalars['String']>;
  ratingType: RatingType;
  cidbDetails: Scalars['JSON'];
  pkkDetails?: Maybe<Scalars['JSON']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  contactPerson: Array<ContactEntity>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subconClaim: Array<SubcontractClaimEntity>;
  subconAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  defect: Array<DefectManagementEntity>;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractorMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractorDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractorCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcontractor?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcontractor?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  subcontractorCompanyAssignment?: Maybe<Array<SubcontractorCompanyAssignmentEntity>>;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueAdvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  activeSubcontract?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractorEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityOverdueAdvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityActiveSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorName?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailAddr?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type SubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  paymentTo?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC: Scalars['Boolean'];
  creditorAC?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SubcontractorInvitationEntity = AuditEntity & {
  __typename?: 'SubcontractorInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  subconTender?: Maybe<SubcontractTenderEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  RFQInfo: Scalars['JSON'];
};

export type SubcontractorSubconAccPayment = AuditEntity & {
  __typename?: 'SubcontractorSubconAccPayment';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubcontractorTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractorTypeEntity = AuditEntity & {
  __typename?: 'SubcontractorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
};

export type SubcontractorTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type SubontractInfo = {
  __typename?: 'SubontractInfo';
  subcontractSum?: Maybe<Scalars['Float']>;
  subcontractDate?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionCompanyType = {
  __typename?: 'SubscriptionCompanyType';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
};

export type SubscriptionCurrencyType = {
  __typename?: 'SubscriptionCurrencyType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  symbol?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type SupplierAccPaymentEntity = AuditEntity & {
  __typename?: 'SupplierAccPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SupplierAccTransaction = AuditEntity & {
  __typename?: 'SupplierAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SupplierAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierCCategoryEntity = AuditEntity & {
  __typename?: 'SupplierCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
};

export type SupplierCCategoryInput = {
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
};

export type SupplierCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SupplierCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
};

export type SupplierCompanyAssignmentInput = {
  supplierID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SupplierDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type SupplierEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierEntity = AuditEntity & {
  __typename?: 'SupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  supplierTypeID?: Maybe<Scalars['String']>;
  supplierType?: Maybe<SupplierTypeEntity>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  costConnection?: Maybe<Array<SupplierCCategoryEntity>>;
  costItemConnection?: Maybe<Array<PricingSupplierEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  SupplierProduct?: Maybe<Array<SupplierCCategoryEntity>>;
  supplierItems?: Maybe<Array<PricingSupplierEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  contactPerson: Array<ContactEntity>;
  rfqSubmission: Array<RfqSupplierSubmissionEntity>;
  invitedRFQ: Array<RfqSupplierInvitationEntity>;
  supplierInv?: Maybe<Array<ApInvoiceEntity>>;
  supplierDN?: Maybe<Array<ApDebitNoteEntity>>;
  supplierCN?: Maybe<Array<ApCreditNoteEntity>>;
  supplierDNS?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  supplierAdvance?: Maybe<Array<ApPaymentEntity>>;
  supplierPayment?: Maybe<Array<ApPaymentEntity>>;
  supplierRefund?: Maybe<Array<ApRefundEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  supplierCompanyAssignment?: Maybe<Array<SupplierCompanyAssignmentEntity>>;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  costCategory: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingInfo?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
  latestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
  rfq: Array<RfqEntity>;
  /** CustomFieldResolver */
  indelibleAccount?: Maybe<Scalars['Boolean']>;
};


export type SupplierEntityRfqSubmissionArgs = {
  rfqID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOverdueInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOutstandingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityLatestSubmittedQuotationArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type SupplierImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  eftPayment: Scalars['Boolean'];
  contactName: Scalars['String'];
  contactDesignation?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactMobileNo: Scalars['String'];
  suppType?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  ratingType?: Maybe<RatingType>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type SupplierInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  bankDetails?: Maybe<BankDetailsInput>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SupplierTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SupplierTypeEntity = AuditEntity & {
  __typename?: 'SupplierTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type SupplierTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type TankEntity = AuditEntity & {
  __typename?: 'TankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  tankType: TankType;
  name: Scalars['String'];
  regNo: Scalars['String'];
  plantMachineryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fuelConsumptionLowerLimit: Scalars['Float'];
  fuelConsumptionUpperLimit: Scalars['Float'];
  fuelConsumptionMethod: FuelConsumptionMethod;
  serialNo?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  plantMachinery?: Maybe<PlantMachineryEntity>;
};

export type TankMachinery = PlantMachineryEntity | PnmRentalEntity;

export type TankObjectType = {
  __typename?: 'TankObjectType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum TankType {
  NoTank = 'NO_TANK',
  FixedTank = 'FIXED_TANK',
  MobileTank = 'MOBILE_TANK'
}

export enum TaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum TaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type TaxDetail = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  accountId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  scheme_type_id: Scalars['String'];
  createdTs: Scalars['DateTime'];
};

export type TaxObject = {
  __typename?: 'TaxObject';
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxDate?: Maybe<Scalars['DateTime']>;
};

export type TaxSchemeTypeListingOutput = {
  __typename?: 'TaxSchemeTypeListingOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
};

export type TaxSchemeTypeSortByOutput = {
  __typename?: 'TaxSchemeTypeSortByOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
  count: Scalars['Float'];
};

export type TestServiceInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  inputStatus?: Maybe<WorkFlowStatus>;
};

export enum ToDoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export enum ToDoType {
  ArClaim = 'AR_CLAIM',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArMiscInv = 'AR_MISC_INV',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ApClaim = 'AP_CLAIM',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApMiscInv = 'AP_MISC_INV',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Vo = 'VO',
  Svo = 'SVO',
  Subcontract = 'SUBCONTRACT',
  Df = 'DF',
  Budget = 'BUDGET',
  UtilizeBudget = 'UTILIZE_BUDGET',
  TransferBudget = 'TRANSFER_BUDGET',
  Default = 'DEFAULT',
  Rental = 'RENTAL',
  Assignment = 'ASSIGNMENT',
  Maintenance = 'MAINTENANCE'
}

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TrackingLog = {
  __typename?: 'TrackingLog';
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type TrackingLogInput = {
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export type TreeCostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
  parentCostCategoryID?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum TrxType {
  Grn = 'GRN',
  IssueOut = 'ISSUE_OUT',
  TransferOut = 'TRANSFER_OUT',
  TransferIn = 'TRANSFER_IN',
  Wastage = 'WASTAGE'
}

export type Uom = {
  __typename?: 'UOM';
  uomID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  negoItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  siteMaterial?: Maybe<Array<SiteMaterialEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  bq?: Maybe<Array<BqEntity>>;
};

export type UomImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newUOM?: Maybe<Scalars['Boolean']>;
  newName?: Maybe<Scalars['String']>;
  newCode?: Maybe<Scalars['String']>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeEntity = AuditEntity & {
  __typename?: 'UomExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  fromUomID: Scalars['String'];
  toUomID: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUOM: UomEntity;
  toUOM: UomEntity;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateSubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  ratingType?: Maybe<RatingType>;
  cidbDetails?: Maybe<CidbDetailsInput>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
  address?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  confirmedPassword: Scalars['String'];
};


export type UserCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'UserCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserCompanyAssignmentInput = {
  userID: Scalars['String'];
  companyID: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type Vo = {
  __typename?: 'VO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  contractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export enum VoStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancel = 'CANCEL',
  Partial = 'PARTIAL',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Close = 'CLOSE',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum VoType {
  Addition = 'ADDITION',
  Omission = 'OMISSION'
}

export type VideoCamDeleteInput = {
  ID: Scalars['String'];
};

export type VideoCamEntity = AuditEntity & {
  __typename?: 'VideoCamEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
};

export type VideoCamInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type VoOmission = {
  __typename?: 'VoOmission';
  omission: Scalars['Float'];
};

export type VoSum = {
  __typename?: 'VoSUM';
  sum?: Maybe<Scalars['Float']>;
};

export type WbsBudgetTransferDetailEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetTransferHeaderID: Scalars['String'];
  wbsBudgetTransferHeader: WbsBudgetTransferHeaderEntity;
  fromCostCategoryID?: Maybe<Scalars['String']>;
  fromCostCategory?: Maybe<CostCategoryEntity>;
  toCostCategoryID?: Maybe<Scalars['String']>;
  toCostCategory?: Maybe<CostCategoryEntity>;
  fromCostItemID?: Maybe<Scalars['String']>;
  fromCostItem?: Maybe<CostItemEntity>;
  toCostItemID?: Maybe<Scalars['String']>;
  toCostItem?: Maybe<CostItemEntity>;
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type WbsBudgetTransferEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  fromCostCategoryID: Scalars['String'];
  fromCostCategory: CostCategoryEntity;
  toCostCategoryID: Scalars['String'];
  toCostCategory: CostCategoryEntity;
  transferDate: Scalars['String'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
};

export type WbsBudgetTransferHeaderEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
  wbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
};

export type WbsEntity = AuditEntity & {
  __typename?: 'WBSEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  parentWBSID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  isLastNode: Scalars['Boolean'];
  isDefault?: Maybe<Scalars['Boolean']>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsCostSubcontract?: Maybe<Array<SubcontractCostWbsEntity>>;
  wbsSchedule?: Maybe<Array<WbsScheduleEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  subcontractAccount?: Maybe<Array<ContractAccountEntity>>;
  fromWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  toWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  childrenWbs?: Maybe<Array<WbsEntity>>;
  parentWbs?: Maybe<WbsEntity>;
  budget: Array<WbsBudgetEntity>;
  /** CustomFieldResolver */
  finalSequence?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  originalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  committedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  sumContractVORevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  anticipatedProfit?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  wbsProfitStatus?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalDN?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalCostByClass?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  costByClass?: Maybe<Scalars['JSON']>;
  originalRevenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  revenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  originalCostDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  costDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  /** CustomFieldResolver */
  bqAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  bqCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  wbsParentChildSequence?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type WbsEntityOriginalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityAnticipatedProfitArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityWbsProfitStatusArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevenueDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WageAdvanceLedger = {
  __typename?: 'WageAdvanceLedger';
  docAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  certHour?: Maybe<Scalars['Float']>;
  timeIn?: Maybe<Scalars['String']>;
  timeOut?: Maybe<Scalars['String']>;
};

export type WageCycleDeleteInput = {
  ID: Scalars['String'];
};

export type WageCycleDetailDeleteInput = {
  ID: Scalars['String'];
};

export type WageCycleDetailEntity = AuditEntity & {
  __typename?: 'WageCycleDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wageCycleID: Scalars['String'];
  workerID: Scalars['String'];
  wageAmt: Scalars['Float'];
  wageCycle?: Maybe<WageCycleEntity>;
  worker?: Maybe<WorkerEntity>;
};

export type WageCycleDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wageCycleID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  wageAmt?: Maybe<Scalars['Float']>;
};

export type WageCycleEntity = AuditEntity & {
  __typename?: 'WageCycleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerWageID?: Maybe<Scalars['String']>;
  closedBy: Scalars['String'];
  docDate: Scalars['String'];
  site?: Maybe<SiteEntity>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
};

export type WageCycleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  siteID: Scalars['String'];
};

export enum WageStatus {
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export enum WageType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE'
}

export type WagesRatePolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WagesRatePolicyEntity = AuditEntity & {
  __typename?: 'WagesRatePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WagesRatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  holidayTypeID?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type WbsBudgetDetailEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  budgetID: Scalars['String'];
  budget: WbsBudgetEntity;
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  creditTerm: Scalars['Float'];
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  remark?: Maybe<Scalars['String']>;
  updateSchedule: Scalars['Boolean'];
  budgetType: BudgetType;
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  /** CustomFieldResolver */
  trackQty?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  originalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQtyPurchasing?: Maybe<Scalars['Float']>;
  fromCostCategory: Scalars['String'];
  toCostCategory: Scalars['String'];
};


export type WbsBudgetDetailEntityTrackQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalAmtArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyPurchasingArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  poID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetDetailScheduleEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsScheduleID: Scalars['String'];
  wbsSchedule?: Maybe<WbsScheduleEntity>;
  scheduleAmt: Scalars['Float'];
};

export type WbsBudgetEntity = AuditEntity & {
  __typename?: 'WbsBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  copyFromBudgetID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version: Scalars['Float'];
  budgetStatus: BudgetStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetWbsDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  copyFromBudget?: Maybe<WbsBudgetEntity>;
  wbs: Array<WbsEntity>;
  totalCostRevenue: Scalars['JSON'];
};


export type WbsBudgetEntityTotalCostRevenueArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsDayworkCostRevenueEntity = AuditEntity & {
  __typename?: 'WbsDayworkCostRevenueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
};

export type WbsScheduleEntity = AuditEntity & {
  __typename?: 'WbsScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  period: Scalars['Float'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  /** CustomFieldResolver */
  code?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  budgetDetailScheduleAmt?: Maybe<Scalars['Float']>;
};


export type WbsScheduleEntityBudgetDetailScheduleAmtArgs = {
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
};

export type WbsUtilizedBudgetEntity = AuditEntity & {
  __typename?: 'WbsUtilizedBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  year: Scalars['Float'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt: Scalars['Float'];
  budgetStatus: BudgetStatus;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  statusName: Scalars['String'];
  refTable: Scalars['String'];
  approvalStatus: WorkFlowStatus;
  approveRejectDate: Scalars['DateTime'];
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'WorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowStepID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  approvalDT?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'WorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyAssignmentID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  refID: Scalars['String'];
  refTable: Scalars['String'];
  submitterID: Scalars['String'];
  submitDate: Scalars['String'];
  workFlowStatus: WorkFlowStatus;
  workFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
};

export type WorkFlowInput = {
  accountID?: Maybe<Scalars['String']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  submitterID?: Maybe<Scalars['String']>;
  submitDate?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  workFlowStatus?: Maybe<WorkFlowStatus>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum WorkFlowStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED'
}

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'WorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowID: Scalars['String'];
  approvalRoleID: Scalars['String'];
  notifiedDT: Scalars['String'];
  stepNo: Scalars['Float'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
  workFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowStepInput = {
  workFlowStepID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  subscriptionAccountID?: Maybe<Scalars['String']>;
  userList?: Maybe<Array<UserListInput>>;
  predicate?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type WorkerAdvanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAdvanceEntity = AuditEntity & {
  __typename?: 'WorkerAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  docDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerAdvanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workerID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  wageCycleID?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type WorkerAttendanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAttendanceEntity = AuditEntity & {
  __typename?: 'WorkerAttendanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workDate: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  workerID: Scalars['String'];
  siteID: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  worker?: Maybe<WorkerEntity>;
  workerWage: WorkerWageEntity;
  /** CustomFieldResolver */
  attendanceSummary?: Maybe<Scalars['JSON']>;
};

export type WorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
};

export type WorkerDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerDocuments = {
  __typename?: 'WorkerDocuments';
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type WorkerEntity = AuditEntity & {
  __typename?: 'WorkerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactID: Scalars['String'];
  jobTypeID: Scalars['String'];
  jobType?: Maybe<JobTypeEntity>;
  identityNo: Scalars['String'];
  nationality: Nationality;
  workPermitNo?: Maybe<Scalars['String']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  wageRate: Scalars['Float'];
  wageType: WageType;
  commonStatus: CommonStatus;
  workerAttendance?: Maybe<Array<WorkerAttendanceEntity>>;
  workerAdvance?: Maybe<Array<WorkerAdvanceEntity>>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  contact: ContactEntity;
  attachment?: Maybe<WorkerDocuments>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteResolver: Array<SiteEntity>;
};

export type WorkerHour = {
  __typename?: 'WorkerHour';
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHourInput = {
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  jobTypeID?: Maybe<Scalars['String']>;
  identityNo?: Maybe<Scalars['String']>;
  workPermitNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wageRate?: Maybe<Scalars['Float']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  wageType?: Maybe<WageType>;
  nationality?: Maybe<Nationality>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerSiteAssignmentEntity = AuditEntity & {
  __typename?: 'WorkerSiteAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  worker?: Maybe<WorkerEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
};

export type WorkerWageCycle = {
  __typename?: 'WorkerWageCycle';
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageCycleInput = {
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerWageEntity = AuditEntity & {
  __typename?: 'WorkerWageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerID: Scalars['String'];
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  site?: Maybe<SiteEntity>;
  worker?: Maybe<WorkerEntity>;
  workerAttendance?: Maybe<WorkerAttendanceEntity>;
  wageCycle?: Maybe<WageCycleEntity>;
  workerWageHours?: Maybe<Scalars['JSON']>;
  workerWageAmount: Scalars['JSON'];
};


export type WorkerWageEntityWorkerWageAmountArgs = {
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerWageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  remarks?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type WorkingHourPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WorkingHourPolicyEntity = AuditEntity & {
  __typename?: 'WorkingHourPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  shiftStartTime: Scalars['String'];
  shiftEndTime: Scalars['String'];
  halfStartTime: Scalars['String'];
  halfEndTime: Scalars['String'];
  commonStatus: CommonStatus;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WorkingHourPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  shiftStartTime?: Maybe<Scalars['String']>;
  shiftEndTime?: Maybe<Scalars['String']>;
  halfStartTime?: Maybe<Scalars['String']>;
  halfEndTime?: Maybe<Scalars['String']>;
};

export type WorkshopEntity = AuditEntity & {
  __typename?: 'WorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  workshopStatus: WorkshopStatus;
  ratingType: RatingType;
  costConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export enum WorkshopStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type BatchWorkAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  identityNo?: Maybe<Scalars['String']>;
  totalHour?: Maybe<Scalars['Float']>;
  ot?: Maybe<Scalars['Float']>;
  publicHoliday?: Maybe<Scalars['Float']>;
  weekend?: Maybe<Scalars['Float']>;
};

export type DefectLogInput = {
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceGeneralTinEntity = {
  __typename?: 'eInvoiceGeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
};

export type Items = {
  __typename?: 'items';
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PmStatus = {
  __typename?: 'pmStatus';
  available?: Maybe<Scalars['Float']>;
  assigned?: Maybe<Scalars['Float']>;
  outOfOrder?: Maybe<Scalars['Float']>;
  maintenance?: Maybe<Scalars['Float']>;
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RunningNumberSetupInput = {
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export type TotalDoAmt = {
  __typename?: 'totalDOAmt';
  total?: Maybe<Scalars['Float']>;
};

export type UomExchange = {
  __typename?: 'uomExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UomExchanges = {
  __typename?: 'uomExchanges';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'avatar' | 'lastestAccessedEntity'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
  siteAccess?: Maybe<Scalars['Boolean']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'accountID' | 'name' | 'lastestAccessedEntity'>
    ) }
  ) }
);

export type UploadProfilePicMutationVariables = Exact<{
  userID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProfilePic'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetSiteDrawerPermissionQueryVariables = Exact<{
  pageMode?: Maybe<Scalars['String']>;
}>;


export type GetSiteDrawerPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteDrawerPermission'>
);

export type GetSiteBusinessInsightQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteBusinessInsightQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount'>
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
  )> }
);

export type GetSiteWorkerAttendanceTrackingQueryVariables = Exact<{
  workerID: Scalars['String'];
  workDate: Scalars['String'];
}>;


export type GetSiteWorkerAttendanceTrackingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteWorkerAttendanceTracking'>
);

export type DefectLodgeOverTimeSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type DefectLodgeOverTimeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'defectLodgeOverTimeSummary'>
);

export type DefectStatusAnalysisSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type DefectStatusAnalysisSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'defectStatusAnalysisSummary'>
);

export type DefectSubconAnalysisSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type DefectSubconAnalysisSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'defectSubconAnalysisSummary'>
);

export type DefectTradeAnalysisSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type DefectTradeAnalysisSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'defectTradeAnalysisSummary'>
);

export type GetWorkerAnalysisQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
}>;


export type GetWorkerAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkerAnalysis'>
);

export type GetManLabourAnalysisQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  selectedYear: Scalars['Float'];
}>;


export type GetManLabourAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getManLabourAnalysis'>
);

export type GetAllSiteQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAllSiteQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'name' | 'address' | 'contactNo' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetStockMovementAnalysisQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
}>;


export type GetStockMovementAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMovementAnalysis'>
);

export type GetCostItemMovementAnalysisQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
}>;


export type GetCostItemMovementAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMovementAnalysis'>
);

export type GetStockMonthYearQueryVariables = Exact<{
  siteMaterialID: Scalars['String'];
  isBothStatus?: Maybe<Scalars['Boolean']>;
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetStockMonthYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMonthYear'>
);

export type GetCostItemMonthYearQueryVariables = Exact<{
  costItemID: Scalars['String'];
  isBothStatus?: Maybe<Scalars['Boolean']>;
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCostItemMonthYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMonthYear'>
);

export type GetStockMovementByDayAnalysisQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
}>;


export type GetStockMovementByDayAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMovementByDayAnalysis'>
);

export type GetCostItemMovementByDayAnalysisQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetCostItemMovementByDayAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMovementByDayAnalysis'>
);

export type GetCostItemMoveInOutAnalysisQueryVariables = Exact<{
  costItemID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
}>;


export type GetCostItemMoveInOutAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMoveInOutAnalysis'>
);

export type GetStockMoveInOutAnalysisQueryVariables = Exact<{
  siteMaterialID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
}>;


export type GetStockMoveInOutAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMoveInOutAnalysis'>
);

export type CostItemSiteUsageSummaryQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type CostItemSiteUsageSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costItemSiteUsageSummary'>
);

export type StockSiteUsageSummaryQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  siteMaterialID?: Maybe<Scalars['String']>;
}>;


export type StockSiteUsageSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockSiteUsageSummary'>
);

export type CostItemSiteUsageComparisonQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type CostItemSiteUsageComparisonQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costItemSiteUsageComparison'>
);

export type StockSiteUsageComparisonQueryVariables = Exact<{
  year?: Maybe<Scalars['Float']>;
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
}>;


export type StockSiteUsageComparisonQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockSiteUsageComparison'>
);

export type GetAllCostItemQueryVariables = Exact<{
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAllCostItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllCostItem'>
);

export type GetAllSiteItemQueryVariables = Exact<{
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAllSiteItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllSiteItem'>
);

export type GetShareAttachmentMutationVariables = Exact<{
  file: Scalars['Upload'];
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetShareAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { shareAttachment?: Maybe<(
    { __typename?: 'ShareAttachmentEntity' }
    & Pick<ShareAttachmentEntity, 'ID' | 'bucketFileName' | 'fileName' | 'fileURL'>
  )> }
);

export type GetWageLabourAnalysisQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetWageLabourAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWageLabourAnalysis'>
);

export type GetContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'latestRunNum' | 'docNumChecking' | 'contractPOCounts' | 'contractStatus' | 'subcontractSummary' | 'contactPerson' | 'closureRemarks' | 'closureDate' | 'modBy' | 'extensionDate'>
    & { site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'name' | 'contractID' | 'address' | 'ID'>
    )>>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID' | 'title'>
    )>>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetContractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'contractSum' | 'revisedContractSum'>
  )>, getAllContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID'>
  )> }
);

export type GetContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title'>
  )> }
);

export type GetDefectQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetDefectQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount'>
  & { getDefect: Array<(
    { __typename?: 'DefectEntity' }
    & Pick<DefectEntity, 'ID' | 'description' | 'defectStatus' | 'siteID' | 'costCategoryID' | 'reportedDate' | 'expectedDate' | 'completionDate' | 'createdTs' | 'createdBy' | 'activityLog' | 'docNo' | 'profileInfo'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name'>
    )>, trade?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, defectAssignment?: Maybe<Array<(
      { __typename?: 'DefectAssignmentEntity' }
      & Pick<DefectAssignmentEntity, 'ID' | 'assignmentDate' | 'expectedDate'>
      & { subcontractor?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type CreateDefectMutationVariables = Exact<{
  input: DefectInput;
}>;


export type CreateDefectMutation = (
  { __typename?: 'Mutation' }
  & { createDefect: (
    { __typename?: 'DefectEntity' }
    & Pick<DefectEntity, 'ID'>
  ) }
);

export type UpdateDefectMutationVariables = Exact<{
  input: DefectInput;
}>;


export type UpdateDefectMutation = (
  { __typename?: 'Mutation' }
  & { updateDefect: (
    { __typename?: 'DefectEntity' }
    & Pick<DefectEntity, 'ID'>
  ) }
);

export type CreateActivityLogsMutationVariables = Exact<{
  file?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  input: ActivityLogInput;
  defectID: Scalars['String'];
}>;


export type CreateActivityLogsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createActivityLogs'>
);

export type GetDefectDetailDataQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
  defectID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetDefectDetailDataQuery = (
  { __typename?: 'Query' }
  & { getDefect: Array<(
    { __typename?: 'DefectEntity' }
    & Pick<DefectEntity, 'ID' | 'description' | 'defectStatus' | 'siteID' | 'costCategoryID' | 'reportedDate' | 'expectedDate' | 'completionDate' | 'createdTs' | 'createdBy' | 'activityLog' | 'docNo'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name'>
    )>, trade?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, defectAssignment?: Maybe<Array<(
      { __typename?: 'DefectAssignmentEntity' }
      & Pick<DefectAssignmentEntity, 'ID' | 'defectID' | 'assignmentDate' | 'subcontractorID'>
      & { subcontractor?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'commonStatus' | 'costClass'>
    & { subcontractor?: Maybe<Array<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )>> }
  )> }
);

export type CreateDefectAssignmentMutationVariables = Exact<{
  ID: Scalars['String'];
  input: DefectAssignmentInput;
}>;


export type CreateDefectAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDefectAssignment'>
);

export type GetDefectAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  defectID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetDefectAssignmentQuery = (
  { __typename?: 'Query' }
  & { getDefectAssignment: Array<(
    { __typename?: 'DefectAssignmentEntity' }
    & Pick<DefectAssignmentEntity, 'ID' | 'defectID' | 'subcontractorID' | 'assignmentDate' | 'expectedDate' | 'remarks'>
    & { defect?: Maybe<(
      { __typename?: 'DefectEntity' }
      & Pick<DefectEntity, 'ID' | 'description'>
      & { trade?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name' | 'description'>
      )> }
    )>, subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )> }
  )> }
);

export type GetSubcontractorCostCategoryQueryVariables = Exact<{
  costCategoryID: Scalars['String'];
}>;


export type GetSubcontractorCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractorCostCategory'>
);

export type CreateDefectMgmtMutationVariables = Exact<{
  input: DefectManagementInput;
}>;


export type CreateDefectMgmtMutation = (
  { __typename?: 'Mutation' }
  & { createDefectMgmt: (
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID'>
  ) }
);

export type UpdateDefectMgmtMutationVariables = Exact<{
  input: DefectManagementInput;
}>;


export type UpdateDefectMgmtMutation = (
  { __typename?: 'Mutation' }
  & { updateDefectMgmt: (
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID'>
  ) }
);

export type CreateActivityLogMutationVariables = Exact<{
  input: DefectLogInput;
  defectID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateActivityLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createActivityLog'>
);

export type AssignDefectMutationVariables = Exact<{
  input: DefectAssignmentInput;
  defectID: Scalars['String'];
}>;


export type AssignDefectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignDefect'>
);

export type ReOpenDefectMutationVariables = Exact<{
  input: ReOpenDefectInput;
  defectID: Scalars['String'];
}>;


export type ReOpenDefectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reOpenDefect'>
);

export type GetDefectMgmtQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDefectMgmtQuery = (
  { __typename?: 'Query' }
  & { getDefectMgmt: Array<(
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID' | 'remarks' | 'title' | 'defectStatus' | 'contractID' | 'subcontractorID' | 'expectedDate' | 'createdTs' | 'createdBy' | 'docNo' | 'defectLog'>
    & { subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )> }
  )> }
);

export type GetContractDefectListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractDefectListingQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractStatus' | 'contractSum' | 'contractDate' | 'contractDefectCounts'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetDefectListingDataQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetDefectListingDataQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title'>
  )>, getDefectMgmt: Array<(
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID' | 'remarks' | 'title' | 'defectStatus' | 'contractID' | 'subcontractorID' | 'expectedDate' | 'createdTs' | 'createdBy' | 'docNo' | 'defectLog'>
    & { subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'commonStatus'>
    & { subcontractor?: Maybe<Array<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )>> }
  )> }
);

export type DocumentListingQueryVariables = Exact<{
  documentFolderID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type CreateDocumentFolderMutationVariables = Exact<{
  input: DocumentFolderInput;
}>;


export type CreateDocumentFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDocumentFolder'>
);

export type UpdateDocumentFolderMutationVariables = Exact<{
  input: DocumentFolderInput;
}>;


export type UpdateDocumentFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentFolder'>
);

export type DeleteDocumentFolderMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDocumentFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocumentFolder'>
);

export type GetDocumentFolderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetDocumentFolderQuery = (
  { __typename?: 'Query' }
  & { getDocumentFolder?: Maybe<Array<(
    { __typename?: 'DocumentFolderEntity' }
    & Pick<DocumentFolderEntity, 'ID' | 'name' | 'siteID' | 'description'>
    & { documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'ID'>
    )>> }
  )>> }
);

export type AddDigitalDocumentsMutationVariables = Exact<{
  input: DigitalDocumentInput;
}>;


export type AddDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addDigitalDocuments'>
);

export type DeleteDigitalDocumentsMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalDocuments'>
);

export type GetDigitalDocsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDigitalDocsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount'>
);

export type SiteHomeSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type SiteHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalStockIn' | 'totalStockOut' | 'totalDefect' | 'totalSiteSurveillance' | 'todayTotalAttendance' | 'uomDate' | 'getRolePermission' | 'getSitePermission'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'userName' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type GetSiteHomeSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalStockIn' | 'totalStockOut' | 'totalDefect' | 'totalSiteSurveillance' | 'todayTotalAttendance' | 'totalDocFolder' | 'totalDigitalDoc' | 'getRolePermission' | 'getSitePermission'>
);

export type VerifySiteQueryVariables = Exact<{
  accountID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type VerifySiteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifySite'>
);

export type GoodReceiveNoteRpaMutationVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type GoodReceiveNoteRpaMutation = (
  { __typename?: 'Mutation' }
  & { goodReceiveNoteRPA: Array<(
    { __typename?: 'GoodReceiveNoteRPAResult' }
    & Pick<GoodReceiveNoteRpaResult, 'invoiceDate' | 'invoiceID' | 'invoiceTotal' | 'subTotal' | 'vendorName' | 'vendorAddress' | 'customerAddress' | 'customerAddressRecipient' | 'remittanceAddressRecipient' | 'purchaseOrder'>
    & { invoiceItems?: Maybe<Array<(
      { __typename?: 'GRNItem' }
      & Pick<GrnItem, 'costItemID' | 'name' | 'description' | 'productCode' | 'unitPrice' | 'quantity' | 'amount'>
      & { uom?: Maybe<(
        { __typename?: 'UOM' }
        & Pick<Uom, 'uomID' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetSiteAttendanceSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteAttendanceSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount' | 'workerCount' | 'workerAttendanceCount'>
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'name' | 'address'>
  )> }
);

export type UploadPdfDocumentMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadPDFDocument: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'refID' | 'fileName' | 'fileURL' | 'mediaType' | 'description'>
  )> }
);

export type UploadPdfpoMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfpoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadPDFPO'>
);

export type GetGrnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetGrnQuery = (
  { __typename?: 'Query' }
  & { getGRN: Array<(
    { __typename?: 'GRNEntity' }
    & Pick<GrnEntity, 'ID' | 'siteID' | 'docNo' | 'docDate' | 'supplier' | 'docRef' | 'grnStatus' | 'remark' | 'cancelRejectDate'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name'>
    )>, grnItem?: Maybe<Array<(
      { __typename?: 'GRNItemEntity' }
      & Pick<GrnItemEntity, 'ID' | 'uomID' | 'quantity' | 'siteMaterialID' | 'costItemID' | 'description'>
      & { siteMaterial?: Maybe<(
        { __typename?: 'SiteMaterialEntity' }
        & Pick<SiteMaterialEntity, 'name' | 'stockCode'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name' | 'code' | 'uomID'>
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'code'>
      )> }
    )>> }
  )> }
);

export type GetSiteSwitchQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSiteSwitchQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'name' | 'contractID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )> }
  )> }
);

export type CreateGrnMutationVariables = Exact<{
  grnInput: GrnInput;
  grnItemInput: Array<GrnItemInput> | GrnItemInput;
}>;


export type CreateGrnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGRN'>
);

export type UpdateGrnMutationVariables = Exact<{
  grnInput: GrnInput;
  grnItemInput: Array<GrnItemInput> | GrnItemInput;
}>;


export type UpdateGrnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGRN'>
);

export type DeleteGrnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteGrnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGRN'>
);

export type GetGrnItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetGrnItemQuery = (
  { __typename?: 'Query' }
  & { getGRNItem: Array<(
    { __typename?: 'GRNItemEntity' }
    & Pick<GrnItemEntity, 'ID'>
    & { siteMaterial?: Maybe<(
      { __typename?: 'SiteMaterialEntity' }
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'name'>
      )> }
    )> }
  )> }
);

export type CreateGrnItemMutationVariables = Exact<{
  input: GrnItemInput;
}>;


export type CreateGrnItemMutation = (
  { __typename?: 'Mutation' }
  & { createGRNItem: (
    { __typename?: 'GRNItemEntity' }
    & Pick<GrnItemEntity, 'ID'>
  ) }
);

export type GetSiteMaterialAndUomQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSiteMaterialAndUomQuery = (
  { __typename?: 'Query' }
  & { getSiteMaterial: Array<(
    { __typename?: 'SiteMaterialEntity' }
    & Pick<SiteMaterialEntity, 'ID' | 'name' | 'stockCode' | 'uomID'>
  )>, getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code'>
  )> }
);

export type GetUomExchangeListQueryVariables = Exact<{
  uomID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type GetGrnListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  grnStatus?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetGrnListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGRNCount'>
  & { getGRN: Array<(
    { __typename?: 'GRNEntity' }
    & Pick<GrnEntity, 'ID' | 'siteID' | 'docNo' | 'docDate' | 'supplier' | 'docRef' | 'grnStatus' | 'remark' | 'cancelRejectDate'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name'>
    )>, grnItem?: Maybe<Array<(
      { __typename?: 'GRNItemEntity' }
      & Pick<GrnItemEntity, 'ID' | 'uomID' | 'quantity' | 'siteMaterialID' | 'costItemID' | 'description'>
      & { siteMaterial?: Maybe<(
        { __typename?: 'SiteMaterialEntity' }
        & Pick<SiteMaterialEntity, 'name' | 'stockCode'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name' | 'code' | 'uomID'>
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'code'>
      )> }
    )>> }
  )> }
);

export type GetSiteInventorySummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteInventorySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount' | 'stockMovementCount' | 'lastStockMovementDates' | 'totalStockIn' | 'totalStockOut' | 'lastGRNDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID'>
  ) }
);

export type GetRoleForSiteQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
}>;


export type GetRoleForSiteQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'accountID' | 'name' | 'softwareCode'>
  )> }
);

export type GetAllCostStockCodeQueryVariables = Exact<{
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAllCostStockCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllCostStockCode'>
);

export type GetAllSiteStockCodeQueryVariables = Exact<{
  siteIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAllSiteStockCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllSiteStockCode'>
);

export type CreateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type CreateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicy: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID'>
  ) }
);

export type UpdateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type UpdateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicy: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID'>
  ) }
);

export type GetApprovalPolicyQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type GetApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicy?: Maybe<Array<(
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID' | 'approvalList' | 'category' | 'createdTs' | 'modTs' | 'lowerLimit' | 'upperLimit'>
    & { approvalPolicyAssignment?: Maybe<Array<(
      { __typename?: 'ApprovalPolicyAssignmentEntity' }
      & Pick<ApprovalPolicyAssignmentEntity, 'approvalPolicyID' | 'ID' | 'contractID' | 'approvalList' | 'category'>
    )>> }
  )>> }
);

export type GetApprovalLogQueryVariables = Exact<{
  refID: Scalars['String'];
}>;


export type GetApprovalLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovalLog'>
);

export type ApprovableLedgerWfCheckingQueryVariables = Exact<{
  input: CheckingInput;
}>;


export type ApprovableLedgerWfCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvableLedgerWFChecking'>
);

export type GetStockMovementQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
}>;


export type GetStockMovementQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastStockMovementDates'>
  & { getStockMovement: Array<(
    { __typename?: 'StockMovementEntity' }
    & Pick<StockMovementEntity, 'ID' | 'uomID' | 'siteMaterialID' | 'siteID' | 'costItemID' | 'stockMovementType' | 'stockMovementStatus' | 'docDate' | 'docNo' | 'remark' | 'quantity' | 'createdBy' | 'modTs' | 'createdTs' | 'profileInfo'>
    & { siteMaterial?: Maybe<(
      { __typename?: 'SiteMaterialEntity' }
      & Pick<SiteMaterialEntity, 'ID' | 'name'>
    )>, uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'name' | 'code'>
      & { stockMovement?: Maybe<Array<(
        { __typename?: 'StockMovementEntity' }
        & Pick<StockMovementEntity, 'docNo'>
      )>> }
    )>, workFlow?: Maybe<Array<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID'>
    )>> }
  )> }
);

export type GetStockMovementListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
  stockMovementStatus?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetStockMovementListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMovementCount'>
  & { getStockMovement: Array<(
    { __typename?: 'StockMovementEntity' }
    & Pick<StockMovementEntity, 'ID' | 'uomID' | 'siteMaterialID' | 'siteID' | 'costItemID' | 'stockMovementStatus' | 'docDate' | 'docNo' | 'remark' | 'quantity' | 'createdTs' | 'profileInfo'>
    & { siteMaterial?: Maybe<(
      { __typename?: 'SiteMaterialEntity' }
      & Pick<SiteMaterialEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name'>
    )>, uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code'>
    )>, costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'name'>
    )>, workFlow?: Maybe<Array<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID'>
    )>> }
  )> }
);

export type GetStockMovementOffsetQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
  stockMovementStatus?: Maybe<StockMovementStatus>;
  offSet: Scalars['Float'];
  limit: Scalars['Float'];
}>;


export type GetStockMovementOffsetQuery = (
  { __typename?: 'Query' }
  & { getStockMovementOffset: (
    { __typename?: 'StockMovementData' }
    & Pick<StockMovementData, 'count'>
    & { data: Array<(
      { __typename?: 'StockMovementEntity' }
      & Pick<StockMovementEntity, 'ID' | 'uomID' | 'siteMaterialID' | 'siteID' | 'costItemID' | 'stockMovementStatus' | 'docDate' | 'docNo' | 'remark' | 'quantity' | 'createdTs' | 'profileInfo'>
      & { siteMaterial?: Maybe<(
        { __typename?: 'SiteMaterialEntity' }
        & Pick<SiteMaterialEntity, 'ID' | 'name'>
      )>, site?: Maybe<(
        { __typename?: 'SiteEntity' }
        & Pick<SiteEntity, 'ID' | 'name'>
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name'>
      )>, workFlow?: Maybe<Array<(
        { __typename?: 'WorkFlowEntity' }
        & Pick<WorkFlowEntity, 'ID'>
      )>> }
    )> }
  ) }
);

export type GetSiteandUsersQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  superUserBool?: Maybe<UserSelection>;
}>;


export type GetSiteandUsersQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name' | 'address' | 'contactNo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
      & { customerDetail?: Maybe<(
        { __typename?: 'CustomerEntity' }
        & Pick<CustomerEntity, 'name'>
      )> }
    )> }
  )>, getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName'>
  )> }
);

export type GetStockMovementCategoriesQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetStockMovementCategoriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMovementCategories'>
);

export type GetStockCategoryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
}>;


export type GetStockCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockCategory'>
);

export type GetSiteMaterialInStockQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetSiteMaterialInStockQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteMaterialInStock'>
);

export type StockMovementCheckingQueryVariables = Exact<{
  uomID: Scalars['String'];
  siteMaterialID: Scalars['String'];
  siteID: Scalars['String'];
}>;


export type StockMovementCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockMovementChecking'>
);

export type GetCheckInStockQueryVariables = Exact<{
  siteID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
}>;


export type GetCheckInStockQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckInStock'>
);

export type GetSiteMaterialPolicyCheckerQueryVariables = Exact<{
  category: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
}>;


export type GetSiteMaterialPolicyCheckerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteMaterialPolicyChecker'>
);

export type CreateStockMovementMutationVariables = Exact<{
  input: StockMovementInput;
}>;


export type CreateStockMovementMutation = (
  { __typename?: 'Mutation' }
  & { createStockMovement: (
    { __typename?: 'StockMovementEntity' }
    & Pick<StockMovementEntity, 'ID' | 'quantity' | 'remark' | 'docDate' | 'siteMaterialID' | 'costItemID'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'name'>
    )> }
  ) }
);

export type UpdateStockMovementMutationVariables = Exact<{
  input: StockMovementInput;
}>;


export type UpdateStockMovementMutation = (
  { __typename?: 'Mutation' }
  & { updateStockMovement: (
    { __typename?: 'StockMovementEntity' }
    & Pick<StockMovementEntity, 'ID' | 'docNo' | 'stockMovementType'>
  ) }
);

export type DeleteStockMovementMutationVariables = Exact<{
  input: StockMovementDeleteInput;
}>;


export type DeleteStockMovementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockMovement'>
);

export type GetCostItemMovementQueryVariables = Exact<{
  siteID: Scalars['String'];
  stockMovementType: Scalars['String'];
}>;


export type GetCostItemMovementQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMovement'>
);

export type GetCostItemMovementCategoriesQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetCostItemMovementCategoriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemMovementCategories'>
);

export type GetCostItemCategoryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetCostItemCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemCategory'>
);

export type GetStockMovementItemDetailQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  itemID?: Maybe<Scalars['String']>;
  proSubscriber?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  stockMovementType?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetStockMovementItemDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockMovementItemDetail'>
);

export type GetStockCostItemSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  proSubscriber?: Maybe<Scalars['Boolean']>;
}>;


export type GetStockCostItemSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockCostItemSummary'>
);

export type GetItemByClassQueryVariables = Exact<{
  costClass: CostClass;
  isInventory?: Maybe<Scalars['Boolean']>;
}>;


export type GetItemByClassQuery = (
  { __typename?: 'Query' }
  & { getItemByClass: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name' | 'uomID' | 'createdTs' | 'isInventory'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type CostItemMovementCheckingQueryVariables = Exact<{
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  siteID: Scalars['String'];
}>;


export type CostItemMovementCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costItemMovementChecking'>
);

export type GetCostItemInStockQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetCostItemInStockQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemInStock'>
);

export type GetContractSiteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractSiteQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'accountID' | 'customerID' | 'title' | 'contractNo' | 'description' | 'contractDate' | 'contractSum' | 'startDate' | 'endDate' | 'contractStatus'>
    & { site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
    )>> }
  )> }
);

export type GetSiteContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteContractQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'description' | 'contractSum' | 'contractStatus'>
    )> }
  )> }
);

export type SiteManagementExecSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
  siteID: Scalars['String'];
}>;


export type SiteManagementExecSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteWorkerAttendanceInfo' | 'doCount' | 'MOSCount' | 'lastSiteProgressDate'>
);

export type GetSiteWorkersQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSiteWorkersQuery = (
  { __typename?: 'Query' }
  & { getSiteWorkers: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'modTs' | 'nationality'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name' | 'contactNo' | 'email'>
    ) }
  )> }
);

export type GetSiteProgressQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteProgressQuery = (
  { __typename?: 'Query' }
  & { getSiteProgress: Array<(
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'contractID' | 'title' | 'docDate' | 'imagesInfo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )> }
  )> }
);

export type GetThumbnailDocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type GetThumbnailDocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'mediaType' | 'refTable' | 'refID' | 'description' | 'fileName' | 'fileURL' | 'accountID' | 'bucketFileName' | 'opacity'>
  )>> }
);

export type CreateSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type CreateSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & { createSiteProgress: (
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'ID' | 'title'>
  ) }
);

export type UpdateSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type UpdateSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteProgress: (
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'ID' | 'title'>
  ) }
);

export type DeleteSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type DeleteSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteProgress'>
);

export type GetSiteStockItemsQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetSiteStockItemsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteStockItems'>
);

export type GetStockItemDetailQueryVariables = Exact<{
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
}>;


export type GetStockItemDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockItemDetail'>
);

export type GetSiteStockLedgerQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetSiteStockLedgerQuery = (
  { __typename?: 'Query' }
  & { getSiteStockLedger: Array<(
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID' | 'trackingDate' | 'remarks' | 'docRef' | 'quantity' | 'trxType' | 'siteID' | 'costItemID' | 'createdBy' | 'createdTs'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name'>
    )>, uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type CreateSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type CreateSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & { createSiteStockLedger: (
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID'>
  ) }
);

export type UpdateSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type UpdateSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteStockLedger: (
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID'>
  ) }
);

export type DeleteSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type DeleteSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteStockLedger'>
);

export type GetSiteWorkerAttendanceQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
}>;


export type GetSiteWorkerAttendanceQuery = (
  { __typename?: 'Query' }
  & { getSiteWorkerAttendance: Array<(
    { __typename?: 'WorkerAttendanceEntity' }
    & Pick<WorkerAttendanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'workerID' | 'siteID' | 'timeIn' | 'timeOut'>
  )> }
);

export type GetDiaryTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetDiaryTypeQuery = (
  { __typename?: 'Query' }
  & { getDiaryType: Array<(
    { __typename?: 'DiaryTypeEntity' }
    & Pick<DiaryTypeEntity, 'ID' | 'name' | 'followUp'>
  )> }
);

export type CreateDiaryTypeMutationVariables = Exact<{
  input: DiaryTypeInput;
}>;


export type CreateDiaryTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDiaryType: (
    { __typename?: 'DiaryTypeEntity' }
    & Pick<DiaryTypeEntity, 'ID'>
  ) }
);

export type GetSafetyChecklistQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSafetyChecklistQuery = (
  { __typename?: 'Query' }
  & { getSafetyChecklist: Array<(
    { __typename?: 'SafetyChecklistEntity' }
    & Pick<SafetyChecklistEntity, 'ID' | 'siteID' | 'title' | 'nextScheduleDate' | 'endDate' | 'isActive' | 'frequency' | 'checklistCategoryID'>
    & { checklistCategory?: Maybe<(
      { __typename?: 'ChecklistCategoryEntity' }
      & Pick<ChecklistCategoryEntity, 'ID' | 'name' | 'color'>
    )>, safetyChecklistItem?: Maybe<Array<(
      { __typename?: 'SafetyChecklistItemEntity' }
      & Pick<SafetyChecklistItemEntity, 'ID' | 'safetyChecklistID' | 'title' | 'notes'>
    )>> }
  )> }
);

export type CreateSafetyChecklistMutationVariables = Exact<{
  input: SafetyChecklistInput;
}>;


export type CreateSafetyChecklistMutation = (
  { __typename?: 'Mutation' }
  & { createSafetyChecklist: (
    { __typename?: 'SafetyChecklistEntity' }
    & Pick<SafetyChecklistEntity, 'ID'>
  ) }
);

export type CreateSafetyChecklistItemMutationVariables = Exact<{
  input: SafetyChecklistItemInput;
}>;


export type CreateSafetyChecklistItemMutation = (
  { __typename?: 'Mutation' }
  & { createSafetyChecklistItem: (
    { __typename?: 'SafetyChecklistItemEntity' }
    & Pick<SafetyChecklistItemEntity, 'ID' | 'safetyChecklistID'>
  ) }
);

export type GetSiteSafetyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  SiteSafetyStatus?: Maybe<SiteSafetyStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteSafetyQuery = (
  { __typename?: 'Query' }
  & { getSiteSafety: Array<(
    { __typename?: 'SiteSafetyEntity' }
    & Pick<SiteSafetyEntity, 'ID' | 'siteID' | 'safetyChecklistID' | 'docDate' | 'assignedToID' | 'status'>
    & { safetyChecklist?: Maybe<(
      { __typename?: 'SafetyChecklistEntity' }
      & Pick<SafetyChecklistEntity, 'ID' | 'title' | 'nextScheduleDate' | 'isActive' | 'frequency' | 'checklistCategoryID'>
      & { checklistCategory?: Maybe<(
        { __typename?: 'ChecklistCategoryEntity' }
        & Pick<ChecklistCategoryEntity, 'ID' | 'name' | 'color'>
      )> }
    )>, siteSafetyItem?: Maybe<Array<(
      { __typename?: 'SiteSafetyItemEntity' }
      & Pick<SiteSafetyItemEntity, 'ID' | 'siteSafetyID' | 'safetyChecklistItemID' | 'isChecked' | 'remarks' | 'followUp' | 'diaryTypeID' | 'location'>
      & { safetyChecklistItem?: Maybe<(
        { __typename?: 'SafetyChecklistItemEntity' }
        & Pick<SafetyChecklistItemEntity, 'ID' | 'title' | 'notes'>
      )>, diaryType?: Maybe<(
        { __typename?: 'DiaryTypeEntity' }
        & Pick<DiaryTypeEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type CreateSiteSafetyMutationVariables = Exact<{
  input: SiteSafetyInput;
}>;


export type CreateSiteSafetyMutation = (
  { __typename?: 'Mutation' }
  & { createSiteSafety: (
    { __typename?: 'SiteSafetyEntity' }
    & Pick<SiteSafetyEntity, 'ID'>
  ) }
);

export type UpdateSiteSafetyMutationVariables = Exact<{
  input: SiteSafetyInput;
}>;


export type UpdateSiteSafetyMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteSafety: (
    { __typename?: 'SiteSafetyEntity' }
    & Pick<SiteSafetyEntity, 'ID'>
  ) }
);

export type GetSiteSafetyItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  siteSafetyID?: Maybe<Scalars['String']>;
}>;


export type GetSiteSafetyItemQuery = (
  { __typename?: 'Query' }
  & { getSiteSafetyItem: Array<(
    { __typename?: 'SiteSafetyItemEntity' }
    & Pick<SiteSafetyItemEntity, 'ID' | 'siteSafetyID' | 'safetyChecklistItemID' | 'isChecked' | 'remarks' | 'followUp' | 'diaryTypeID' | 'location'>
    & { safetyChecklistItem?: Maybe<(
      { __typename?: 'SafetyChecklistItemEntity' }
      & Pick<SafetyChecklistItemEntity, 'ID' | 'title' | 'notes'>
    )>, diaryType?: Maybe<(
      { __typename?: 'DiaryTypeEntity' }
      & Pick<DiaryTypeEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateSiteSafetyItemMutationVariables = Exact<{
  input: SiteSafetyItemInput;
}>;


export type CreateSiteSafetyItemMutation = (
  { __typename?: 'Mutation' }
  & { createSiteSafetyItem: (
    { __typename?: 'SiteSafetyItemEntity' }
    & Pick<SiteSafetyItemEntity, 'ID' | 'siteSafetyID'>
  ) }
);

export type UpdateSiteSafetyItemMutationVariables = Exact<{
  input: SiteSafetyItemInput;
}>;


export type UpdateSiteSafetyItemMutation = (
  { __typename?: 'Mutation' }
  & { createSiteSafetyItem: (
    { __typename?: 'SiteSafetyItemEntity' }
    & Pick<SiteSafetyItemEntity, 'ID' | 'siteSafetyID'>
  ) }
);

export type GetSiteImageQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteImageQuery = (
  { __typename?: 'Query' }
  & { getSiteImage: Array<(
    { __typename?: 'SiteImageEntity' }
    & Pick<SiteImageEntity, 'ID' | 'siteID' | 'title' | 'remark' | 'createdTs'>
  )> }
);

export type SiteImageListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type SiteImageListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileURL' | 'refID' | 'mediaType'>
  )>> }
);

export type CreateSiteImageMutationVariables = Exact<{
  input: SiteImageInput;
}>;


export type CreateSiteImageMutation = (
  { __typename?: 'Mutation' }
  & { createSiteImage: (
    { __typename?: 'SiteImageEntity' }
    & Pick<SiteImageEntity, 'ID' | 'title' | 'remark' | 'image'>
  ) }
);

export type UpdateSiteImageMutationVariables = Exact<{
  input: SiteImageInput;
}>;


export type UpdateSiteImageMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteImage: (
    { __typename?: 'SiteImageEntity' }
    & Pick<SiteImageEntity, 'ID'>
  ) }
);

export type DeleteSiteImageMutationVariables = Exact<{
  input: SiteImageDeleteInput;
}>;


export type DeleteSiteImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteImage'>
);

export type CancelSiteImageMutationVariables = Exact<{
  input: SiteImageDeleteInput;
}>;


export type CancelSiteImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSiteImage'>
);

export type GetSiteSurveillanceSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteSurveillanceSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount' | 'siteImagesCount' | 'videoCamCount'>
);

export type GetWorkerWageVerificationQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetWorkerWageVerificationQuery = (
  { __typename?: 'Query' }
  & { getWorkerWageVerification: Array<(
    { __typename?: 'WorkerWageEntity' }
    & Pick<WorkerWageEntity, 'ID' | 'verifiedHour' | 'workDate' | 'amount' | 'createdBy' | 'modBy' | 'workHour' | 'remarks' | 'workerWageHours' | 'workerWageAmount'>
    & { worker?: Maybe<(
      { __typename?: 'WorkerEntity' }
      & Pick<WorkerEntity, 'ID' | 'wageRate' | 'nationality' | 'wageType'>
      & { attachment?: Maybe<(
        { __typename?: 'WorkerDocuments' }
        & Pick<WorkerDocuments, 'fileURL'>
      )>, contact: (
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name'>
      ), jobType?: Maybe<(
        { __typename?: 'JobTypeEntity' }
        & Pick<JobTypeEntity, 'ID' | 'name'>
      )> }
    )>, workerAttendance?: Maybe<(
      { __typename?: 'WorkerAttendanceEntity' }
      & Pick<WorkerAttendanceEntity, 'timeIn' | 'timeOut'>
    )> }
  )> }
);

export type GetWorkerWageQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetWorkerWageQuery = (
  { __typename?: 'Query' }
  & { getWorkerWage: Array<(
    { __typename?: 'WorkerWageEntity' }
    & Pick<WorkerWageEntity, 'ID' | 'verifiedHour' | 'workDate' | 'amount' | 'modBy' | 'workHour' | 'remarks' | 'workerWageHours' | 'wageStatus'>
    & { worker?: Maybe<(
      { __typename?: 'WorkerEntity' }
      & Pick<WorkerEntity, 'ID' | 'nationality' | 'wageType'>
      & { attachment?: Maybe<(
        { __typename?: 'WorkerDocuments' }
        & Pick<WorkerDocuments, 'fileURL'>
      )>, contact: (
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name'>
      ), jobType?: Maybe<(
        { __typename?: 'JobTypeEntity' }
        & Pick<JobTypeEntity, 'ID' | 'name'>
      )> }
    )>, workerAttendance?: Maybe<(
      { __typename?: 'WorkerAttendanceEntity' }
      & Pick<WorkerAttendanceEntity, 'timeIn' | 'timeOut'>
    )> }
  )> }
);

export type UpdateWorkerVerifiedHourMutationVariables = Exact<{
  workerRateIDs: Array<WorkerHourInput> | WorkerHourInput;
}>;


export type UpdateWorkerVerifiedHourMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWorkerVerifiedHour'>
);

export type GetSiteWagesSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteWagesSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount' | 'getDailyRegisteredWorkHours' | 'getDailyWagesVerification' | 'getWagesBook' | 'wageCycleClosureInfo' | 'workerAttendanceCount'>
);

export type CreateWageCycleMutationVariables = Exact<{
  input: WageCycleInput;
  workerWageCycleIDs: Array<WorkerWageCycleInput> | WorkerWageCycleInput;
}>;


export type CreateWageCycleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createWageCycle'>
);

export type GetWageCycleQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetWageCycleQuery = (
  { __typename?: 'Query' }
  & { getWageCycle: Array<(
    { __typename?: 'WageCycleEntity' }
    & Pick<WageCycleEntity, 'ID' | 'docDate' | 'createdTs' | 'createdBy' | 'closedBy' | 'modBy'>
    & { wageCycleDetail?: Maybe<Array<(
      { __typename?: 'WageCycleDetailEntity' }
      & Pick<WageCycleDetailEntity, 'ID' | 'wageAmt'>
      & { worker?: Maybe<(
        { __typename?: 'WorkerEntity' }
        & Pick<WorkerEntity, 'ID' | 'wageType'>
        & { jobType?: Maybe<(
          { __typename?: 'JobTypeEntity' }
          & Pick<JobTypeEntity, 'name'>
        )>, workerWage?: Maybe<Array<(
          { __typename?: 'WorkerWageEntity' }
          & Pick<WorkerWageEntity, 'verifiedHour' | 'amount'>
        )>>, workerAdvance?: Maybe<Array<(
          { __typename?: 'WorkerAdvanceEntity' }
          & Pick<WorkerAdvanceEntity, 'amount'>
        )>>, contact: (
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'name'>
        ), attachment?: Maybe<(
          { __typename?: 'WorkerDocuments' }
          & Pick<WorkerDocuments, 'fileURL'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetCreatedbyNameQueryVariables = Exact<{
  userIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetCreatedbyNameQuery = (
  { __typename?: 'Query' }
  & { getUsersbyIds: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name'>
  )> }
);

export type GetWorkerCycleDetailQueryVariables = Exact<{
  workerID: Scalars['String'];
  wageCycleID: Scalars['String'];
}>;


export type GetWorkerCycleDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkerCycleDetail'>
  & { getWorker: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'contactID' | 'jobTypeID' | 'wageRate' | 'nationality'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name'>
    ), jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'ID' | 'name'>
    )>, attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL'>
    )>, workerWage?: Maybe<Array<(
      { __typename?: 'WorkerWageEntity' }
      & Pick<WorkerWageEntity, 'wageCycleID' | 'verifiedHour' | 'amount'>
    )>>, workerAdvance?: Maybe<Array<(
      { __typename?: 'WorkerAdvanceEntity' }
      & Pick<WorkerAdvanceEntity, 'wageCycleID' | 'amount'>
    )>> }
  )> }
);

export type GetUnpaidWageAdvanceQueryVariables = Exact<{
  workerID: Scalars['String'];
}>;


export type GetUnpaidWageAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUnpaidWageAdvance'>
);

export type GetPaidWorkerQueryVariables = Exact<{
  siteID: Scalars['String'];
  wageCycleID: Scalars['String'];
}>;


export type GetPaidWorkerQuery = (
  { __typename?: 'Query' }
  & { getPaidWorker: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'wageRate' | 'wageType' | 'nationality'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name'>
    ), jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'name'>
    )>, workerWage?: Maybe<Array<(
      { __typename?: 'WorkerWageEntity' }
      & Pick<WorkerWageEntity, 'verifiedHour' | 'amount' | 'wageCycleID'>
    )>>, workerAdvance?: Maybe<Array<(
      { __typename?: 'WorkerAdvanceEntity' }
      & Pick<WorkerAdvanceEntity, 'amount' | 'wageCycleID'>
    )>>, attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL'>
    )> }
  )> }
);

export type GetUnpaidWorkerJsonQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetUnpaidWorkerJsonQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUnpaidWorkerJson'>
);

export type GetUnpaidWorkerQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetUnpaidWorkerQuery = (
  { __typename?: 'Query' }
  & { getUnpaidWorker: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'wageType'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name'>
    ), jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'name'>
    )>, workerWage?: Maybe<Array<(
      { __typename?: 'WorkerWageEntity' }
      & Pick<WorkerWageEntity, 'verifiedHour' | 'amount' | 'wageCycleID'>
    )>>, workerAdvance?: Maybe<Array<(
      { __typename?: 'WorkerAdvanceEntity' }
      & Pick<WorkerAdvanceEntity, 'amount' | 'wageCycleID'>
    )>>, attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL'>
    )> }
  )> }
);

export type GetWageCycleSummaryQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetWageCycleSummaryQuery = (
  { __typename?: 'Query' }
  & { getWageCycle: Array<(
    { __typename?: 'WageCycleEntity' }
    & Pick<WageCycleEntity, 'ID' | 'createdBy' | 'docDate'>
  )> }
);

export type GetWagesBookQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetWagesBookQuery = (
  { __typename?: 'Query' }
  & { getWorker: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'wageType' | 'wageRate' | 'nationality'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name'>
    ), jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'name'>
    )>, workerWage?: Maybe<Array<(
      { __typename?: 'WorkerWageEntity' }
      & Pick<WorkerWageEntity, 'verifiedHour' | 'amount'>
    )>>, workerAdvance?: Maybe<Array<(
      { __typename?: 'WorkerAdvanceEntity' }
      & Pick<WorkerAdvanceEntity, 'amount'>
    )>>, attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL'>
    )> }
  )> }
);

export type GetWorkerWageAdvanceQueryVariables = Exact<{
  workerID: Scalars['String'];
}>;


export type GetWorkerWageAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkerWageAdvance'>
);

export type CreateWorkerAdvanceMutationVariables = Exact<{
  input: WorkerAdvanceInput;
}>;


export type CreateWorkerAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createWorkerAdvance: (
    { __typename?: 'WorkerAdvanceEntity' }
    & Pick<WorkerAdvanceEntity, 'ID' | 'workerID' | 'amount' | 'docDate' | 'description' | 'createdBy' | 'modBy'>
  ) }
);

export type CreateWorkerWageMutationVariables = Exact<{
  input: WorkerWageInput;
}>;


export type CreateWorkerWageMutation = (
  { __typename?: 'Mutation' }
  & { createWorkerWage: (
    { __typename?: 'WorkerWageEntity' }
    & Pick<WorkerWageEntity, 'ID' | 'workerID' | 'siteID' | 'amount' | 'workHour' | 'verifiedHour' | 'workDate' | 'createdBy' | 'modBy'>
  ) }
);

export type GetWorkerWageBookQueryVariables = Exact<{
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetWorkerWageBookQuery = (
  { __typename?: 'Query' }
  & { getWorkerWageBook: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'wageType' | 'createdBy' | 'wageRate' | 'nationality'>
    & { contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name'>
    ), jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'name'>
    )>, workerWage?: Maybe<Array<(
      { __typename?: 'WorkerWageEntity' }
      & Pick<WorkerWageEntity, 'verifiedHour' | 'amount'>
    )>>, workerAdvance?: Maybe<Array<(
      { __typename?: 'WorkerAdvanceEntity' }
      & Pick<WorkerAdvanceEntity, 'amount'>
    )>>, attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL'>
    )> }
  )> }
);

export type GetWorkerSalaryQueryVariables = Exact<{
  workerWageType?: Maybe<Scalars['String']>;
  workerWageRate?: Maybe<Scalars['Float']>;
  workDate?: Maybe<Scalars['DateTime']>;
  workHour?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetWorkerSalaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWorkerSalary'>
);

export type GetWorkerFromSiteQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetWorkerFromSiteQuery = (
  { __typename?: 'Query' }
  & { getWorkerFromSite: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'commonStatus' | 'address' | 'wageRate' | 'wageType' | 'workPermitNo' | 'nationality' | 'identityNo' | 'docExpiryDate' | 'jobTypeID'>
    & { jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'ID' | 'name'>
    )>, contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name' | 'email' | 'contactNo'>
    ), attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL' | 'fileName' | 'mediaType'>
    )>, workerAttendance?: Maybe<Array<(
      { __typename?: 'WorkerAttendanceEntity' }
      & Pick<WorkerAttendanceEntity, 'timeIn' | 'timeOut' | 'workDate'>
    )>> }
  )> }
);

export type GetWorkerAttendanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['String']>;
}>;


export type GetWorkerAttendanceQuery = (
  { __typename?: 'Query' }
  & { getWorkerAttendance: Array<(
    { __typename?: 'WorkerAttendanceEntity' }
    & Pick<WorkerAttendanceEntity, 'ID' | 'timeIn' | 'timeOut' | 'temperature' | 'workDate' | 'workerID' | 'attendanceSummary'>
    & { worker?: Maybe<(
      { __typename?: 'WorkerEntity' }
      & Pick<WorkerEntity, 'ID'>
      & { contact: (
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name'>
      ), jobType?: Maybe<(
        { __typename?: 'JobTypeEntity' }
        & Pick<JobTypeEntity, 'name'>
      )>, attachment?: Maybe<(
        { __typename?: 'WorkerDocuments' }
        & Pick<WorkerDocuments, 'fileURL'>
      )> }
    )> }
  )> }
);

export type CreateWorkerAttendanceMutationVariables = Exact<{
  input: WorkerAttendanceInput;
}>;


export type CreateWorkerAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { createWorkerAttendance: (
    { __typename?: 'WorkerAttendanceEntity' }
    & Pick<WorkerAttendanceEntity, 'ID' | 'workerID'>
  ) }
);

export type UpdateWorkerAttendanceMutationVariables = Exact<{
  input: WorkerAttendanceInput;
}>;


export type UpdateWorkerAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkerAttendance: (
    { __typename?: 'WorkerAttendanceEntity' }
    & Pick<WorkerAttendanceEntity, 'ID' | 'workerID'>
  ) }
);

export type DeleteWorkerAttendanceMutationVariables = Exact<{
  input: WorkerAttendanceDeleteInput;
}>;


export type DeleteWorkerAttendanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorkerAttendance'>
);

export type CreateBatchWorkerAttendanceMutationVariables = Exact<{
  input: Array<BatchWorkAttendanceInput> | BatchWorkAttendanceInput;
}>;


export type CreateBatchWorkerAttendanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchWorkerAttendance'>
);

export type WorkerAttendanceValidationMutationVariables = Exact<{
  siteID: Scalars['String'];
  siteWorkerAttendanceInput: Array<SiteWorkerAttendanceImportInput> | SiteWorkerAttendanceImportInput;
}>;


export type WorkerAttendanceValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'workerAttendanceValidation'>
);

export type WorkerAttendanceImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type WorkerAttendanceImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'workerAttendanceImport'>
);

export type GetSubcontractorQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'email' | 'accountID' | 'registrationNo' | 'associatedStatus' | 'contactNo' | 'creditTerm' | 'currencyID' | 'ratingType' | 'cidbDetails' | 'pkkDetails' | 'statusRemarks' | 'bankDetails' | 'gstRegNo' | 'description' | 'gstExpDate' | 'platformAccess' | 'sstRegNo' | 'address' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'nominatedSC'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, subconClaim: Array<(
      { __typename?: 'SubcontractClaimEntity' }
      & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt'>
    )>, subconAdvance?: Maybe<Array<(
      { __typename?: 'SubcontractAdvanceEntity' }
      & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'baseAmt'>
    )>>, subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum'>
    )>> }
  )> }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    avatar
    lastestAccessedEntity
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginId: String!, $password: String!, $siteAccess: Boolean) {
  login(loginId: $loginId, password: $password, siteAccess: $siteAccess) {
    accessToken
    refreshToken
    user {
      accountID
      name
      lastestAccessedEntity
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *      siteAccess: // value for 'siteAccess'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UploadProfilePicDocument = gql`
    mutation uploadProfilePic($userID: String!, $file: Upload!) {
  uploadProfilePic(userID: $userID, file: $file)
}
    `;
export type UploadProfilePicMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicMutation, UploadProfilePicMutationVariables>;

/**
 * __useUploadProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicMutation, { data, loading, error }] = useUploadProfilePicMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicMutation, UploadProfilePicMutationVariables>(UploadProfilePicDocument, baseOptions);
      }
export type UploadProfilePicMutationHookResult = ReturnType<typeof useUploadProfilePicMutation>;
export type UploadProfilePicMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicMutation>;
export type UploadProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String, $entityID: String) {
  logout(ID: $ID, entityID: $entityID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetSiteDrawerPermissionDocument = gql`
    query getSiteDrawerPermission($pageMode: String) {
  getSiteDrawerPermission(pageMode: $pageMode)
}
    `;

/**
 * __useGetSiteDrawerPermissionQuery__
 *
 * To run a query within a React component, call `useGetSiteDrawerPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteDrawerPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteDrawerPermissionQuery({
 *   variables: {
 *      pageMode: // value for 'pageMode'
 *   },
 * });
 */
export function useGetSiteDrawerPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteDrawerPermissionQuery, GetSiteDrawerPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteDrawerPermissionQuery, GetSiteDrawerPermissionQueryVariables>(GetSiteDrawerPermissionDocument, baseOptions);
      }
export function useGetSiteDrawerPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteDrawerPermissionQuery, GetSiteDrawerPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteDrawerPermissionQuery, GetSiteDrawerPermissionQueryVariables>(GetSiteDrawerPermissionDocument, baseOptions);
        }
export type GetSiteDrawerPermissionQueryHookResult = ReturnType<typeof useGetSiteDrawerPermissionQuery>;
export type GetSiteDrawerPermissionLazyQueryHookResult = ReturnType<typeof useGetSiteDrawerPermissionLazyQuery>;
export type GetSiteDrawerPermissionQueryResult = ApolloReactCommon.QueryResult<GetSiteDrawerPermissionQuery, GetSiteDrawerPermissionQueryVariables>;
export const GetSiteBusinessInsightDocument = gql`
    query getSiteBusinessInsight($siteID: String) {
  totalSiteCount
  getSite(ID: $siteID) {
    ID
    contractID
    name
    address
  }
}
    `;

/**
 * __useGetSiteBusinessInsightQuery__
 *
 * To run a query within a React component, call `useGetSiteBusinessInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteBusinessInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteBusinessInsightQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteBusinessInsightQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteBusinessInsightQuery, GetSiteBusinessInsightQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteBusinessInsightQuery, GetSiteBusinessInsightQueryVariables>(GetSiteBusinessInsightDocument, baseOptions);
      }
export function useGetSiteBusinessInsightLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteBusinessInsightQuery, GetSiteBusinessInsightQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteBusinessInsightQuery, GetSiteBusinessInsightQueryVariables>(GetSiteBusinessInsightDocument, baseOptions);
        }
export type GetSiteBusinessInsightQueryHookResult = ReturnType<typeof useGetSiteBusinessInsightQuery>;
export type GetSiteBusinessInsightLazyQueryHookResult = ReturnType<typeof useGetSiteBusinessInsightLazyQuery>;
export type GetSiteBusinessInsightQueryResult = ApolloReactCommon.QueryResult<GetSiteBusinessInsightQuery, GetSiteBusinessInsightQueryVariables>;
export const GetSiteWorkerAttendanceTrackingDocument = gql`
    query getSiteWorkerAttendanceTracking($workerID: String!, $workDate: String!) {
  getSiteWorkerAttendanceTracking(workerID: $workerID, workDate: $workDate)
}
    `;

/**
 * __useGetSiteWorkerAttendanceTrackingQuery__
 *
 * To run a query within a React component, call `useGetSiteWorkerAttendanceTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWorkerAttendanceTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWorkerAttendanceTrackingQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *      workDate: // value for 'workDate'
 *   },
 * });
 */
export function useGetSiteWorkerAttendanceTrackingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWorkerAttendanceTrackingQuery, GetSiteWorkerAttendanceTrackingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWorkerAttendanceTrackingQuery, GetSiteWorkerAttendanceTrackingQueryVariables>(GetSiteWorkerAttendanceTrackingDocument, baseOptions);
      }
export function useGetSiteWorkerAttendanceTrackingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWorkerAttendanceTrackingQuery, GetSiteWorkerAttendanceTrackingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWorkerAttendanceTrackingQuery, GetSiteWorkerAttendanceTrackingQueryVariables>(GetSiteWorkerAttendanceTrackingDocument, baseOptions);
        }
export type GetSiteWorkerAttendanceTrackingQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceTrackingQuery>;
export type GetSiteWorkerAttendanceTrackingLazyQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceTrackingLazyQuery>;
export type GetSiteWorkerAttendanceTrackingQueryResult = ApolloReactCommon.QueryResult<GetSiteWorkerAttendanceTrackingQuery, GetSiteWorkerAttendanceTrackingQueryVariables>;
export const DefectLodgeOverTimeSummaryDocument = gql`
    query defectLodgeOverTimeSummary($siteID: String, $startDate: String, $endDate: String) {
  defectLodgeOverTimeSummary(siteID: $siteID, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useDefectLodgeOverTimeSummaryQuery__
 *
 * To run a query within a React component, call `useDefectLodgeOverTimeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefectLodgeOverTimeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefectLodgeOverTimeSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDefectLodgeOverTimeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefectLodgeOverTimeSummaryQuery, DefectLodgeOverTimeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DefectLodgeOverTimeSummaryQuery, DefectLodgeOverTimeSummaryQueryVariables>(DefectLodgeOverTimeSummaryDocument, baseOptions);
      }
export function useDefectLodgeOverTimeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefectLodgeOverTimeSummaryQuery, DefectLodgeOverTimeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefectLodgeOverTimeSummaryQuery, DefectLodgeOverTimeSummaryQueryVariables>(DefectLodgeOverTimeSummaryDocument, baseOptions);
        }
export type DefectLodgeOverTimeSummaryQueryHookResult = ReturnType<typeof useDefectLodgeOverTimeSummaryQuery>;
export type DefectLodgeOverTimeSummaryLazyQueryHookResult = ReturnType<typeof useDefectLodgeOverTimeSummaryLazyQuery>;
export type DefectLodgeOverTimeSummaryQueryResult = ApolloReactCommon.QueryResult<DefectLodgeOverTimeSummaryQuery, DefectLodgeOverTimeSummaryQueryVariables>;
export const DefectStatusAnalysisSummaryDocument = gql`
    query defectStatusAnalysisSummary($siteID: String, $startDate: String, $endDate: String) {
  defectStatusAnalysisSummary(siteID: $siteID, startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useDefectStatusAnalysisSummaryQuery__
 *
 * To run a query within a React component, call `useDefectStatusAnalysisSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefectStatusAnalysisSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefectStatusAnalysisSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDefectStatusAnalysisSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefectStatusAnalysisSummaryQuery, DefectStatusAnalysisSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DefectStatusAnalysisSummaryQuery, DefectStatusAnalysisSummaryQueryVariables>(DefectStatusAnalysisSummaryDocument, baseOptions);
      }
export function useDefectStatusAnalysisSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefectStatusAnalysisSummaryQuery, DefectStatusAnalysisSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefectStatusAnalysisSummaryQuery, DefectStatusAnalysisSummaryQueryVariables>(DefectStatusAnalysisSummaryDocument, baseOptions);
        }
export type DefectStatusAnalysisSummaryQueryHookResult = ReturnType<typeof useDefectStatusAnalysisSummaryQuery>;
export type DefectStatusAnalysisSummaryLazyQueryHookResult = ReturnType<typeof useDefectStatusAnalysisSummaryLazyQuery>;
export type DefectStatusAnalysisSummaryQueryResult = ApolloReactCommon.QueryResult<DefectStatusAnalysisSummaryQuery, DefectStatusAnalysisSummaryQueryVariables>;
export const DefectSubconAnalysisSummaryDocument = gql`
    query defectSubconAnalysisSummary($siteID: String) {
  defectSubconAnalysisSummary(siteID: $siteID)
}
    `;

/**
 * __useDefectSubconAnalysisSummaryQuery__
 *
 * To run a query within a React component, call `useDefectSubconAnalysisSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefectSubconAnalysisSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefectSubconAnalysisSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useDefectSubconAnalysisSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefectSubconAnalysisSummaryQuery, DefectSubconAnalysisSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DefectSubconAnalysisSummaryQuery, DefectSubconAnalysisSummaryQueryVariables>(DefectSubconAnalysisSummaryDocument, baseOptions);
      }
export function useDefectSubconAnalysisSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefectSubconAnalysisSummaryQuery, DefectSubconAnalysisSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefectSubconAnalysisSummaryQuery, DefectSubconAnalysisSummaryQueryVariables>(DefectSubconAnalysisSummaryDocument, baseOptions);
        }
export type DefectSubconAnalysisSummaryQueryHookResult = ReturnType<typeof useDefectSubconAnalysisSummaryQuery>;
export type DefectSubconAnalysisSummaryLazyQueryHookResult = ReturnType<typeof useDefectSubconAnalysisSummaryLazyQuery>;
export type DefectSubconAnalysisSummaryQueryResult = ApolloReactCommon.QueryResult<DefectSubconAnalysisSummaryQuery, DefectSubconAnalysisSummaryQueryVariables>;
export const DefectTradeAnalysisSummaryDocument = gql`
    query defectTradeAnalysisSummary($siteID: String) {
  defectTradeAnalysisSummary(siteID: $siteID)
}
    `;

/**
 * __useDefectTradeAnalysisSummaryQuery__
 *
 * To run a query within a React component, call `useDefectTradeAnalysisSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefectTradeAnalysisSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefectTradeAnalysisSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useDefectTradeAnalysisSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefectTradeAnalysisSummaryQuery, DefectTradeAnalysisSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DefectTradeAnalysisSummaryQuery, DefectTradeAnalysisSummaryQueryVariables>(DefectTradeAnalysisSummaryDocument, baseOptions);
      }
export function useDefectTradeAnalysisSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefectTradeAnalysisSummaryQuery, DefectTradeAnalysisSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefectTradeAnalysisSummaryQuery, DefectTradeAnalysisSummaryQueryVariables>(DefectTradeAnalysisSummaryDocument, baseOptions);
        }
export type DefectTradeAnalysisSummaryQueryHookResult = ReturnType<typeof useDefectTradeAnalysisSummaryQuery>;
export type DefectTradeAnalysisSummaryLazyQueryHookResult = ReturnType<typeof useDefectTradeAnalysisSummaryLazyQuery>;
export type DefectTradeAnalysisSummaryQueryResult = ApolloReactCommon.QueryResult<DefectTradeAnalysisSummaryQuery, DefectTradeAnalysisSummaryQueryVariables>;
export const GetWorkerAnalysisDocument = gql`
    query getWorkerAnalysis($siteID: String, $startDate: String!) {
  getWorkerAnalysis(siteID: $siteID, startDate: $startDate)
}
    `;

/**
 * __useGetWorkerAnalysisQuery__
 *
 * To run a query within a React component, call `useGetWorkerAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerAnalysisQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetWorkerAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerAnalysisQuery, GetWorkerAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerAnalysisQuery, GetWorkerAnalysisQueryVariables>(GetWorkerAnalysisDocument, baseOptions);
      }
export function useGetWorkerAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerAnalysisQuery, GetWorkerAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerAnalysisQuery, GetWorkerAnalysisQueryVariables>(GetWorkerAnalysisDocument, baseOptions);
        }
export type GetWorkerAnalysisQueryHookResult = ReturnType<typeof useGetWorkerAnalysisQuery>;
export type GetWorkerAnalysisLazyQueryHookResult = ReturnType<typeof useGetWorkerAnalysisLazyQuery>;
export type GetWorkerAnalysisQueryResult = ApolloReactCommon.QueryResult<GetWorkerAnalysisQuery, GetWorkerAnalysisQueryVariables>;
export const GetManLabourAnalysisDocument = gql`
    query getManLabourAnalysis($siteID: String, $selectedYear: Float!) {
  getManLabourAnalysis(siteID: $siteID, selectedYear: $selectedYear)
}
    `;

/**
 * __useGetManLabourAnalysisQuery__
 *
 * To run a query within a React component, call `useGetManLabourAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManLabourAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManLabourAnalysisQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      selectedYear: // value for 'selectedYear'
 *   },
 * });
 */
export function useGetManLabourAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetManLabourAnalysisQuery, GetManLabourAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetManLabourAnalysisQuery, GetManLabourAnalysisQueryVariables>(GetManLabourAnalysisDocument, baseOptions);
      }
export function useGetManLabourAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetManLabourAnalysisQuery, GetManLabourAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetManLabourAnalysisQuery, GetManLabourAnalysisQueryVariables>(GetManLabourAnalysisDocument, baseOptions);
        }
export type GetManLabourAnalysisQueryHookResult = ReturnType<typeof useGetManLabourAnalysisQuery>;
export type GetManLabourAnalysisLazyQueryHookResult = ReturnType<typeof useGetManLabourAnalysisLazyQuery>;
export type GetManLabourAnalysisQueryResult = ApolloReactCommon.QueryResult<GetManLabourAnalysisQuery, GetManLabourAnalysisQueryVariables>;
export const GetAllSiteDocument = gql`
    query getAllSite($orderByAsc: String) {
  getSite(orderByAsc: $orderByAsc) {
    ID
    name
    address
    contactNo
    contractID
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetAllSiteQuery__
 *
 * To run a query within a React component, call `useGetAllSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSiteQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAllSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSiteQuery, GetAllSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSiteQuery, GetAllSiteQueryVariables>(GetAllSiteDocument, baseOptions);
      }
export function useGetAllSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSiteQuery, GetAllSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSiteQuery, GetAllSiteQueryVariables>(GetAllSiteDocument, baseOptions);
        }
export type GetAllSiteQueryHookResult = ReturnType<typeof useGetAllSiteQuery>;
export type GetAllSiteLazyQueryHookResult = ReturnType<typeof useGetAllSiteLazyQuery>;
export type GetAllSiteQueryResult = ApolloReactCommon.QueryResult<GetAllSiteQuery, GetAllSiteQueryVariables>;
export const GetStockMovementAnalysisDocument = gql`
    query getStockMovementAnalysis($siteID: String, $siteMaterialID: String!, $year: Float) {
  getStockMovementAnalysis(siteID: $siteID, siteMaterialID: $siteMaterialID, year: $year)
}
    `;

/**
 * __useGetStockMovementAnalysisQuery__
 *
 * To run a query within a React component, call `useGetStockMovementAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementAnalysisQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetStockMovementAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementAnalysisQuery, GetStockMovementAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementAnalysisQuery, GetStockMovementAnalysisQueryVariables>(GetStockMovementAnalysisDocument, baseOptions);
      }
export function useGetStockMovementAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementAnalysisQuery, GetStockMovementAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementAnalysisQuery, GetStockMovementAnalysisQueryVariables>(GetStockMovementAnalysisDocument, baseOptions);
        }
export type GetStockMovementAnalysisQueryHookResult = ReturnType<typeof useGetStockMovementAnalysisQuery>;
export type GetStockMovementAnalysisLazyQueryHookResult = ReturnType<typeof useGetStockMovementAnalysisLazyQuery>;
export type GetStockMovementAnalysisQueryResult = ApolloReactCommon.QueryResult<GetStockMovementAnalysisQuery, GetStockMovementAnalysisQueryVariables>;
export const GetCostItemMovementAnalysisDocument = gql`
    query getCostItemMovementAnalysis($siteID: String, $costItemID: String!, $year: Float) {
  getCostItemMovementAnalysis(siteID: $siteID, costItemID: $costItemID, year: $year)
}
    `;

/**
 * __useGetCostItemMovementAnalysisQuery__
 *
 * To run a query within a React component, call `useGetCostItemMovementAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMovementAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMovementAnalysisQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetCostItemMovementAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMovementAnalysisQuery, GetCostItemMovementAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMovementAnalysisQuery, GetCostItemMovementAnalysisQueryVariables>(GetCostItemMovementAnalysisDocument, baseOptions);
      }
export function useGetCostItemMovementAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMovementAnalysisQuery, GetCostItemMovementAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMovementAnalysisQuery, GetCostItemMovementAnalysisQueryVariables>(GetCostItemMovementAnalysisDocument, baseOptions);
        }
export type GetCostItemMovementAnalysisQueryHookResult = ReturnType<typeof useGetCostItemMovementAnalysisQuery>;
export type GetCostItemMovementAnalysisLazyQueryHookResult = ReturnType<typeof useGetCostItemMovementAnalysisLazyQuery>;
export type GetCostItemMovementAnalysisQueryResult = ApolloReactCommon.QueryResult<GetCostItemMovementAnalysisQuery, GetCostItemMovementAnalysisQueryVariables>;
export const GetStockMonthYearDocument = gql`
    query getStockMonthYear($siteMaterialID: String!, $isBothStatus: Boolean, $siteIDs: [String!]) {
  getStockMonthYear(siteMaterialID: $siteMaterialID, isBothStatus: $isBothStatus, siteIDs: $siteIDs)
}
    `;

/**
 * __useGetStockMonthYearQuery__
 *
 * To run a query within a React component, call `useGetStockMonthYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMonthYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMonthYearQuery({
 *   variables: {
 *      siteMaterialID: // value for 'siteMaterialID'
 *      isBothStatus: // value for 'isBothStatus'
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetStockMonthYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMonthYearQuery, GetStockMonthYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMonthYearQuery, GetStockMonthYearQueryVariables>(GetStockMonthYearDocument, baseOptions);
      }
export function useGetStockMonthYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMonthYearQuery, GetStockMonthYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMonthYearQuery, GetStockMonthYearQueryVariables>(GetStockMonthYearDocument, baseOptions);
        }
export type GetStockMonthYearQueryHookResult = ReturnType<typeof useGetStockMonthYearQuery>;
export type GetStockMonthYearLazyQueryHookResult = ReturnType<typeof useGetStockMonthYearLazyQuery>;
export type GetStockMonthYearQueryResult = ApolloReactCommon.QueryResult<GetStockMonthYearQuery, GetStockMonthYearQueryVariables>;
export const GetCostItemMonthYearDocument = gql`
    query getCostItemMonthYear($costItemID: String!, $isBothStatus: Boolean, $siteIDs: [String!]) {
  getCostItemMonthYear(costItemID: $costItemID, isBothStatus: $isBothStatus, siteIDs: $siteIDs)
}
    `;

/**
 * __useGetCostItemMonthYearQuery__
 *
 * To run a query within a React component, call `useGetCostItemMonthYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMonthYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMonthYearQuery({
 *   variables: {
 *      costItemID: // value for 'costItemID'
 *      isBothStatus: // value for 'isBothStatus'
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetCostItemMonthYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMonthYearQuery, GetCostItemMonthYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMonthYearQuery, GetCostItemMonthYearQueryVariables>(GetCostItemMonthYearDocument, baseOptions);
      }
export function useGetCostItemMonthYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMonthYearQuery, GetCostItemMonthYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMonthYearQuery, GetCostItemMonthYearQueryVariables>(GetCostItemMonthYearDocument, baseOptions);
        }
export type GetCostItemMonthYearQueryHookResult = ReturnType<typeof useGetCostItemMonthYearQuery>;
export type GetCostItemMonthYearLazyQueryHookResult = ReturnType<typeof useGetCostItemMonthYearLazyQuery>;
export type GetCostItemMonthYearQueryResult = ApolloReactCommon.QueryResult<GetCostItemMonthYearQuery, GetCostItemMonthYearQueryVariables>;
export const GetStockMovementByDayAnalysisDocument = gql`
    query getStockMovementByDayAnalysis($year: Float, $month: Float, $siteID: String, $siteMaterialID: String) {
  getStockMovementByDayAnalysis(year: $year, month: $month, siteID: $siteID, siteMaterialID: $siteMaterialID)
}
    `;

/**
 * __useGetStockMovementByDayAnalysisQuery__
 *
 * To run a query within a React component, call `useGetStockMovementByDayAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementByDayAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementByDayAnalysisQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *   },
 * });
 */
export function useGetStockMovementByDayAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementByDayAnalysisQuery, GetStockMovementByDayAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementByDayAnalysisQuery, GetStockMovementByDayAnalysisQueryVariables>(GetStockMovementByDayAnalysisDocument, baseOptions);
      }
export function useGetStockMovementByDayAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementByDayAnalysisQuery, GetStockMovementByDayAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementByDayAnalysisQuery, GetStockMovementByDayAnalysisQueryVariables>(GetStockMovementByDayAnalysisDocument, baseOptions);
        }
export type GetStockMovementByDayAnalysisQueryHookResult = ReturnType<typeof useGetStockMovementByDayAnalysisQuery>;
export type GetStockMovementByDayAnalysisLazyQueryHookResult = ReturnType<typeof useGetStockMovementByDayAnalysisLazyQuery>;
export type GetStockMovementByDayAnalysisQueryResult = ApolloReactCommon.QueryResult<GetStockMovementByDayAnalysisQuery, GetStockMovementByDayAnalysisQueryVariables>;
export const GetCostItemMovementByDayAnalysisDocument = gql`
    query getCostItemMovementByDayAnalysis($year: Float, $month: Float, $siteID: String, $costItemID: String) {
  getCostItemMovementByDayAnalysis(year: $year, month: $month, siteID: $siteID, costItemID: $costItemID)
}
    `;

/**
 * __useGetCostItemMovementByDayAnalysisQuery__
 *
 * To run a query within a React component, call `useGetCostItemMovementByDayAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMovementByDayAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMovementByDayAnalysisQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetCostItemMovementByDayAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMovementByDayAnalysisQuery, GetCostItemMovementByDayAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMovementByDayAnalysisQuery, GetCostItemMovementByDayAnalysisQueryVariables>(GetCostItemMovementByDayAnalysisDocument, baseOptions);
      }
export function useGetCostItemMovementByDayAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMovementByDayAnalysisQuery, GetCostItemMovementByDayAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMovementByDayAnalysisQuery, GetCostItemMovementByDayAnalysisQueryVariables>(GetCostItemMovementByDayAnalysisDocument, baseOptions);
        }
export type GetCostItemMovementByDayAnalysisQueryHookResult = ReturnType<typeof useGetCostItemMovementByDayAnalysisQuery>;
export type GetCostItemMovementByDayAnalysisLazyQueryHookResult = ReturnType<typeof useGetCostItemMovementByDayAnalysisLazyQuery>;
export type GetCostItemMovementByDayAnalysisQueryResult = ApolloReactCommon.QueryResult<GetCostItemMovementByDayAnalysisQuery, GetCostItemMovementByDayAnalysisQueryVariables>;
export const GetCostItemMoveInOutAnalysisDocument = gql`
    query getCostItemMoveInOutAnalysis($costItemID: String!, $siteID: String, $year: Float) {
  getCostItemMoveInOutAnalysis(costItemID: $costItemID, siteID: $siteID, year: $year)
}
    `;

/**
 * __useGetCostItemMoveInOutAnalysisQuery__
 *
 * To run a query within a React component, call `useGetCostItemMoveInOutAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMoveInOutAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMoveInOutAnalysisQuery({
 *   variables: {
 *      costItemID: // value for 'costItemID'
 *      siteID: // value for 'siteID'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetCostItemMoveInOutAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMoveInOutAnalysisQuery, GetCostItemMoveInOutAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMoveInOutAnalysisQuery, GetCostItemMoveInOutAnalysisQueryVariables>(GetCostItemMoveInOutAnalysisDocument, baseOptions);
      }
export function useGetCostItemMoveInOutAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMoveInOutAnalysisQuery, GetCostItemMoveInOutAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMoveInOutAnalysisQuery, GetCostItemMoveInOutAnalysisQueryVariables>(GetCostItemMoveInOutAnalysisDocument, baseOptions);
        }
export type GetCostItemMoveInOutAnalysisQueryHookResult = ReturnType<typeof useGetCostItemMoveInOutAnalysisQuery>;
export type GetCostItemMoveInOutAnalysisLazyQueryHookResult = ReturnType<typeof useGetCostItemMoveInOutAnalysisLazyQuery>;
export type GetCostItemMoveInOutAnalysisQueryResult = ApolloReactCommon.QueryResult<GetCostItemMoveInOutAnalysisQuery, GetCostItemMoveInOutAnalysisQueryVariables>;
export const GetStockMoveInOutAnalysisDocument = gql`
    query getStockMoveInOutAnalysis($siteMaterialID: String!, $siteID: String, $year: Float) {
  getStockMoveInOutAnalysis(siteMaterialID: $siteMaterialID, siteID: $siteID, year: $year)
}
    `;

/**
 * __useGetStockMoveInOutAnalysisQuery__
 *
 * To run a query within a React component, call `useGetStockMoveInOutAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMoveInOutAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMoveInOutAnalysisQuery({
 *   variables: {
 *      siteMaterialID: // value for 'siteMaterialID'
 *      siteID: // value for 'siteID'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetStockMoveInOutAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMoveInOutAnalysisQuery, GetStockMoveInOutAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMoveInOutAnalysisQuery, GetStockMoveInOutAnalysisQueryVariables>(GetStockMoveInOutAnalysisDocument, baseOptions);
      }
export function useGetStockMoveInOutAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMoveInOutAnalysisQuery, GetStockMoveInOutAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMoveInOutAnalysisQuery, GetStockMoveInOutAnalysisQueryVariables>(GetStockMoveInOutAnalysisDocument, baseOptions);
        }
export type GetStockMoveInOutAnalysisQueryHookResult = ReturnType<typeof useGetStockMoveInOutAnalysisQuery>;
export type GetStockMoveInOutAnalysisLazyQueryHookResult = ReturnType<typeof useGetStockMoveInOutAnalysisLazyQuery>;
export type GetStockMoveInOutAnalysisQueryResult = ApolloReactCommon.QueryResult<GetStockMoveInOutAnalysisQuery, GetStockMoveInOutAnalysisQueryVariables>;
export const CostItemSiteUsageSummaryDocument = gql`
    query costItemSiteUsageSummary($year: Float, $month: Float, $costItemID: String) {
  costItemSiteUsageSummary(year: $year, month: $month, costItemID: $costItemID)
}
    `;

/**
 * __useCostItemSiteUsageSummaryQuery__
 *
 * To run a query within a React component, call `useCostItemSiteUsageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostItemSiteUsageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostItemSiteUsageSummaryQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useCostItemSiteUsageSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostItemSiteUsageSummaryQuery, CostItemSiteUsageSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CostItemSiteUsageSummaryQuery, CostItemSiteUsageSummaryQueryVariables>(CostItemSiteUsageSummaryDocument, baseOptions);
      }
export function useCostItemSiteUsageSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostItemSiteUsageSummaryQuery, CostItemSiteUsageSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostItemSiteUsageSummaryQuery, CostItemSiteUsageSummaryQueryVariables>(CostItemSiteUsageSummaryDocument, baseOptions);
        }
export type CostItemSiteUsageSummaryQueryHookResult = ReturnType<typeof useCostItemSiteUsageSummaryQuery>;
export type CostItemSiteUsageSummaryLazyQueryHookResult = ReturnType<typeof useCostItemSiteUsageSummaryLazyQuery>;
export type CostItemSiteUsageSummaryQueryResult = ApolloReactCommon.QueryResult<CostItemSiteUsageSummaryQuery, CostItemSiteUsageSummaryQueryVariables>;
export const StockSiteUsageSummaryDocument = gql`
    query stockSiteUsageSummary($year: Float, $month: Float, $siteMaterialID: String) {
  stockSiteUsageSummary(year: $year, month: $month, siteMaterialID: $siteMaterialID)
}
    `;

/**
 * __useStockSiteUsageSummaryQuery__
 *
 * To run a query within a React component, call `useStockSiteUsageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockSiteUsageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockSiteUsageSummaryQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      siteMaterialID: // value for 'siteMaterialID'
 *   },
 * });
 */
export function useStockSiteUsageSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockSiteUsageSummaryQuery, StockSiteUsageSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<StockSiteUsageSummaryQuery, StockSiteUsageSummaryQueryVariables>(StockSiteUsageSummaryDocument, baseOptions);
      }
export function useStockSiteUsageSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockSiteUsageSummaryQuery, StockSiteUsageSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockSiteUsageSummaryQuery, StockSiteUsageSummaryQueryVariables>(StockSiteUsageSummaryDocument, baseOptions);
        }
export type StockSiteUsageSummaryQueryHookResult = ReturnType<typeof useStockSiteUsageSummaryQuery>;
export type StockSiteUsageSummaryLazyQueryHookResult = ReturnType<typeof useStockSiteUsageSummaryLazyQuery>;
export type StockSiteUsageSummaryQueryResult = ApolloReactCommon.QueryResult<StockSiteUsageSummaryQuery, StockSiteUsageSummaryQueryVariables>;
export const CostItemSiteUsageComparisonDocument = gql`
    query costItemSiteUsageComparison($year: Float, $siteIDs: [String!], $costItemID: String) {
  costItemSiteUsageComparison(year: $year, siteIDs: $siteIDs, costItemID: $costItemID)
}
    `;

/**
 * __useCostItemSiteUsageComparisonQuery__
 *
 * To run a query within a React component, call `useCostItemSiteUsageComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostItemSiteUsageComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostItemSiteUsageComparisonQuery({
 *   variables: {
 *      year: // value for 'year'
 *      siteIDs: // value for 'siteIDs'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useCostItemSiteUsageComparisonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostItemSiteUsageComparisonQuery, CostItemSiteUsageComparisonQueryVariables>) {
        return ApolloReactHooks.useQuery<CostItemSiteUsageComparisonQuery, CostItemSiteUsageComparisonQueryVariables>(CostItemSiteUsageComparisonDocument, baseOptions);
      }
export function useCostItemSiteUsageComparisonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostItemSiteUsageComparisonQuery, CostItemSiteUsageComparisonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostItemSiteUsageComparisonQuery, CostItemSiteUsageComparisonQueryVariables>(CostItemSiteUsageComparisonDocument, baseOptions);
        }
export type CostItemSiteUsageComparisonQueryHookResult = ReturnType<typeof useCostItemSiteUsageComparisonQuery>;
export type CostItemSiteUsageComparisonLazyQueryHookResult = ReturnType<typeof useCostItemSiteUsageComparisonLazyQuery>;
export type CostItemSiteUsageComparisonQueryResult = ApolloReactCommon.QueryResult<CostItemSiteUsageComparisonQuery, CostItemSiteUsageComparisonQueryVariables>;
export const StockSiteUsageComparisonDocument = gql`
    query stockSiteUsageComparison($year: Float, $siteIDs: [String!], $siteMaterialID: String) {
  stockSiteUsageComparison(year: $year, siteIDs: $siteIDs, siteMaterialID: $siteMaterialID)
}
    `;

/**
 * __useStockSiteUsageComparisonQuery__
 *
 * To run a query within a React component, call `useStockSiteUsageComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockSiteUsageComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockSiteUsageComparisonQuery({
 *   variables: {
 *      year: // value for 'year'
 *      siteIDs: // value for 'siteIDs'
 *      siteMaterialID: // value for 'siteMaterialID'
 *   },
 * });
 */
export function useStockSiteUsageComparisonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockSiteUsageComparisonQuery, StockSiteUsageComparisonQueryVariables>) {
        return ApolloReactHooks.useQuery<StockSiteUsageComparisonQuery, StockSiteUsageComparisonQueryVariables>(StockSiteUsageComparisonDocument, baseOptions);
      }
export function useStockSiteUsageComparisonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockSiteUsageComparisonQuery, StockSiteUsageComparisonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockSiteUsageComparisonQuery, StockSiteUsageComparisonQueryVariables>(StockSiteUsageComparisonDocument, baseOptions);
        }
export type StockSiteUsageComparisonQueryHookResult = ReturnType<typeof useStockSiteUsageComparisonQuery>;
export type StockSiteUsageComparisonLazyQueryHookResult = ReturnType<typeof useStockSiteUsageComparisonLazyQuery>;
export type StockSiteUsageComparisonQueryResult = ApolloReactCommon.QueryResult<StockSiteUsageComparisonQuery, StockSiteUsageComparisonQueryVariables>;
export const GetAllCostItemDocument = gql`
    query getAllCostItem($siteIDs: [String!]) {
  getAllCostItem(siteIDs: $siteIDs)
}
    `;

/**
 * __useGetAllCostItemQuery__
 *
 * To run a query within a React component, call `useGetAllCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCostItemQuery({
 *   variables: {
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetAllCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCostItemQuery, GetAllCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCostItemQuery, GetAllCostItemQueryVariables>(GetAllCostItemDocument, baseOptions);
      }
export function useGetAllCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCostItemQuery, GetAllCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCostItemQuery, GetAllCostItemQueryVariables>(GetAllCostItemDocument, baseOptions);
        }
export type GetAllCostItemQueryHookResult = ReturnType<typeof useGetAllCostItemQuery>;
export type GetAllCostItemLazyQueryHookResult = ReturnType<typeof useGetAllCostItemLazyQuery>;
export type GetAllCostItemQueryResult = ApolloReactCommon.QueryResult<GetAllCostItemQuery, GetAllCostItemQueryVariables>;
export const GetAllSiteItemDocument = gql`
    query getAllSiteItem($siteIDs: [String!]) {
  getAllSiteItem(siteIDs: $siteIDs)
}
    `;

/**
 * __useGetAllSiteItemQuery__
 *
 * To run a query within a React component, call `useGetAllSiteItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSiteItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSiteItemQuery({
 *   variables: {
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetAllSiteItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSiteItemQuery, GetAllSiteItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSiteItemQuery, GetAllSiteItemQueryVariables>(GetAllSiteItemDocument, baseOptions);
      }
export function useGetAllSiteItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSiteItemQuery, GetAllSiteItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSiteItemQuery, GetAllSiteItemQueryVariables>(GetAllSiteItemDocument, baseOptions);
        }
export type GetAllSiteItemQueryHookResult = ReturnType<typeof useGetAllSiteItemQuery>;
export type GetAllSiteItemLazyQueryHookResult = ReturnType<typeof useGetAllSiteItemLazyQuery>;
export type GetAllSiteItemQueryResult = ApolloReactCommon.QueryResult<GetAllSiteItemQuery, GetAllSiteItemQueryVariables>;
export const GetShareAttachmentDocument = gql`
    mutation getShareAttachment($file: Upload!, $contractID: String) {
  shareAttachment(file: $file, contractID: $contractID) {
    ID
    bucketFileName
    fileName
    fileURL
  }
}
    `;
export type GetShareAttachmentMutationFn = ApolloReactCommon.MutationFunction<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;

/**
 * __useGetShareAttachmentMutation__
 *
 * To run a mutation, you first call `useGetShareAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareAttachmentMutation, { data, loading, error }] = useGetShareAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetShareAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>(GetShareAttachmentDocument, baseOptions);
      }
export type GetShareAttachmentMutationHookResult = ReturnType<typeof useGetShareAttachmentMutation>;
export type GetShareAttachmentMutationResult = ApolloReactCommon.MutationResult<GetShareAttachmentMutation>;
export type GetShareAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;
export const GetWageLabourAnalysisDocument = gql`
    query getWageLabourAnalysis($siteID: String) {
  getWageLabourAnalysis(siteID: $siteID)
}
    `;

/**
 * __useGetWageLabourAnalysisQuery__
 *
 * To run a query within a React component, call `useGetWageLabourAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWageLabourAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWageLabourAnalysisQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetWageLabourAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWageLabourAnalysisQuery, GetWageLabourAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWageLabourAnalysisQuery, GetWageLabourAnalysisQueryVariables>(GetWageLabourAnalysisDocument, baseOptions);
      }
export function useGetWageLabourAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWageLabourAnalysisQuery, GetWageLabourAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWageLabourAnalysisQuery, GetWageLabourAnalysisQueryVariables>(GetWageLabourAnalysisDocument, baseOptions);
        }
export type GetWageLabourAnalysisQueryHookResult = ReturnType<typeof useGetWageLabourAnalysisQuery>;
export type GetWageLabourAnalysisLazyQueryHookResult = ReturnType<typeof useGetWageLabourAnalysisLazyQuery>;
export type GetWageLabourAnalysisQueryResult = ApolloReactCommon.QueryResult<GetWageLabourAnalysisQuery, GetWageLabourAnalysisQueryVariables>;
export const GetContractDocument = gql`
    query getContract($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    site {
      name
      contractID
      address
      ID
    }
    contractDate
    contractSum
    voSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    ladAmt
    ladRate
    creditTerm
    latestRunNum
    docNumChecking
    contractPOCounts
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractTenderDetail {
      ID
      title
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
    subcontractSummary
    contactPerson
    closureRemarks
    closureDate
    modBy
    extensionDate
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
  }
}
    `;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const GetContractSubmenuDocument = gql`
    query getContractSubmenu($ID: String) {
  getContract(ID: $ID) {
    ID
    title
    accountID
    contractNo
    customerID
    contractSum
    revisedContractSum
  }
  getAllContract: getContract {
    ID
    title
    accountID
    contractNo
    customerID
  }
}
    `;

/**
 * __useGetContractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetContractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
      }
export function useGetContractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
        }
export type GetContractSubmenuQueryHookResult = ReturnType<typeof useGetContractSubmenuQuery>;
export type GetContractSubmenuLazyQueryHookResult = ReturnType<typeof useGetContractSubmenuLazyQuery>;
export type GetContractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>;
export const GetContractTitleDocument = gql`
    query getContractTitle($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
  }
}
    `;

/**
 * __useGetContractTitleQuery__
 *
 * To run a query within a React component, call `useGetContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
      }
export function useGetContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
        }
export type GetContractTitleQueryHookResult = ReturnType<typeof useGetContractTitleQuery>;
export type GetContractTitleLazyQueryHookResult = ReturnType<typeof useGetContractTitleLazyQuery>;
export type GetContractTitleQueryResult = ApolloReactCommon.QueryResult<GetContractTitleQuery, GetContractTitleQueryVariables>;
export const GetDefectDocument = gql`
    query getDefect($ID: String, $defectStatus: DefectStatus, $orderByDesc: String, $orderByAsc: String, $siteID: String) {
  totalSiteCount
  getDefect(ID: $ID, defectStatus: $defectStatus, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, siteID: $siteID) {
    ID
    description
    defectStatus
    siteID
    costCategoryID
    reportedDate
    expectedDate
    completionDate
    createdTs
    createdBy
    activityLog
    docNo
    profileInfo
    site {
      ID
      name
    }
    trade {
      ID
      name
    }
    defectAssignment {
      ID
      assignmentDate
      expectedDate
      subcontractor {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetDefectQuery__
 *
 * To run a query within a React component, call `useGetDefectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      defectStatus: // value for 'defectStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetDefectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectQuery, GetDefectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectQuery, GetDefectQueryVariables>(GetDefectDocument, baseOptions);
      }
export function useGetDefectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectQuery, GetDefectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectQuery, GetDefectQueryVariables>(GetDefectDocument, baseOptions);
        }
export type GetDefectQueryHookResult = ReturnType<typeof useGetDefectQuery>;
export type GetDefectLazyQueryHookResult = ReturnType<typeof useGetDefectLazyQuery>;
export type GetDefectQueryResult = ApolloReactCommon.QueryResult<GetDefectQuery, GetDefectQueryVariables>;
export const CreateDefectDocument = gql`
    mutation createDefect($input: DefectInput!) {
  createDefect(input: $input) {
    ID
  }
}
    `;
export type CreateDefectMutationFn = ApolloReactCommon.MutationFunction<CreateDefectMutation, CreateDefectMutationVariables>;

/**
 * __useCreateDefectMutation__
 *
 * To run a mutation, you first call `useCreateDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefectMutation, { data, loading, error }] = useCreateDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefectMutation, CreateDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefectMutation, CreateDefectMutationVariables>(CreateDefectDocument, baseOptions);
      }
export type CreateDefectMutationHookResult = ReturnType<typeof useCreateDefectMutation>;
export type CreateDefectMutationResult = ApolloReactCommon.MutationResult<CreateDefectMutation>;
export type CreateDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefectMutation, CreateDefectMutationVariables>;
export const UpdateDefectDocument = gql`
    mutation updateDefect($input: DefectInput!) {
  updateDefect(input: $input) {
    ID
  }
}
    `;
export type UpdateDefectMutationFn = ApolloReactCommon.MutationFunction<UpdateDefectMutation, UpdateDefectMutationVariables>;

/**
 * __useUpdateDefectMutation__
 *
 * To run a mutation, you first call `useUpdateDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefectMutation, { data, loading, error }] = useUpdateDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefectMutation, UpdateDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefectMutation, UpdateDefectMutationVariables>(UpdateDefectDocument, baseOptions);
      }
export type UpdateDefectMutationHookResult = ReturnType<typeof useUpdateDefectMutation>;
export type UpdateDefectMutationResult = ApolloReactCommon.MutationResult<UpdateDefectMutation>;
export type UpdateDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefectMutation, UpdateDefectMutationVariables>;
export const CreateActivityLogsDocument = gql`
    mutation createActivityLogs($file: [Upload!], $input: ActivityLogInput!, $defectID: String!) {
  createActivityLogs(input: $input, defectID: $defectID, file: $file)
}
    `;
export type CreateActivityLogsMutationFn = ApolloReactCommon.MutationFunction<CreateActivityLogsMutation, CreateActivityLogsMutationVariables>;

/**
 * __useCreateActivityLogsMutation__
 *
 * To run a mutation, you first call `useCreateActivityLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityLogsMutation, { data, loading, error }] = useCreateActivityLogsMutation({
 *   variables: {
 *      file: // value for 'file'
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *   },
 * });
 */
export function useCreateActivityLogsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActivityLogsMutation, CreateActivityLogsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActivityLogsMutation, CreateActivityLogsMutationVariables>(CreateActivityLogsDocument, baseOptions);
      }
export type CreateActivityLogsMutationHookResult = ReturnType<typeof useCreateActivityLogsMutation>;
export type CreateActivityLogsMutationResult = ApolloReactCommon.MutationResult<CreateActivityLogsMutation>;
export type CreateActivityLogsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActivityLogsMutation, CreateActivityLogsMutationVariables>;
export const GetDefectDetailDataDocument = gql`
    query getDefectDetailData($siteID: String, $orderByAsc: String, $orderByDesc: String, $costClass: CostClass, $commonStatus: CommonStatus, $defectID: String, $defectStatus: DefectStatus, $subcontractorID: String) {
  getDefect(ID: $defectID, defectStatus: $defectStatus, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, siteID: $siteID) {
    ID
    description
    defectStatus
    siteID
    costCategoryID
    reportedDate
    expectedDate
    completionDate
    createdTs
    createdBy
    activityLog
    docNo
    site {
      ID
      name
    }
    trade {
      ID
      name
    }
    defectAssignment {
      ID
      defectID
      assignmentDate
      subcontractorID
      subcontractor {
        ID
        name
      }
    }
  }
  getCostCategory(costClass: $costClass, commonStatus: $commonStatus, subcontractorID: $subcontractorID) {
    ID
    name
    commonStatus
    costClass
    subcontractor {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetDefectDetailDataQuery__
 *
 * To run a query within a React component, call `useGetDefectDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectDetailDataQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      costClass: // value for 'costClass'
 *      commonStatus: // value for 'commonStatus'
 *      defectID: // value for 'defectID'
 *      defectStatus: // value for 'defectStatus'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetDefectDetailDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectDetailDataQuery, GetDefectDetailDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectDetailDataQuery, GetDefectDetailDataQueryVariables>(GetDefectDetailDataDocument, baseOptions);
      }
export function useGetDefectDetailDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectDetailDataQuery, GetDefectDetailDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectDetailDataQuery, GetDefectDetailDataQueryVariables>(GetDefectDetailDataDocument, baseOptions);
        }
export type GetDefectDetailDataQueryHookResult = ReturnType<typeof useGetDefectDetailDataQuery>;
export type GetDefectDetailDataLazyQueryHookResult = ReturnType<typeof useGetDefectDetailDataLazyQuery>;
export type GetDefectDetailDataQueryResult = ApolloReactCommon.QueryResult<GetDefectDetailDataQuery, GetDefectDetailDataQueryVariables>;
export const CreateDefectAssignmentDocument = gql`
    mutation createDefectAssignment($ID: String!, $input: DefectAssignmentInput!) {
  createDefectAssignment(ID: $ID, input: $input)
}
    `;
export type CreateDefectAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateDefectAssignmentMutation, CreateDefectAssignmentMutationVariables>;

/**
 * __useCreateDefectAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateDefectAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefectAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefectAssignmentMutation, { data, loading, error }] = useCreateDefectAssignmentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefectAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefectAssignmentMutation, CreateDefectAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefectAssignmentMutation, CreateDefectAssignmentMutationVariables>(CreateDefectAssignmentDocument, baseOptions);
      }
export type CreateDefectAssignmentMutationHookResult = ReturnType<typeof useCreateDefectAssignmentMutation>;
export type CreateDefectAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateDefectAssignmentMutation>;
export type CreateDefectAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefectAssignmentMutation, CreateDefectAssignmentMutationVariables>;
export const GetDefectAssignmentDocument = gql`
    query getDefectAssignment($ID: String, $defectID: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float) {
  getDefectAssignment(ID: $ID, defectID: $defectID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take) {
    ID
    defectID
    subcontractorID
    assignmentDate
    expectedDate
    remarks
    defect {
      ID
      description
      trade {
        ID
        name
        description
      }
    }
    subcontractor {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetDefectAssignmentQuery__
 *
 * To run a query within a React component, call `useGetDefectAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      defectID: // value for 'defectID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetDefectAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectAssignmentQuery, GetDefectAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectAssignmentQuery, GetDefectAssignmentQueryVariables>(GetDefectAssignmentDocument, baseOptions);
      }
export function useGetDefectAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectAssignmentQuery, GetDefectAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectAssignmentQuery, GetDefectAssignmentQueryVariables>(GetDefectAssignmentDocument, baseOptions);
        }
export type GetDefectAssignmentQueryHookResult = ReturnType<typeof useGetDefectAssignmentQuery>;
export type GetDefectAssignmentLazyQueryHookResult = ReturnType<typeof useGetDefectAssignmentLazyQuery>;
export type GetDefectAssignmentQueryResult = ApolloReactCommon.QueryResult<GetDefectAssignmentQuery, GetDefectAssignmentQueryVariables>;
export const GetSubcontractorCostCategoryDocument = gql`
    query getSubcontractorCostCategory($costCategoryID: String!) {
  getSubcontractorCostCategory(costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetSubcontractorCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCostCategoryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSubcontractorCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>(GetSubcontractorCostCategoryDocument, baseOptions);
      }
export function useGetSubcontractorCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>(GetSubcontractorCostCategoryDocument, baseOptions);
        }
export type GetSubcontractorCostCategoryQueryHookResult = ReturnType<typeof useGetSubcontractorCostCategoryQuery>;
export type GetSubcontractorCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCostCategoryLazyQuery>;
export type GetSubcontractorCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>;
export const CreateDefectMgmtDocument = gql`
    mutation createDefectMgmt($input: DefectManagementInput!) {
  createDefectMgmt(input: $input) {
    ID
  }
}
    `;
export type CreateDefectMgmtMutationFn = ApolloReactCommon.MutationFunction<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>;

/**
 * __useCreateDefectMgmtMutation__
 *
 * To run a mutation, you first call `useCreateDefectMgmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefectMgmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefectMgmtMutation, { data, loading, error }] = useCreateDefectMgmtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefectMgmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>(CreateDefectMgmtDocument, baseOptions);
      }
export type CreateDefectMgmtMutationHookResult = ReturnType<typeof useCreateDefectMgmtMutation>;
export type CreateDefectMgmtMutationResult = ApolloReactCommon.MutationResult<CreateDefectMgmtMutation>;
export type CreateDefectMgmtMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>;
export const UpdateDefectMgmtDocument = gql`
    mutation updateDefectMgmt($input: DefectManagementInput!) {
  updateDefectMgmt(input: $input) {
    ID
  }
}
    `;
export type UpdateDefectMgmtMutationFn = ApolloReactCommon.MutationFunction<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>;

/**
 * __useUpdateDefectMgmtMutation__
 *
 * To run a mutation, you first call `useUpdateDefectMgmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefectMgmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefectMgmtMutation, { data, loading, error }] = useUpdateDefectMgmtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefectMgmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>(UpdateDefectMgmtDocument, baseOptions);
      }
export type UpdateDefectMgmtMutationHookResult = ReturnType<typeof useUpdateDefectMgmtMutation>;
export type UpdateDefectMgmtMutationResult = ApolloReactCommon.MutationResult<UpdateDefectMgmtMutation>;
export type UpdateDefectMgmtMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>;
export const CreateActivityLogDocument = gql`
    mutation createActivityLog($input: defectLogInput!, $defectID: String!, $file: [Upload!]) {
  createActivityLog(input: $input, defectID: $defectID, file: $file)
}
    `;
export type CreateActivityLogMutationFn = ApolloReactCommon.MutationFunction<CreateActivityLogMutation, CreateActivityLogMutationVariables>;

/**
 * __useCreateActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityLogMutation, { data, loading, error }] = useCreateActivityLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateActivityLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActivityLogMutation, CreateActivityLogMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActivityLogMutation, CreateActivityLogMutationVariables>(CreateActivityLogDocument, baseOptions);
      }
export type CreateActivityLogMutationHookResult = ReturnType<typeof useCreateActivityLogMutation>;
export type CreateActivityLogMutationResult = ApolloReactCommon.MutationResult<CreateActivityLogMutation>;
export type CreateActivityLogMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActivityLogMutation, CreateActivityLogMutationVariables>;
export const AssignDefectDocument = gql`
    mutation assignDefect($input: DefectAssignmentInput!, $defectID: String!) {
  assignDefect(input: $input, ID: $defectID)
}
    `;
export type AssignDefectMutationFn = ApolloReactCommon.MutationFunction<AssignDefectMutation, AssignDefectMutationVariables>;

/**
 * __useAssignDefectMutation__
 *
 * To run a mutation, you first call `useAssignDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDefectMutation, { data, loading, error }] = useAssignDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *   },
 * });
 */
export function useAssignDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignDefectMutation, AssignDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignDefectMutation, AssignDefectMutationVariables>(AssignDefectDocument, baseOptions);
      }
export type AssignDefectMutationHookResult = ReturnType<typeof useAssignDefectMutation>;
export type AssignDefectMutationResult = ApolloReactCommon.MutationResult<AssignDefectMutation>;
export type AssignDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignDefectMutation, AssignDefectMutationVariables>;
export const ReOpenDefectDocument = gql`
    mutation reOpenDefect($input: ReOpenDefectInput!, $defectID: String!) {
  reOpenDefect(input: $input, ID: $defectID)
}
    `;
export type ReOpenDefectMutationFn = ApolloReactCommon.MutationFunction<ReOpenDefectMutation, ReOpenDefectMutationVariables>;

/**
 * __useReOpenDefectMutation__
 *
 * To run a mutation, you first call `useReOpenDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOpenDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOpenDefectMutation, { data, loading, error }] = useReOpenDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *   },
 * });
 */
export function useReOpenDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReOpenDefectMutation, ReOpenDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<ReOpenDefectMutation, ReOpenDefectMutationVariables>(ReOpenDefectDocument, baseOptions);
      }
export type ReOpenDefectMutationHookResult = ReturnType<typeof useReOpenDefectMutation>;
export type ReOpenDefectMutationResult = ApolloReactCommon.MutationResult<ReOpenDefectMutation>;
export type ReOpenDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<ReOpenDefectMutation, ReOpenDefectMutationVariables>;
export const GetDefectMgmtDocument = gql`
    query getDefectMgmt($ID: String, $contractID: String, $defectStatus: DefectStatus, $orderByDesc: String, $orderByAsc: String) {
  getDefectMgmt(ID: $ID, contractID: $contractID, defectStatus: $defectStatus, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    remarks
    title
    defectStatus
    contractID
    subcontractorID
    expectedDate
    createdTs
    createdBy
    docNo
    defectLog
    subcontractor {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetDefectMgmtQuery__
 *
 * To run a query within a React component, call `useGetDefectMgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectMgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectMgmtQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      defectStatus: // value for 'defectStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDefectMgmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>(GetDefectMgmtDocument, baseOptions);
      }
export function useGetDefectMgmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>(GetDefectMgmtDocument, baseOptions);
        }
export type GetDefectMgmtQueryHookResult = ReturnType<typeof useGetDefectMgmtQuery>;
export type GetDefectMgmtLazyQueryHookResult = ReturnType<typeof useGetDefectMgmtLazyQuery>;
export type GetDefectMgmtQueryResult = ApolloReactCommon.QueryResult<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>;
export const GetContractDefectListingDocument = gql`
    query getContractDefectListing($ID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    contractStatus
    contractSum
    customerDetail {
      ID
      name
    }
    contractDate
    contractDefectCounts
  }
}
    `;

/**
 * __useGetContractDefectListingQuery__
 *
 * To run a query within a React component, call `useGetContractDefectListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDefectListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDefectListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractDefectListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDefectListingQuery, GetContractDefectListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDefectListingQuery, GetContractDefectListingQueryVariables>(GetContractDefectListingDocument, baseOptions);
      }
export function useGetContractDefectListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDefectListingQuery, GetContractDefectListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDefectListingQuery, GetContractDefectListingQueryVariables>(GetContractDefectListingDocument, baseOptions);
        }
export type GetContractDefectListingQueryHookResult = ReturnType<typeof useGetContractDefectListingQuery>;
export type GetContractDefectListingLazyQueryHookResult = ReturnType<typeof useGetContractDefectListingLazyQuery>;
export type GetContractDefectListingQueryResult = ApolloReactCommon.QueryResult<GetContractDefectListingQuery, GetContractDefectListingQueryVariables>;
export const GetDefectListingDataDocument = gql`
    query getDefectListingData($contractID: String, $orderByAsc: String, $orderByDesc: String, $costClass: CostClass, $commonStatus: CommonStatus) {
  getContract(ID: $contractID, orderByAsc: $orderByAsc) {
    ID
    title
  }
  getDefectMgmt(contractID: $contractID, orderByDesc: $orderByDesc) {
    ID
    remarks
    title
    defectStatus
    contractID
    subcontractorID
    expectedDate
    createdTs
    createdBy
    docNo
    defectLog
    subcontractor {
      name
      ID
    }
  }
  getCostCategory(costClass: $costClass, commonStatus: $commonStatus) {
    ID
    name
    commonStatus
    subcontractor {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetDefectListingDataQuery__
 *
 * To run a query within a React component, call `useGetDefectListingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectListingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectListingDataQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      costClass: // value for 'costClass'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetDefectListingDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectListingDataQuery, GetDefectListingDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectListingDataQuery, GetDefectListingDataQueryVariables>(GetDefectListingDataDocument, baseOptions);
      }
export function useGetDefectListingDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectListingDataQuery, GetDefectListingDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectListingDataQuery, GetDefectListingDataQueryVariables>(GetDefectListingDataDocument, baseOptions);
        }
export type GetDefectListingDataQueryHookResult = ReturnType<typeof useGetDefectListingDataQuery>;
export type GetDefectListingDataLazyQueryHookResult = ReturnType<typeof useGetDefectListingDataLazyQuery>;
export type GetDefectListingDataQueryResult = ApolloReactCommon.QueryResult<GetDefectListingDataQuery, GetDefectListingDataQueryVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($documentFolderID: String, $refID: String, $refTable: String) {
  DocumentListing(documentFolderID: $documentFolderID, refID: $refID, refTable: $refTable) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      documentFolderID: // value for 'documentFolderID'
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const CreateDocumentFolderDocument = gql`
    mutation createDocumentFolder($input: DocumentFolderInput!) {
  createDocumentFolder(input: $input)
}
    `;
export type CreateDocumentFolderMutationFn = ApolloReactCommon.MutationFunction<CreateDocumentFolderMutation, CreateDocumentFolderMutationVariables>;

/**
 * __useCreateDocumentFolderMutation__
 *
 * To run a mutation, you first call `useCreateDocumentFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentFolderMutation, { data, loading, error }] = useCreateDocumentFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDocumentFolderMutation, CreateDocumentFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDocumentFolderMutation, CreateDocumentFolderMutationVariables>(CreateDocumentFolderDocument, baseOptions);
      }
export type CreateDocumentFolderMutationHookResult = ReturnType<typeof useCreateDocumentFolderMutation>;
export type CreateDocumentFolderMutationResult = ApolloReactCommon.MutationResult<CreateDocumentFolderMutation>;
export type CreateDocumentFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDocumentFolderMutation, CreateDocumentFolderMutationVariables>;
export const UpdateDocumentFolderDocument = gql`
    mutation updateDocumentFolder($input: DocumentFolderInput!) {
  updateDocumentFolder(input: $input)
}
    `;
export type UpdateDocumentFolderMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentFolderMutation, UpdateDocumentFolderMutationVariables>;

/**
 * __useUpdateDocumentFolderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentFolderMutation, { data, loading, error }] = useUpdateDocumentFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentFolderMutation, UpdateDocumentFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentFolderMutation, UpdateDocumentFolderMutationVariables>(UpdateDocumentFolderDocument, baseOptions);
      }
export type UpdateDocumentFolderMutationHookResult = ReturnType<typeof useUpdateDocumentFolderMutation>;
export type UpdateDocumentFolderMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentFolderMutation>;
export type UpdateDocumentFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentFolderMutation, UpdateDocumentFolderMutationVariables>;
export const DeleteDocumentFolderDocument = gql`
    mutation deleteDocumentFolder($ID: String!) {
  deleteDocumentFolder(ID: $ID)
}
    `;
export type DeleteDocumentFolderMutationFn = ApolloReactCommon.MutationFunction<DeleteDocumentFolderMutation, DeleteDocumentFolderMutationVariables>;

/**
 * __useDeleteDocumentFolderMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentFolderMutation, { data, loading, error }] = useDeleteDocumentFolderMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDocumentFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDocumentFolderMutation, DeleteDocumentFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDocumentFolderMutation, DeleteDocumentFolderMutationVariables>(DeleteDocumentFolderDocument, baseOptions);
      }
export type DeleteDocumentFolderMutationHookResult = ReturnType<typeof useDeleteDocumentFolderMutation>;
export type DeleteDocumentFolderMutationResult = ApolloReactCommon.MutationResult<DeleteDocumentFolderMutation>;
export type DeleteDocumentFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDocumentFolderMutation, DeleteDocumentFolderMutationVariables>;
export const GetDocumentFolderDocument = gql`
    query getDocumentFolder($ID: String, $siteID: String) {
  getDocumentFolder(ID: $ID, siteID: $siteID) {
    ID
    name
    siteID
    description
    documents {
      ID
    }
  }
}
    `;

/**
 * __useGetDocumentFolderQuery__
 *
 * To run a query within a React component, call `useGetDocumentFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentFolderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetDocumentFolderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentFolderQuery, GetDocumentFolderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentFolderQuery, GetDocumentFolderQueryVariables>(GetDocumentFolderDocument, baseOptions);
      }
export function useGetDocumentFolderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentFolderQuery, GetDocumentFolderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentFolderQuery, GetDocumentFolderQueryVariables>(GetDocumentFolderDocument, baseOptions);
        }
export type GetDocumentFolderQueryHookResult = ReturnType<typeof useGetDocumentFolderQuery>;
export type GetDocumentFolderLazyQueryHookResult = ReturnType<typeof useGetDocumentFolderLazyQuery>;
export type GetDocumentFolderQueryResult = ApolloReactCommon.QueryResult<GetDocumentFolderQuery, GetDocumentFolderQueryVariables>;
export const AddDigitalDocumentsDocument = gql`
    mutation addDigitalDocuments($input: DigitalDocumentInput!) {
  addDigitalDocuments(input: $input)
}
    `;
export type AddDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;

/**
 * __useAddDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useAddDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDigitalDocumentsMutation, { data, loading, error }] = useAddDigitalDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>(AddDigitalDocumentsDocument, baseOptions);
      }
export type AddDigitalDocumentsMutationHookResult = ReturnType<typeof useAddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<AddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;
export const DeleteDigitalDocumentsDocument = gql`
    mutation deleteDigitalDocuments($ID: String!) {
  deleteDigitalDocuments(ID: $ID)
}
    `;
export type DeleteDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;

/**
 * __useDeleteDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalDocumentsMutation, { data, loading, error }] = useDeleteDigitalDocumentsMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>(DeleteDigitalDocumentsDocument, baseOptions);
      }
export type DeleteDigitalDocumentsMutationHookResult = ReturnType<typeof useDeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;
export const GetDigitalDocsSummaryDocument = gql`
    query getDigitalDocsSummary {
  totalSiteCount
}
    `;

/**
 * __useGetDigitalDocsSummaryQuery__
 *
 * To run a query within a React component, call `useGetDigitalDocsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalDocsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalDocsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitalDocsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalDocsSummaryQuery, GetDigitalDocsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalDocsSummaryQuery, GetDigitalDocsSummaryQueryVariables>(GetDigitalDocsSummaryDocument, baseOptions);
      }
export function useGetDigitalDocsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalDocsSummaryQuery, GetDigitalDocsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalDocsSummaryQuery, GetDigitalDocsSummaryQueryVariables>(GetDigitalDocsSummaryDocument, baseOptions);
        }
export type GetDigitalDocsSummaryQueryHookResult = ReturnType<typeof useGetDigitalDocsSummaryQuery>;
export type GetDigitalDocsSummaryLazyQueryHookResult = ReturnType<typeof useGetDigitalDocsSummaryLazyQuery>;
export type GetDigitalDocsSummaryQueryResult = ApolloReactCommon.QueryResult<GetDigitalDocsSummaryQuery, GetDigitalDocsSummaryQueryVariables>;
export const SiteHomeSummaryDocument = gql`
    query siteHomeSummary {
  totalStockIn
  totalStockOut
  totalDefect
  totalSiteSurveillance
  todayTotalAttendance
  uomDate
  loggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    userName
    department
    superUser
    jobTitle
  }
  getRolePermission(siteManagement: true)
  getSitePermission
}
    `;

/**
 * __useSiteHomeSummaryQuery__
 *
 * To run a query within a React component, call `useSiteHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteHomeSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteHomeSummaryQuery, SiteHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteHomeSummaryQuery, SiteHomeSummaryQueryVariables>(SiteHomeSummaryDocument, baseOptions);
      }
export function useSiteHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteHomeSummaryQuery, SiteHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteHomeSummaryQuery, SiteHomeSummaryQueryVariables>(SiteHomeSummaryDocument, baseOptions);
        }
export type SiteHomeSummaryQueryHookResult = ReturnType<typeof useSiteHomeSummaryQuery>;
export type SiteHomeSummaryLazyQueryHookResult = ReturnType<typeof useSiteHomeSummaryLazyQuery>;
export type SiteHomeSummaryQueryResult = ApolloReactCommon.QueryResult<SiteHomeSummaryQuery, SiteHomeSummaryQueryVariables>;
export const GetSiteHomeSummaryDocument = gql`
    query getSiteHomeSummary($siteID: String) {
  totalStockIn(siteID: $siteID)
  totalStockOut(siteID: $siteID)
  totalDefect(siteID: $siteID)
  totalSiteSurveillance(siteID: $siteID)
  todayTotalAttendance(siteID: $siteID)
  totalDocFolder(siteID: $siteID)
  totalDigitalDoc(refID: $siteID, refTable: "c_site")
  getRolePermission(siteManagement: true)
  getSitePermission
}
    `;

/**
 * __useGetSiteHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteHomeSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteHomeSummaryQuery, GetSiteHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteHomeSummaryQuery, GetSiteHomeSummaryQueryVariables>(GetSiteHomeSummaryDocument, baseOptions);
      }
export function useGetSiteHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteHomeSummaryQuery, GetSiteHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteHomeSummaryQuery, GetSiteHomeSummaryQueryVariables>(GetSiteHomeSummaryDocument, baseOptions);
        }
export type GetSiteHomeSummaryQueryHookResult = ReturnType<typeof useGetSiteHomeSummaryQuery>;
export type GetSiteHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteHomeSummaryLazyQuery>;
export type GetSiteHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteHomeSummaryQuery, GetSiteHomeSummaryQueryVariables>;
export const VerifySiteDocument = gql`
    query verifySite($accountID: String, $siteID: String) {
  verifySite(accountID: $accountID, siteID: $siteID)
}
    `;

/**
 * __useVerifySiteQuery__
 *
 * To run a query within a React component, call `useVerifySiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifySiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifySiteQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useVerifySiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifySiteQuery, VerifySiteQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifySiteQuery, VerifySiteQueryVariables>(VerifySiteDocument, baseOptions);
      }
export function useVerifySiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifySiteQuery, VerifySiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifySiteQuery, VerifySiteQueryVariables>(VerifySiteDocument, baseOptions);
        }
export type VerifySiteQueryHookResult = ReturnType<typeof useVerifySiteQuery>;
export type VerifySiteLazyQueryHookResult = ReturnType<typeof useVerifySiteLazyQuery>;
export type VerifySiteQueryResult = ApolloReactCommon.QueryResult<VerifySiteQuery, VerifySiteQueryVariables>;
export const GoodReceiveNoteRpaDocument = gql`
    mutation goodReceiveNoteRPA($siteID: String, $files: [Upload!]!) {
  goodReceiveNoteRPA(siteID: $siteID, files: $files) {
    invoiceDate
    invoiceID
    invoiceTotal
    subTotal
    vendorName
    vendorAddress
    customerAddress
    customerAddressRecipient
    remittanceAddressRecipient
    purchaseOrder
    invoiceItems {
      costItemID
      name
      description
      productCode
      uom {
        uomID
        code
      }
      unitPrice
      quantity
      amount
    }
  }
}
    `;
export type GoodReceiveNoteRpaMutationFn = ApolloReactCommon.MutationFunction<GoodReceiveNoteRpaMutation, GoodReceiveNoteRpaMutationVariables>;

/**
 * __useGoodReceiveNoteRpaMutation__
 *
 * To run a mutation, you first call `useGoodReceiveNoteRpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoodReceiveNoteRpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goodReceiveNoteRpaMutation, { data, loading, error }] = useGoodReceiveNoteRpaMutation({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useGoodReceiveNoteRpaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GoodReceiveNoteRpaMutation, GoodReceiveNoteRpaMutationVariables>) {
        return ApolloReactHooks.useMutation<GoodReceiveNoteRpaMutation, GoodReceiveNoteRpaMutationVariables>(GoodReceiveNoteRpaDocument, baseOptions);
      }
export type GoodReceiveNoteRpaMutationHookResult = ReturnType<typeof useGoodReceiveNoteRpaMutation>;
export type GoodReceiveNoteRpaMutationResult = ApolloReactCommon.MutationResult<GoodReceiveNoteRpaMutation>;
export type GoodReceiveNoteRpaMutationOptions = ApolloReactCommon.BaseMutationOptions<GoodReceiveNoteRpaMutation, GoodReceiveNoteRpaMutationVariables>;
export const GetSiteAttendanceSummaryDocument = gql`
    query getSiteAttendanceSummary($siteID: String) {
  totalSiteCount
  workerCount(siteID: $siteID)
  workerAttendanceCount(siteID: $siteID)
  getSite(ID: $siteID) {
    ID
    name
    address
  }
}
    `;

/**
 * __useGetSiteAttendanceSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteAttendanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAttendanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAttendanceSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteAttendanceSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteAttendanceSummaryQuery, GetSiteAttendanceSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteAttendanceSummaryQuery, GetSiteAttendanceSummaryQueryVariables>(GetSiteAttendanceSummaryDocument, baseOptions);
      }
export function useGetSiteAttendanceSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteAttendanceSummaryQuery, GetSiteAttendanceSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteAttendanceSummaryQuery, GetSiteAttendanceSummaryQueryVariables>(GetSiteAttendanceSummaryDocument, baseOptions);
        }
export type GetSiteAttendanceSummaryQueryHookResult = ReturnType<typeof useGetSiteAttendanceSummaryQuery>;
export type GetSiteAttendanceSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteAttendanceSummaryLazyQuery>;
export type GetSiteAttendanceSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteAttendanceSummaryQuery, GetSiteAttendanceSummaryQueryVariables>;
export const UploadPdfDocumentDocument = gql`
    mutation uploadPDFDocument($input: LedgerPDFInput!) {
  uploadPDFDocument(input: $input) {
    ID
    refID
    fileName
    fileURL
    mediaType
    description
  }
}
    `;
export type UploadPdfDocumentMutationFn = ApolloReactCommon.MutationFunction<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;

/**
 * __useUploadPdfDocumentMutation__
 *
 * To run a mutation, you first call `useUploadPdfDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfDocumentMutation, { data, loading, error }] = useUploadPdfDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>(UploadPdfDocumentDocument, baseOptions);
      }
export type UploadPdfDocumentMutationHookResult = ReturnType<typeof useUploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationResult = ApolloReactCommon.MutationResult<UploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;
export const UploadPdfpoDocument = gql`
    mutation uploadPDFPO($input: LedgerPDFInput!) {
  uploadPDFPO(input: $input)
}
    `;
export type UploadPdfpoMutationFn = ApolloReactCommon.MutationFunction<UploadPdfpoMutation, UploadPdfpoMutationVariables>;

/**
 * __useUploadPdfpoMutation__
 *
 * To run a mutation, you first call `useUploadPdfpoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfpoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfpoMutation, { data, loading, error }] = useUploadPdfpoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfpoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfpoMutation, UploadPdfpoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfpoMutation, UploadPdfpoMutationVariables>(UploadPdfpoDocument, baseOptions);
      }
export type UploadPdfpoMutationHookResult = ReturnType<typeof useUploadPdfpoMutation>;
export type UploadPdfpoMutationResult = ApolloReactCommon.MutationResult<UploadPdfpoMutation>;
export type UploadPdfpoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfpoMutation, UploadPdfpoMutationVariables>;
export const GetGrnDocument = gql`
    query getGRN($ID: String, $orderByDesc: String, $siteID: String) {
  getGRN(ID: $ID, orderByDesc: $orderByDesc, siteID: $siteID) {
    ID
    siteID
    site {
      ID
      contractID
      name
    }
    docNo
    docDate
    supplier
    docRef
    grnStatus
    remark
    cancelRejectDate
    grnItem {
      ID
      uomID
      quantity
      siteMaterialID
      siteMaterial {
        name
        stockCode
      }
      costItemID
      costItem {
        ID
        name
        code
        uomID
      }
      description
      uom {
        code
      }
    }
  }
}
    `;

/**
 * __useGetGrnQuery__
 *
 * To run a query within a React component, call `useGetGrnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetGrnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrnQuery, GetGrnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrnQuery, GetGrnQueryVariables>(GetGrnDocument, baseOptions);
      }
export function useGetGrnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrnQuery, GetGrnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrnQuery, GetGrnQueryVariables>(GetGrnDocument, baseOptions);
        }
export type GetGrnQueryHookResult = ReturnType<typeof useGetGrnQuery>;
export type GetGrnLazyQueryHookResult = ReturnType<typeof useGetGrnLazyQuery>;
export type GetGrnQueryResult = ApolloReactCommon.QueryResult<GetGrnQuery, GetGrnQueryVariables>;
export const GetSiteSwitchDocument = gql`
    query getSiteSwitch($ID: String) {
  getSite(ID: $ID) {
    ID
    name
    contractID
    contract {
      title
    }
  }
}
    `;

/**
 * __useGetSiteSwitchQuery__
 *
 * To run a query within a React component, call `useGetSiteSwitchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSwitchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSwitchQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSiteSwitchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSwitchQuery, GetSiteSwitchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSwitchQuery, GetSiteSwitchQueryVariables>(GetSiteSwitchDocument, baseOptions);
      }
export function useGetSiteSwitchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSwitchQuery, GetSiteSwitchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSwitchQuery, GetSiteSwitchQueryVariables>(GetSiteSwitchDocument, baseOptions);
        }
export type GetSiteSwitchQueryHookResult = ReturnType<typeof useGetSiteSwitchQuery>;
export type GetSiteSwitchLazyQueryHookResult = ReturnType<typeof useGetSiteSwitchLazyQuery>;
export type GetSiteSwitchQueryResult = ApolloReactCommon.QueryResult<GetSiteSwitchQuery, GetSiteSwitchQueryVariables>;
export const CreateGrnDocument = gql`
    mutation createGRN($grnInput: GRNInput!, $grnItemInput: [GRNItemInput!]!) {
  createGRN(grnInput: $grnInput, grnItemInput: $grnItemInput)
}
    `;
export type CreateGrnMutationFn = ApolloReactCommon.MutationFunction<CreateGrnMutation, CreateGrnMutationVariables>;

/**
 * __useCreateGrnMutation__
 *
 * To run a mutation, you first call `useCreateGrnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrnMutation, { data, loading, error }] = useCreateGrnMutation({
 *   variables: {
 *      grnInput: // value for 'grnInput'
 *      grnItemInput: // value for 'grnItemInput'
 *   },
 * });
 */
export function useCreateGrnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGrnMutation, CreateGrnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGrnMutation, CreateGrnMutationVariables>(CreateGrnDocument, baseOptions);
      }
export type CreateGrnMutationHookResult = ReturnType<typeof useCreateGrnMutation>;
export type CreateGrnMutationResult = ApolloReactCommon.MutationResult<CreateGrnMutation>;
export type CreateGrnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGrnMutation, CreateGrnMutationVariables>;
export const UpdateGrnDocument = gql`
    mutation updateGRN($grnInput: GRNInput!, $grnItemInput: [GRNItemInput!]!) {
  updateGRN(grnInput: $grnInput, grnItemInput: $grnItemInput)
}
    `;
export type UpdateGrnMutationFn = ApolloReactCommon.MutationFunction<UpdateGrnMutation, UpdateGrnMutationVariables>;

/**
 * __useUpdateGrnMutation__
 *
 * To run a mutation, you first call `useUpdateGrnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrnMutation, { data, loading, error }] = useUpdateGrnMutation({
 *   variables: {
 *      grnInput: // value for 'grnInput'
 *      grnItemInput: // value for 'grnItemInput'
 *   },
 * });
 */
export function useUpdateGrnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGrnMutation, UpdateGrnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGrnMutation, UpdateGrnMutationVariables>(UpdateGrnDocument, baseOptions);
      }
export type UpdateGrnMutationHookResult = ReturnType<typeof useUpdateGrnMutation>;
export type UpdateGrnMutationResult = ApolloReactCommon.MutationResult<UpdateGrnMutation>;
export type UpdateGrnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGrnMutation, UpdateGrnMutationVariables>;
export const DeleteGrnDocument = gql`
    mutation deleteGRN($ID: String!) {
  deleteGRN(ID: $ID)
}
    `;
export type DeleteGrnMutationFn = ApolloReactCommon.MutationFunction<DeleteGrnMutation, DeleteGrnMutationVariables>;

/**
 * __useDeleteGrnMutation__
 *
 * To run a mutation, you first call `useDeleteGrnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrnMutation, { data, loading, error }] = useDeleteGrnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteGrnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGrnMutation, DeleteGrnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGrnMutation, DeleteGrnMutationVariables>(DeleteGrnDocument, baseOptions);
      }
export type DeleteGrnMutationHookResult = ReturnType<typeof useDeleteGrnMutation>;
export type DeleteGrnMutationResult = ApolloReactCommon.MutationResult<DeleteGrnMutation>;
export type DeleteGrnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrnMutation, DeleteGrnMutationVariables>;
export const GetGrnItemDocument = gql`
    query getGRNItem($ID: String, $orderByDesc: String) {
  getGRNItem(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    siteMaterial {
      uom {
        name
      }
    }
  }
}
    `;

/**
 * __useGetGrnItemQuery__
 *
 * To run a query within a React component, call `useGetGrnItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrnItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrnItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetGrnItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrnItemQuery, GetGrnItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrnItemQuery, GetGrnItemQueryVariables>(GetGrnItemDocument, baseOptions);
      }
export function useGetGrnItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrnItemQuery, GetGrnItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrnItemQuery, GetGrnItemQueryVariables>(GetGrnItemDocument, baseOptions);
        }
export type GetGrnItemQueryHookResult = ReturnType<typeof useGetGrnItemQuery>;
export type GetGrnItemLazyQueryHookResult = ReturnType<typeof useGetGrnItemLazyQuery>;
export type GetGrnItemQueryResult = ApolloReactCommon.QueryResult<GetGrnItemQuery, GetGrnItemQueryVariables>;
export const CreateGrnItemDocument = gql`
    mutation createGRNItem($input: GRNItemInput!) {
  createGRNItem(input: $input) {
    ID
  }
}
    `;
export type CreateGrnItemMutationFn = ApolloReactCommon.MutationFunction<CreateGrnItemMutation, CreateGrnItemMutationVariables>;

/**
 * __useCreateGrnItemMutation__
 *
 * To run a mutation, you first call `useCreateGrnItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrnItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrnItemMutation, { data, loading, error }] = useCreateGrnItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGrnItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGrnItemMutation, CreateGrnItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGrnItemMutation, CreateGrnItemMutationVariables>(CreateGrnItemDocument, baseOptions);
      }
export type CreateGrnItemMutationHookResult = ReturnType<typeof useCreateGrnItemMutation>;
export type CreateGrnItemMutationResult = ApolloReactCommon.MutationResult<CreateGrnItemMutation>;
export type CreateGrnItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGrnItemMutation, CreateGrnItemMutationVariables>;
export const GetSiteMaterialAndUomDocument = gql`
    query getSiteMaterialAndUom($orderByAsc: String, $commonStatus: CommonStatus) {
  getSiteMaterial(orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    ID
    name
    stockCode
    uomID
  }
  getUOM(orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    ID
    name
    code
  }
}
    `;

/**
 * __useGetSiteMaterialAndUomQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialAndUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialAndUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialAndUomQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSiteMaterialAndUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialAndUomQuery, GetSiteMaterialAndUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialAndUomQuery, GetSiteMaterialAndUomQueryVariables>(GetSiteMaterialAndUomDocument, baseOptions);
      }
export function useGetSiteMaterialAndUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialAndUomQuery, GetSiteMaterialAndUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialAndUomQuery, GetSiteMaterialAndUomQueryVariables>(GetSiteMaterialAndUomDocument, baseOptions);
        }
export type GetSiteMaterialAndUomQueryHookResult = ReturnType<typeof useGetSiteMaterialAndUomQuery>;
export type GetSiteMaterialAndUomLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialAndUomLazyQuery>;
export type GetSiteMaterialAndUomQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialAndUomQuery, GetSiteMaterialAndUomQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUomExchangeList($uomID: String!) {
  getUOMExchangeList(uomID: $uomID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      uomID: // value for 'uomID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const GetGrnListingDocument = gql`
    query getGRNListing($ID: String, $orderByDesc: String, $siteID: String, $skip: Float, $take: Float, $grnStatus: String, $searchValue: String) {
  getGRNCount(ID: $ID, siteID: $siteID, grnStatus: $grnStatus, searchValue: $searchValue)
  getGRN(ID: $ID, orderByDesc: $orderByDesc, siteID: $siteID, skip: $skip, take: $take, grnStatus: $grnStatus, searchValue: $searchValue) {
    ID
    siteID
    site {
      ID
      contractID
      name
    }
    docNo
    docDate
    supplier
    docRef
    grnStatus
    remark
    cancelRejectDate
    grnItem {
      ID
      uomID
      quantity
      siteMaterialID
      siteMaterial {
        name
        stockCode
      }
      costItemID
      costItem {
        ID
        name
        code
        uomID
      }
      description
      uom {
        code
      }
    }
  }
}
    `;

/**
 * __useGetGrnListingQuery__
 *
 * To run a query within a React component, call `useGetGrnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrnListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      grnStatus: // value for 'grnStatus'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetGrnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrnListingQuery, GetGrnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrnListingQuery, GetGrnListingQueryVariables>(GetGrnListingDocument, baseOptions);
      }
export function useGetGrnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrnListingQuery, GetGrnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrnListingQuery, GetGrnListingQueryVariables>(GetGrnListingDocument, baseOptions);
        }
export type GetGrnListingQueryHookResult = ReturnType<typeof useGetGrnListingQuery>;
export type GetGrnListingLazyQueryHookResult = ReturnType<typeof useGetGrnListingLazyQuery>;
export type GetGrnListingQueryResult = ApolloReactCommon.QueryResult<GetGrnListingQuery, GetGrnListingQueryVariables>;
export const GetSiteInventorySummaryDocument = gql`
    query getSiteInventorySummary($siteID: String) {
  totalSiteCount
  stockMovementCount(siteID: $siteID)
  lastStockMovementDates(siteID: $siteID)
  totalStockIn(siteID: $siteID)
  totalStockOut(siteID: $siteID)
  lastGRNDate(siteID: $siteID)
  loggedInUserProfile {
    ID
    accountID
  }
}
    `;

/**
 * __useGetSiteInventorySummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteInventorySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteInventorySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteInventorySummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteInventorySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteInventorySummaryQuery, GetSiteInventorySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteInventorySummaryQuery, GetSiteInventorySummaryQueryVariables>(GetSiteInventorySummaryDocument, baseOptions);
      }
export function useGetSiteInventorySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteInventorySummaryQuery, GetSiteInventorySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteInventorySummaryQuery, GetSiteInventorySummaryQueryVariables>(GetSiteInventorySummaryDocument, baseOptions);
        }
export type GetSiteInventorySummaryQueryHookResult = ReturnType<typeof useGetSiteInventorySummaryQuery>;
export type GetSiteInventorySummaryLazyQueryHookResult = ReturnType<typeof useGetSiteInventorySummaryLazyQuery>;
export type GetSiteInventorySummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteInventorySummaryQuery, GetSiteInventorySummaryQueryVariables>;
export const GetRoleForSiteDocument = gql`
    query getRoleForSite($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $name: String, $softwareCode: SoftwareCode) {
  getRole(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, name: $name, softwareCode: $softwareCode) {
    ID
    accountID
    name
    softwareCode
  }
}
    `;

/**
 * __useGetRoleForSiteQuery__
 *
 * To run a query within a React component, call `useGetRoleForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleForSiteQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetRoleForSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleForSiteQuery, GetRoleForSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleForSiteQuery, GetRoleForSiteQueryVariables>(GetRoleForSiteDocument, baseOptions);
      }
export function useGetRoleForSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleForSiteQuery, GetRoleForSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleForSiteQuery, GetRoleForSiteQueryVariables>(GetRoleForSiteDocument, baseOptions);
        }
export type GetRoleForSiteQueryHookResult = ReturnType<typeof useGetRoleForSiteQuery>;
export type GetRoleForSiteLazyQueryHookResult = ReturnType<typeof useGetRoleForSiteLazyQuery>;
export type GetRoleForSiteQueryResult = ApolloReactCommon.QueryResult<GetRoleForSiteQuery, GetRoleForSiteQueryVariables>;
export const GetAllCostStockCodeDocument = gql`
    query getAllCostStockCode($siteIDs: [String!]) {
  getAllCostStockCode(siteIDs: $siteIDs)
}
    `;

/**
 * __useGetAllCostStockCodeQuery__
 *
 * To run a query within a React component, call `useGetAllCostStockCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCostStockCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCostStockCodeQuery({
 *   variables: {
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetAllCostStockCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCostStockCodeQuery, GetAllCostStockCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCostStockCodeQuery, GetAllCostStockCodeQueryVariables>(GetAllCostStockCodeDocument, baseOptions);
      }
export function useGetAllCostStockCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCostStockCodeQuery, GetAllCostStockCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCostStockCodeQuery, GetAllCostStockCodeQueryVariables>(GetAllCostStockCodeDocument, baseOptions);
        }
export type GetAllCostStockCodeQueryHookResult = ReturnType<typeof useGetAllCostStockCodeQuery>;
export type GetAllCostStockCodeLazyQueryHookResult = ReturnType<typeof useGetAllCostStockCodeLazyQuery>;
export type GetAllCostStockCodeQueryResult = ApolloReactCommon.QueryResult<GetAllCostStockCodeQuery, GetAllCostStockCodeQueryVariables>;
export const GetAllSiteStockCodeDocument = gql`
    query getAllSiteStockCode($siteIDs: [String!]) {
  getAllSiteStockCode(siteIDs: $siteIDs)
}
    `;

/**
 * __useGetAllSiteStockCodeQuery__
 *
 * To run a query within a React component, call `useGetAllSiteStockCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSiteStockCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSiteStockCodeQuery({
 *   variables: {
 *      siteIDs: // value for 'siteIDs'
 *   },
 * });
 */
export function useGetAllSiteStockCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllSiteStockCodeQuery, GetAllSiteStockCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllSiteStockCodeQuery, GetAllSiteStockCodeQueryVariables>(GetAllSiteStockCodeDocument, baseOptions);
      }
export function useGetAllSiteStockCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllSiteStockCodeQuery, GetAllSiteStockCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllSiteStockCodeQuery, GetAllSiteStockCodeQueryVariables>(GetAllSiteStockCodeDocument, baseOptions);
        }
export type GetAllSiteStockCodeQueryHookResult = ReturnType<typeof useGetAllSiteStockCodeQuery>;
export type GetAllSiteStockCodeLazyQueryHookResult = ReturnType<typeof useGetAllSiteStockCodeLazyQuery>;
export type GetAllSiteStockCodeQueryResult = ApolloReactCommon.QueryResult<GetAllSiteStockCodeQuery, GetAllSiteStockCodeQueryVariables>;
export const CreateApprovalPolicyDocument = gql`
    mutation createApprovalPolicy($input: ApprovalPolicyInput!) {
  createApprovalPolicy(input: $input) {
    ID
  }
}
    `;
export type CreateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;

/**
 * __useCreateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyMutation, { data, loading, error }] = useCreateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>(CreateApprovalPolicyDocument, baseOptions);
      }
export type CreateApprovalPolicyMutationHookResult = ReturnType<typeof useCreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;
export const UpdateApprovalPolicyDocument = gql`
    mutation updateApprovalPolicy($input: ApprovalPolicyInput!) {
  updateApprovalPolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;

/**
 * __useUpdateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyMutation, { data, loading, error }] = useUpdateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>(UpdateApprovalPolicyDocument, baseOptions);
      }
export type UpdateApprovalPolicyMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;
export const GetApprovalPolicyDocument = gql`
    query getApprovalPolicy($category: String!) {
  getApprovalPolicy(category: $category) {
    ID
    approvalList
    category
    createdTs
    modTs
    lowerLimit
    upperLimit
    approvalPolicyAssignment {
      approvalPolicyID
      ID
      contractID
      approvalList
      category
    }
  }
}
    `;

/**
 * __useGetApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
      }
export function useGetApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
        }
export type GetApprovalPolicyQueryHookResult = ReturnType<typeof useGetApprovalPolicyQuery>;
export type GetApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyLazyQuery>;
export type GetApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>;
export const GetApprovalLogDocument = gql`
    query getApprovalLog($refID: String!) {
  getApprovalLog(refID: $refID)
}
    `;

/**
 * __useGetApprovalLogQuery__
 *
 * To run a query within a React component, call `useGetApprovalLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalLogQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useGetApprovalLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
      }
export function useGetApprovalLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
        }
export type GetApprovalLogQueryHookResult = ReturnType<typeof useGetApprovalLogQuery>;
export type GetApprovalLogLazyQueryHookResult = ReturnType<typeof useGetApprovalLogLazyQuery>;
export type GetApprovalLogQueryResult = ApolloReactCommon.QueryResult<GetApprovalLogQuery, GetApprovalLogQueryVariables>;
export const ApprovableLedgerWfCheckingDocument = gql`
    query approvableLedgerWFChecking($input: CheckingInput!) {
  approvableLedgerWFChecking(input: $input)
}
    `;

/**
 * __useApprovableLedgerWfCheckingQuery__
 *
 * To run a query within a React component, call `useApprovableLedgerWfCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovableLedgerWfCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovableLedgerWfCheckingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovableLedgerWfCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
      }
export function useApprovableLedgerWfCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
        }
export type ApprovableLedgerWfCheckingQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingQuery>;
export type ApprovableLedgerWfCheckingLazyQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingLazyQuery>;
export type ApprovableLedgerWfCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>;
export const GetStockMovementDocument = gql`
    query getStockMovement($ID: String, $orderByDesc: String, $siteID: String, $siteMaterialID: String, $stockMovementType: StockMovementType) {
  getStockMovement(ID: $ID, orderByDesc: $orderByDesc, siteID: $siteID, siteMaterialID: $siteMaterialID, stockMovementType: $stockMovementType) {
    ID
    uomID
    siteMaterialID
    siteID
    siteMaterial {
      ID
      name
    }
    uom {
      ID
      name
      code
    }
    costItemID
    costItem {
      name
      code
      stockMovement {
        docNo
      }
    }
    stockMovementType
    stockMovementStatus
    docDate
    docNo
    remark
    quantity
    createdBy
    modTs
    createdTs
    profileInfo
    workFlow {
      ID
    }
  }
  lastStockMovementDates
}
    `;

/**
 * __useGetStockMovementQuery__
 *
 * To run a query within a React component, call `useGetStockMovementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *      stockMovementType: // value for 'stockMovementType'
 *   },
 * });
 */
export function useGetStockMovementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementQuery, GetStockMovementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementQuery, GetStockMovementQueryVariables>(GetStockMovementDocument, baseOptions);
      }
export function useGetStockMovementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementQuery, GetStockMovementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementQuery, GetStockMovementQueryVariables>(GetStockMovementDocument, baseOptions);
        }
export type GetStockMovementQueryHookResult = ReturnType<typeof useGetStockMovementQuery>;
export type GetStockMovementLazyQueryHookResult = ReturnType<typeof useGetStockMovementLazyQuery>;
export type GetStockMovementQueryResult = ApolloReactCommon.QueryResult<GetStockMovementQuery, GetStockMovementQueryVariables>;
export const GetStockMovementListingDocument = gql`
    query getStockMovementListing($ID: String, $orderByDesc: String, $siteID: String, $siteMaterialID: String, $stockMovementType: StockMovementType, $stockMovementStatus: String, $take: Float, $skip: Float, $searchValue: String) {
  getStockMovementCount(siteID: $siteID, stockMovementType: $stockMovementType, stockMovementStatus: $stockMovementStatus, searchValue: $searchValue)
  getStockMovement(ID: $ID, orderByDesc: $orderByDesc, siteID: $siteID, siteMaterialID: $siteMaterialID, stockMovementType: $stockMovementType, stockMovementStatus: $stockMovementStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    uomID
    siteMaterialID
    siteMaterial {
      ID
      name
    }
    siteID
    site {
      ID
      name
    }
    uom {
      ID
      code
    }
    costItemID
    costItem {
      name
    }
    stockMovementStatus
    docDate
    docNo
    remark
    quantity
    createdTs
    profileInfo
    workFlow {
      ID
    }
  }
}
    `;

/**
 * __useGetStockMovementListingQuery__
 *
 * To run a query within a React component, call `useGetStockMovementListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *      stockMovementType: // value for 'stockMovementType'
 *      stockMovementStatus: // value for 'stockMovementStatus'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetStockMovementListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementListingQuery, GetStockMovementListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementListingQuery, GetStockMovementListingQueryVariables>(GetStockMovementListingDocument, baseOptions);
      }
export function useGetStockMovementListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementListingQuery, GetStockMovementListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementListingQuery, GetStockMovementListingQueryVariables>(GetStockMovementListingDocument, baseOptions);
        }
export type GetStockMovementListingQueryHookResult = ReturnType<typeof useGetStockMovementListingQuery>;
export type GetStockMovementListingLazyQueryHookResult = ReturnType<typeof useGetStockMovementListingLazyQuery>;
export type GetStockMovementListingQueryResult = ApolloReactCommon.QueryResult<GetStockMovementListingQuery, GetStockMovementListingQueryVariables>;
export const GetStockMovementOffsetDocument = gql`
    query getStockMovementOffset($ID: String, $siteID: String, $siteMaterialID: String, $stockMovementType: StockMovementType, $stockMovementStatus: StockMovementStatus, $offSet: Float!, $limit: Float!) {
  getStockMovementOffset(ID: $ID, siteID: $siteID, siteMaterialID: $siteMaterialID, stockMovementType: $stockMovementType, stockMovementStatus: $stockMovementStatus, offSet: $offSet, limit: $limit) {
    count
    data {
      ID
      uomID
      siteMaterialID
      siteMaterial {
        ID
        name
      }
      siteID
      site {
        ID
        name
      }
      uom {
        ID
        code
      }
      costItemID
      costItem {
        name
      }
      stockMovementStatus
      docDate
      docNo
      remark
      quantity
      createdTs
      profileInfo
      workFlow {
        ID
      }
    }
  }
}
    `;

/**
 * __useGetStockMovementOffsetQuery__
 *
 * To run a query within a React component, call `useGetStockMovementOffsetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementOffsetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementOffsetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *      stockMovementType: // value for 'stockMovementType'
 *      stockMovementStatus: // value for 'stockMovementStatus'
 *      offSet: // value for 'offSet'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStockMovementOffsetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementOffsetQuery, GetStockMovementOffsetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementOffsetQuery, GetStockMovementOffsetQueryVariables>(GetStockMovementOffsetDocument, baseOptions);
      }
export function useGetStockMovementOffsetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementOffsetQuery, GetStockMovementOffsetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementOffsetQuery, GetStockMovementOffsetQueryVariables>(GetStockMovementOffsetDocument, baseOptions);
        }
export type GetStockMovementOffsetQueryHookResult = ReturnType<typeof useGetStockMovementOffsetQuery>;
export type GetStockMovementOffsetLazyQueryHookResult = ReturnType<typeof useGetStockMovementOffsetLazyQuery>;
export type GetStockMovementOffsetQueryResult = ApolloReactCommon.QueryResult<GetStockMovementOffsetQuery, GetStockMovementOffsetQueryVariables>;
export const GetSiteandUsersDocument = gql`
    query getSiteandUsers($ID: String, $superUserBool: UserSelection) {
  getSite(ID: $ID) {
    ID
    accountID
    contractID
    name
    address
    contactNo
    contract {
      title
      customerDetail {
        name
      }
    }
  }
  getUsersByAccountAndSoftware(superUserBool: $superUserBool) {
    ID
    name
    department
    email
    contactNo
    superUser
    commonStatus
    jobTitle
    userName
  }
}
    `;

/**
 * __useGetSiteandUsersQuery__
 *
 * To run a query within a React component, call `useGetSiteandUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteandUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteandUsersQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      superUserBool: // value for 'superUserBool'
 *   },
 * });
 */
export function useGetSiteandUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteandUsersQuery, GetSiteandUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteandUsersQuery, GetSiteandUsersQueryVariables>(GetSiteandUsersDocument, baseOptions);
      }
export function useGetSiteandUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteandUsersQuery, GetSiteandUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteandUsersQuery, GetSiteandUsersQueryVariables>(GetSiteandUsersDocument, baseOptions);
        }
export type GetSiteandUsersQueryHookResult = ReturnType<typeof useGetSiteandUsersQuery>;
export type GetSiteandUsersLazyQueryHookResult = ReturnType<typeof useGetSiteandUsersLazyQuery>;
export type GetSiteandUsersQueryResult = ApolloReactCommon.QueryResult<GetSiteandUsersQuery, GetSiteandUsersQueryVariables>;
export const GetStockMovementCategoriesDocument = gql`
    query getStockMovementCategories($siteID: String!) {
  getStockMovementCategories(siteID: $siteID)
}
    `;

/**
 * __useGetStockMovementCategoriesQuery__
 *
 * To run a query within a React component, call `useGetStockMovementCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementCategoriesQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetStockMovementCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementCategoriesQuery, GetStockMovementCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementCategoriesQuery, GetStockMovementCategoriesQueryVariables>(GetStockMovementCategoriesDocument, baseOptions);
      }
export function useGetStockMovementCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementCategoriesQuery, GetStockMovementCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementCategoriesQuery, GetStockMovementCategoriesQueryVariables>(GetStockMovementCategoriesDocument, baseOptions);
        }
export type GetStockMovementCategoriesQueryHookResult = ReturnType<typeof useGetStockMovementCategoriesQuery>;
export type GetStockMovementCategoriesLazyQueryHookResult = ReturnType<typeof useGetStockMovementCategoriesLazyQuery>;
export type GetStockMovementCategoriesQueryResult = ApolloReactCommon.QueryResult<GetStockMovementCategoriesQuery, GetStockMovementCategoriesQueryVariables>;
export const GetStockCategoryDocument = gql`
    query getStockCategory($siteID: String, $siteMaterialID: String) {
  getStockCategory(siteID: $siteID, siteMaterialID: $siteMaterialID)
}
    `;

/**
 * __useGetStockCategoryQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *   },
 * });
 */
export function useGetStockCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryQuery, GetStockCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryQuery, GetStockCategoryQueryVariables>(GetStockCategoryDocument, baseOptions);
      }
export function useGetStockCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryQuery, GetStockCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryQuery, GetStockCategoryQueryVariables>(GetStockCategoryDocument, baseOptions);
        }
export type GetStockCategoryQueryHookResult = ReturnType<typeof useGetStockCategoryQuery>;
export type GetStockCategoryLazyQueryHookResult = ReturnType<typeof useGetStockCategoryLazyQuery>;
export type GetStockCategoryQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryQuery, GetStockCategoryQueryVariables>;
export const GetSiteMaterialInStockDocument = gql`
    query getSiteMaterialInStock($siteID: String!) {
  getSiteMaterialInStock(siteID: $siteID)
}
    `;

/**
 * __useGetSiteMaterialInStockQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialInStockQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteMaterialInStockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialInStockQuery, GetSiteMaterialInStockQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialInStockQuery, GetSiteMaterialInStockQueryVariables>(GetSiteMaterialInStockDocument, baseOptions);
      }
export function useGetSiteMaterialInStockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialInStockQuery, GetSiteMaterialInStockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialInStockQuery, GetSiteMaterialInStockQueryVariables>(GetSiteMaterialInStockDocument, baseOptions);
        }
export type GetSiteMaterialInStockQueryHookResult = ReturnType<typeof useGetSiteMaterialInStockQuery>;
export type GetSiteMaterialInStockLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialInStockLazyQuery>;
export type GetSiteMaterialInStockQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialInStockQuery, GetSiteMaterialInStockQueryVariables>;
export const StockMovementCheckingDocument = gql`
    query stockMovementChecking($uomID: String!, $siteMaterialID: String!, $siteID: String!) {
  stockMovementChecking(uomID: $uomID, siteMaterialID: $siteMaterialID, siteID: $siteID)
}
    `;

/**
 * __useStockMovementCheckingQuery__
 *
 * To run a query within a React component, call `useStockMovementCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockMovementCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockMovementCheckingQuery({
 *   variables: {
 *      uomID: // value for 'uomID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useStockMovementCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockMovementCheckingQuery, StockMovementCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<StockMovementCheckingQuery, StockMovementCheckingQueryVariables>(StockMovementCheckingDocument, baseOptions);
      }
export function useStockMovementCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockMovementCheckingQuery, StockMovementCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockMovementCheckingQuery, StockMovementCheckingQueryVariables>(StockMovementCheckingDocument, baseOptions);
        }
export type StockMovementCheckingQueryHookResult = ReturnType<typeof useStockMovementCheckingQuery>;
export type StockMovementCheckingLazyQueryHookResult = ReturnType<typeof useStockMovementCheckingLazyQuery>;
export type StockMovementCheckingQueryResult = ApolloReactCommon.QueryResult<StockMovementCheckingQuery, StockMovementCheckingQueryVariables>;
export const GetCheckInStockDocument = gql`
    query getCheckInStock($siteID: String!, $costItemID: String, $siteMaterialID: String) {
  getCheckInStock(siteID: $siteID, costItemID: $costItemID, siteMaterialID: $siteMaterialID)
}
    `;

/**
 * __useGetCheckInStockQuery__
 *
 * To run a query within a React component, call `useGetCheckInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInStockQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *      siteMaterialID: // value for 'siteMaterialID'
 *   },
 * });
 */
export function useGetCheckInStockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckInStockQuery, GetCheckInStockQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckInStockQuery, GetCheckInStockQueryVariables>(GetCheckInStockDocument, baseOptions);
      }
export function useGetCheckInStockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckInStockQuery, GetCheckInStockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckInStockQuery, GetCheckInStockQueryVariables>(GetCheckInStockDocument, baseOptions);
        }
export type GetCheckInStockQueryHookResult = ReturnType<typeof useGetCheckInStockQuery>;
export type GetCheckInStockLazyQueryHookResult = ReturnType<typeof useGetCheckInStockLazyQuery>;
export type GetCheckInStockQueryResult = ApolloReactCommon.QueryResult<GetCheckInStockQuery, GetCheckInStockQueryVariables>;
export const GetSiteMaterialPolicyCheckerDocument = gql`
    query getSiteMaterialPolicyChecker($category: String!, $costItemID: String, $siteID: String!) {
  getSiteMaterialPolicyChecker(category: $category, costItemID: $costItemID, siteID: $siteID)
}
    `;

/**
 * __useGetSiteMaterialPolicyCheckerQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialPolicyCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialPolicyCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialPolicyCheckerQuery({
 *   variables: {
 *      category: // value for 'category'
 *      costItemID: // value for 'costItemID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteMaterialPolicyCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialPolicyCheckerQuery, GetSiteMaterialPolicyCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialPolicyCheckerQuery, GetSiteMaterialPolicyCheckerQueryVariables>(GetSiteMaterialPolicyCheckerDocument, baseOptions);
      }
export function useGetSiteMaterialPolicyCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialPolicyCheckerQuery, GetSiteMaterialPolicyCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialPolicyCheckerQuery, GetSiteMaterialPolicyCheckerQueryVariables>(GetSiteMaterialPolicyCheckerDocument, baseOptions);
        }
export type GetSiteMaterialPolicyCheckerQueryHookResult = ReturnType<typeof useGetSiteMaterialPolicyCheckerQuery>;
export type GetSiteMaterialPolicyCheckerLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialPolicyCheckerLazyQuery>;
export type GetSiteMaterialPolicyCheckerQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialPolicyCheckerQuery, GetSiteMaterialPolicyCheckerQueryVariables>;
export const CreateStockMovementDocument = gql`
    mutation createStockMovement($input: StockMovementInput!) {
  createStockMovement(input: $input) {
    ID
    quantity
    remark
    uom {
      name
    }
    docDate
    siteMaterialID
    costItemID
  }
}
    `;
export type CreateStockMovementMutationFn = ApolloReactCommon.MutationFunction<CreateStockMovementMutation, CreateStockMovementMutationVariables>;

/**
 * __useCreateStockMovementMutation__
 *
 * To run a mutation, you first call `useCreateStockMovementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMovementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMovementMutation, { data, loading, error }] = useCreateStockMovementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockMovementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockMovementMutation, CreateStockMovementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockMovementMutation, CreateStockMovementMutationVariables>(CreateStockMovementDocument, baseOptions);
      }
export type CreateStockMovementMutationHookResult = ReturnType<typeof useCreateStockMovementMutation>;
export type CreateStockMovementMutationResult = ApolloReactCommon.MutationResult<CreateStockMovementMutation>;
export type CreateStockMovementMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockMovementMutation, CreateStockMovementMutationVariables>;
export const UpdateStockMovementDocument = gql`
    mutation updateStockMovement($input: StockMovementInput!) {
  updateStockMovement(input: $input) {
    ID
    docNo
    stockMovementType
  }
}
    `;
export type UpdateStockMovementMutationFn = ApolloReactCommon.MutationFunction<UpdateStockMovementMutation, UpdateStockMovementMutationVariables>;

/**
 * __useUpdateStockMovementMutation__
 *
 * To run a mutation, you first call `useUpdateStockMovementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockMovementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockMovementMutation, { data, loading, error }] = useUpdateStockMovementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockMovementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockMovementMutation, UpdateStockMovementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockMovementMutation, UpdateStockMovementMutationVariables>(UpdateStockMovementDocument, baseOptions);
      }
export type UpdateStockMovementMutationHookResult = ReturnType<typeof useUpdateStockMovementMutation>;
export type UpdateStockMovementMutationResult = ApolloReactCommon.MutationResult<UpdateStockMovementMutation>;
export type UpdateStockMovementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockMovementMutation, UpdateStockMovementMutationVariables>;
export const DeleteStockMovementDocument = gql`
    mutation deleteStockMovement($input: StockMovementDeleteInput!) {
  deleteStockMovement(input: $input)
}
    `;
export type DeleteStockMovementMutationFn = ApolloReactCommon.MutationFunction<DeleteStockMovementMutation, DeleteStockMovementMutationVariables>;

/**
 * __useDeleteStockMovementMutation__
 *
 * To run a mutation, you first call `useDeleteStockMovementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockMovementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockMovementMutation, { data, loading, error }] = useDeleteStockMovementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStockMovementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockMovementMutation, DeleteStockMovementMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockMovementMutation, DeleteStockMovementMutationVariables>(DeleteStockMovementDocument, baseOptions);
      }
export type DeleteStockMovementMutationHookResult = ReturnType<typeof useDeleteStockMovementMutation>;
export type DeleteStockMovementMutationResult = ApolloReactCommon.MutationResult<DeleteStockMovementMutation>;
export type DeleteStockMovementMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockMovementMutation, DeleteStockMovementMutationVariables>;
export const GetCostItemMovementDocument = gql`
    query getCostItemMovement($siteID: String!, $stockMovementType: String!) {
  getCostItemMovement(siteID: $siteID, stockMovementType: $stockMovementType)
}
    `;

/**
 * __useGetCostItemMovementQuery__
 *
 * To run a query within a React component, call `useGetCostItemMovementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMovementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMovementQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      stockMovementType: // value for 'stockMovementType'
 *   },
 * });
 */
export function useGetCostItemMovementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMovementQuery, GetCostItemMovementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMovementQuery, GetCostItemMovementQueryVariables>(GetCostItemMovementDocument, baseOptions);
      }
export function useGetCostItemMovementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMovementQuery, GetCostItemMovementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMovementQuery, GetCostItemMovementQueryVariables>(GetCostItemMovementDocument, baseOptions);
        }
export type GetCostItemMovementQueryHookResult = ReturnType<typeof useGetCostItemMovementQuery>;
export type GetCostItemMovementLazyQueryHookResult = ReturnType<typeof useGetCostItemMovementLazyQuery>;
export type GetCostItemMovementQueryResult = ApolloReactCommon.QueryResult<GetCostItemMovementQuery, GetCostItemMovementQueryVariables>;
export const GetCostItemMovementCategoriesDocument = gql`
    query getCostItemMovementCategories($siteID: String!) {
  getCostItemMovementCategories(siteID: $siteID)
}
    `;

/**
 * __useGetCostItemMovementCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCostItemMovementCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemMovementCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemMovementCategoriesQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetCostItemMovementCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemMovementCategoriesQuery, GetCostItemMovementCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemMovementCategoriesQuery, GetCostItemMovementCategoriesQueryVariables>(GetCostItemMovementCategoriesDocument, baseOptions);
      }
export function useGetCostItemMovementCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemMovementCategoriesQuery, GetCostItemMovementCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemMovementCategoriesQuery, GetCostItemMovementCategoriesQueryVariables>(GetCostItemMovementCategoriesDocument, baseOptions);
        }
export type GetCostItemMovementCategoriesQueryHookResult = ReturnType<typeof useGetCostItemMovementCategoriesQuery>;
export type GetCostItemMovementCategoriesLazyQueryHookResult = ReturnType<typeof useGetCostItemMovementCategoriesLazyQuery>;
export type GetCostItemMovementCategoriesQueryResult = ApolloReactCommon.QueryResult<GetCostItemMovementCategoriesQuery, GetCostItemMovementCategoriesQueryVariables>;
export const GetCostItemCategoryDocument = gql`
    query getCostItemCategory($siteID: String, $costItemID: String) {
  getCostItemCategory(siteID: $siteID, costItemID: $costItemID)
}
    `;

/**
 * __useGetCostItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetCostItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemCategoryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetCostItemCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemCategoryQuery, GetCostItemCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemCategoryQuery, GetCostItemCategoryQueryVariables>(GetCostItemCategoryDocument, baseOptions);
      }
export function useGetCostItemCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemCategoryQuery, GetCostItemCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemCategoryQuery, GetCostItemCategoryQueryVariables>(GetCostItemCategoryDocument, baseOptions);
        }
export type GetCostItemCategoryQueryHookResult = ReturnType<typeof useGetCostItemCategoryQuery>;
export type GetCostItemCategoryLazyQueryHookResult = ReturnType<typeof useGetCostItemCategoryLazyQuery>;
export type GetCostItemCategoryQueryResult = ApolloReactCommon.QueryResult<GetCostItemCategoryQuery, GetCostItemCategoryQueryVariables>;
export const GetStockMovementItemDetailDocument = gql`
    query getStockMovementItemDetail($siteID: String, $itemID: String, $proSubscriber: Boolean, $limit: Float, $offset: Float, $stockMovementType: String, $searchValue: String) {
  getStockMovementItemDetail(siteID: $siteID, itemID: $itemID, proSubscriber: $proSubscriber, limit: $limit, offset: $offset, stockMovementType: $stockMovementType, searchValue: $searchValue)
}
    `;

/**
 * __useGetStockMovementItemDetailQuery__
 *
 * To run a query within a React component, call `useGetStockMovementItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockMovementItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockMovementItemDetailQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      itemID: // value for 'itemID'
 *      proSubscriber: // value for 'proSubscriber'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      stockMovementType: // value for 'stockMovementType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetStockMovementItemDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockMovementItemDetailQuery, GetStockMovementItemDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockMovementItemDetailQuery, GetStockMovementItemDetailQueryVariables>(GetStockMovementItemDetailDocument, baseOptions);
      }
export function useGetStockMovementItemDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockMovementItemDetailQuery, GetStockMovementItemDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockMovementItemDetailQuery, GetStockMovementItemDetailQueryVariables>(GetStockMovementItemDetailDocument, baseOptions);
        }
export type GetStockMovementItemDetailQueryHookResult = ReturnType<typeof useGetStockMovementItemDetailQuery>;
export type GetStockMovementItemDetailLazyQueryHookResult = ReturnType<typeof useGetStockMovementItemDetailLazyQuery>;
export type GetStockMovementItemDetailQueryResult = ApolloReactCommon.QueryResult<GetStockMovementItemDetailQuery, GetStockMovementItemDetailQueryVariables>;
export const GetStockCostItemSummaryDocument = gql`
    query getStockCostItemSummary($siteID: String, $proSubscriber: Boolean) {
  getStockCostItemSummary(siteID: $siteID, proSubscriber: $proSubscriber)
}
    `;

/**
 * __useGetStockCostItemSummaryQuery__
 *
 * To run a query within a React component, call `useGetStockCostItemSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCostItemSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCostItemSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      proSubscriber: // value for 'proSubscriber'
 *   },
 * });
 */
export function useGetStockCostItemSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCostItemSummaryQuery, GetStockCostItemSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCostItemSummaryQuery, GetStockCostItemSummaryQueryVariables>(GetStockCostItemSummaryDocument, baseOptions);
      }
export function useGetStockCostItemSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCostItemSummaryQuery, GetStockCostItemSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCostItemSummaryQuery, GetStockCostItemSummaryQueryVariables>(GetStockCostItemSummaryDocument, baseOptions);
        }
export type GetStockCostItemSummaryQueryHookResult = ReturnType<typeof useGetStockCostItemSummaryQuery>;
export type GetStockCostItemSummaryLazyQueryHookResult = ReturnType<typeof useGetStockCostItemSummaryLazyQuery>;
export type GetStockCostItemSummaryQueryResult = ApolloReactCommon.QueryResult<GetStockCostItemSummaryQuery, GetStockCostItemSummaryQueryVariables>;
export const GetItemByClassDocument = gql`
    query getItemByClass($costClass: CostClass!, $isInventory: Boolean) {
  getItemByClass(costClass: $costClass, isInventory: $isInventory) {
    ID
    name
    uomID
    createdTs
    isInventory
    UOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetItemByClassQuery__
 *
 * To run a query within a React component, call `useGetItemByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByClassQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *      isInventory: // value for 'isInventory'
 *   },
 * });
 */
export function useGetItemByClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemByClassQuery, GetItemByClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemByClassQuery, GetItemByClassQueryVariables>(GetItemByClassDocument, baseOptions);
      }
export function useGetItemByClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemByClassQuery, GetItemByClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemByClassQuery, GetItemByClassQueryVariables>(GetItemByClassDocument, baseOptions);
        }
export type GetItemByClassQueryHookResult = ReturnType<typeof useGetItemByClassQuery>;
export type GetItemByClassLazyQueryHookResult = ReturnType<typeof useGetItemByClassLazyQuery>;
export type GetItemByClassQueryResult = ApolloReactCommon.QueryResult<GetItemByClassQuery, GetItemByClassQueryVariables>;
export const CostItemMovementCheckingDocument = gql`
    query costItemMovementChecking($uomID: String!, $costItemID: String!, $siteID: String!) {
  costItemMovementChecking(uomID: $uomID, costItemID: $costItemID, siteID: $siteID)
}
    `;

/**
 * __useCostItemMovementCheckingQuery__
 *
 * To run a query within a React component, call `useCostItemMovementCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostItemMovementCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostItemMovementCheckingQuery({
 *   variables: {
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useCostItemMovementCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostItemMovementCheckingQuery, CostItemMovementCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<CostItemMovementCheckingQuery, CostItemMovementCheckingQueryVariables>(CostItemMovementCheckingDocument, baseOptions);
      }
export function useCostItemMovementCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostItemMovementCheckingQuery, CostItemMovementCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostItemMovementCheckingQuery, CostItemMovementCheckingQueryVariables>(CostItemMovementCheckingDocument, baseOptions);
        }
export type CostItemMovementCheckingQueryHookResult = ReturnType<typeof useCostItemMovementCheckingQuery>;
export type CostItemMovementCheckingLazyQueryHookResult = ReturnType<typeof useCostItemMovementCheckingLazyQuery>;
export type CostItemMovementCheckingQueryResult = ApolloReactCommon.QueryResult<CostItemMovementCheckingQuery, CostItemMovementCheckingQueryVariables>;
export const GetCostItemInStockDocument = gql`
    query getCostItemInStock($siteID: String!) {
  getCostItemInStock(siteID: $siteID)
}
    `;

/**
 * __useGetCostItemInStockQuery__
 *
 * To run a query within a React component, call `useGetCostItemInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemInStockQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetCostItemInStockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemInStockQuery, GetCostItemInStockQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemInStockQuery, GetCostItemInStockQueryVariables>(GetCostItemInStockDocument, baseOptions);
      }
export function useGetCostItemInStockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemInStockQuery, GetCostItemInStockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemInStockQuery, GetCostItemInStockQueryVariables>(GetCostItemInStockDocument, baseOptions);
        }
export type GetCostItemInStockQueryHookResult = ReturnType<typeof useGetCostItemInStockQuery>;
export type GetCostItemInStockLazyQueryHookResult = ReturnType<typeof useGetCostItemInStockLazyQuery>;
export type GetCostItemInStockQueryResult = ApolloReactCommon.QueryResult<GetCostItemInStockQuery, GetCostItemInStockQueryVariables>;
export const GetContractSiteDocument = gql`
    query getContractSite($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    accountID
    customerID
    title
    contractNo
    description
    contractDate
    contractSum
    startDate
    endDate
    contractStatus
    site {
      ID
      contractID
      name
      address
    }
  }
}
    `;

/**
 * __useGetContractSiteQuery__
 *
 * To run a query within a React component, call `useGetContractSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSiteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSiteQuery, GetContractSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSiteQuery, GetContractSiteQueryVariables>(GetContractSiteDocument, baseOptions);
      }
export function useGetContractSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSiteQuery, GetContractSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSiteQuery, GetContractSiteQueryVariables>(GetContractSiteDocument, baseOptions);
        }
export type GetContractSiteQueryHookResult = ReturnType<typeof useGetContractSiteQuery>;
export type GetContractSiteLazyQueryHookResult = ReturnType<typeof useGetContractSiteLazyQuery>;
export type GetContractSiteQueryResult = ApolloReactCommon.QueryResult<GetContractSiteQuery, GetContractSiteQueryVariables>;
export const GetSiteContractDocument = gql`
    query getSiteContract($ID: String, $contractID: String) {
  getSite(ID: $ID, contractID: $contractID) {
    ID
    contractID
    name
    address
    contract {
      ID
      title
      description
      contractSum
      contractStatus
    }
  }
}
    `;

/**
 * __useGetSiteContractQuery__
 *
 * To run a query within a React component, call `useGetSiteContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteContractQuery, GetSiteContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteContractQuery, GetSiteContractQueryVariables>(GetSiteContractDocument, baseOptions);
      }
export function useGetSiteContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteContractQuery, GetSiteContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteContractQuery, GetSiteContractQueryVariables>(GetSiteContractDocument, baseOptions);
        }
export type GetSiteContractQueryHookResult = ReturnType<typeof useGetSiteContractQuery>;
export type GetSiteContractLazyQueryHookResult = ReturnType<typeof useGetSiteContractLazyQuery>;
export type GetSiteContractQueryResult = ApolloReactCommon.QueryResult<GetSiteContractQuery, GetSiteContractQueryVariables>;
export const SiteManagementExecSummaryDocument = gql`
    query siteManagementExecSummary($contractID: String!, $siteID: String!) {
  siteWorkerAttendanceInfo(siteID: $siteID)
  doCount(contractID: $contractID, siteID: $siteID)
  MOSCount(siteID: $siteID)
  lastSiteProgressDate(siteID: $siteID)
}
    `;

/**
 * __useSiteManagementExecSummaryQuery__
 *
 * To run a query within a React component, call `useSiteManagementExecSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteManagementExecSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteManagementExecSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useSiteManagementExecSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>(SiteManagementExecSummaryDocument, baseOptions);
      }
export function useSiteManagementExecSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>(SiteManagementExecSummaryDocument, baseOptions);
        }
export type SiteManagementExecSummaryQueryHookResult = ReturnType<typeof useSiteManagementExecSummaryQuery>;
export type SiteManagementExecSummaryLazyQueryHookResult = ReturnType<typeof useSiteManagementExecSummaryLazyQuery>;
export type SiteManagementExecSummaryQueryResult = ApolloReactCommon.QueryResult<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>;
export const GetSiteWorkersDocument = gql`
    query getSiteWorkers($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String, $commonStatus: CommonStatus) {
  getSiteWorkers(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, contractID: $contractID, commonStatus: $commonStatus) {
    ID
    modTs
    nationality
    contact {
      name
      contactNo
      email
    }
  }
}
    `;

/**
 * __useGetSiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetSiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWorkersQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSiteWorkersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>(GetSiteWorkersDocument, baseOptions);
      }
export function useGetSiteWorkersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>(GetSiteWorkersDocument, baseOptions);
        }
export type GetSiteWorkersQueryHookResult = ReturnType<typeof useGetSiteWorkersQuery>;
export type GetSiteWorkersLazyQueryHookResult = ReturnType<typeof useGetSiteWorkersLazyQuery>;
export type GetSiteWorkersQueryResult = ApolloReactCommon.QueryResult<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>;
export const GetSiteProgressDocument = gql`
    query getSiteProgress($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String, $siteID: String) {
  getSiteProgress(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, contractID: $contractID, siteID: $siteID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    contractID
    title
    docDate
    contract {
      title
    }
    imagesInfo
  }
}
    `;

/**
 * __useGetSiteProgressQuery__
 *
 * To run a query within a React component, call `useGetSiteProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteProgressQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteProgressQuery, GetSiteProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteProgressQuery, GetSiteProgressQueryVariables>(GetSiteProgressDocument, baseOptions);
      }
export function useGetSiteProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteProgressQuery, GetSiteProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteProgressQuery, GetSiteProgressQueryVariables>(GetSiteProgressDocument, baseOptions);
        }
export type GetSiteProgressQueryHookResult = ReturnType<typeof useGetSiteProgressQuery>;
export type GetSiteProgressLazyQueryHookResult = ReturnType<typeof useGetSiteProgressLazyQuery>;
export type GetSiteProgressQueryResult = ApolloReactCommon.QueryResult<GetSiteProgressQuery, GetSiteProgressQueryVariables>;
export const GetThumbnailDocumentListingDocument = gql`
    query getThumbnailDocumentListing($refID: String, $refTable: String) {
  DocumentListing(refID: $refID, refTable: $refTable) {
    ID
    mediaType
    refTable
    refID
    description
    fileName
    fileURL
    accountID
    bucketFileName
    opacity
  }
}
    `;

/**
 * __useGetThumbnailDocumentListingQuery__
 *
 * To run a query within a React component, call `useGetThumbnailDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThumbnailDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThumbnailDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useGetThumbnailDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>(GetThumbnailDocumentListingDocument, baseOptions);
      }
export function useGetThumbnailDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>(GetThumbnailDocumentListingDocument, baseOptions);
        }
export type GetThumbnailDocumentListingQueryHookResult = ReturnType<typeof useGetThumbnailDocumentListingQuery>;
export type GetThumbnailDocumentListingLazyQueryHookResult = ReturnType<typeof useGetThumbnailDocumentListingLazyQuery>;
export type GetThumbnailDocumentListingQueryResult = ApolloReactCommon.QueryResult<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>;
export const CreateSiteProgressDocument = gql`
    mutation createSiteProgress($input: SiteProgressInput!) {
  createSiteProgress(input: $input) {
    ID
    title
  }
}
    `;
export type CreateSiteProgressMutationFn = ApolloReactCommon.MutationFunction<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>;

/**
 * __useCreateSiteProgressMutation__
 *
 * To run a mutation, you first call `useCreateSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteProgressMutation, { data, loading, error }] = useCreateSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>(CreateSiteProgressDocument, baseOptions);
      }
export type CreateSiteProgressMutationHookResult = ReturnType<typeof useCreateSiteProgressMutation>;
export type CreateSiteProgressMutationResult = ApolloReactCommon.MutationResult<CreateSiteProgressMutation>;
export type CreateSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>;
export const UpdateSiteProgressDocument = gql`
    mutation updateSiteProgress($input: SiteProgressInput!) {
  updateSiteProgress(input: $input) {
    ID
    title
  }
}
    `;
export type UpdateSiteProgressMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>;

/**
 * __useUpdateSiteProgressMutation__
 *
 * To run a mutation, you first call `useUpdateSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteProgressMutation, { data, loading, error }] = useUpdateSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>(UpdateSiteProgressDocument, baseOptions);
      }
export type UpdateSiteProgressMutationHookResult = ReturnType<typeof useUpdateSiteProgressMutation>;
export type UpdateSiteProgressMutationResult = ApolloReactCommon.MutationResult<UpdateSiteProgressMutation>;
export type UpdateSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>;
export const DeleteSiteProgressDocument = gql`
    mutation deleteSiteProgress($input: SiteProgressInput!) {
  deleteSiteProgress(input: $input)
}
    `;
export type DeleteSiteProgressMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>;

/**
 * __useDeleteSiteProgressMutation__
 *
 * To run a mutation, you first call `useDeleteSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteProgressMutation, { data, loading, error }] = useDeleteSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>(DeleteSiteProgressDocument, baseOptions);
      }
export type DeleteSiteProgressMutationHookResult = ReturnType<typeof useDeleteSiteProgressMutation>;
export type DeleteSiteProgressMutationResult = ApolloReactCommon.MutationResult<DeleteSiteProgressMutation>;
export type DeleteSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>;
export const GetSiteStockItemsDocument = gql`
    query getSiteStockItems($siteID: String!) {
  getSiteStockItems(siteID: $siteID)
}
    `;

/**
 * __useGetSiteStockItemsQuery__
 *
 * To run a query within a React component, call `useGetSiteStockItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteStockItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteStockItemsQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteStockItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>(GetSiteStockItemsDocument, baseOptions);
      }
export function useGetSiteStockItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>(GetSiteStockItemsDocument, baseOptions);
        }
export type GetSiteStockItemsQueryHookResult = ReturnType<typeof useGetSiteStockItemsQuery>;
export type GetSiteStockItemsLazyQueryHookResult = ReturnType<typeof useGetSiteStockItemsLazyQuery>;
export type GetSiteStockItemsQueryResult = ApolloReactCommon.QueryResult<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>;
export const GetStockItemDetailDocument = gql`
    query getStockItemDetail($siteID: String!, $costItemID: String!, $uomID: String!) {
  getStockItemDetail(siteID: $siteID, costItemID: $costItemID, uomID: $uomID)
}
    `;

/**
 * __useGetStockItemDetailQuery__
 *
 * To run a query within a React component, call `useGetStockItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemDetailQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *      uomID: // value for 'uomID'
 *   },
 * });
 */
export function useGetStockItemDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
      }
export function useGetStockItemDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
        }
export type GetStockItemDetailQueryHookResult = ReturnType<typeof useGetStockItemDetailQuery>;
export type GetStockItemDetailLazyQueryHookResult = ReturnType<typeof useGetStockItemDetailLazyQuery>;
export type GetStockItemDetailQueryResult = ApolloReactCommon.QueryResult<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>;
export const GetSiteStockLedgerDocument = gql`
    query getSiteStockLedger($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $uomID: String, $costItemID: String) {
  getSiteStockLedger(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, uomID: $uomID, costItemID: $costItemID) {
    ID
    trackingDate
    remarks
    docRef
    quantity
    trxType
    siteID
    costItemID
    site {
      ID
      name
      address
    }
    costItem {
      ID
      name
    }
    uom {
      ID
      name
      code
    }
    createdBy
    createdTs
  }
}
    `;

/**
 * __useGetSiteStockLedgerQuery__
 *
 * To run a query within a React component, call `useGetSiteStockLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteStockLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteStockLedgerQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetSiteStockLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>(GetSiteStockLedgerDocument, baseOptions);
      }
export function useGetSiteStockLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>(GetSiteStockLedgerDocument, baseOptions);
        }
export type GetSiteStockLedgerQueryHookResult = ReturnType<typeof useGetSiteStockLedgerQuery>;
export type GetSiteStockLedgerLazyQueryHookResult = ReturnType<typeof useGetSiteStockLedgerLazyQuery>;
export type GetSiteStockLedgerQueryResult = ApolloReactCommon.QueryResult<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>;
export const CreateSiteStockLedgerDocument = gql`
    mutation createSiteStockLedger($input: SiteStockLedgerInput!) {
  createSiteStockLedger(input: $input) {
    ID
  }
}
    `;
export type CreateSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>;

/**
 * __useCreateSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useCreateSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteStockLedgerMutation, { data, loading, error }] = useCreateSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>(CreateSiteStockLedgerDocument, baseOptions);
      }
export type CreateSiteStockLedgerMutationHookResult = ReturnType<typeof useCreateSiteStockLedgerMutation>;
export type CreateSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<CreateSiteStockLedgerMutation>;
export type CreateSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>;
export const UpdateSiteStockLedgerDocument = gql`
    mutation updateSiteStockLedger($input: SiteStockLedgerInput!) {
  updateSiteStockLedger(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>;

/**
 * __useUpdateSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteStockLedgerMutation, { data, loading, error }] = useUpdateSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>(UpdateSiteStockLedgerDocument, baseOptions);
      }
export type UpdateSiteStockLedgerMutationHookResult = ReturnType<typeof useUpdateSiteStockLedgerMutation>;
export type UpdateSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<UpdateSiteStockLedgerMutation>;
export type UpdateSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>;
export const DeleteSiteStockLedgerDocument = gql`
    mutation deleteSiteStockLedger($input: SiteStockLedgerInput!) {
  deleteSiteStockLedger(input: $input)
}
    `;
export type DeleteSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>;

/**
 * __useDeleteSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useDeleteSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteStockLedgerMutation, { data, loading, error }] = useDeleteSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>(DeleteSiteStockLedgerDocument, baseOptions);
      }
export type DeleteSiteStockLedgerMutationHookResult = ReturnType<typeof useDeleteSiteStockLedgerMutation>;
export type DeleteSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<DeleteSiteStockLedgerMutation>;
export type DeleteSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>;
export const GetSiteWorkerAttendanceDocument = gql`
    query getSiteWorkerAttendance($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $siteID: String, $siteWorkerID: String) {
  getSiteWorkerAttendance(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, siteID: $siteID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    workerID
    siteID
    timeIn
    timeOut
  }
}
    `;

/**
 * __useGetSiteWorkerAttendanceQuery__
 *
 * To run a query within a React component, call `useGetSiteWorkerAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWorkerAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWorkerAttendanceQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      siteWorkerID: // value for 'siteWorkerID'
 *   },
 * });
 */
export function useGetSiteWorkerAttendanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>(GetSiteWorkerAttendanceDocument, baseOptions);
      }
export function useGetSiteWorkerAttendanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>(GetSiteWorkerAttendanceDocument, baseOptions);
        }
export type GetSiteWorkerAttendanceQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceQuery>;
export type GetSiteWorkerAttendanceLazyQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceLazyQuery>;
export type GetSiteWorkerAttendanceQueryResult = ApolloReactCommon.QueryResult<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>;
export const GetDiaryTypeDocument = gql`
    query getDiaryType($ID: String) {
  getDiaryType(ID: $ID) {
    ID
    name
    followUp
  }
}
    `;

/**
 * __useGetDiaryTypeQuery__
 *
 * To run a query within a React component, call `useGetDiaryTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiaryTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiaryTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetDiaryTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiaryTypeQuery, GetDiaryTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiaryTypeQuery, GetDiaryTypeQueryVariables>(GetDiaryTypeDocument, baseOptions);
      }
export function useGetDiaryTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiaryTypeQuery, GetDiaryTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiaryTypeQuery, GetDiaryTypeQueryVariables>(GetDiaryTypeDocument, baseOptions);
        }
export type GetDiaryTypeQueryHookResult = ReturnType<typeof useGetDiaryTypeQuery>;
export type GetDiaryTypeLazyQueryHookResult = ReturnType<typeof useGetDiaryTypeLazyQuery>;
export type GetDiaryTypeQueryResult = ApolloReactCommon.QueryResult<GetDiaryTypeQuery, GetDiaryTypeQueryVariables>;
export const CreateDiaryTypeDocument = gql`
    mutation createDiaryType($input: DiaryTypeInput!) {
  createDiaryType(input: $input) {
    ID
  }
}
    `;
export type CreateDiaryTypeMutationFn = ApolloReactCommon.MutationFunction<CreateDiaryTypeMutation, CreateDiaryTypeMutationVariables>;

/**
 * __useCreateDiaryTypeMutation__
 *
 * To run a mutation, you first call `useCreateDiaryTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiaryTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiaryTypeMutation, { data, loading, error }] = useCreateDiaryTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiaryTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDiaryTypeMutation, CreateDiaryTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDiaryTypeMutation, CreateDiaryTypeMutationVariables>(CreateDiaryTypeDocument, baseOptions);
      }
export type CreateDiaryTypeMutationHookResult = ReturnType<typeof useCreateDiaryTypeMutation>;
export type CreateDiaryTypeMutationResult = ApolloReactCommon.MutationResult<CreateDiaryTypeMutation>;
export type CreateDiaryTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDiaryTypeMutation, CreateDiaryTypeMutationVariables>;
export const GetSafetyChecklistDocument = gql`
    query getSafetyChecklist($ID: String, $orderByDesc: String, $orderByAsc: String) {
  getSafetyChecklist(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    siteID
    title
    nextScheduleDate
    endDate
    isActive
    frequency
    checklistCategoryID
    checklistCategory {
      ID
      name
      color
    }
    safetyChecklistItem {
      ID
      safetyChecklistID
      title
      notes
    }
  }
}
    `;

/**
 * __useGetSafetyChecklistQuery__
 *
 * To run a query within a React component, call `useGetSafetyChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSafetyChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSafetyChecklistQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSafetyChecklistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSafetyChecklistQuery, GetSafetyChecklistQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSafetyChecklistQuery, GetSafetyChecklistQueryVariables>(GetSafetyChecklistDocument, baseOptions);
      }
export function useGetSafetyChecklistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSafetyChecklistQuery, GetSafetyChecklistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSafetyChecklistQuery, GetSafetyChecklistQueryVariables>(GetSafetyChecklistDocument, baseOptions);
        }
export type GetSafetyChecklistQueryHookResult = ReturnType<typeof useGetSafetyChecklistQuery>;
export type GetSafetyChecklistLazyQueryHookResult = ReturnType<typeof useGetSafetyChecklistLazyQuery>;
export type GetSafetyChecklistQueryResult = ApolloReactCommon.QueryResult<GetSafetyChecklistQuery, GetSafetyChecklistQueryVariables>;
export const CreateSafetyChecklistDocument = gql`
    mutation createSafetyChecklist($input: SafetyChecklistInput!) {
  createSafetyChecklist(input: $input) {
    ID
  }
}
    `;
export type CreateSafetyChecklistMutationFn = ApolloReactCommon.MutationFunction<CreateSafetyChecklistMutation, CreateSafetyChecklistMutationVariables>;

/**
 * __useCreateSafetyChecklistMutation__
 *
 * To run a mutation, you first call `useCreateSafetyChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSafetyChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSafetyChecklistMutation, { data, loading, error }] = useCreateSafetyChecklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSafetyChecklistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSafetyChecklistMutation, CreateSafetyChecklistMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSafetyChecklistMutation, CreateSafetyChecklistMutationVariables>(CreateSafetyChecklistDocument, baseOptions);
      }
export type CreateSafetyChecklistMutationHookResult = ReturnType<typeof useCreateSafetyChecklistMutation>;
export type CreateSafetyChecklistMutationResult = ApolloReactCommon.MutationResult<CreateSafetyChecklistMutation>;
export type CreateSafetyChecklistMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSafetyChecklistMutation, CreateSafetyChecklistMutationVariables>;
export const CreateSafetyChecklistItemDocument = gql`
    mutation createSafetyChecklistItem($input: SafetyChecklistItemInput!) {
  createSafetyChecklistItem(input: $input) {
    ID
    safetyChecklistID
  }
}
    `;
export type CreateSafetyChecklistItemMutationFn = ApolloReactCommon.MutationFunction<CreateSafetyChecklistItemMutation, CreateSafetyChecklistItemMutationVariables>;

/**
 * __useCreateSafetyChecklistItemMutation__
 *
 * To run a mutation, you first call `useCreateSafetyChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSafetyChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSafetyChecklistItemMutation, { data, loading, error }] = useCreateSafetyChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSafetyChecklistItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSafetyChecklistItemMutation, CreateSafetyChecklistItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSafetyChecklistItemMutation, CreateSafetyChecklistItemMutationVariables>(CreateSafetyChecklistItemDocument, baseOptions);
      }
export type CreateSafetyChecklistItemMutationHookResult = ReturnType<typeof useCreateSafetyChecklistItemMutation>;
export type CreateSafetyChecklistItemMutationResult = ApolloReactCommon.MutationResult<CreateSafetyChecklistItemMutation>;
export type CreateSafetyChecklistItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSafetyChecklistItemMutation, CreateSafetyChecklistItemMutationVariables>;
export const GetSiteSafetyDocument = gql`
    query getSiteSafety($ID: String, $SiteSafetyStatus: SiteSafetyStatus, $orderByDesc: String, $orderByAsc: String, $siteID: String) {
  getSiteSafety(ID: $ID, SiteSafetyStatus: $SiteSafetyStatus, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, siteID: $siteID) {
    ID
    siteID
    safetyChecklistID
    safetyChecklist {
      ID
      title
      nextScheduleDate
      isActive
      frequency
      checklistCategoryID
      checklistCategory {
        ID
        name
        color
      }
    }
    docDate
    assignedToID
    status
    siteSafetyItem {
      ID
      siteSafetyID
      safetyChecklistItemID
      safetyChecklistItem {
        ID
        title
        notes
      }
      isChecked
      remarks
      followUp
      diaryTypeID
      diaryType {
        ID
        name
      }
      location
    }
  }
}
    `;

/**
 * __useGetSiteSafetyQuery__
 *
 * To run a query within a React component, call `useGetSiteSafetyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSafetyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSafetyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      SiteSafetyStatus: // value for 'SiteSafetyStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteSafetyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSafetyQuery, GetSiteSafetyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSafetyQuery, GetSiteSafetyQueryVariables>(GetSiteSafetyDocument, baseOptions);
      }
export function useGetSiteSafetyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSafetyQuery, GetSiteSafetyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSafetyQuery, GetSiteSafetyQueryVariables>(GetSiteSafetyDocument, baseOptions);
        }
export type GetSiteSafetyQueryHookResult = ReturnType<typeof useGetSiteSafetyQuery>;
export type GetSiteSafetyLazyQueryHookResult = ReturnType<typeof useGetSiteSafetyLazyQuery>;
export type GetSiteSafetyQueryResult = ApolloReactCommon.QueryResult<GetSiteSafetyQuery, GetSiteSafetyQueryVariables>;
export const CreateSiteSafetyDocument = gql`
    mutation createSiteSafety($input: SiteSafetyInput!) {
  createSiteSafety(input: $input) {
    ID
  }
}
    `;
export type CreateSiteSafetyMutationFn = ApolloReactCommon.MutationFunction<CreateSiteSafetyMutation, CreateSiteSafetyMutationVariables>;

/**
 * __useCreateSiteSafetyMutation__
 *
 * To run a mutation, you first call `useCreateSiteSafetyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteSafetyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteSafetyMutation, { data, loading, error }] = useCreateSiteSafetyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteSafetyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteSafetyMutation, CreateSiteSafetyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteSafetyMutation, CreateSiteSafetyMutationVariables>(CreateSiteSafetyDocument, baseOptions);
      }
export type CreateSiteSafetyMutationHookResult = ReturnType<typeof useCreateSiteSafetyMutation>;
export type CreateSiteSafetyMutationResult = ApolloReactCommon.MutationResult<CreateSiteSafetyMutation>;
export type CreateSiteSafetyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteSafetyMutation, CreateSiteSafetyMutationVariables>;
export const UpdateSiteSafetyDocument = gql`
    mutation updateSiteSafety($input: SiteSafetyInput!) {
  updateSiteSafety(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteSafetyMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteSafetyMutation, UpdateSiteSafetyMutationVariables>;

/**
 * __useUpdateSiteSafetyMutation__
 *
 * To run a mutation, you first call `useUpdateSiteSafetyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteSafetyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteSafetyMutation, { data, loading, error }] = useUpdateSiteSafetyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteSafetyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteSafetyMutation, UpdateSiteSafetyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteSafetyMutation, UpdateSiteSafetyMutationVariables>(UpdateSiteSafetyDocument, baseOptions);
      }
export type UpdateSiteSafetyMutationHookResult = ReturnType<typeof useUpdateSiteSafetyMutation>;
export type UpdateSiteSafetyMutationResult = ApolloReactCommon.MutationResult<UpdateSiteSafetyMutation>;
export type UpdateSiteSafetyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteSafetyMutation, UpdateSiteSafetyMutationVariables>;
export const GetSiteSafetyItemDocument = gql`
    query getSiteSafetyItem($ID: String, $orderByDesc: String, $orderByAsc: String, $siteSafetyID: String) {
  getSiteSafetyItem(ID: $ID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, siteSafetyID: $siteSafetyID) {
    ID
    siteSafetyID
    safetyChecklistItemID
    safetyChecklistItem {
      ID
      title
      notes
    }
    isChecked
    remarks
    followUp
    diaryTypeID
    diaryType {
      ID
      name
    }
    location
  }
}
    `;

/**
 * __useGetSiteSafetyItemQuery__
 *
 * To run a query within a React component, call `useGetSiteSafetyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSafetyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSafetyItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      siteSafetyID: // value for 'siteSafetyID'
 *   },
 * });
 */
export function useGetSiteSafetyItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSafetyItemQuery, GetSiteSafetyItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSafetyItemQuery, GetSiteSafetyItemQueryVariables>(GetSiteSafetyItemDocument, baseOptions);
      }
export function useGetSiteSafetyItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSafetyItemQuery, GetSiteSafetyItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSafetyItemQuery, GetSiteSafetyItemQueryVariables>(GetSiteSafetyItemDocument, baseOptions);
        }
export type GetSiteSafetyItemQueryHookResult = ReturnType<typeof useGetSiteSafetyItemQuery>;
export type GetSiteSafetyItemLazyQueryHookResult = ReturnType<typeof useGetSiteSafetyItemLazyQuery>;
export type GetSiteSafetyItemQueryResult = ApolloReactCommon.QueryResult<GetSiteSafetyItemQuery, GetSiteSafetyItemQueryVariables>;
export const CreateSiteSafetyItemDocument = gql`
    mutation createSiteSafetyItem($input: SiteSafetyItemInput!) {
  createSiteSafetyItem(input: $input) {
    ID
    siteSafetyID
  }
}
    `;
export type CreateSiteSafetyItemMutationFn = ApolloReactCommon.MutationFunction<CreateSiteSafetyItemMutation, CreateSiteSafetyItemMutationVariables>;

/**
 * __useCreateSiteSafetyItemMutation__
 *
 * To run a mutation, you first call `useCreateSiteSafetyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteSafetyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteSafetyItemMutation, { data, loading, error }] = useCreateSiteSafetyItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteSafetyItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteSafetyItemMutation, CreateSiteSafetyItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteSafetyItemMutation, CreateSiteSafetyItemMutationVariables>(CreateSiteSafetyItemDocument, baseOptions);
      }
export type CreateSiteSafetyItemMutationHookResult = ReturnType<typeof useCreateSiteSafetyItemMutation>;
export type CreateSiteSafetyItemMutationResult = ApolloReactCommon.MutationResult<CreateSiteSafetyItemMutation>;
export type CreateSiteSafetyItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteSafetyItemMutation, CreateSiteSafetyItemMutationVariables>;
export const UpdateSiteSafetyItemDocument = gql`
    mutation updateSiteSafetyItem($input: SiteSafetyItemInput!) {
  createSiteSafetyItem(input: $input) {
    ID
    siteSafetyID
  }
}
    `;
export type UpdateSiteSafetyItemMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteSafetyItemMutation, UpdateSiteSafetyItemMutationVariables>;

/**
 * __useUpdateSiteSafetyItemMutation__
 *
 * To run a mutation, you first call `useUpdateSiteSafetyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteSafetyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteSafetyItemMutation, { data, loading, error }] = useUpdateSiteSafetyItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteSafetyItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteSafetyItemMutation, UpdateSiteSafetyItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteSafetyItemMutation, UpdateSiteSafetyItemMutationVariables>(UpdateSiteSafetyItemDocument, baseOptions);
      }
export type UpdateSiteSafetyItemMutationHookResult = ReturnType<typeof useUpdateSiteSafetyItemMutation>;
export type UpdateSiteSafetyItemMutationResult = ApolloReactCommon.MutationResult<UpdateSiteSafetyItemMutation>;
export type UpdateSiteSafetyItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteSafetyItemMutation, UpdateSiteSafetyItemMutationVariables>;
export const GetSiteImageDocument = gql`
    query getSiteImage($ID: String, $orderByDesc: String, $siteID: String) {
  getSiteImage(ID: $ID, siteID: $siteID, orderByDesc: $orderByDesc) {
    ID
    siteID
    title
    remark
    createdTs
  }
}
    `;

/**
 * __useGetSiteImageQuery__
 *
 * To run a query within a React component, call `useGetSiteImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteImageQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteImageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteImageQuery, GetSiteImageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteImageQuery, GetSiteImageQueryVariables>(GetSiteImageDocument, baseOptions);
      }
export function useGetSiteImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteImageQuery, GetSiteImageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteImageQuery, GetSiteImageQueryVariables>(GetSiteImageDocument, baseOptions);
        }
export type GetSiteImageQueryHookResult = ReturnType<typeof useGetSiteImageQuery>;
export type GetSiteImageLazyQueryHookResult = ReturnType<typeof useGetSiteImageLazyQuery>;
export type GetSiteImageQueryResult = ApolloReactCommon.QueryResult<GetSiteImageQuery, GetSiteImageQueryVariables>;
export const SiteImageListingDocument = gql`
    query siteImageListing($refID: String, $refTable: String) {
  DocumentListing(refID: $refID, refTable: $refTable) {
    fileURL
    refID
    mediaType
  }
}
    `;

/**
 * __useSiteImageListingQuery__
 *
 * To run a query within a React component, call `useSiteImageListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteImageListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteImageListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useSiteImageListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
      }
export function useSiteImageListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
        }
export type SiteImageListingQueryHookResult = ReturnType<typeof useSiteImageListingQuery>;
export type SiteImageListingLazyQueryHookResult = ReturnType<typeof useSiteImageListingLazyQuery>;
export type SiteImageListingQueryResult = ApolloReactCommon.QueryResult<SiteImageListingQuery, SiteImageListingQueryVariables>;
export const CreateSiteImageDocument = gql`
    mutation createSiteImage($input: SiteImageInput!) {
  createSiteImage(input: $input) {
    ID
    title
    remark
    image
  }
}
    `;
export type CreateSiteImageMutationFn = ApolloReactCommon.MutationFunction<CreateSiteImageMutation, CreateSiteImageMutationVariables>;

/**
 * __useCreateSiteImageMutation__
 *
 * To run a mutation, you first call `useCreateSiteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteImageMutation, { data, loading, error }] = useCreateSiteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteImageMutation, CreateSiteImageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteImageMutation, CreateSiteImageMutationVariables>(CreateSiteImageDocument, baseOptions);
      }
export type CreateSiteImageMutationHookResult = ReturnType<typeof useCreateSiteImageMutation>;
export type CreateSiteImageMutationResult = ApolloReactCommon.MutationResult<CreateSiteImageMutation>;
export type CreateSiteImageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteImageMutation, CreateSiteImageMutationVariables>;
export const UpdateSiteImageDocument = gql`
    mutation updateSiteImage($input: SiteImageInput!) {
  updateSiteImage(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteImageMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteImageMutation, UpdateSiteImageMutationVariables>;

/**
 * __useUpdateSiteImageMutation__
 *
 * To run a mutation, you first call `useUpdateSiteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteImageMutation, { data, loading, error }] = useUpdateSiteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteImageMutation, UpdateSiteImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteImageMutation, UpdateSiteImageMutationVariables>(UpdateSiteImageDocument, baseOptions);
      }
export type UpdateSiteImageMutationHookResult = ReturnType<typeof useUpdateSiteImageMutation>;
export type UpdateSiteImageMutationResult = ApolloReactCommon.MutationResult<UpdateSiteImageMutation>;
export type UpdateSiteImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteImageMutation, UpdateSiteImageMutationVariables>;
export const DeleteSiteImageDocument = gql`
    mutation deleteSiteImage($input: SiteImageDeleteInput!) {
  deleteSiteImage(input: $input)
}
    `;
export type DeleteSiteImageMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteImageMutation, DeleteSiteImageMutationVariables>;

/**
 * __useDeleteSiteImageMutation__
 *
 * To run a mutation, you first call `useDeleteSiteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteImageMutation, { data, loading, error }] = useDeleteSiteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteImageMutation, DeleteSiteImageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteImageMutation, DeleteSiteImageMutationVariables>(DeleteSiteImageDocument, baseOptions);
      }
export type DeleteSiteImageMutationHookResult = ReturnType<typeof useDeleteSiteImageMutation>;
export type DeleteSiteImageMutationResult = ApolloReactCommon.MutationResult<DeleteSiteImageMutation>;
export type DeleteSiteImageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteImageMutation, DeleteSiteImageMutationVariables>;
export const CancelSiteImageDocument = gql`
    mutation cancelSiteImage($input: SiteImageDeleteInput!) {
  cancelSiteImage(input: $input)
}
    `;
export type CancelSiteImageMutationFn = ApolloReactCommon.MutationFunction<CancelSiteImageMutation, CancelSiteImageMutationVariables>;

/**
 * __useCancelSiteImageMutation__
 *
 * To run a mutation, you first call `useCancelSiteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSiteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSiteImageMutation, { data, loading, error }] = useCancelSiteImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSiteImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSiteImageMutation, CancelSiteImageMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSiteImageMutation, CancelSiteImageMutationVariables>(CancelSiteImageDocument, baseOptions);
      }
export type CancelSiteImageMutationHookResult = ReturnType<typeof useCancelSiteImageMutation>;
export type CancelSiteImageMutationResult = ApolloReactCommon.MutationResult<CancelSiteImageMutation>;
export type CancelSiteImageMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSiteImageMutation, CancelSiteImageMutationVariables>;
export const GetSiteSurveillanceSummaryDocument = gql`
    query getSiteSurveillanceSummary($siteID: String) {
  totalSiteCount
  siteImagesCount(siteID: $siteID)
  videoCamCount(siteID: $siteID)
}
    `;

/**
 * __useGetSiteSurveillanceSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteSurveillanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSurveillanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSurveillanceSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteSurveillanceSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSurveillanceSummaryQuery, GetSiteSurveillanceSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSurveillanceSummaryQuery, GetSiteSurveillanceSummaryQueryVariables>(GetSiteSurveillanceSummaryDocument, baseOptions);
      }
export function useGetSiteSurveillanceSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSurveillanceSummaryQuery, GetSiteSurveillanceSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSurveillanceSummaryQuery, GetSiteSurveillanceSummaryQueryVariables>(GetSiteSurveillanceSummaryDocument, baseOptions);
        }
export type GetSiteSurveillanceSummaryQueryHookResult = ReturnType<typeof useGetSiteSurveillanceSummaryQuery>;
export type GetSiteSurveillanceSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteSurveillanceSummaryLazyQuery>;
export type GetSiteSurveillanceSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteSurveillanceSummaryQuery, GetSiteSurveillanceSummaryQueryVariables>;
export const GetWorkerWageVerificationDocument = gql`
    query getWorkerWageVerification($ID: String, $siteID: String, $workDate: DateTime) {
  getWorkerWageVerification(ID: $ID, siteID: $siteID, workDate: $workDate) {
    ID
    verifiedHour
    workDate
    amount
    createdBy
    modBy
    workHour
    remarks
    workerWageHours
    workerWageAmount(workerDate: $workDate)
    worker {
      ID
      wageRate
      nationality
      attachment {
        fileURL
      }
      wageType
      contact {
        name
      }
      jobType {
        ID
        name
      }
    }
    workerAttendance {
      timeIn
      timeOut
    }
  }
}
    `;

/**
 * __useGetWorkerWageVerificationQuery__
 *
 * To run a query within a React component, call `useGetWorkerWageVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerWageVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerWageVerificationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      workDate: // value for 'workDate'
 *   },
 * });
 */
export function useGetWorkerWageVerificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerWageVerificationQuery, GetWorkerWageVerificationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerWageVerificationQuery, GetWorkerWageVerificationQueryVariables>(GetWorkerWageVerificationDocument, baseOptions);
      }
export function useGetWorkerWageVerificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerWageVerificationQuery, GetWorkerWageVerificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerWageVerificationQuery, GetWorkerWageVerificationQueryVariables>(GetWorkerWageVerificationDocument, baseOptions);
        }
export type GetWorkerWageVerificationQueryHookResult = ReturnType<typeof useGetWorkerWageVerificationQuery>;
export type GetWorkerWageVerificationLazyQueryHookResult = ReturnType<typeof useGetWorkerWageVerificationLazyQuery>;
export type GetWorkerWageVerificationQueryResult = ApolloReactCommon.QueryResult<GetWorkerWageVerificationQuery, GetWorkerWageVerificationQueryVariables>;
export const GetWorkerWageDocument = gql`
    query getWorkerWage($ID: String, $siteID: String, $workDate: DateTime) {
  getWorkerWage(ID: $ID, siteID: $siteID, workDate: $workDate) {
    ID
    verifiedHour
    workDate
    amount
    modBy
    workHour
    remarks
    workerWageHours
    wageStatus
    worker {
      ID
      nationality
      attachment {
        fileURL
      }
      wageType
      contact {
        name
      }
      jobType {
        ID
        name
      }
    }
    workerAttendance {
      timeIn
      timeOut
    }
  }
}
    `;

/**
 * __useGetWorkerWageQuery__
 *
 * To run a query within a React component, call `useGetWorkerWageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerWageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerWageQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      workDate: // value for 'workDate'
 *   },
 * });
 */
export function useGetWorkerWageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerWageQuery, GetWorkerWageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerWageQuery, GetWorkerWageQueryVariables>(GetWorkerWageDocument, baseOptions);
      }
export function useGetWorkerWageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerWageQuery, GetWorkerWageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerWageQuery, GetWorkerWageQueryVariables>(GetWorkerWageDocument, baseOptions);
        }
export type GetWorkerWageQueryHookResult = ReturnType<typeof useGetWorkerWageQuery>;
export type GetWorkerWageLazyQueryHookResult = ReturnType<typeof useGetWorkerWageLazyQuery>;
export type GetWorkerWageQueryResult = ApolloReactCommon.QueryResult<GetWorkerWageQuery, GetWorkerWageQueryVariables>;
export const UpdateWorkerVerifiedHourDocument = gql`
    mutation updateWorkerVerifiedHour($workerRateIDs: [WorkerHourInput!]!) {
  updateWorkerVerifiedHour(workerRateIDs: $workerRateIDs)
}
    `;
export type UpdateWorkerVerifiedHourMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkerVerifiedHourMutation, UpdateWorkerVerifiedHourMutationVariables>;

/**
 * __useUpdateWorkerVerifiedHourMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerVerifiedHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerVerifiedHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerVerifiedHourMutation, { data, loading, error }] = useUpdateWorkerVerifiedHourMutation({
 *   variables: {
 *      workerRateIDs: // value for 'workerRateIDs'
 *   },
 * });
 */
export function useUpdateWorkerVerifiedHourMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkerVerifiedHourMutation, UpdateWorkerVerifiedHourMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkerVerifiedHourMutation, UpdateWorkerVerifiedHourMutationVariables>(UpdateWorkerVerifiedHourDocument, baseOptions);
      }
export type UpdateWorkerVerifiedHourMutationHookResult = ReturnType<typeof useUpdateWorkerVerifiedHourMutation>;
export type UpdateWorkerVerifiedHourMutationResult = ApolloReactCommon.MutationResult<UpdateWorkerVerifiedHourMutation>;
export type UpdateWorkerVerifiedHourMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkerVerifiedHourMutation, UpdateWorkerVerifiedHourMutationVariables>;
export const GetSiteWagesSummaryDocument = gql`
    query getSiteWagesSummary($siteID: String) {
  totalSiteCount
  getDailyRegisteredWorkHours(siteID: $siteID)
  getDailyWagesVerification(siteID: $siteID)
  getWagesBook(siteID: $siteID)
  wageCycleClosureInfo(siteID: $siteID)
  workerAttendanceCount(siteID: $siteID)
}
    `;

/**
 * __useGetSiteWagesSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteWagesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWagesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWagesSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteWagesSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWagesSummaryQuery, GetSiteWagesSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWagesSummaryQuery, GetSiteWagesSummaryQueryVariables>(GetSiteWagesSummaryDocument, baseOptions);
      }
export function useGetSiteWagesSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWagesSummaryQuery, GetSiteWagesSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWagesSummaryQuery, GetSiteWagesSummaryQueryVariables>(GetSiteWagesSummaryDocument, baseOptions);
        }
export type GetSiteWagesSummaryQueryHookResult = ReturnType<typeof useGetSiteWagesSummaryQuery>;
export type GetSiteWagesSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteWagesSummaryLazyQuery>;
export type GetSiteWagesSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteWagesSummaryQuery, GetSiteWagesSummaryQueryVariables>;
export const CreateWageCycleDocument = gql`
    mutation createWageCycle($input: WageCycleInput!, $workerWageCycleIDs: [WorkerWageCycleInput!]!) {
  createWageCycle(input: $input, workerWageCycleIDs: $workerWageCycleIDs)
}
    `;
export type CreateWageCycleMutationFn = ApolloReactCommon.MutationFunction<CreateWageCycleMutation, CreateWageCycleMutationVariables>;

/**
 * __useCreateWageCycleMutation__
 *
 * To run a mutation, you first call `useCreateWageCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWageCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWageCycleMutation, { data, loading, error }] = useCreateWageCycleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      workerWageCycleIDs: // value for 'workerWageCycleIDs'
 *   },
 * });
 */
export function useCreateWageCycleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWageCycleMutation, CreateWageCycleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWageCycleMutation, CreateWageCycleMutationVariables>(CreateWageCycleDocument, baseOptions);
      }
export type CreateWageCycleMutationHookResult = ReturnType<typeof useCreateWageCycleMutation>;
export type CreateWageCycleMutationResult = ApolloReactCommon.MutationResult<CreateWageCycleMutation>;
export type CreateWageCycleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWageCycleMutation, CreateWageCycleMutationVariables>;
export const GetWageCycleDocument = gql`
    query getWageCycle($siteID: String, $docDate: String, $ID: String) {
  getWageCycle(siteID: $siteID, docDate: $docDate, ID: $ID) {
    ID
    docDate
    createdTs
    createdBy
    closedBy
    modBy
    wageCycleDetail {
      ID
      wageAmt
      worker {
        ID
        wageType
        jobType {
          name
        }
        workerWage {
          verifiedHour
          amount
        }
        workerAdvance {
          amount
        }
        contact {
          name
        }
        attachment {
          fileURL
        }
      }
    }
  }
}
    `;

/**
 * __useGetWageCycleQuery__
 *
 * To run a query within a React component, call `useGetWageCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWageCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWageCycleQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetWageCycleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWageCycleQuery, GetWageCycleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWageCycleQuery, GetWageCycleQueryVariables>(GetWageCycleDocument, baseOptions);
      }
export function useGetWageCycleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWageCycleQuery, GetWageCycleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWageCycleQuery, GetWageCycleQueryVariables>(GetWageCycleDocument, baseOptions);
        }
export type GetWageCycleQueryHookResult = ReturnType<typeof useGetWageCycleQuery>;
export type GetWageCycleLazyQueryHookResult = ReturnType<typeof useGetWageCycleLazyQuery>;
export type GetWageCycleQueryResult = ApolloReactCommon.QueryResult<GetWageCycleQuery, GetWageCycleQueryVariables>;
export const GetCreatedbyNameDocument = gql`
    query getCreatedbyName($userIDs: [String!]!) {
  getUsersbyIds(userIDs: $userIDs) {
    ID
    name
  }
}
    `;

/**
 * __useGetCreatedbyNameQuery__
 *
 * To run a query within a React component, call `useGetCreatedbyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreatedbyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreatedbyNameQuery({
 *   variables: {
 *      userIDs: // value for 'userIDs'
 *   },
 * });
 */
export function useGetCreatedbyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreatedbyNameQuery, GetCreatedbyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreatedbyNameQuery, GetCreatedbyNameQueryVariables>(GetCreatedbyNameDocument, baseOptions);
      }
export function useGetCreatedbyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreatedbyNameQuery, GetCreatedbyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreatedbyNameQuery, GetCreatedbyNameQueryVariables>(GetCreatedbyNameDocument, baseOptions);
        }
export type GetCreatedbyNameQueryHookResult = ReturnType<typeof useGetCreatedbyNameQuery>;
export type GetCreatedbyNameLazyQueryHookResult = ReturnType<typeof useGetCreatedbyNameLazyQuery>;
export type GetCreatedbyNameQueryResult = ApolloReactCommon.QueryResult<GetCreatedbyNameQuery, GetCreatedbyNameQueryVariables>;
export const GetWorkerCycleDetailDocument = gql`
    query getWorkerCycleDetail($workerID: String!, $wageCycleID: String!) {
  getWorker(ID: $workerID) {
    ID
    contactID
    jobTypeID
    wageRate
    nationality
    contact {
      ID
      name
    }
    jobType {
      ID
      name
    }
    attachment {
      fileURL
    }
    workerWage {
      wageCycleID
      verifiedHour
      amount
    }
    workerAdvance {
      wageCycleID
      amount
    }
  }
  getWorkerCycleDetail(workerID: $workerID, wageCycleID: $wageCycleID)
}
    `;

/**
 * __useGetWorkerCycleDetailQuery__
 *
 * To run a query within a React component, call `useGetWorkerCycleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerCycleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerCycleDetailQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *      wageCycleID: // value for 'wageCycleID'
 *   },
 * });
 */
export function useGetWorkerCycleDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerCycleDetailQuery, GetWorkerCycleDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerCycleDetailQuery, GetWorkerCycleDetailQueryVariables>(GetWorkerCycleDetailDocument, baseOptions);
      }
export function useGetWorkerCycleDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerCycleDetailQuery, GetWorkerCycleDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerCycleDetailQuery, GetWorkerCycleDetailQueryVariables>(GetWorkerCycleDetailDocument, baseOptions);
        }
export type GetWorkerCycleDetailQueryHookResult = ReturnType<typeof useGetWorkerCycleDetailQuery>;
export type GetWorkerCycleDetailLazyQueryHookResult = ReturnType<typeof useGetWorkerCycleDetailLazyQuery>;
export type GetWorkerCycleDetailQueryResult = ApolloReactCommon.QueryResult<GetWorkerCycleDetailQuery, GetWorkerCycleDetailQueryVariables>;
export const GetUnpaidWageAdvanceDocument = gql`
    query getUnpaidWageAdvance($workerID: String!) {
  getUnpaidWageAdvance(workerID: $workerID)
}
    `;

/**
 * __useGetUnpaidWageAdvanceQuery__
 *
 * To run a query within a React component, call `useGetUnpaidWageAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidWageAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidWageAdvanceQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *   },
 * });
 */
export function useGetUnpaidWageAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnpaidWageAdvanceQuery, GetUnpaidWageAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnpaidWageAdvanceQuery, GetUnpaidWageAdvanceQueryVariables>(GetUnpaidWageAdvanceDocument, baseOptions);
      }
export function useGetUnpaidWageAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnpaidWageAdvanceQuery, GetUnpaidWageAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnpaidWageAdvanceQuery, GetUnpaidWageAdvanceQueryVariables>(GetUnpaidWageAdvanceDocument, baseOptions);
        }
export type GetUnpaidWageAdvanceQueryHookResult = ReturnType<typeof useGetUnpaidWageAdvanceQuery>;
export type GetUnpaidWageAdvanceLazyQueryHookResult = ReturnType<typeof useGetUnpaidWageAdvanceLazyQuery>;
export type GetUnpaidWageAdvanceQueryResult = ApolloReactCommon.QueryResult<GetUnpaidWageAdvanceQuery, GetUnpaidWageAdvanceQueryVariables>;
export const GetPaidWorkerDocument = gql`
    query getPaidWorker($siteID: String!, $wageCycleID: String!) {
  getPaidWorker(siteID: $siteID, wageCycleID: $wageCycleID) {
    ID
    wageRate
    wageType
    nationality
    contact {
      name
    }
    jobType {
      name
    }
    workerWage {
      verifiedHour
      amount
      wageCycleID
    }
    workerAdvance {
      amount
      wageCycleID
    }
    attachment {
      fileURL
    }
  }
}
    `;

/**
 * __useGetPaidWorkerQuery__
 *
 * To run a query within a React component, call `useGetPaidWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidWorkerQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      wageCycleID: // value for 'wageCycleID'
 *   },
 * });
 */
export function useGetPaidWorkerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaidWorkerQuery, GetPaidWorkerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaidWorkerQuery, GetPaidWorkerQueryVariables>(GetPaidWorkerDocument, baseOptions);
      }
export function useGetPaidWorkerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaidWorkerQuery, GetPaidWorkerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaidWorkerQuery, GetPaidWorkerQueryVariables>(GetPaidWorkerDocument, baseOptions);
        }
export type GetPaidWorkerQueryHookResult = ReturnType<typeof useGetPaidWorkerQuery>;
export type GetPaidWorkerLazyQueryHookResult = ReturnType<typeof useGetPaidWorkerLazyQuery>;
export type GetPaidWorkerQueryResult = ApolloReactCommon.QueryResult<GetPaidWorkerQuery, GetPaidWorkerQueryVariables>;
export const GetUnpaidWorkerJsonDocument = gql`
    query getUnpaidWorkerJson($siteID: String!) {
  getUnpaidWorkerJson(siteID: $siteID)
}
    `;

/**
 * __useGetUnpaidWorkerJsonQuery__
 *
 * To run a query within a React component, call `useGetUnpaidWorkerJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidWorkerJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidWorkerJsonQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetUnpaidWorkerJsonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnpaidWorkerJsonQuery, GetUnpaidWorkerJsonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnpaidWorkerJsonQuery, GetUnpaidWorkerJsonQueryVariables>(GetUnpaidWorkerJsonDocument, baseOptions);
      }
export function useGetUnpaidWorkerJsonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnpaidWorkerJsonQuery, GetUnpaidWorkerJsonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnpaidWorkerJsonQuery, GetUnpaidWorkerJsonQueryVariables>(GetUnpaidWorkerJsonDocument, baseOptions);
        }
export type GetUnpaidWorkerJsonQueryHookResult = ReturnType<typeof useGetUnpaidWorkerJsonQuery>;
export type GetUnpaidWorkerJsonLazyQueryHookResult = ReturnType<typeof useGetUnpaidWorkerJsonLazyQuery>;
export type GetUnpaidWorkerJsonQueryResult = ApolloReactCommon.QueryResult<GetUnpaidWorkerJsonQuery, GetUnpaidWorkerJsonQueryVariables>;
export const GetUnpaidWorkerDocument = gql`
    query getUnpaidWorker($siteID: String!) {
  getUnpaidWorker(siteID: $siteID) {
    ID
    wageType
    contact {
      name
    }
    jobType {
      name
    }
    workerWage {
      verifiedHour
      amount
      wageCycleID
    }
    workerAdvance {
      amount
      wageCycleID
    }
    attachment {
      fileURL
    }
  }
}
    `;

/**
 * __useGetUnpaidWorkerQuery__
 *
 * To run a query within a React component, call `useGetUnpaidWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidWorkerQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetUnpaidWorkerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnpaidWorkerQuery, GetUnpaidWorkerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnpaidWorkerQuery, GetUnpaidWorkerQueryVariables>(GetUnpaidWorkerDocument, baseOptions);
      }
export function useGetUnpaidWorkerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnpaidWorkerQuery, GetUnpaidWorkerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnpaidWorkerQuery, GetUnpaidWorkerQueryVariables>(GetUnpaidWorkerDocument, baseOptions);
        }
export type GetUnpaidWorkerQueryHookResult = ReturnType<typeof useGetUnpaidWorkerQuery>;
export type GetUnpaidWorkerLazyQueryHookResult = ReturnType<typeof useGetUnpaidWorkerLazyQuery>;
export type GetUnpaidWorkerQueryResult = ApolloReactCommon.QueryResult<GetUnpaidWorkerQuery, GetUnpaidWorkerQueryVariables>;
export const GetWageCycleSummaryDocument = gql`
    query getWageCycleSummary($siteID: String, $ID: String) {
  getWageCycle(siteID: $siteID, ID: $ID) {
    ID
    createdBy
    docDate
  }
}
    `;

/**
 * __useGetWageCycleSummaryQuery__
 *
 * To run a query within a React component, call `useGetWageCycleSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWageCycleSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWageCycleSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetWageCycleSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWageCycleSummaryQuery, GetWageCycleSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWageCycleSummaryQuery, GetWageCycleSummaryQueryVariables>(GetWageCycleSummaryDocument, baseOptions);
      }
export function useGetWageCycleSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWageCycleSummaryQuery, GetWageCycleSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWageCycleSummaryQuery, GetWageCycleSummaryQueryVariables>(GetWageCycleSummaryDocument, baseOptions);
        }
export type GetWageCycleSummaryQueryHookResult = ReturnType<typeof useGetWageCycleSummaryQuery>;
export type GetWageCycleSummaryLazyQueryHookResult = ReturnType<typeof useGetWageCycleSummaryLazyQuery>;
export type GetWageCycleSummaryQueryResult = ApolloReactCommon.QueryResult<GetWageCycleSummaryQuery, GetWageCycleSummaryQueryVariables>;
export const GetWagesBookDocument = gql`
    query getWagesBook($ID: String, $orderByDesc: String) {
  getWorker(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    wageType
    wageRate
    nationality
    contact {
      name
    }
    jobType {
      name
    }
    workerWage {
      verifiedHour
      amount
    }
    workerAdvance {
      amount
    }
    attachment {
      fileURL
    }
  }
}
    `;

/**
 * __useGetWagesBookQuery__
 *
 * To run a query within a React component, call `useGetWagesBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWagesBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWagesBookQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetWagesBookQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWagesBookQuery, GetWagesBookQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWagesBookQuery, GetWagesBookQueryVariables>(GetWagesBookDocument, baseOptions);
      }
export function useGetWagesBookLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWagesBookQuery, GetWagesBookQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWagesBookQuery, GetWagesBookQueryVariables>(GetWagesBookDocument, baseOptions);
        }
export type GetWagesBookQueryHookResult = ReturnType<typeof useGetWagesBookQuery>;
export type GetWagesBookLazyQueryHookResult = ReturnType<typeof useGetWagesBookLazyQuery>;
export type GetWagesBookQueryResult = ApolloReactCommon.QueryResult<GetWagesBookQuery, GetWagesBookQueryVariables>;
export const GetWorkerWageAdvanceDocument = gql`
    query getWorkerWageAdvance($workerID: String!) {
  getWorkerWageAdvance(workerID: $workerID)
}
    `;

/**
 * __useGetWorkerWageAdvanceQuery__
 *
 * To run a query within a React component, call `useGetWorkerWageAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerWageAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerWageAdvanceQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *   },
 * });
 */
export function useGetWorkerWageAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerWageAdvanceQuery, GetWorkerWageAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerWageAdvanceQuery, GetWorkerWageAdvanceQueryVariables>(GetWorkerWageAdvanceDocument, baseOptions);
      }
export function useGetWorkerWageAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerWageAdvanceQuery, GetWorkerWageAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerWageAdvanceQuery, GetWorkerWageAdvanceQueryVariables>(GetWorkerWageAdvanceDocument, baseOptions);
        }
export type GetWorkerWageAdvanceQueryHookResult = ReturnType<typeof useGetWorkerWageAdvanceQuery>;
export type GetWorkerWageAdvanceLazyQueryHookResult = ReturnType<typeof useGetWorkerWageAdvanceLazyQuery>;
export type GetWorkerWageAdvanceQueryResult = ApolloReactCommon.QueryResult<GetWorkerWageAdvanceQuery, GetWorkerWageAdvanceQueryVariables>;
export const CreateWorkerAdvanceDocument = gql`
    mutation createWorkerAdvance($input: WorkerAdvanceInput!) {
  createWorkerAdvance(input: $input) {
    ID
    workerID
    amount
    docDate
    description
    createdBy
    modBy
  }
}
    `;
export type CreateWorkerAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateWorkerAdvanceMutation, CreateWorkerAdvanceMutationVariables>;

/**
 * __useCreateWorkerAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateWorkerAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerAdvanceMutation, { data, loading, error }] = useCreateWorkerAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkerAdvanceMutation, CreateWorkerAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkerAdvanceMutation, CreateWorkerAdvanceMutationVariables>(CreateWorkerAdvanceDocument, baseOptions);
      }
export type CreateWorkerAdvanceMutationHookResult = ReturnType<typeof useCreateWorkerAdvanceMutation>;
export type CreateWorkerAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateWorkerAdvanceMutation>;
export type CreateWorkerAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkerAdvanceMutation, CreateWorkerAdvanceMutationVariables>;
export const CreateWorkerWageDocument = gql`
    mutation createWorkerWage($input: WorkerWageInput!) {
  createWorkerWage(input: $input) {
    ID
    workerID
    siteID
    amount
    workHour
    verifiedHour
    workDate
    createdBy
    modBy
  }
}
    `;
export type CreateWorkerWageMutationFn = ApolloReactCommon.MutationFunction<CreateWorkerWageMutation, CreateWorkerWageMutationVariables>;

/**
 * __useCreateWorkerWageMutation__
 *
 * To run a mutation, you first call `useCreateWorkerWageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerWageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerWageMutation, { data, loading, error }] = useCreateWorkerWageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerWageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkerWageMutation, CreateWorkerWageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkerWageMutation, CreateWorkerWageMutationVariables>(CreateWorkerWageDocument, baseOptions);
      }
export type CreateWorkerWageMutationHookResult = ReturnType<typeof useCreateWorkerWageMutation>;
export type CreateWorkerWageMutationResult = ApolloReactCommon.MutationResult<CreateWorkerWageMutation>;
export type CreateWorkerWageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkerWageMutation, CreateWorkerWageMutationVariables>;
export const GetWorkerWageBookDocument = gql`
    query getWorkerWageBook($siteID: String) {
  getWorkerWageBook(siteID: $siteID) {
    ID
    wageType
    createdBy
    wageRate
    nationality
    contact {
      name
    }
    jobType {
      name
    }
    workerWage {
      verifiedHour
      amount
    }
    workerAdvance {
      amount
    }
    attachment {
      fileURL
    }
  }
}
    `;

/**
 * __useGetWorkerWageBookQuery__
 *
 * To run a query within a React component, call `useGetWorkerWageBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerWageBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerWageBookQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetWorkerWageBookQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerWageBookQuery, GetWorkerWageBookQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerWageBookQuery, GetWorkerWageBookQueryVariables>(GetWorkerWageBookDocument, baseOptions);
      }
export function useGetWorkerWageBookLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerWageBookQuery, GetWorkerWageBookQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerWageBookQuery, GetWorkerWageBookQueryVariables>(GetWorkerWageBookDocument, baseOptions);
        }
export type GetWorkerWageBookQueryHookResult = ReturnType<typeof useGetWorkerWageBookQuery>;
export type GetWorkerWageBookLazyQueryHookResult = ReturnType<typeof useGetWorkerWageBookLazyQuery>;
export type GetWorkerWageBookQueryResult = ApolloReactCommon.QueryResult<GetWorkerWageBookQuery, GetWorkerWageBookQueryVariables>;
export const GetWorkerSalaryDocument = gql`
    query getWorkerSalary($workerWageType: String, $workerWageRate: Float, $workDate: DateTime, $workHour: Float, $siteID: String) {
  getWorkerSalary(workerWageType: $workerWageType, workerWageRate: $workerWageRate, workDate: $workDate, workHour: $workHour, siteID: $siteID)
}
    `;

/**
 * __useGetWorkerSalaryQuery__
 *
 * To run a query within a React component, call `useGetWorkerSalaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerSalaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerSalaryQuery({
 *   variables: {
 *      workerWageType: // value for 'workerWageType'
 *      workerWageRate: // value for 'workerWageRate'
 *      workDate: // value for 'workDate'
 *      workHour: // value for 'workHour'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetWorkerSalaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerSalaryQuery, GetWorkerSalaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerSalaryQuery, GetWorkerSalaryQueryVariables>(GetWorkerSalaryDocument, baseOptions);
      }
export function useGetWorkerSalaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerSalaryQuery, GetWorkerSalaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerSalaryQuery, GetWorkerSalaryQueryVariables>(GetWorkerSalaryDocument, baseOptions);
        }
export type GetWorkerSalaryQueryHookResult = ReturnType<typeof useGetWorkerSalaryQuery>;
export type GetWorkerSalaryLazyQueryHookResult = ReturnType<typeof useGetWorkerSalaryLazyQuery>;
export type GetWorkerSalaryQueryResult = ApolloReactCommon.QueryResult<GetWorkerSalaryQuery, GetWorkerSalaryQueryVariables>;
export const GetWorkerFromSiteDocument = gql`
    query getWorkerFromSite($siteID: String!) {
  getWorkerFromSite(siteID: $siteID) {
    ID
    commonStatus
    address
    wageRate
    wageType
    workPermitNo
    nationality
    identityNo
    docExpiryDate
    jobTypeID
    jobType {
      ID
      name
    }
    contact {
      name
      email
      contactNo
    }
    attachment {
      fileURL
      fileName
      mediaType
    }
    workerAttendance {
      timeIn
      timeOut
      workDate
    }
  }
}
    `;

/**
 * __useGetWorkerFromSiteQuery__
 *
 * To run a query within a React component, call `useGetWorkerFromSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerFromSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerFromSiteQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetWorkerFromSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerFromSiteQuery, GetWorkerFromSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerFromSiteQuery, GetWorkerFromSiteQueryVariables>(GetWorkerFromSiteDocument, baseOptions);
      }
export function useGetWorkerFromSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerFromSiteQuery, GetWorkerFromSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerFromSiteQuery, GetWorkerFromSiteQueryVariables>(GetWorkerFromSiteDocument, baseOptions);
        }
export type GetWorkerFromSiteQueryHookResult = ReturnType<typeof useGetWorkerFromSiteQuery>;
export type GetWorkerFromSiteLazyQueryHookResult = ReturnType<typeof useGetWorkerFromSiteLazyQuery>;
export type GetWorkerFromSiteQueryResult = ApolloReactCommon.QueryResult<GetWorkerFromSiteQuery, GetWorkerFromSiteQueryVariables>;
export const GetWorkerAttendanceDocument = gql`
    query getWorkerAttendance($ID: String, $orderByDesc: String, $siteID: String, $workDate: String) {
  getWorkerAttendance(ID: $ID, orderByDesc: $orderByDesc, siteID: $siteID, workDate: $workDate) {
    ID
    timeIn
    timeOut
    temperature
    workDate
    workerID
    worker {
      ID
      contact {
        name
      }
      jobType {
        name
      }
      attachment {
        fileURL
      }
    }
    attendanceSummary
  }
}
    `;

/**
 * __useGetWorkerAttendanceQuery__
 *
 * To run a query within a React component, call `useGetWorkerAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerAttendanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      siteID: // value for 'siteID'
 *      workDate: // value for 'workDate'
 *   },
 * });
 */
export function useGetWorkerAttendanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerAttendanceQuery, GetWorkerAttendanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerAttendanceQuery, GetWorkerAttendanceQueryVariables>(GetWorkerAttendanceDocument, baseOptions);
      }
export function useGetWorkerAttendanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerAttendanceQuery, GetWorkerAttendanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerAttendanceQuery, GetWorkerAttendanceQueryVariables>(GetWorkerAttendanceDocument, baseOptions);
        }
export type GetWorkerAttendanceQueryHookResult = ReturnType<typeof useGetWorkerAttendanceQuery>;
export type GetWorkerAttendanceLazyQueryHookResult = ReturnType<typeof useGetWorkerAttendanceLazyQuery>;
export type GetWorkerAttendanceQueryResult = ApolloReactCommon.QueryResult<GetWorkerAttendanceQuery, GetWorkerAttendanceQueryVariables>;
export const CreateWorkerAttendanceDocument = gql`
    mutation createWorkerAttendance($input: WorkerAttendanceInput!) {
  createWorkerAttendance(input: $input) {
    ID
    workerID
  }
}
    `;
export type CreateWorkerAttendanceMutationFn = ApolloReactCommon.MutationFunction<CreateWorkerAttendanceMutation, CreateWorkerAttendanceMutationVariables>;

/**
 * __useCreateWorkerAttendanceMutation__
 *
 * To run a mutation, you first call `useCreateWorkerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerAttendanceMutation, { data, loading, error }] = useCreateWorkerAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerAttendanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkerAttendanceMutation, CreateWorkerAttendanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkerAttendanceMutation, CreateWorkerAttendanceMutationVariables>(CreateWorkerAttendanceDocument, baseOptions);
      }
export type CreateWorkerAttendanceMutationHookResult = ReturnType<typeof useCreateWorkerAttendanceMutation>;
export type CreateWorkerAttendanceMutationResult = ApolloReactCommon.MutationResult<CreateWorkerAttendanceMutation>;
export type CreateWorkerAttendanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkerAttendanceMutation, CreateWorkerAttendanceMutationVariables>;
export const UpdateWorkerAttendanceDocument = gql`
    mutation updateWorkerAttendance($input: WorkerAttendanceInput!) {
  updateWorkerAttendance(input: $input) {
    ID
    workerID
  }
}
    `;
export type UpdateWorkerAttendanceMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkerAttendanceMutation, UpdateWorkerAttendanceMutationVariables>;

/**
 * __useUpdateWorkerAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerAttendanceMutation, { data, loading, error }] = useUpdateWorkerAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkerAttendanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkerAttendanceMutation, UpdateWorkerAttendanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkerAttendanceMutation, UpdateWorkerAttendanceMutationVariables>(UpdateWorkerAttendanceDocument, baseOptions);
      }
export type UpdateWorkerAttendanceMutationHookResult = ReturnType<typeof useUpdateWorkerAttendanceMutation>;
export type UpdateWorkerAttendanceMutationResult = ApolloReactCommon.MutationResult<UpdateWorkerAttendanceMutation>;
export type UpdateWorkerAttendanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkerAttendanceMutation, UpdateWorkerAttendanceMutationVariables>;
export const DeleteWorkerAttendanceDocument = gql`
    mutation deleteWorkerAttendance($input: WorkerAttendanceDeleteInput!) {
  deleteWorkerAttendance(input: $input)
}
    `;
export type DeleteWorkerAttendanceMutationFn = ApolloReactCommon.MutationFunction<DeleteWorkerAttendanceMutation, DeleteWorkerAttendanceMutationVariables>;

/**
 * __useDeleteWorkerAttendanceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerAttendanceMutation, { data, loading, error }] = useDeleteWorkerAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkerAttendanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWorkerAttendanceMutation, DeleteWorkerAttendanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWorkerAttendanceMutation, DeleteWorkerAttendanceMutationVariables>(DeleteWorkerAttendanceDocument, baseOptions);
      }
export type DeleteWorkerAttendanceMutationHookResult = ReturnType<typeof useDeleteWorkerAttendanceMutation>;
export type DeleteWorkerAttendanceMutationResult = ApolloReactCommon.MutationResult<DeleteWorkerAttendanceMutation>;
export type DeleteWorkerAttendanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWorkerAttendanceMutation, DeleteWorkerAttendanceMutationVariables>;
export const CreateBatchWorkerAttendanceDocument = gql`
    mutation createBatchWorkerAttendance($input: [batchWorkAttendanceInput!]!) {
  createBatchWorkerAttendance(input: $input)
}
    `;
export type CreateBatchWorkerAttendanceMutationFn = ApolloReactCommon.MutationFunction<CreateBatchWorkerAttendanceMutation, CreateBatchWorkerAttendanceMutationVariables>;

/**
 * __useCreateBatchWorkerAttendanceMutation__
 *
 * To run a mutation, you first call `useCreateBatchWorkerAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchWorkerAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchWorkerAttendanceMutation, { data, loading, error }] = useCreateBatchWorkerAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchWorkerAttendanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchWorkerAttendanceMutation, CreateBatchWorkerAttendanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchWorkerAttendanceMutation, CreateBatchWorkerAttendanceMutationVariables>(CreateBatchWorkerAttendanceDocument, baseOptions);
      }
export type CreateBatchWorkerAttendanceMutationHookResult = ReturnType<typeof useCreateBatchWorkerAttendanceMutation>;
export type CreateBatchWorkerAttendanceMutationResult = ApolloReactCommon.MutationResult<CreateBatchWorkerAttendanceMutation>;
export type CreateBatchWorkerAttendanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchWorkerAttendanceMutation, CreateBatchWorkerAttendanceMutationVariables>;
export const WorkerAttendanceValidationDocument = gql`
    mutation workerAttendanceValidation($siteID: String!, $siteWorkerAttendanceInput: [SiteWorkerAttendanceImportInput!]!) {
  workerAttendanceValidation(siteID: $siteID, siteWorkerAttendanceInput: $siteWorkerAttendanceInput)
}
    `;
export type WorkerAttendanceValidationMutationFn = ApolloReactCommon.MutationFunction<WorkerAttendanceValidationMutation, WorkerAttendanceValidationMutationVariables>;

/**
 * __useWorkerAttendanceValidationMutation__
 *
 * To run a mutation, you first call `useWorkerAttendanceValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerAttendanceValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerAttendanceValidationMutation, { data, loading, error }] = useWorkerAttendanceValidationMutation({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      siteWorkerAttendanceInput: // value for 'siteWorkerAttendanceInput'
 *   },
 * });
 */
export function useWorkerAttendanceValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WorkerAttendanceValidationMutation, WorkerAttendanceValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<WorkerAttendanceValidationMutation, WorkerAttendanceValidationMutationVariables>(WorkerAttendanceValidationDocument, baseOptions);
      }
export type WorkerAttendanceValidationMutationHookResult = ReturnType<typeof useWorkerAttendanceValidationMutation>;
export type WorkerAttendanceValidationMutationResult = ApolloReactCommon.MutationResult<WorkerAttendanceValidationMutation>;
export type WorkerAttendanceValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<WorkerAttendanceValidationMutation, WorkerAttendanceValidationMutationVariables>;
export const WorkerAttendanceImportDocument = gql`
    mutation workerAttendanceImport($batchID: String!) {
  workerAttendanceImport(batchID: $batchID)
}
    `;
export type WorkerAttendanceImportMutationFn = ApolloReactCommon.MutationFunction<WorkerAttendanceImportMutation, WorkerAttendanceImportMutationVariables>;

/**
 * __useWorkerAttendanceImportMutation__
 *
 * To run a mutation, you first call `useWorkerAttendanceImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerAttendanceImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerAttendanceImportMutation, { data, loading, error }] = useWorkerAttendanceImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useWorkerAttendanceImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WorkerAttendanceImportMutation, WorkerAttendanceImportMutationVariables>) {
        return ApolloReactHooks.useMutation<WorkerAttendanceImportMutation, WorkerAttendanceImportMutationVariables>(WorkerAttendanceImportDocument, baseOptions);
      }
export type WorkerAttendanceImportMutationHookResult = ReturnType<typeof useWorkerAttendanceImportMutation>;
export type WorkerAttendanceImportMutationResult = ApolloReactCommon.MutationResult<WorkerAttendanceImportMutation>;
export type WorkerAttendanceImportMutationOptions = ApolloReactCommon.BaseMutationOptions<WorkerAttendanceImportMutation, WorkerAttendanceImportMutationVariables>;
export const GetSubcontractorDocument = gql`
    query getSubcontractor($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    email
    accountID
    registrationNo
    associatedStatus
    contactNo
    creditTerm
    currencyID
    ratingType
    cidbDetails
    pkkDetails
    statusRemarks
    bankDetails
    gstRegNo
    description
    gstExpDate
    platformAccess
    sstRegNo
    address
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    createdTs
    createdBy
    modTs
    modBy
    description
    nominatedSC
    subconClaim {
      ID
      outstandingAmt
    }
    subconAdvance {
      ID
      outstandingAmt
      baseAmt
    }
    subcontract {
      ID
      title
      subcontractSum
    }
  }
}
    `;

/**
 * __useGetSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
      }
export function useGetSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
        }
export type GetSubcontractorQueryHookResult = ReturnType<typeof useGetSubcontractorQuery>;
export type GetSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLazyQuery>;
export type GetSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorQuery, GetSubcontractorQueryVariables>;